import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ViewedStoriesItem from './components/ViewedStoriesItem';

import browseStoriesIcon from '@images/icons/mydashboard/browse-stories.svg';
import browseStoriesReceived from '@images/icons/mydashboard/browse-stories-received.svg';
import reviewOffersIcon from '@images/icons/mydashboard/review-offers.svg';
import makeABidIcon from '@images/icons/mydashboard/make-a-bid.svg';
import viewAllIcon from '@images/icons/arrow-right.svg';
import filtersCardIcon from '@images/icons/mydashboard/filters-card.svg';
import notificationBellIcon from '@images/icons/mydashboard/notification-bell-icon.svg';

import TrendingStoriesItem from './components/TrendingStoriesItem';
import FiltersItem from './components/FiltersItem';
import { Link } from 'react-router-dom';

import { createStructuredSelector } from 'reselect';
import { selectRecentlyViewedStories, selectRecentlyViewedStoriesLoading, selectTrendingStories } from './selectors';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { requestGetRecentlyViewedStories, requestGetTrendingStories } from './reducer';
import { connect } from 'react-redux';
import { IndividualDashboardProps } from './types';
import { selectUserProfile } from '@app/containers/ProfileContainer/selectors';
import { If } from 'tsw-sdk';
import { requestGetSavedFilters } from './tabs/BrowseStories/reducer';
import { selectSavedFiltersList } from './tabs/BrowseStories/selectors';
import { PROFILE_IMAGE_PROD_URL, PROFILE_IMAGE_QA_URL, PROFILE_IMAGE_URL } from '@app/utils/constants';
import { selectAccountStatusData } from '@app/containers/HomeContainer/selectors';
import CustomEmpty from '@app/components/CustomEmpty';
import StyledSpin from '@app/components/StyledSpin';

const MyDashboardContainer = styled.div`
  width: 100%;
  min-height: 100%;
  height: auto;
  display: flex;
  background-color: ${colors.dashboardBg};
`;

const DashboardContentContainer = styled.div`
  width: calc(100% - 27rem);
  height: auto;
  padding: 4rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
`;

const FiltersSidebar = styled.div`
  width: 27rem;
  background-color: ${colors.white};
  padding: 2rem 1.87rem 1.87rem 1.87rem;
`;

const MyDashboardHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MyDashboardHeading = styled.h1`
  ${fonts.style.MyDashboardHeading()};
  color: ${colors.myDashboardColors.heading};
`;

const UserProfile = styled.div`
  height: 3.18rem;
  width: 3.18rem;
  border-radius: 100%;
  background-color: ${colors.myDashboardColors.userAvatar};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.white};
  user-select: none;
  cursor: pointer;
  margin-bottom: auto;
`;

const RecentlyViewedStoriesContainer = styled.div`
  width: 100%:
  margin-top: 2rem;
`;

const RecentlyViewedTitle = styled.h1`
  ${fonts.size.xBig()};
  ${fonts.weights.bold()};
`;

const LinksCardContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  margin-top: 1.9375rem;
  margin-bottom: 2rem;
  grid-gap: 2rem;
  justify-content: center;
  align-items: center;
  justify-items: center;
`;

const LinksCard = styled.div`
  width: 19.12rem;
  height: 16rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.5rem 2.6rem 2.6rem 2.6rem;
  box-shadow: 0px 0px 1.25rem 0px ${colors.myDashboardColors.cardShadow};
  background-color: ${colors.white};
  border-radius: 1rem;
  cursor: pointer;

  & img {
    width: 6rem;
    height: 6rem;
    margin: 0 auto;
  }
`;

const LinksCardTitle = styled.h1`
  width: 100%;
  ${fonts.weights.bold()}
  ${fonts.size.big()}
  color: ${colors.myDashboardColors.cardTitle};
  margin-top: 2.12rem;
  text-align: center;
`;

const TrendingStoriesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

const TrendingStoriesHeading = styled.h2`
  ${fonts.weights.bold()}
  ${fonts.size.xBig()}
  color: ${colors.myDashboardColors.storyName};
  margin-bottom: 1.5rem;
`;

const ViewAllLinkContainer = styled(Link)`
  height: auto;
  display: flex;
  align-items: center;
  color: ${colors.viewAllColor};
  margin-top: 1.875rem;
  cursor: pointer;
`;

const FilterItemLink = styled(Link)`
  height: auto;
  display: flex;
  align-items: center;
  color: ${colors.viewAllColor};
  cursor: pointer;
`;

const ViewAllText = styled.p`
  margin-right: 0.5rem;
  ${fonts.weights.bold()}
  ${fonts.size.small()}
`;

const FiltersContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
`;

const FiltersHeading = styled.h2`
  ${fonts.weights.bold()}
  ${fonts.size.xBig()}
  color: ${colors.myDashboardColors.storyName};
  margin-bottom: 0px;
`;

const SaveFiltersCard = styled.div`
  width: 100%;
  height: auto;
  border-radius: 1rem;
  display: flex;
  align-items: flex-start;
  padding: 1rem;
  margin-top: 3rem;
  cursor: pointer;
  box-shadow: 0px 0px 1.25rem 0px ${colors.myDashboardColors.cardShadow};
`;

const NotificationCard = styled.div`
  width: 100%;
  height: auto;
  border-radius: 1rem;
  box-shadow: 0px 0px 1.25rem 0px ${colors.myDashboardColors.cardShadow};
  display: flex;
  align-items: flex-start;
  padding: 1rem;
  cursor: pointer;
  position: relative;
`;

const NewNotificationPill = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  background-color: ${colors.notificationColors.notificationPillColor};
  position: absolute;
  top: -0.3rem;
  right: -0.3rem;
`;

const SaveFiltersContent = styled.div`
  width: 13.25rem;
  display: flex;
  flex-direction: column;
  margin-left: 2.12rem;
`;

const SaveFiltersTitle = styled.h1`
  width: 100%;
  ${fonts.weights.bold()}
  ${fonts.size.big()}
  line-height: 1.75rem;
  margin-bottom: 0px;
`;

const SaveFiltersMessage = styled.p`
  width: 100%;
  ${fonts.weights.fw400()}
  ${fonts.size.xRegular()}
  margin-top: 0.5rem;
  line-height: 1.5rem;
`;

const ProfilePic = styled.img`
  width: 3.18rem;
  height: 3.18rem;
  border-radius: 100%;
`;

const IndividualDashboard = ({
  recentlyViewedStories,
  trendingStories,
  savedFilters,
  userProfileData,
  accountStatusData,
  recentlyViewedStoriesLoading,
  dispatchGetRecentlyViewedStories,
  dispatchGetSavedFilters,
  dispatchGetTrendingStories
}: IndividualDashboardProps) => {
  const [profileUrlLocal, setProfileUrlLocal] = useState<string>('');

  useEffect(() => {
    if (userProfileData) {
      if (userProfileData?.profilePic) {
        if (process.env.ENVIRONMENT_NAME === 'qa') {
          setProfileUrlLocal(`${PROFILE_IMAGE_QA_URL}/${userProfileData?.profilePic}`);
        } else if (process.env.ENVIRONMENT_NAME === 'production') {
          setProfileUrlLocal(`${PROFILE_IMAGE_PROD_URL}/${userProfileData?.profilePic}`);
        } else {
          setProfileUrlLocal(`${PROFILE_IMAGE_URL}/${userProfileData?.profilePic}`);
        }
      }
      dispatchGetTrendingStories();

      if (userProfileData?.id) {
        dispatchGetRecentlyViewedStories(1, 5, userProfileData?.id);
      }
    }
  }, [userProfileData]);

  useEffect(() => {
    dispatchGetSavedFilters();
  }, []);

  return (
    <MyDashboardContainer data-testid="individual-dashboard-container">
      <DashboardContentContainer>
        <MyDashboardHeader>
          <MyDashboardHeading>{translate('my_dashboard')}</MyDashboardHeading>
          <If
            condition={profileUrlLocal !== ''}
            otherwise={
              <UserProfile>{`${userProfileData?.firstName?.split('')[0]}${
                userProfileData?.lastName?.split('')[0]
              }`}</UserProfile>
            }
          >
            <ProfilePic src={profileUrlLocal} />
          </If>
        </MyDashboardHeader>
        <RecentlyViewedStoriesContainer>
          <RecentlyViewedTitle>{translate('recently_viewed_stories')}</RecentlyViewedTitle>
          <If condition={!recentlyViewedStoriesLoading} otherwise={<StyledSpin />}>
            <If condition={recentlyViewedStories.length > 0} otherwise={<CustomEmpty text={'no_stories'} />}>
              {recentlyViewedStories?.map((recentlyViewedStory: any) => (
                <ViewedStoriesItem
                  key={recentlyViewedStory.id}
                  downloaded={recentlyViewedStory.buyerActions?.hasDownloaded}
                  reviewed={recentlyViewedStory.buyerActions?.hasReviewed}
                  offeredrejected={recentlyViewedStory.buyerActions?.hasOffered}
                  storyName={recentlyViewedStory?.title}
                  updatedAt={recentlyViewedStory?.updatedAt}
                  isNew={!recentlyViewedStory.buyerActions?.hasViewed}
                />
              ))}
            </If>
          </If>
        </RecentlyViewedStoriesContainer>
        <LinksCardContainer>
          <Link to="/dashboard?activeTab=browse_stories">
            <LinksCard>
              <img src={browseStoriesIcon} />
              <LinksCardTitle>{translate('browse_stories')}</LinksCardTitle>
            </LinksCard>
          </Link>
          <Link to="/dashboard?activeTab=stories_received">
            <LinksCard>
              <img src={browseStoriesReceived} />
              <LinksCardTitle>{translate('browse_stories_received')}</LinksCardTitle>
            </LinksCard>
          </Link>
          <Link to="/dashboard?activeTab=my_offers">
            <LinksCard>
              <img src={reviewOffersIcon} />
              <LinksCardTitle>{translate('review_offers')}</LinksCardTitle>
            </LinksCard>
          </Link>
          <Link to="/dashboard?activeTab=my_favourites">
            <LinksCard>
              <img src={makeABidIcon} />
              <LinksCardTitle>{translate('make_a_offer')}</LinksCardTitle>
            </LinksCard>
          </Link>
        </LinksCardContainer>
      </DashboardContentContainer>
      <FiltersSidebar>
        <ViewAllLinkContainer
          to={{
            pathname: '/dashboard',
            search: `?activeTab=my_notifications`
          }}
        >
          <NotificationCard>
            <If condition={accountStatusData?.unreadNotifications > 0}>
              <NewNotificationPill />
            </If>

            <img src={notificationBellIcon} />
            <SaveFiltersContent>
              <SaveFiltersTitle>{translate('notifications')}</SaveFiltersTitle>
              <SaveFiltersMessage>{translate('notification_card_description')}</SaveFiltersMessage>
            </SaveFiltersContent>
          </NotificationCard>
        </ViewAllLinkContainer>
        <TrendingStoriesContainer>
          <TrendingStoriesHeading>{translate('trending_stories')}</TrendingStoriesHeading>
          <If condition={trendingStories.length > 0} otherwise={<CustomEmpty text={'no_stories'} />}>
            {trendingStories.slice(0, 3).map((trendingStory: any) => (
              <TrendingStoriesItem key={trendingStory.id} trendingStory={trendingStory} storyId={trendingStory.id} />
            ))}
            <ViewAllLinkContainer
              to={{
                pathname: '/dashboard',
                search: `?activeTab=browse_stories`,
                hash: 'trendingStoriesSection'
              }}
            >
              <ViewAllText>{translate('view_all')}</ViewAllText>
              <img src={viewAllIcon} />
            </ViewAllLinkContainer>
          </If>
        </TrendingStoriesContainer>
        <FiltersContainer>
          <FiltersHeading>{translate('saved_filters')}</FiltersHeading>
          <If condition={savedFilters.length > 0} otherwise={<CustomEmpty text={'no_filters'} />}>
            {savedFilters?.slice(0, 3).map((el: any) => (
              <FilterItemLink
                to={{
                  pathname: '/dashboard',
                  search: `?activeTab=browse_stories`,
                  state: { filter: el }
                }}
                key={el.id}
              >
                <FiltersItem filter={el} />
              </FilterItemLink>
            ))}
            <ViewAllLinkContainer
              to={{
                pathname: '/dashboard',
                search: `?activeTab=browse_stories`,
                state: { showsavedFilters: true }
              }}
            >
              <ViewAllText>{translate('view_all')}</ViewAllText>
              <img src={viewAllIcon} />
            </ViewAllLinkContainer>
          </If>
          <ViewAllLinkContainer
            to={{
              pathname: '/dashboard',
              search: `?activeTab=browse_stories`
            }}
          >
            <SaveFiltersCard>
              <img src={filtersCardIcon} />
              <SaveFiltersContent>
                <SaveFiltersTitle>{translate('save_filters_title')}</SaveFiltersTitle>
                <SaveFiltersMessage>{translate('save_filters_message')}</SaveFiltersMessage>
              </SaveFiltersContent>
            </SaveFiltersCard>
          </ViewAllLinkContainer>
        </FiltersContainer>
      </FiltersSidebar>
    </MyDashboardContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  recentlyViewedStories: selectRecentlyViewedStories(),
  trendingStories: selectTrendingStories(),
  userProfileData: selectUserProfile(),
  savedFilters: selectSavedFiltersList(),
  accountStatusData: selectAccountStatusData(),
  recentlyViewedStoriesLoading: selectRecentlyViewedStoriesLoading()
});

const mapDispatchToProps = (dispatch: (arg0: AnyAction) => any) => {
  return {
    dispatchGetRecentlyViewedStories: (page: number, limit: number, buyerId: string) =>
      dispatch(requestGetRecentlyViewedStories({ page, limit, buyerId })),
    dispatchGetSavedFilters: () => dispatch(requestGetSavedFilters({})),
    dispatchGetTrendingStories: () => dispatch(requestGetTrendingStories({}))
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(IndividualDashboard) as React.FC;

export const IndividualDashboardTest = IndividualDashboard;
