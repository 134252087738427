import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import SearchIcon from '@images/icons/browse-stories/search-icon.svg';
import { Input } from 'antd';
import ClosedOffersCard from './components/ClosedOffersCard';

import { createStructuredSelector } from 'reselect';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import {
  requestGetClosedOffers,
  requestGetMissedOffers,
  requestGetOpenOffers,
  requestSendMailToLegalTeam
} from './reducer';
import { injectIntl } from 'react-intl';
import { selectUserProfile } from '@app/containers/ProfileContainer/selectors';
import {
  selectOpenOffers,
  selectClosedOffers,
  selectMissedOffers,
  selectOpenOffersTotal,
  selectClosedOffersTotal,
  selectMissedOffersTotal,
  selectSendMailToLegalTeamLoading
} from './selectors';
import { MyOffersProps } from './types';
import StoryCard from '@app/components/StoryCard';
import { requestGenerateStoryPdf, requestToggleFavourite, resetStoryPdfData } from '../BrowseStories/reducer';
import { selectStoryPdfUrl, selectStoryPdfLoading } from '../BrowseStories/selectors';
import moment from 'moment';
import { Portal } from '@app/components/Portal';
import AcceptedOffer from './components/AcceptedOffer';
import { If, Pagination } from 'tsw-sdk';
import MissedOfferCard from './components/MissedOfferCard';
import debounce from 'lodash-es/debounce';
import CustomEmpty from '@app/components/CustomEmpty';
import {
  requestGetImages,
  requestGetNarration,
  requestGetScreenPlay,
  resetState
} from '@app/containers/StoryDetailsPage/reducer';
import {
  selectImagesUrlList,
  selectNarrationUrl,
  selectScreenPlayUrl,
  selectScreenplayUrlLoading
} from '@app/containers/StoryDetailsPage/selectors';
import { useHistory } from 'react-router-dom';
import routeConstants from '@app/utils/routeConstants';
import SupportModal from './components/AcceptedOffer/components/SupportModal';

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 4.5rem 7.06rem 2rem 2rem;
  background: ${colors.dashboardBg};
  min-height: 100%;
  align-items: flex-start;
`;

const HeadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
`;

const OffersHeading = styled.h1`
  ${fonts.family.SpoofFamily};
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  line-height: 3.93rem;
  color: ${colors.myDashboardColors.heading};
  margin-bottom: 0;
  text-align: center;
`;

const CustomSearchInput = styled(Input)`
  && {
    width: 18.75rem;
    padding: 0.5rem;
    gap: 0.62rem;
    height: 1.93rem;
    border: 0.5px solid ${colors.lightBorder};
    border-radius: 0.31rem;
    ${fonts.size.xSmall()};
    line-height: 0.93rem;
    background: ${colors.white};

    &.ant-input:placeholder-shown {
      color: ${colors.lightBorder};
      ${fonts.size.xSmall()};
      line-height: 0.93rem;
      ${fonts.weights.fw400()};
    }

    &.ant-input-affix-wrapper > input.ant-input {
      background: ${colors.white};
    }
  }
`;

const StyledImg = styled.img``;

const StoryCardWrapper = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
`;

const SectionHeadingContainer = styled.div`
  width: 100%;
  height: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 3rem;
  margin-top: 2rem;
`;

const MyOffers = ({
  openOffersList,
  openOffersTotal,
  closedOffersList,
  closedOffersTotal,
  missedOffersList,
  missedOffersTotal,
  buyerProfile,
  storyPdfUrl,
  storyPdfLoading,
  sendMailToLegalTeamLoading,
  narrationUrl,
  screenplayUrl,
  imagesUrl,
  dispatchResetStoryPdf,
  dispatchGetClosedOffers,
  dispatchGetMissedOffers,
  dispatchToggleFavStory,
  dispatchGenerateStoryPdf,
  dispatchGetOpenOffers,
  dispatchSendMailToLegalTeam,
  dispatchGetImagesUrl,
  dispatchGetNarrationUrl,
  dispatchGetSignedUrl,
  dispatchResetState
}: MyOffersProps) => {
  const perPage = 10;
  const history = useHistory();

  const [showAcceptedOffer, setshowAcceptedOffer] = useState<boolean>(false);
  const [closedOfferAmount, setClosedOfferAmount] = useState<number>(0);
  const [authorName, setauthorName] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [sellerProfilePic, setSellerProfilePic] = useState<string>('');
  const [currentClosedOffer, setCurrentClosedOffer] = useState<any>({});

  const [openOffersCurrentPage, setOpenOffersCurrentPage] = useState<number>(1);
  const [closedOffersCurrentPage, setClosedOffersCurrentPage] = useState<number>(1);
  const [missedOffersCurrentPage, setMissedOffersCurrentPage] = useState<number>(1);
  const [supportModalVisible, setSupportModalVisible] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      dispatchResetStoryPdf();
    };
  }, []);

  useEffect(() => {
    dispatchGetOpenOffers(buyerProfile?.id, perPage, openOffersCurrentPage);
  }, [openOffersCurrentPage]);

  useEffect(() => {
    dispatchGetClosedOffers(buyerProfile?.id, perPage, closedOffersCurrentPage);
  }, [closedOffersCurrentPage]);

  useEffect(() => {
    dispatchGetMissedOffers(buyerProfile?.id, perPage, missedOffersCurrentPage);
  }, [missedOffersCurrentPage]);

  useEffect(() => {
    if (storyPdfUrl) {
      const anchorTag = document.createElement('a');

      anchorTag.href = storyPdfUrl;
      anchorTag.setAttribute('target', '_blank');
      anchorTag.click();

      dispatchResetStoryPdf();
    }
  }, [storyPdfUrl]);

  const toggleCardFavourite = (storyId: string) => {
    dispatchToggleFavStory(storyId);
  };

  const handleStoryCardDownload = (storyId: string, sellerId: string) => {
    dispatchGenerateStoryPdf(buyerProfile.id, storyId, sellerId);
  };

  const onClosedOfferClick = (amount: number, writerData: any, offer: any) => {
    if (offer.story.state !== 'DRAFTED') {
      history.push(
        `${routeConstants.dashboard.route}?activeTab=accepted-offer&storyId=${offer?.story?.id}&offerId=${offer.id}`
      );
      const writerName = writerData?.firstName + ' ' + writerData?.lastName;
      setauthorName(writerName);
      setSellerProfilePic(writerData?.profilePic);
      setClosedOfferAmount(amount);
      setCurrentClosedOffer(offer);
    }

    // setshowAcceptedOffer(true);
  };

  const debouncedSearch = useCallback(
    debounce((term) => {
      dispatchGetOpenOffers(buyerProfile?.id, perPage, openOffersCurrentPage, term);
    }, 500),
    []
  );

  return (
    <Container>
      <SupportModal visible={supportModalVisible} onClose={() => setSupportModalVisible(false)} />

      <HeadingContainer>
        <OffersHeading>{translate('my_offers')}</OffersHeading>
        <CustomSearchInput
          suffix={<StyledImg src={SearchIcon} data-testid="search-icon" />}
          placeholder={translate('search')}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            debouncedSearch(e.target.value);
          }}
          value={searchTerm}
          data-testid="search-input-offer"
        />
      </HeadingContainer>
      <SectionHeadingContainer>
        <OffersHeading>{translate('open_offers')}</OffersHeading>
        <Pagination
          total={openOffersTotal}
          currentPage={openOffersCurrentPage}
          perPage={perPage}
          setPage={(page: number) => setOpenOffersCurrentPage(page)}
          testKey="open-offers"
        />
      </SectionHeadingContainer>
      <If
        condition={openOffersList.length > 0}
        otherwise={<CustomEmpty text={searchTerm ? 'no_results' : 'no_offers'} />}
      >
        <StoryCardWrapper>
          {openOffersList?.map((openOffer: any) => (
            <StoryCard
              key={openOffer.id}
              canMakeOffer={false}
              isFavourite={openOffer?.story?.buyerActions?.hasLiked}
              storyData={openOffer?.story}
              isOfferCountered={false}
              toggleFavourite={toggleCardFavourite}
              handleDownload={handleStoryCardDownload}
              downloadLoading={storyPdfLoading}
              isOfferMade={true}
              offerId={openOffer.id}
            />
          ))}
        </StoryCardWrapper>
      </If>

      <SectionHeadingContainer>
        <OffersHeading>{translate('closed_offers')}</OffersHeading>
        <Pagination
          total={closedOffersTotal}
          currentPage={closedOffersCurrentPage}
          perPage={perPage}
          setPage={(page: number) => setClosedOffersCurrentPage(page)}
          testKey="closed-offers"
        />
      </SectionHeadingContainer>
      <If condition={closedOffersList.length > 0} otherwise={<CustomEmpty text={'no_offers'} />}>
        <StoryCardWrapper>
          {closedOffersList?.map((closedOffer: any) => (
            <ClosedOffersCard
              key={closedOffer.id}
              title={closedOffer?.story?.title}
              date={
                closedOffer?.createdAt && moment(closedOffer?.createdAt).zone('GMT+0530').format('DD.MM.YYYY | HH:mm')
              }
              amount={
                closedOffer?.details?.length > 0 && closedOffer?.details[closedOffer?.details?.length - 1]?.amount
              }
              handleClick={(amount, writer) => onClosedOfferClick(amount, writer, closedOffer)}
              storyData={closedOffer?.story}
              handleDownload={handleStoryCardDownload}
              writerId={closedOffer?.story?.writer?.id}
              downloadLoading={storyPdfLoading}
              isStoryLegal={closedOffer.story.state !== 'DRAFTED'}
              latestOfferDetails={closedOffer?.details[closedOffer?.details?.length - 1]}
              showSupportModal={() => setSupportModalVisible(true)}
            />
          ))}
        </StoryCardWrapper>
      </If>
      <SectionHeadingContainer>
        <OffersHeading>{translate('missed_offers')}</OffersHeading>
        <Pagination
          total={missedOffersTotal}
          currentPage={missedOffersCurrentPage}
          perPage={perPage}
          setPage={(page: number) => setMissedOffersCurrentPage(page)}
          testKey="missed-offers"
        />
      </SectionHeadingContainer>
      <If condition={missedOffersList.length > 0} otherwise={<CustomEmpty text={'no_offers'} />}>
        <StoryCardWrapper>
          {missedOffersList?.map((missedOffer: any) => (
            <MissedOfferCard
              key={missedOffer.id}
              title={missedOffer?.story?.title}
              date={
                missedOffer?.createdAt && moment(missedOffer?.createdAt).zone('GMT+0530').format('DD.MM.YYYY | HH:mm')
              }
              amount={
                missedOffer?.details?.length > 0 && missedOffer?.details[missedOffer?.details?.length - 1]?.amount
              }
              handleDownload={handleStoryCardDownload}
              writerId={missedOffer?.story?.writer?.id}
              storyId={missedOffer?.story?.id}
              downloadLoading={storyPdfLoading}
            />
          ))}
        </StoryCardWrapper>
      </If>
      <If condition={showAcceptedOffer}>
        <Portal>
          <AcceptedOffer
            userProfile={buyerProfile}
            profilePic={sellerProfilePic}
            acceptedOfferAmount={closedOfferAmount}
            authorName={authorName}
            currentOffer={currentClosedOffer}
            sendMailToLegalTeamLoading={sendMailToLegalTeamLoading}
            narrationUrl={narrationUrl}
            screenplayUrl={screenplayUrl}
            imagesUrl={imagesUrl}
            onBack={() => setshowAcceptedOffer(false)}
            dispatchSendMailToLegalTeam={dispatchSendMailToLegalTeam}
            dispatchGetImagesUrl={dispatchGetImagesUrl}
            dispatchGetNarrationUrl={dispatchGetNarrationUrl}
            dispatchGetSignedUrl={dispatchGetSignedUrl}
            dispatchResetState={dispatchResetState}
          />
        </Portal>
      </If>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  openOffersList: selectOpenOffers(),
  openOffersTotal: selectOpenOffersTotal(),
  closedOffersList: selectClosedOffers(),
  closedOffersTotal: selectClosedOffersTotal(),
  missedOffersList: selectMissedOffers(),
  missedOffersTotal: selectMissedOffersTotal(),
  buyerProfile: selectUserProfile(),
  storyPdfUrl: selectStoryPdfUrl(),
  storyPdfLoading: selectStoryPdfLoading(),
  sendMailToLegalTeamLoading: selectSendMailToLegalTeamLoading(),
  screenPlayUrlLoading: selectScreenplayUrlLoading(),
  screenplayUrl: selectScreenPlayUrl(),
  narrationUrl: selectNarrationUrl(),
  imagesUrl: selectImagesUrlList()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGetOpenOffers: (buyerId: string, limit: number, page: number, search?: string) =>
      dispatch(requestGetOpenOffers({ buyerId, limit, page, search })),
    dispatchGetClosedOffers: (buyerId: string, limit: number, page: number) =>
      dispatch(requestGetClosedOffers({ buyerId, limit, page })),
    dispatchGetMissedOffers: (buyerId: string, limit: number, page: number) =>
      dispatch(requestGetMissedOffers({ buyerId, limit, page })),
    dispatchToggleFavStory: (storyId: string) => dispatch(requestToggleFavourite({ storyId })),
    dispatchGenerateStoryPdf: (buyerId: string, storyId: string, sellerId: string) =>
      dispatch(
        requestGenerateStoryPdf({
          buyerId,
          storyId,
          sellerId
        })
      ),
    dispatchResetStoryPdf: () => dispatch(resetStoryPdfData()),
    dispatchSendMailToLegalTeam: (supportNeeded: boolean, offerId: string) =>
      dispatch(
        requestSendMailToLegalTeam({
          supportNeeded,
          offerId
        })
      ),
    dispatchGetSignedUrl: (folderPath: string, fileName: string) =>
      dispatch(requestGetScreenPlay({ folderPath, fileName })),
    dispatchGetNarrationUrl: (folderPath: string, fileName: string) =>
      dispatch(requestGetNarration({ folderPath, fileName })),
    dispatchGetImagesUrl: (fileNames: string[]) => dispatch(requestGetImages({ fileNames })),
    dispatchResetState: () => dispatch(resetState())
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, injectIntl)(MyOffers) as React.FC;

export const MyOffersTest = MyOffers;
