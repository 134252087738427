export const HEADER_HEIGHT = '7rem';
export const MIN_SIDEBAR_WIDTH = '4.5rem';
export const MOBILE_DRAWER_BREAKPOINT = 450;
export const BACKSPACE = 'Backspace';
export const ARROWLEFT = 'ArrowLeft';
export const ARROWRIGHT = 'ArrowRight';
export const SELLER_EXISTS_ERROR = 'PreSignUp failed with error the buyer already exist.';
export const USER_DOES_NOT_EXISTS = 'User does not exist.';
export const BUYER = 'BUYER';
export const USER_IS_NOT_CONFIRMED = 'User is not confirmed.';
export const AVAILABLE_PACKAGES = {
  ADMIN: 'BUYER_ORGANIZATION_ONBOARDING',
  INDIVIDUAL: 'BUYER_INDIVIDUAL_ONBOARDING'
};
export const PROFILE_PATH = 'images/profile';
export const BANNER_PATH = 'images/banner';

export const PROFILE_IMAGE_URL = 'https://tsw-profile-assets.s3.ap-south-1.amazonaws.com/images/profile';
export const BANNER_IMAGE_URL = 'https://tsw-profile-assets.s3.ap-south-1.amazonaws.com/images/banner';

export const PROFILE_IMAGE_QA_URL = 'https://tsw-profile-assets-qa.s3.ap-south-1.amazonaws.com/images/profile';
export const BANNER_IMAGE_QA_URL = 'https://tsw-profile-assets-qa.s3.ap-south-1.amazonaws.com/images/banner';

export const PROFILE_IMAGE_PROD_URL = 'https://tsw-profile-assets-prod.s3.ap-south-1.amazonaws.com/images/profile';
export const BANNER_IMAGE_PROD_URL = 'https://tsw-profile-assets-prod.s3.ap-south-1.amazonaws.com/images/banner';

export const PROFILE = 'PROFILE';

export const TERMS_AND_CONDITIONS_URL = 'https://tsw-assets.s3.ap-south-1.amazonaws.com/tnc_spotwriters.pdf';

export const PRIVACY_POLICY_URL = 'https://tsw-assets.s3.ap-south-1.amazonaws.com/privacy_policy_spotwriters.pdf';

export const FAQ_URL = 'https://www.spotwriters.com/faq';

export const COGNITO_USER_GROUPS = {
  ADMIN: 'buyer_admin',
  INDIVIDUAL: 'buyer_individual',
  ORGANIZATION: 'buyer_organization'
};

export const COUPON_BUYER_TYPES = {
  ADMIN: 'BUYER_ORGANIZATION_ADMIN',
  INDIVIDUAL: 'BUYER_INDIVIDUAL'
};

export const SUBSCRIPTION_PLAN_TYPES = {
  ADMIN: 'SPOT_ORGANIZATION',
  INDIVIDUAL: 'SPOT_INDIVIDUAL'
};

export const BUYER_ACCOUNT_TYPE = {
  INDIVIDUAL: 'INDIVIDUAL',
  ORGANIZATION_ADMIN: 'ADMIN',
  ORGANIZATION_USER: 'ORGANIZATION_USER'
};

export const STORIES_STATES = {
  PUBLISHED: 'PUBLISHED'
};

export const ATTACHMENT_TYPE = {
  IMAGE: 'IMAGE',
  SCREENPLAY: 'SCREENPLAY',
  AUDIO_NARRATION: 'AUDIO_NARRATION'
};

export const SCREENPLAY_PATH = 'storyMaterial/screenplays';
export const NARRATION_PATH = 'storyMaterial/audioNarrations';
export const SUPPORTING_IMAGES_PATH = 'storyMaterial/images';
export const OFFER_ACTION = {
  ACCEPT_OFFER: 'ACCEPT_OFFER',
  DECLINE_OFFER: 'DECLINE_OFFER',
  RETRACT_OFFER: 'RETRACT_OFFER'
};

export const OFFER_STATE = {
  OFFER_PLACED: 'OFFER_PLACED',
  BUYER_COUNTERED: 'BUYER_COUNTERED',
  SELLER_COUNTERED: 'SELLER_COUNTERED',
  SELLER_ACCEPTED: 'SELLER_ACCEPTED',
  BUYER_ACCEPTED: 'BUYER_ACCEPTED',
  SELLER_DECLINED: 'SELLER_DECLINED',
  BUYER_DECLINED: 'BUYER_DECLINED',
  BUYER_RETRACTED: 'BUYER_RETRACTED',
  SELLER_RETRACTED: 'SELLER_RETRACTED'
};

export const NOTIFICATION_TYPES = {
  OFFER: 'offer',
  COPYRIGHT: 'copyright',
  OFFER_CRONJOB: 'offer-cronjob',
  MY_OFFER: 'my-offer',
  CONVERSATION: 'conversation',
  ACCEPTED_OFFER: 'offer-accepted'
};

export const BUYER_RENEWAL_PAYLOAD = {
  BUYER_RENEWAL_REASON: 'BUYER_RENEWAL',
  BUYER_RENEWAL_NOTES: 'For buyer subscription renewal'
};

export const SCREENPLAY_ACCESS_STATE = {
  SCREENPLAY_NOT_REQUESTED: 'SCREENPLAY_NOT_REQUESTED',
  SCREENPLAY_REQUESTED: 'SCREENPLAY_REQUESTED',
  SCREENPLAY_APPROVED: 'SCREENPLAY_APPROVED',
  SCREENPLAY_DECLINED: 'SCREENPLAY_DECLINED'
};

export const MESSAGE_TYPES = {
  SCREENPLAY_REQUEST: 'SCREENPLAY_REQUEST',
  CHAT: 'CHAT'
};

export const REQUEST_STATE = {
  REQUESTED: 'REQUESTED',
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED'
};

export const COUPON_INDV = 'FirstMonthIndv';
export const COUPON_ADMIN = 'FirstMonthAdmin';
