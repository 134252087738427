import { createSelector } from 'reselect';
import get from 'lodash-es/get';
import { initialState } from './reducer';

export const selectAdminNotificationDomain = (state: any) => state.adminNotificationsReducer || initialState;

export const selectInboxNotifications = () =>
  createSelector(selectAdminNotificationDomain, (substate) => get(substate, 'inbox'));

export const selectInboxNotificationsTotal = () =>
  createSelector(selectAdminNotificationDomain, (substate) => get(substate, 'inboxTotal'));

export const selectNotifications = () =>
  createSelector(selectAdminNotificationDomain, (substate) => get(substate, 'notifications'));

export const selectNotificationsTotal = () =>
  createSelector(selectAdminNotificationDomain, (substate) => get(substate, 'notificationsTotal'));

export const selectReadNotificationLoading = () =>
  createSelector(selectAdminNotificationDomain, (substate) => get(substate, 'readNotificationLoading'));
