import React, { useEffect, useState } from 'react';
import Sidebar from '@app/components/Sidebar';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import BrowseStories from './IndividualDashboard/tabs/BrowseStories';
import { DashboardProps } from './types';

import { logoutAction } from '@app/reducers';
import { selectAccessToken, selectIdToken, selectRefreshToken } from '../TokenProvider/selectors';
import { createStructuredSelector } from 'reselect';
import { get } from 'lodash-es';
import { COGNITO_USER_GROUPS, SUBSCRIPTION_PLAN_TYPES } from '@app/utils/constants';
import AdminDashboard from './AdminDashboard';
import IndividiualDashboard from './IndividualDashboard';
import MyNotificationsAdmin from './AdminDashboard/tabs/MyNotifications';
import MyOffers from './IndividualDashboard/tabs/MyOffers';
import MyFavourites from './IndividualDashboard/tabs/MyFavourites';
import MyCommunity from './IndividualDashboard/tabs/MyCommunity';
import MyProfile from './IndividualDashboard/tabs/MyProfile';
import MyNotificationsIndividual from './IndividualDashboard/tabs/MyNotifications';

import { selectAccountType, selectUserProfile } from '../ProfileContainer/selectors';
import { requestGetAccountType, requestGetProfile } from '../ProfileContainer/reducer';
import { requestRefreshToken } from '../LoginContainer/reducer';
import jwtDecode from 'jwt-decode';
import StoryDetailPage from '../StoryDetailsPage';
import OfferHistory from '../OfferHistory';
import { requestAccountStatus } from '../HomeContainer/reducer';
import { If, PageSpinner } from 'tsw-sdk';
import { selectGlobalLoading } from '../App/selectors';
import { selectAccountStatusData } from '../HomeContainer/selectors';
import SubscriptionStatusScreen from './components/SubscriptionStatusScreen';
import { selectShowBanner } from './selectors';
import { requestGenerateRenewalPaymentLink, requestSendReminder, setShowSubscriptionBanner } from './reducer';
import OfferDetail from '../OfferHistory/components/OfferDetail';
import AcceptedOfferPage from '../AcceptedOfferPage';

const DashboardContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow-x: auto;
`;

const PageContainer = styled.div`
  width: 100%;
  min-width: 70rem;
  height: 100%;
  position: relative;
  overflow-y: auto;
`;

type IdToken = {
  email: string;
};

const DashboardContainerPage = ({
  accessToken,
  idToken,
  accountType,
  refreshToken,
  globalLoading,
  userProfile,
  accountStatusData,
  showSubscriptionBanner,
  dispatchLogout,
  dispatchGetAccountType,
  dispatchRefreshToken,
  dispatchGetUserProfile,
  dispatchAccountStatus,
  dispatchSetShowSubscriptionBanner,
  dispatchSendRenewalReminder,
  dispatchRenewalPayment
}: DashboardProps) => {
  const location = useLocation();

  const cognitoGroup: any = get(accessToken, 'cognito:groups', []);

  let userType = cognitoGroup.filter(
    (group: string) =>
      group === COGNITO_USER_GROUPS.ADMIN ||
      group === COGNITO_USER_GROUPS.INDIVIDUAL ||
      group === COGNITO_USER_GROUPS.ORGANIZATION
  );

  userType = userType.length ? userType[0] : '';

  const [renderDashboard, setRenderDashboard] = useState<boolean>(false);

  const params = new URLSearchParams(location?.search);

  const activeTab = params.get('activeTab');

  useEffect(() => {
    if (userProfile) {
      if (Object.keys(userProfile).length > 2) {
        setRenderDashboard(true);
      }
    }
  }, [userProfile]);

  useEffect(() => {
    window.scrollTo({
      top: 0
    });
    dispatchGetAccountType();
    dispatchAccountStatus();
    dispatchGetUserProfile();
  }, []);

  useEffect(() => {
    if (refreshToken && idToken) {
      if (!cognitoGroup.length) {
        const decoded: IdToken = jwtDecode(idToken);
        dispatchRefreshToken(decoded.email, refreshToken);
      } else {
        if (
          !cognitoGroup.includes(COGNITO_USER_GROUPS.ADMIN) &&
          !cognitoGroup.includes(COGNITO_USER_GROUPS.INDIVIDUAL) &&
          !cognitoGroup.includes(COGNITO_USER_GROUPS.ORGANIZATION)
        ) {
          const decoded: IdToken = jwtDecode(idToken);
          dispatchRefreshToken(decoded.email, refreshToken);
        }
      }
    }
  }, [refreshToken, idToken]);

  const getPageIndividual = () => {
    switch (activeTab) {
      case 'my_dashboard':
        return <IndividiualDashboard />;
      case 'browse_stories':
        return <BrowseStories />;
      // case 'stories_received':
      //   return <StoriesReceived />;
      case 'my_offers':
        return <MyOffers />;
      case 'my_favourites':
        return <MyFavourites />;
      case 'my_community':
        return <MyCommunity />;
      case 'my_notifications':
        return <MyNotificationsIndividual />;
      case 'my_profile':
        return <MyProfile />;
      case 'story_details':
        return <StoryDetailPage />;
      case 'offer-history':
        return <OfferHistory />;
      case 'offer-detail':
        return <OfferDetail />;
      case 'accepted-offer':
        return <AcceptedOfferPage />;
      default:
        return <IndividiualDashboard />;
    }
  };

  const getPageAdmin = () => {
    switch (activeTab) {
      // case 'my_offer':
      //   return <MyOffer />;
      case 'my_notifications':
        return <MyNotificationsAdmin />;
      case 'admin_dashboard':
        return <AdminDashboard />;
      default:
        return <AdminDashboard />;
    }
  };

  const getPage = () => {
    if (userType) {
      if (userType === COGNITO_USER_GROUPS.INDIVIDUAL || userType === COGNITO_USER_GROUPS.ORGANIZATION) {
        return getPageIndividual();
      } else {
        return getPageAdmin();
      }
    } else {
      if (accountType) {
        if (accountType === SUBSCRIPTION_PLAN_TYPES.INDIVIDUAL) {
          return getPageIndividual();
        } else {
          return getPageAdmin();
        }
      }
    }
  };

  return (
    <DashboardContainer id="dashboard-container">
      <PageSpinner loading={globalLoading} />
      <Sidebar dispatchLogout={dispatchLogout} userType={userType || accountType} />
      <If condition={renderDashboard}>
        <PageContainer>
          {getPage()}
          <If
            condition={Object.keys(accountStatusData).length > 0 ? !accountStatusData?.subscription?.isActive : false}
          >
            <SubscriptionStatusScreen
              userProfile={userProfile}
              subscriptionStatus={accountStatusData?.subscription}
              accountType={userType || accountType}
              showSubscriptionBanner={showSubscriptionBanner}
              dispatchSetShowSubscriptionBanner={dispatchSetShowSubscriptionBanner}
              dispatchSendRenewalReminder={dispatchSendRenewalReminder}
              dispatchRenewalPayment={dispatchRenewalPayment}
            />
          </If>
        </PageContainer>
      </If>
    </DashboardContainer>
  );
};

export const mapStateToProps = createStructuredSelector({
  accessToken: selectAccessToken(),
  accountType: selectAccountType(),
  idToken: selectIdToken(),
  refreshToken: selectRefreshToken(),
  globalLoading: selectGlobalLoading(),
  userProfile: selectUserProfile(),
  accountStatusData: selectAccountStatusData(),
  showSubscriptionBanner: selectShowBanner()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchLogout: () => dispatch(logoutAction()),
    dispatchGetAccountType: () => dispatch(requestGetAccountType({})),
    dispatchRefreshToken: (email: string, refreshToken: string) =>
      dispatch(requestRefreshToken({ email, refreshToken })),
    dispatchGetUserProfile: () => dispatch(requestGetProfile({})),
    dispatchAccountStatus: () => dispatch(requestAccountStatus()),
    dispatchSetShowSubscriptionBanner: (showSubscriptionBanner: boolean) =>
      dispatch(setShowSubscriptionBanner(showSubscriptionBanner)),
    dispatchSendRenewalReminder: () => dispatch(requestSendReminder({})),
    dispatchRenewalPayment: (amount: number, reason: string, notes: string) =>
      dispatch(requestGenerateRenewalPaymentLink({ amount, reason, notes }))
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(DashboardContainerPage);

export const DashboardContainerPageTest = DashboardContainerPage;
