import { getQueryResponse } from '@app/utils/graphqlUtils';
import { BuyerAccountStatus } from '@app/utils/queries';
import { get } from 'lodash-es';
import { put, takeLatest, all, call } from 'redux-saga/effects';

import { requestAccountStatus, failureAccountStatus, successAccountStatus } from './reducer';

export function* getAccountStatus(): Generator<any, any, any> {
  try {
    const { ok, data, error } = yield call(getQueryResponse, BuyerAccountStatus);

    if (ok) {
      const buyerAccountStatus = get(data, 'buyerAccountStatus', {});

      yield put(successAccountStatus(buyerAccountStatus));
    } else {
      yield put(failureAccountStatus(error));
    }
  } catch (err) {
    yield put(failureAccountStatus(err));
  }
}
// Individual exports for testing
export default function* homeContainerSaga() {
  yield all([takeLatest(requestAccountStatus.toString(), getAccountStatus)]);
}

export const homeContainer = [takeLatest(requestAccountStatus.toString(), getAccountStatus)];
