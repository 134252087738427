import React from 'react';
import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import { Button, Form, Input, message } from 'antd';
import styled from 'styled-components';
import { CounterOfferModalProps } from './types';
import { ArrowRightOutlined } from '@ant-design/icons';
import { preventInvalidCharsNumberInput } from '@app/utils/uiUtils';
import TextArea from 'antd/lib/input/TextArea';

const CounterOfferModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 6.5rem;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${colors.white};
`;

const CounterOfferTitle = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  color: ${colors.primary};
  text-align: center;
  line-height: 3.93rem;
`;

const CounterOfferForm = styled(Form)`
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;

  && {
    width: 16rem;

    & > div:nth-of-type(3) {
      margin-top: 3rem;
    }
  }
`;

const CounterOfferFormItem = styled(Form.Item)`
  && {
    margin-bottom: 0px;

    label {
      ${fonts.weights.fw600()};
      ${fonts.size.regular()};
      line-height: 1.25rem;
      color: ${colors.myOffers.counterOfferFormLabel};
    }

    input {
      background-color: ${colors.white};
      border-radius: 0.31rem;
      -moz-appearance: textfield;
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
`;

// const CounterOfferAmountNote = styled.p`
//   ${fonts.weights.fw400()};
//   ${fonts.size.extraSmall()};
//   line-height: 0.62rem;
//   margin-top: 0.25rem;
//   color: ${colors.myOffers.counterOfferNoteColor};
// `;

const CurrentOfferPill = styled.div`
  width: fit-content;
  height: 1.5rem;
  border-radius: 6.25rem;
  background-color: ${colors.myOffers.scriptIncludedPillBg};
  color: ${colors.myOffers.scriptIncludedPillColor};
  margin-top: 1rem;
  padding: 0.25rem 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  ${fonts.weights.fw800()};
  ${fonts.size.extraSmall()};
  line-height: 1rem;
  white-space: nowrap;
`;

const FormActionsContainer = styled.div`
  margin-top: 3rem;
  margin-bottom: 5.18rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CancelBtn = styled(Button)`
  width: 7.81rem;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0.75rem 2rem 0.75rem 2rem;
  display: flex;
  align-items: center;
  background-color: ${colors.myOffers.grayBtnBg};
  color: ${colors.myOffers.grayBtnColor};
  cursor: pointer;
  ${fonts.weights.fw800()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
  border: none;

  &:hover {
    background-color: ${colors.myOffers.grayBtnBg};
    color: ${colors.myOffers.grayBtnColor};
    border: none;
  }
`;

const CounterOfferBtn = styled(Button)`
  width: 12.62rem;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0.75rem 2rem 0.75rem 2rem;
  display: flex;
  align-items: center;
  background-color: ${colors.primary};
  color: ${colors.white};
  margin-left: 2rem;
  cursor: pointer;
  ${fonts.weights.fw800()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
  border: none;

  &:hover {
    background-color: ${colors.primary};
    color: ${colors.white};
    border: none;
  }

  &:focus {
    background-color: ${colors.primary};
    color: ${colors.white};
    border: none;
  }
`;

const CounterOfferSubHeadingText = styled.p`
  ${fonts.weights.fw400()};
  ${fonts.size.regular()};
  line-height: 1.25rem;
  margin-top: 0.5rem;
  color: ${colors.myOffers.counterOfferFormLabel};
  text-align: center;
`;

const StyledTextArea = styled(TextArea)`
  &&& {
    min-height: 1.93rem;
    > * {
      background-color: ${colors.white} !important;
      border: 0.5px solid ${colors.cardHeader};
      border-radius: 0.31rem;
      margin-top: 0.5rem;
      height: 2.5rem;

      &.ant-input {
        line-height: 1rem;
        min-height: 2.5rem;
      }

      &.ant-input:placeholder-shown {
        color: ${colors.lightBorder};
        font-size: 0.75rem;
        ${fonts.weights.fw400};
        line-height: 0.94rem;
      }

      &.textarea.ant-input {
        min-height: 1.93rem;
        background-color: ${colors.white};
      }
    }
    &.ant-input-textarea-show-count::after {
      margin-top: 0.25rem;
      float: left;
      ${fonts.size.extraSmall()};
      line-height: 0.62rem;
      ${fonts.weights.fw400};
      content: ' ' attr(data-count) ' characters ';
      color: ${colors.lightBorder};
    }
  }
`;

const CounterOfferModal = ({ handleCancel, counterOfferAmount, onCounterOffer }: CounterOfferModalProps) => {
  const [counterOfferForm] = Form.useForm();

  const handleCounterOffer = () => {
    const { counterAmount, noteFromBuyer } = counterOfferForm.getFieldsValue(['counterAmount', 'noteFromBuyer']);

    if (Number(counterAmount) > 0) {
      onCounterOffer(Number(counterAmount), noteFromBuyer);
    } else {
      message.error(translate('more_than_zero'));
    }
  };

  return (
    <CounterOfferModalContent>
      <CounterOfferTitle>{translate('counter_offer')}</CounterOfferTitle>
      <CounterOfferSubHeadingText>
        {translate('counter_offer_seller', { value: counterOfferAmount?.toLocaleString('en-IN') })}
      </CounterOfferSubHeadingText>
      <CounterOfferForm layout="vertical" form={counterOfferForm}>
        <CounterOfferFormItem name={'counterAmount'} label={translate('new_offered_amount')}>
          <Input
            type="number"
            placeholder={translate('counter_offer_placeholder')}
            onKeyDown={preventInvalidCharsNumberInput}
            data-testid="counter-offer-input"
            min={1}
          />
        </CounterOfferFormItem>
        {/* <CounterOfferAmountNote>{translate('counter_offer_note')}</CounterOfferAmountNote> */}

        <CurrentOfferPill>
          {translate('current_offer_offered', { amount: counterOfferAmount?.toLocaleString('en-IN') })}
        </CurrentOfferPill>
        <CounterOfferFormItem name={'noteFromBuyer'} label={translate('note_from_buyer')}>
          <StyledTextArea
            rows={1}
            autoSize
            showCount={true}
            maxLength={250}
            onPaste={(e) => {
              const clipboardData = e.clipboardData;
              const pastedData = clipboardData.getData('Text');

              if (pastedData.length > 250) {
                message.error(translate('250_chars_limit_error'));
              }
            }}
            data-testid="counter-offer-input2"
          />
        </CounterOfferFormItem>
      </CounterOfferForm>
      <FormActionsContainer>
        <CancelBtn onClick={handleCancel} data-testid="counter-offer-cancel">
          {translate('cancel')}
        </CancelBtn>
        <CounterOfferBtn onClick={handleCounterOffer} data-testid="counter-offer-counter-btn">
          {translate('counter_offer_2')} <ArrowRightOutlined />
        </CounterOfferBtn>
      </FormActionsContainer>
    </CounterOfferModalContent>
  );
};

export default CounterOfferModal;
