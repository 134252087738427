import { createSelector } from 'reselect';
import get from 'lodash-es/get';
import { initialState } from './reducer';

export const selectInvitePageDomain = (state: any) => state.invitePageReducer || initialState;

export const selectLoginData = () => createSelector(selectInvitePageDomain, (substate) => get(substate, 'loginData'));

export const selectLoginSuccess = () =>
  createSelector(selectInvitePageDomain, (substate) => get(substate, 'loginSuccess'));

export const selectLoginError = () => createSelector(selectInvitePageDomain, (substate) => get(substate, 'loginError'));

export const selectLoading = () => createSelector(selectInvitePageDomain, (substate) => get(substate, 'loading'));

export const selectReCaptchaState = () =>
  createSelector(selectInvitePageDomain, (substate) => get(substate, 'reCaptchaState'));
