import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { DocumentProps } from 'react-pdf';
import { colors } from '@app/themes';
import { Spin } from 'antd';
import { If } from 'tsw-sdk';

const PageControls = styled('div')`
  opacity: 0;
  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translate(-50%);
  box-shadow: 0 1.87rem 2.5rem 0 rgba(16, 36, 94, 0.2);
  background-color: white;
  transition: opacity 0.3s;
`;

const StyledDocument = styled(Document)<DocumentProps>`
  border-radius: 8px;
  overflow-y: auto;
  height: 100%;

  &:hover ${PageControls} {
    opacity: 1;
  }

  & > div {
    width: auto;
    display: flex;
    justify-content: center;
  }

  & > div > div {
    display: none;
  }

  & canvas {
    margin: 0px;
    border-radius: 8px;
  }
`;

const Loader = styled.div`
  height: 31.25rem !important;
  width: 18.75rem !important;
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  & > div {
    display: block !important;
  }
`;

const StyledDiv = styled.div<any>`
  overflow-y: ${(props) => (props.loading ? 'hidden' : 'auto')};
`;

export type PdfViewerProps = {
  file: string;
  handleLastPageSeen: () => void;
};

const PdfViewer = ({ file, handleLastPageSeen }: PdfViewerProps) => {
  const [numPages, setNumPages] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);

  const lastPageRef: any = useRef(null);
  const documentRef: any = useRef(null);

  useEffect(() => {
    setLoading(true);
  }, [file]);

  function onDocumentLoadSuccess({ numPages }: { numPages: any }) {
    setNumPages(numPages);
    setLoading(false);
  }

  const handleScroll = () => {
    const lastPageElement = lastPageRef.current;
    const documentElement = documentRef.current;
    const documentScrolledPos = documentElement.scrollTop;
    const lastPagePosition = lastPageElement.offsetTop;

    if (documentScrolledPos >= lastPagePosition) {
      handleLastPageSeen();
    }
  };

  return (
    <StyledDiv onWheel={handleScroll} loading={loading}>
      <If condition={loading}>
        <Loader>
          <Spin />
        </Loader>
      </If>
      <StyledDocument
        file={file}
        options={{ workerSrc: '/pdf.worker.js' }}
        onLoadSuccess={onDocumentLoadSuccess}
        inputRef={documentRef}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            inputRef={index === numPages - 1 ? lastPageRef : null}
          />
        ))}
      </StyledDocument>
    </StyledDiv>
  );
};

export default PdfViewer;
