import { translate } from '@app/components/IntlGlobalProvider';
import { CancelBtn, RetractOfferBtn } from '@app/containers/OfferHistory/components/RetractOfferModal';
import { colors, fonts } from '@app/themes';
import { message } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { RequestScreenplayAccessProps } from '../../types';
import { StyledTextArea } from '../MakeOfferComponent';

const RequestAccessPortalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding: 6.5rem 26.4rem 8.81rem 26.4rem;
  background: ${colors.white};
`;

const RequestAccessContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading = styled.h1`
  ${fonts.family.SpoofFamily};
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  line-height: 3.93rem;
  text-align: center;
  color: ${colors.primary};
  margin-bottom: 0px;
`;

const StyledLabel = styled.p`
  ${fonts.weights.fw600()};
  ${fonts.size.regular()};
  line-height: 1.25rem;
  color: ${colors.browseStoriesColors.labelColor};
`;

const FormActionsContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NotesFieldContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const AttemptsRemainingText = styled.p`
  ${fonts.weights.fw600()};
  ${fonts.size.small()};
  line-height: 1.09rem;
  color: ${colors.storyPage.subTextClr};
  margin-top: 2rem;
`;

const RequestScreenplayAccess = ({
  handleCancel,
  storyTitle,
  handleSendRequest,
  requestAttempts
}: RequestScreenplayAccessProps) => {
  const [requestMessage, setRequestMessage] = useState<string>('');

  const requestSendHandler = () => {
    if (requestMessage.trim()) {
      if (requestAttempts < 3) {
        handleSendRequest(requestMessage.trim());
      } else {
        message.error(translate('exhausted_attempts'));
      }
    } else {
      message.error(translate('cannot_be_empty'));
    }
  };

  return (
    <RequestAccessPortalContainer>
      <RequestAccessContentContainer>
        <Heading>{translate('request_access_heading', { storyTitle: storyTitle })}</Heading>
        <NotesFieldContainer>
          <StyledLabel>{translate('notes_for_writer')}</StyledLabel>
          <StyledTextArea
            rows={1}
            autoSize
            showCount
            maxLength={250}
            value={requestMessage}
            data-testid="request-access-notes-input"
            onChange={(e) => setRequestMessage(e.target.value)}
            onPaste={(e) => {
              const clipboardData = e.clipboardData;
              const pastedData = clipboardData.getData('Text');

              if (pastedData.length > 250) {
                message.error(translate('250_chars_limit_error'));
              }
            }}
          />
        </NotesFieldContainer>
        <FormActionsContainer>
          <CancelBtn onClick={handleCancel} data-testid="request-access-offer-cancel">
            {translate('cancel')}
          </CancelBtn>
          <RetractOfferBtn onClick={requestSendHandler} data-testid="request-screenplay-access-button">
            {translate('send_request')}
          </RetractOfferBtn>
        </FormActionsContainer>
        <AttemptsRemainingText>
          {translate('attempts_remaining', { remainingAttempts: 3 - requestAttempts })}
        </AttemptsRemainingText>
      </RequestAccessContentContainer>
    </RequestAccessPortalContainer>
  );
};

export default RequestScreenplayAccess;
