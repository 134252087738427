import CustomEmpty from '@app/components/CustomEmpty';
import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import { message } from 'antd';
import { isEmpty } from 'lodash-es';
import React, { useState } from 'react';
import styled from 'styled-components';
import { If } from 'tsw-sdk';
import { TextProps, ViewSavedFiltersProps } from '../../types';
import { FilterButton, ModalContent, ModalHeading, StyledModal } from '../FilterModal';

const FilterNamesList = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  gap: 1rem;
`;

const UnSelectedText = styled.p<TextProps>`
  ${fonts.weights.fw700()};
  ${fonts.size.xSmall()};
  line-height: 1rem;
  text-align: center;
  white-space: no-wrap;
  ${(props) =>
    props.isClicked ? ` color: ${colors.darkBorder}` : ` color: ${colors.browseStoriesColors.subTextColor}`};

  &:hover {
    color: ${colors.darkBorder};
    ${fonts.size.regular()};
    cursor: pointer;
  }
`;

const ViewSavedFilters = ({ handleCancel, savedFilters, handleApplyFilter }: ViewSavedFiltersProps) => {
  const [filter, setFilter] = useState({});
  const [filterId, setFilterId] = useState(0);
  const handleSelectFilter = (item: any) => {
    setFilter(item);
    setFilterId(item.id);
  };

  const applyFilterHandler = () => {
    if (!isEmpty(filter)) {
      handleApplyFilter(filter);
    } else {
      message.error(translate('choose_filter'));
    }
  };

  return (
    <StyledModal
      visible={true}
      centered={true}
      footer={null}
      closable={true}
      destroyOnClose={true}
      width={561}
      onCancel={handleCancel}
    >
      <ModalContent>
        <ModalHeading>{translate('saved_filters')}</ModalHeading>
        <If condition={savedFilters.length > 0} otherwise={<CustomEmpty text={'no_filters'} />}>
          <FilterNamesList>
            {savedFilters.map((el: any) => (
              <UnSelectedText key={el.id} onClick={() => handleSelectFilter(el)} isClicked={el.id === filterId}>
                {el.name}
              </UnSelectedText>
            ))}
          </FilterNamesList>

          <FilterButton onClick={applyFilterHandler} data-testid="apply-filter-btn">
            {translate('apply_filter')}
          </FilterButton>
        </If>
      </ModalContent>
    </StyledModal>
  );
};

export default ViewSavedFilters;
