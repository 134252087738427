import { colors, fonts } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import { FilterItemProps } from '../../types';

const FiltersItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
`;

const FiltersItemGenres = styled.p`
  ${fonts.weights.black()}
  ${fonts.size.xSmall()}
  color: ${colors.myDashboardColors.trendingStoryDate};
`;

const FiltersItemName = styled.p`
  ${fonts.weights.black()}
  ${fonts.size.regular()};
  line-height: 20px;
  color: ${colors.myDashboardColors.cardTitle};
  margin-top: 0.25rem;
`;

const FiltersItem = ({ filter }: FilterItemProps) => {
  return (
    <FiltersItemContainer>
      <FiltersItemGenres>{`${filter.language?.value},${filter.genre?.value},${filter.tag?.value}`}</FiltersItemGenres>
      <FiltersItemName>{filter?.name}</FiltersItemName>
    </FiltersItemContainer>
  );
};

export default FiltersItem;
