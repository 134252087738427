import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import { prepare } from '@app/utils';

export const initialState = {
  updateSelectedPackageLoading: false,
  updateSelectedPackageError: null,
  isTermsChecked: false,
  isTermsSeen: false,
  isPrivacyPolicyChecked: false,
  isPrivacyPolicySeen: false,
  verifyCouponLoading: false,
  couponCode: '',
  couponValid: false,
  verifyCouponError: null
};

const packageDetailsSlice = createSlice({
  name: 'packageDetailsPage',
  initialState,
  reducers: {
    requestUpdateSelectedPackage: {
      reducer: (state) => {
        state.updateSelectedPackageLoading = true;
      },
      prepare
    },
    requestVerifyCoupon: {
      reducer: (state) => {
        state.verifyCouponLoading = true;
      },
      prepare
    },
    successVerifyCoupon(state, action) {
      state.verifyCouponLoading = false;
      state.verifyCouponError = null;
      state.couponValid = true;
      state.couponCode = action.payload;
    },
    failureVerifyCoupon(state, action) {
      state.verifyCouponError = get(action.payload, 'message', 'something went wrong');
      state.verifyCouponLoading = false;
      state.couponValid = false;
      state.couponCode = '';
    },
    successUpdateSelectedPackage(state) {
      state.updateSelectedPackageLoading = false;
    },
    failureUpdateSelectedPackage(state, action) {
      state.updateSelectedPackageLoading = false;
      state.updateSelectedPackageError = get(action.payload, 'message', 'something_went_wrong');
    },
    setTermsCheckbox(state, action) {
      state.isTermsChecked = action.payload;
    },
    setTermsSeen(state, action) {
      state.isTermsSeen = action.payload;
    },
    setPrivacyPolicyCheckBox(state, action) {
      state.isPrivacyPolicyChecked = action.payload;
    },
    setPrivacyPolicySeen(state, action) {
      state.isPrivacyPolicySeen = action.payload;
    },
    resetCouponCodeValid(state) {
      state.couponValid = false;
    },
    resetState(state) {
      state.updateSelectedPackageLoading = false;
      state.updateSelectedPackageError = null;
      state.isTermsChecked = false;
      state.isTermsSeen = false;
      state.isPrivacyPolicyChecked = false;
      state.isPrivacyPolicySeen = false;
      state.verifyCouponLoading = false;
      state.couponCode = '';
      state.couponValid = false;
      state.verifyCouponError = null;
    }
  }
});

export const {
  requestUpdateSelectedPackage,
  successUpdateSelectedPackage,
  failureUpdateSelectedPackage,
  resetState,
  setTermsCheckbox,
  setTermsSeen,
  setPrivacyPolicyCheckBox,
  setPrivacyPolicySeen,
  requestVerifyCoupon,
  successVerifyCoupon,
  failureVerifyCoupon,
  resetCouponCodeValid
} = packageDetailsSlice.actions;

export default packageDetailsSlice.reducer;
