import { STORIES_STATES } from '@app/utils/constants';
import { getQueryResponse } from '@app/utils/graphqlUtils';
import { AnyAction } from '@reduxjs/toolkit';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { GET_RECENTLY_VIEWED_STORIES, GET_TRENDING_STORIES } from './queries';
import {
  failureGetRecentlyViewedStories,
  failureGetTrendingStories,
  requestGetRecentlyViewedStories,
  requestGetTrendingStories,
  successGetRecentlyViewedStories,
  successGetTrendingStories
} from './reducer';

export function* getRecentlyViewedStories(action: AnyAction): Generator<any, any, any> {
  try {
    const payload: any = {
      filters: {
        where: {
          state: {
            equalTo: STORIES_STATES.PUBLISHED
          }
        },
        asBuyer: {
          withID: action.payload.buyerId,
          wereRecentlyViewed: true
        }
      },
      pages: {
        limit: action.payload.limit,
        page: action.payload.page
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_RECENTLY_VIEWED_STORIES, payload);

    if (ok) {
      const storiesData = data.stories.stories;
      yield put(successGetRecentlyViewedStories(storiesData));
    } else {
      yield put(failureGetRecentlyViewedStories(error));
    }
  } catch (err) {
    yield put(failureGetRecentlyViewedStories(err));
  }
}

export function* getTrendingStories(): Generator<any, any, any> {
  try {
    const payload: any = {
      pagination: {
        limit: 10,
        page: 1
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_TRENDING_STORIES, payload);

    if (ok) {
      const storiesData = data.trendingStories.stories;
      yield put(successGetTrendingStories(storiesData));
    } else {
      yield put(failureGetTrendingStories(error));
    }
  } catch (err) {
    yield put(failureGetTrendingStories(err));
  }
}

export default function* IndividualDashboardContainer() {
  yield all([
    takeLatest(requestGetRecentlyViewedStories.toString(), getRecentlyViewedStories),
    takeLatest(requestGetTrendingStories.toString(), getTrendingStories)
  ]);
}

export const myDashboardSaga = [
  takeLatest(requestGetRecentlyViewedStories.toString(), getRecentlyViewedStories),
  takeLatest(requestGetTrendingStories.toString(), getTrendingStories)
];
