import { colors, fonts } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import storyItemCheckmark from '@images/icons/mydashboard/story-item-check.svg';

export type StoryStatusCheckmarkProps = {
  checked: boolean;
  text: string;
};

const CheckmarkContainer = styled.div`
  display: flex;
  align-items: center;
  &:not(:first-of-type) {
    margin-left: 1.5rem;
  }
`;

const CheckmarkChecked = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 100%;
  border: 1px solid ${colors.myDashboardColors.storyItemCheckmarkBorder};
  position: relative;
  background-color: ${colors.myDashboardColors.storyItemCheckmarkCheckedBg};

  & img {
    position: absolute;
    top: 0;
    left: 0.125rem;
  }
`;

const CheckmarkUnchecked = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 100%;
  border: 1px solid ${colors.myDashboardColors.storyItemCheckmarkBorder};
  background-color: ${colors.myDashboardColors.storyItemCheckmarkUncheckedBg};
`;

const CheckmarkText = styled.div`
  margin-left: 0.25rem;
  color: ${colors.myDashboardColors.storyName};
  ${fonts.weights.black()}
`;

const StoryStatusCheckmark = ({ checked, text }: StoryStatusCheckmarkProps) => {
  return (
    <CheckmarkContainer>
      {checked ? (
        <CheckmarkChecked data-testid="checkmark-checked">
          <img src={storyItemCheckmark} />
        </CheckmarkChecked>
      ) : (
        <CheckmarkUnchecked></CheckmarkUnchecked>
      )}
      <CheckmarkText>{text}</CheckmarkText>
    </CheckmarkContainer>
  );
};

export default StoryStatusCheckmark;
