import React from 'react';
import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import styled from 'styled-components';
// import _ from 'lodash';
import { TERMS_AND_CONDITIONS_URL } from '@app/utils/constants';
import PdfViewer from '@app/components/PdfViewer';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { setTermsSeen } from '../PackageDetailsPage/reducer';
import { connect } from 'react-redux';

const TermsAndConditionsPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 8.31rem 5rem 9.43rem;

  & > div {
    height: 55vh;
  }
`;

const TermsAndConditionsHeader = styled.p`
  ${fonts.family.SpoofFamily};
  font-style: normal;
  ${fonts.weights.black};
  font-size: 3rem;
  line-height: 3.93rem;
  text-align: center;
  color: ${colors.primary};
  text-transform: capitalize;
`;

// const TermsAndConditionsList = styled.ol`
//   max-width: 53.37rem;
//   margin-top: 2rem;
// `;

// const TermsAndConditionsListItem = styled.li`
//   font-style: normal;
//   ${fonts.weights.medium};
//   font-size: 1rem;
//   line-height: 1.25rem;
//   color: ${colors.listItemColor};
// `;

const CloseBtn = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.62rem 2rem;
  width: 6.75rem;
  height: 2.75rem;
  background: ${colors.termsAndConditionCloseBtn};
  border-radius: 0.5rem;
  border: none;
  outline: none;
  color: ${colors.termsAndConditionListItem};
  font-style: normal;
  ${fonts.weights.bold};
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 2rem;
  cursor: pointer;
`;

export type TermsProps = {
  dispatchSetTermsSeen: (checked: boolean) => AnyAction;
};

const TermsAndConditionsContainer = ({ dispatchSetTermsSeen }: TermsProps) => {
  return (
    <TermsAndConditionsPageContainer>
      <TermsAndConditionsHeader>{translate('terms_and_conditions')}</TermsAndConditionsHeader>
      {/* <TermsAndConditionsList>
        {_.range(14).map((number: number) => (
          <TermsAndConditionsListItem key={number}>
            {translate(`terms_and_conditions_${number + 1}`)}
          </TermsAndConditionsListItem>
        ))}
      </TermsAndConditionsList> */}
      <PdfViewer file={TERMS_AND_CONDITIONS_URL} handleLastPageSeen={() => dispatchSetTermsSeen(true)} />
      <CloseBtn onClick={() => history.back()} data-testid="back-btn">
        {translate('close_btn')}
      </CloseBtn>
    </TermsAndConditionsPageContainer>
  );
};

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchSetTermsSeen: (checked: boolean) => dispatch(setTermsSeen(checked))
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(TermsAndConditionsContainer);

export const TermsAndConditionsContainerTest = TermsAndConditionsContainer;
