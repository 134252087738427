import { gql } from 'apollo-boost';

export const GET_OFFER_HISTORY = gql`
  query OfferDetailsHistory($input: OfferHistoryInput!, $pagination: OffersPagination!) {
    offerDetailsHistory(input: $input, pagination: $pagination) {
      transactions {
        id
        amount
        currency
        notes
        offerEntity {
          id
          placedBy
          firstName
          lastName
          profilePic
          profileBanner
          designation
          companyName
          email
        }
        offerState
        createdAt
        updatedAt
        deletedAt
        expiresAt
        daysToClose
      }
      offerState
      placedOn
      expiresOn
      daysLeft
      total
    }
  }
`;

export const GET_CONVERSATION_HISTORY = gql`
  query conversationHistory($input: ConversationHistoryInput!, $pagination: ConversationPagination!) {
    conversationHistory(input: $input, pagination: $pagination) {
      conversationDetails {
        type
        state
        message
        userType
      }
      seller {
        id
        firstName
        lastName
      }
      buyer {
        id
        firstName
        lastName
      }
      total
    }
  }
`;
