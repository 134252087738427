import React from 'react';
import styled from 'styled-components';
import { CustomButton, Timer } from 'tsw-sdk';
import { translate } from '../IntlGlobalProvider';
import { Modal } from 'antd';
import T from '../T';
import OTPField from '../OTPField';

interface OTPModalTypes {
  visible: boolean;
  handleOtpInput: (x: string) => void;
  toggleResend: () => void;
  handleCancel: () => void;
  onOtpVerify?: () => void;
  resendOtpConfirm?: () => void;
  showResend: boolean;
  contactNum?: string;
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 0.75rem;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`;

const OtpTitle = styled(T)`
  && {
    line-height: 3.975rem;
    text-align: center;
  }
`;
const OtpMessage = styled.p`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  text-align: center;
`;

const OtpText = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  opacity: 0.5;
  &:hover {
    cursor: pointer;
  }
`;

const OtpButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  margin: 0 4rem;
`;

const OTPModal = ({
  visible,
  handleOtpInput,
  toggleResend,
  showResend,
  contactNum,
  handleCancel,
  onOtpVerify,
  resendOtpConfirm
}: OTPModalTypes) => {
  return (
    <StyledModal
      visible={visible}
      centered={true}
      footer={null}
      closable={true}
      onCancel={handleCancel}
      destroyOnClose={true}
    >
      <ModalContent>
        <OtpTitle id="mobile_otp_heading" type="spoofHeading" />
        <OtpMessage>{translate('mobile_otp_message', { contactNum: contactNum })}</OtpMessage>
        <OTPField data-testid="otp-input" onOtpInput={handleOtpInput} />
        <Timer toggleResend={toggleResend} runTimer={showResend} initialTime={120} />
        <OtpButtonContainer>
          <CustomButton data-testid="otp-verify" onClick={onOtpVerify}>
            {translate('otp_verify')}
          </CustomButton>
          <OtpText data-testid="resend-button" hidden={!showResend} onClick={resendOtpConfirm}>
            {translate('mobile_otp_resend')}
          </OtpText>
        </OtpButtonContainer>
      </ModalContent>
    </StyledModal>
  );
};

export default OTPModal;
