import { colors, fonts } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import { If } from 'tsw-sdk';
import { NotificationItemProps } from '../../types';

const NotificationItemContainer = styled.div`
  max-width: 766px;
  height: auto;
  background-color: ${colors.white};
  border-radius: 0.71rem 0.71rem 0.71rem 0px;
  display: flex;
  flex-direction: column;
  padding: 0.68rem 4rem 0.68rem 1.43rem;
  position: relative;
  margin-top: 1rem;
  cursor: pointer;
`;

const NewNotificationPill = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  background-color: ${colors.notificationColors.notificationPillColor};
  position: absolute;
  top: -0.3rem;
  right: -0.3rem;
`;

const UnClickableOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.notificationColors.disabledOverlay};
  border-radius: 0.71rem 0.71rem 0.71rem 0px;
  position: absolute;
  top: 0;
  left: 0;
`;

const NotificationTitle = styled.p`
  ${fonts.weights.fw700()};
  ${fonts.size.big()};
  line-height: 1.62rem;
  color: ${colors.notificationColors.notificationTitle};
`;

const NotificationContent = styled.p`
  margin-top: 0.875rem;
  ${fonts.weights.fw400()};
  ${fonts.size.big()};
  line-height: 1.62rem;
  color: ${colors.notificationColors.notificationTitle};
`;

const NotificationItem = ({ isRead, clickable, showTitle, notification, type, handleClick }: NotificationItemProps) => {
  return (
    <NotificationItemContainer
      onClick={() => handleClick(notification, type)}
      data-testid={`notification-item-${notification?.id}`}
    >
      <If condition={!isRead}>
        <NewNotificationPill />
      </If>
      <If condition={!clickable}>
        <UnClickableOverlay />
      </If>
      <If condition={showTitle}>
        <NotificationTitle>{notification?.title}</NotificationTitle>
      </If>
      <NotificationContent>{notification?.desc}</NotificationContent>
    </NotificationItemContainer>
  );
};

export default NotificationItem;
