import { gql } from 'apollo-boost';

export const UPDATE_BUYER_SUBSCRIPTION_PLAN = gql`
  mutation UpdateBuyerSubscriptionPlan($input: BuyerSubscriptionPlanInput!) {
    updateBuyerSubscriptionPlan(input: $input) {
      planSelected
    }
  }
`;

export const APPLY_COUPON = gql`
  mutation ApplyCoupon($input: ApplyCouponInput!) {
    applyCoupon(input: $input) {
      status
    }
  }
`;

export const VERIFY_COUPON = gql`
  query VerifyCoupon($input: ApplyCouponInput!) {
    verifyCoupon(input: $input) {
      status
      reason
    }
  }
`;
