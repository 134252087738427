import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import { prepare } from '@app/utils';

export const initialState = {
  recentlyViewedStories: [],
  trendingStories: [],
  getRecentlyViewedStoriesLoading: false,
  getRecentlyViewedStoriesError: null,
  getTrendingStoriesLoading: false,
  getTrendingStoriesError: null
};

export const individualDashboardSlice = createSlice({
  name: 'myDashboard',
  initialState,
  reducers: {
    requestGetRecentlyViewedStories: {
      reducer: (state) => {
        state.getRecentlyViewedStoriesLoading = true;
        state.getRecentlyViewedStoriesError = null;
      },
      prepare
    },
    requestGetTrendingStories: {
      reducer: (state) => {
        state.getTrendingStoriesLoading = true;
        state.getTrendingStoriesError = null;
      },
      prepare
    },
    successGetTrendingStories(state, action) {
      state.trendingStories = action.payload;
      state.getTrendingStoriesLoading = false;
      state.getTrendingStoriesError = null;
    },
    failureGetTrendingStories(state, action) {
      state.getTrendingStoriesLoading = false;
      state.getTrendingStoriesError = get(action.payload, 'message', 'something_went_wrong');
    },
    successGetRecentlyViewedStories(state, action) {
      state.recentlyViewedStories = action.payload;
      state.getRecentlyViewedStoriesLoading = false;
      state.getRecentlyViewedStoriesError = null;
    },
    failureGetRecentlyViewedStories(state, action) {
      state.getRecentlyViewedStoriesLoading = false;
      state.getRecentlyViewedStoriesError = get(action.payload, 'message', 'something_went_wrong');
    }
  }
});

export const {
  requestGetRecentlyViewedStories,
  successGetRecentlyViewedStories,
  failureGetRecentlyViewedStories,
  requestGetTrendingStories,
  successGetTrendingStories,
  failureGetTrendingStories
} = individualDashboardSlice.actions;

export default individualDashboardSlice.reducer;
