import { colors, fonts } from '@app/themes';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import BackIcon from '@images/icons/browse-stories/back-icon.svg';
import TicketIcon from '@images/icons/browse-stories/ticket2-icon.svg';
import ProfilePic from '@images/icons/browse-stories/author-profile-icon.svg';
import MinimizeIcon from '@images/icons/browse-stories/minimize-icon.svg';
import DownloadIcon from '@images/icons/browse-stories/download2-icon.svg';
import HeartIcon from '@images/icons/browse-stories/heart-icon.svg';
import SelectedHeartIcon from '@images/icons/browse-stories/fav-heart-icon.svg';

import ShareIcon from '@images/icons/browse-stories/share-icon.svg';

import { translate } from '@app/components/IntlGlobalProvider';
import { Button, message, Rate, Spin, Collapse } from 'antd';
import Tags from './components/TagsContainer';
import { CustomInput, If } from 'tsw-sdk';
import { CustomButtonProps, StoryDetailsProps } from './types';
import { Portal } from '@app/components/Portal';
import MakeOfferComponent from './components/MakeOfferComponent';
import { createStructuredSelector } from 'reselect';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import {
  requestConcludeOffer,
  requestGetBuyerLatestOffer,
  requestGetImages,
  requestGetNarration,
  requestGetOfferId,
  requestGetScreenPlay,
  requestGetStory,
  requestMakeCounterOffer,
  requestMakeOffer,
  requestSellerCounterOffer,
  requestSendMessage,
  requestShareStory,
  requestSubmitReview,
  requestUpdateViewTime,
  resetState
} from './reducer';
import { selectUserProfile } from '@app/containers/ProfileContainer/selectors';
import { injectIntl } from 'react-intl';
import {
  selectStoryData,
  selectScreenPlayUrl,
  selectScreenplayUrlLoading,
  selectNarrationUrl,
  selectImagesUrlList,
  selectNarrationUrlLoading,
  selectImagesUrlListLoading,
  selectPollId,
  selectOfferId,
  selectSellerCounterOfferDetails,
  selectOfferState,
  selectBuyerLatestOfferAmount
} from './selectors';
import {
  ATTACHMENT_TYPE,
  COGNITO_USER_GROUPS,
  FAQ_URL,
  MESSAGE_TYPES,
  NARRATION_PATH,
  OFFER_ACTION,
  OFFER_STATE,
  PROFILE_IMAGE_PROD_URL,
  PROFILE_IMAGE_QA_URL,
  PROFILE_IMAGE_URL,
  REQUEST_STATE,
  SCREENPLAY_ACCESS_STATE,
  SCREENPLAY_PATH
} from '@app/utils/constants';

import { LoadingOutlined } from '@ant-design/icons';
import CounterOfferContainer from './components/CounterOfferContainer';
import { Link, useLocation } from 'react-router-dom';
import { StarIcon } from '@app/utils/assets';
import { selectAccessToken } from '../TokenProvider/selectors';
import { get } from 'lodash-es';
import CounterOfferModal from '../DashboardContainerPage/IndividualDashboard/tabs/MyOffers/components/CounterOfferModal';
import DeclineOfferModal from '../DashboardContainerPage/IndividualDashboard/tabs/MyOffers/components/DeclineOfferModal';
import AcceptOfferModal from '../DashboardContainerPage/IndividualDashboard/tabs/MyOffers/components/AcceptOfferModal';
import {
  requestGenerateStoryPdf,
  requestToggleFavourite,
  resetStoryPdfData
} from '../DashboardContainerPage/IndividualDashboard/tabs/BrowseStories/reducer';
import {
  selectStoryPdfLoading,
  selectStoryPdfUrl
} from '../DashboardContainerPage/IndividualDashboard/tabs/BrowseStories/selectors';
import history from '@app/utils/history';
import RequestScreenplayAccess from './components/RequestScreenplayAccess';

const { Panel } = Collapse;

const Container = styled.div`
  width: 100%;
  height: auto;
  background: ${colors.dashboardBg};
`;

const StyledBanner = styled.div`
  width: 100%;
  height: 13.68rem;
  background: ${colors.browseStoriesColors.cardHeaderColor};

  overflow: hidden;
  position: relative;
`;

const BannerContainer = styled.div`
  width: 150%;
  height: 100%;

  position: absolute;
  left: -1.93rem;
  top: -1.56rem;

  display: flex;
  flex-wrap: wrap;
  column-gap: 1.37rem;
`;

const BannerText = styled.h1`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw900()};
  ${fonts.size.LargeBannerText()};
  line-height: 5.59rem;
  text-align: center;

  color: ${colors.browseStoriesColors.largeBannerTextColor};
  margin-bottom: 0px;
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 2rem 3.43rem 2rem 2rem;
`;

const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.79rem;

  cursor: pointer;
`;

const BackButtonText = styled.p`
  ${fonts.weights.fw800()};
  ${fonts.size.small()};
  line-height: 1rem;
  text-align: center;

  color: ${colors.browseStoriesColors.labelColor};
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  margin-top: 0.5rem;
`;

const TitleText = styled.h1`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  line-height: 3.93rem;

  color: ${colors.darkBorder};
`;

const StyledImg = styled.img``;
const ProfileImg = styled.img`
  width: 9rem;
  height: 9rem;
  border-radius: 100%;
  margin-top: 2.56rem;
`;

export const StyledOfferButton = styled(Button)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.62rem;
    background-color: ${colors.darkBorder};
    color: ${colors.white};
    border-radius: 0.5rem;
    ${fonts.weights.fw800()};
    ${fonts.size.regular()};
    line-height: 1.5rem;
    border: none;
    padding: 0.62rem 2rem;
  }
`;

const AuthorNameText = styled.h1`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw900()};
  font-size: 2.16rem;
  line-height: 2.7rem;
  text-align: left;

  color: ${colors.darkBorder};

  margin-top: 2rem;
  margin-bottom: 0px;
`;

const AuthorDesignationText = styled.p`
  ${fonts.weights.fw600()};
  font-size: 1.62rem;
  line-height: 2.16rem;
  text-align: left;

  color: ${colors.darkBorder};
  margin-top: 0.72rem;
`;

const StoryInfoContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  margin-top: 2.56rem;
  margin-bottom: 3rem;
`;

const StorySubContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 50%;
`;

const StorySub2Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

const StoryTableContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`;

const SubHeadingText = styled.p`
  ${fonts.weights.fw600()};
  ${fonts.size.regular()};
  line-height: 1.25rem;

  color: ${colors.browseStoriesColors.labelColor};
`;

const InfoText = styled.p`
  ${fonts.weights.fw800()};
  ${fonts.size.regular()};
  line-height: 1.25rem;

  color: ${colors.browseStoriesColors.labelColor};
`;

const TagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`;

const TagsSubContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

const StorylineTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.37rem;
`;

const PointerImg = styled.img`
  cursor: pointer;
`;

const InlineDownloadContainers = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 3.62rem;

  margin-bottom: 3rem;
`;

const DownloadSubContainer = styled.div`
  width: 13.12rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`;

const DownloadText = styled.p`
  ${fonts.weights.fw800()};
  ${fonts.size.regular()};
  line-height: 1.25rem;

  color: ${colors.browseStoriesColors.labelColor};
  text-decoration: underline;
`;

const ExhaustedAttemptsText = styled.p`
  ${fonts.weights.fw800()};
  ${fonts.size.small()};
  line-height: 0.75rem;

  color: ${colors.redClr};
`;

const AddToFavouritesButton = styled(Button)<CustomButtonProps>`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: ${colors.darkBorder};
    border-radius: 0.5rem;
    ${fonts.weights.fw800()}
    ${fonts.size.regular()};
    line-height: 1.5rem;
    border: 1px solid ${colors.primary};
    padding: 0.62rem 2rem;
    gap: 0.62rem;
    ${(props) => props.width && `width:${props.width}`};
    ${(props) => props.margin && `margin-top:${props.margin}`};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1.5rem;

  margin-top: 0.81rem;
`;

const ReviewSubmissionContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;

  margin-top: 2.56rem;
`;

const CommentInput = styled(CustomInput)`
  width: 16.18rem;
`;

const ScreenPlayDownloadLink = styled.a`
  display: none;
`;

const AudioNarrationDownloadLink = styled.a`
  display: none;
`;

const ImagesDownloadLink = styled.a`
  display: none;
`;

const ReviewSubmitContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
  align-items: center;
`;

const StyledRate = styled(Rate)`
  && {
    &.ant-rate {
      color: ${colors.storyPage.starIconFill};
    }
  }
`;

const RequestAccessButton = styled(StyledOfferButton)`
  && {
    padding: 0.5px 0.63rem;
    height: 1.5rem;
  }
`;

const Accordion = styled(Collapse)`
  &&& {
    width: 24rem;
    border: none;
    background: none;
    margin-top: auto;
    margin-left: auto;

    & .ant-collapse-expand-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & .ant-collapse-header {
      display: flex;
      align-items: center;
      padding: 12px 0px;
      border-bottom: 1px solid ${colors.myDashboardColors.accordionBorder};
      ${fonts.family.Inter()};
      ${fonts.weights.fw200()}

      & * {
        ${fonts.family.Inter()};
        ${fonts.weights.fw200()}
      }
    }

    & .ant-collapse-header-text {
      text-transform: uppercase;
    }

    & .ant-collapse-item {
      border: none;
      box-shadow: none;
    }

    & .ant-collapse-content {
      background-color: transparent;
      border: none;
    }
  }
`;

const AccordionPanel = styled(Panel)`
  &&& {
    text-transform: uppercase;

    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0.62rem 0px 0.5rem 0px;
    }
  }
`;

const AuthorContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const AuthorInfoContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
`;

const TipInfo = styled.p`
  color: ${colors.primary};
  ${fonts.weights.fw600()};
  ${fonts.size.xSmall()};
  line-height: 1rem;
  margin-top: 0.28rem;
  text-transform: none;
`;

const UnderlineTextLink = styled.a`
  text-decoration: underline !important;
  cursor: pointer;
  color: ${colors.primary} !important;
`;

const StoryDetailPage = ({
  buyerProfile,
  storyPdfUrl,
  story,
  screenplayUrl,
  narrationUrl,
  imagesUrl,
  screenPlayUrlLoading,
  narrationUrlLoading,
  imagesUrlLoading,
  pollId,
  accessToken,
  offerIdData,
  counterOfferData,
  storyPdfLoading,
  offerState,
  buyerOfferAmount,
  dispatchMakeOffer,
  dispatchGetStoryDetails,
  dispatchGetSignedUrl,
  dispatchResetState,
  dispatchGetNarrationUrl,
  dispatchGetImagesUrl,
  dispatchGetViewTime,
  dispatchSubmitStoryReview,
  dispatchShareStory,
  dispatchConcludeOffer,
  dispatchToggleFavStory,
  dispatchGenerateStoryPdf,
  dispatchResetStoryPdfUrl,
  dispatchGetOfferId,
  dispatchGetSellerCounterOffer,
  dispatchCounterOffer,
  dispatchGetLatestBuyerOffer,
  dispatchSendAccessRequest
}: StoryDetailsProps) => {
  const locationUrl = useLocation<any>();
  const [showStoryline, setShowStoryline] = useState<boolean>(true);
  const [showMakeOffer, setShowMakeOffer] = useState<boolean>(false);
  const [isOfferCountered, setIsOfferCountered] = useState<boolean>(false);
  const [isOfferMade, setIsOfferMade] = useState<boolean>(false);
  const [storyId, setStoryId] = useState<string>('');
  const [rateValue, setRateValue] = useState<number>(0);
  const [commentInput, setCommentInput] = useState<string>('');
  const [isFavourite, setIsFavourite] = useState<boolean>(false);

  const [showReview, setShowReview] = useState<boolean>(true);
  const [canShareWithTeam, setCanShareWithTeam] = useState<boolean>(true);
  const [authorId, setAuthorId] = useState<number>(0);

  const [screenPlayName, setScreenPlayName] = useState<string>('');
  const [narrationName, setNarrationName] = useState<string>('');
  const [imagesName, setimagesName] = useState<string[]>([]);

  const [showCounterOfferModal, setShowCounterOfferModal] = useState<boolean>(false);
  const [showDeclineOffer, setShowDeclineOffer] = useState<boolean>(false);
  const [showAcceptOffer, setShowAcceptOffer] = useState<boolean>(false);
  const [showRequestScreebplayPage, setShowRequestScreebplayPage] = useState<boolean>(false);

  // const [imageStateUrl, setImageStateUrl] = useState<string[]>(imagesUrl);

  const screenPlayDownloadRef = useRef<any>(null);
  const narrationDownloadRef = useRef<any>(null);
  const imagesDownloadRef = useRef<any>(null);

  const SpinIcon = <LoadingOutlined style={{ fontSize: 12 }} spin />;

  useEffect(() => {
    if (storyPdfUrl) {
      const anchorTag = document.createElement('a');
      anchorTag.href = storyPdfUrl;
      anchorTag.setAttribute('target', '_blank');
      anchorTag.click();
      dispatchResetStoryPdfUrl();
    }
  }, [storyPdfUrl]);

  useEffect(() => {
    if (screenplayUrl) {
      screenPlayDownloadRef?.current.click();
    }
  }, [screenplayUrl]);

  useEffect(() => {
    if (narrationUrl) {
      narrationDownloadRef?.current.click();
    }
  }, [narrationUrl]);

  useEffect(() => {
    if (imagesUrl.length > 0) {
      imagesUrl.forEach((curr) => {
        imagesDownloadRef.current.href = curr;
        imagesDownloadRef?.current?.click();
      });
    }
  }, [imagesUrl]);

  useEffect(() => {
    const params = new URLSearchParams(locationUrl?.search);
    const storyId = params.get('storyId');

    if (storyId && buyerProfile) {
      setStoryId(storyId);
      dispatchGetStoryDetails(storyId, buyerProfile?.id, 0, 1);
      dispatchGetOfferId(storyId, buyerProfile?.id);
      dispatchGetViewTime(storyId, pollId);
    }

    const cognitoGroup: any = get(accessToken, 'cognito:groups', []);

    if (cognitoGroup.length) {
      if (cognitoGroup.includes(COGNITO_USER_GROUPS.ORGANIZATION) || cognitoGroup.includes(COGNITO_USER_GROUPS.ADMIN)) {
        setCanShareWithTeam(true);
      } else {
        setCanShareWithTeam(false);
      }
    }
    return () => {
      dispatchResetState();
      dispatchResetStoryPdfUrl();
    };
  }, [buyerProfile]);

  useEffect(() => {
    let interval: any;
    if (pollId !== 0) {
      interval = setInterval(() => {
        dispatchGetViewTime(storyId, pollId);
      }, 5000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [pollId]);

  useEffect(() => {
    if (story?.supportingMaterials) {
      story?.supportingMaterials.forEach((el: any) => {
        if (el.attachmentType === ATTACHMENT_TYPE.SCREENPLAY) {
          const newScreenPlayName = el.fileName.replace('storyMaterial/screenplays', '');
          setScreenPlayName(newScreenPlayName);
        } else if (el.attachmentType === ATTACHMENT_TYPE.AUDIO_NARRATION) {
          const newAudioNarration = el.fileName.replace('storyMaterial/audioNarrations', '');
          setNarrationName(newAudioNarration);
        } else {
          setimagesName((curr) => [...curr, el.fileName.replace('storyMaterial/images', '')]);
        }
      });
    }
    if (story?.writer?.id) {
      setAuthorId(story?.writer?.id);
    }

    if (story?.buyerActions?.hasLiked) {
      setIsFavourite(true);
    }

    if (story?.buyerActions?.hasReviewed) {
      setShowReview(false);
    }
  }, [story]);

  useEffect(() => {
    if (screenplayUrl) {
      screenPlayDownloadRef?.current.click();
    }
  }, [screenplayUrl]);

  useEffect(() => {
    if (narrationUrl) {
      narrationDownloadRef?.current.click();
    }
  }, [narrationUrl]);

  useEffect(() => {
    if (imagesUrl.length > 0) {
      imagesUrl.forEach((curr) => {
        imagesDownloadRef.current.href = curr;
        imagesDownloadRef?.current?.click();
      });
    }
  }, [imagesUrl]);

  useEffect(() => {
    if (offerIdData !== '') {
      setIsOfferMade(true);
      dispatchGetSellerCounterOffer(offerIdData);
      dispatchGetLatestBuyerOffer(offerIdData);
    } else {
      setIsOfferMade(false);
    }
  }, [offerIdData]);

  useEffect(() => {
    if (counterOfferData) {
      setIsOfferCountered(true);
    }
  }, [counterOfferData]);

  const handleOfferMade = (offerAmt: number, offerNote: string) => {
    setShowMakeOffer(false);
    dispatchMakeOffer(storyId, '', buyerProfile?.id, offerAmt, offerNote, story?.writer?.id);
  };

  const handleCounterOffer = (counterOffer: number, noteFromBuyer: string) => {
    setShowCounterOfferModal(false);
    dispatchCounterOffer(storyId, offerIdData, buyerProfile?.id, counterOffer, noteFromBuyer, story?.writer?.id);
  };

  const handleScreenplayDownload = () => {
    if (screenPlayName) {
      dispatchGetSignedUrl(SCREENPLAY_PATH, screenPlayName);
    } else {
      message.error(translate('no_file_download'));
    }
  };

  const handleNarrationDownload = () => {
    if (narrationName) {
      dispatchGetNarrationUrl(NARRATION_PATH, narrationName);
    } else {
      message.error(translate('no_file_download'));
    }
  };

  const handleImagesDownload = () => {
    if (imagesName.length > 0) {
      dispatchGetImagesUrl(imagesName);
    } else {
      message.error(translate('no_file_download'));
    }
  };

  const handleSubmitReview = () => {
    if (rateValue !== 0) {
      dispatchSubmitStoryReview(storyId, rateValue, commentInput);
      setShowReview(false);
    } else {
      message.error(translate('review_required'));
    }
  };

  const handleShareStory = () => {
    dispatchShareStory(buyerProfile?.id, storyId);
  };

  const handleAcceptOffer = () => {
    dispatchConcludeOffer(storyId, offerIdData, buyerProfile?.id, story?.writer?.id, OFFER_ACTION.ACCEPT_OFFER, '');
  };

  const handleDeclineOffer = (reason: string) => {
    dispatchConcludeOffer(
      storyId,
      offerIdData,
      buyerProfile?.id,
      story?.writer?.id,
      OFFER_ACTION.DECLINE_OFFER,
      reason
    );
  };

  const handleFavouriteAdd = () => {
    dispatchToggleFavStory(storyId);
    setIsFavourite((prev) => !prev);
  };

  const downloadStoryPdf = () => {
    dispatchGenerateStoryPdf(buyerProfile?.id, storyId, story?.writer?.id);
  };

  const handleSendRequest = (requestMessage: string) => {
    setShowRequestScreebplayPage(false);

    dispatchSendAccessRequest(
      buyerProfile?.id,
      story?.writer?.id,
      story?.id,
      requestMessage,
      MESSAGE_TYPES.SCREENPLAY_REQUEST,
      REQUEST_STATE.REQUESTED
    );
  };

  return (
    <Container>
      <ScreenPlayDownloadLink href={screenplayUrl} ref={screenPlayDownloadRef} target="_blank" download={true} />
      <AudioNarrationDownloadLink href={narrationUrl} ref={narrationDownloadRef} target="_blank" download={true} />
      <ImagesDownloadLink ref={imagesDownloadRef} target="_blank" download={true} />

      <StyledBanner>
        <BannerContainer>
          {_.range(30).map((number: number) => (
            <BannerText key={number}>{story?.title}</BannerText>
          ))}
        </BannerContainer>
      </StyledBanner>
      <ContentContainer>
        <BackButtonContainer onClick={() => history.goBack()} data-testid="back-btn">
          <StyledImg src={BackIcon} />
          <BackButtonText>{translate('back')}</BackButtonText>
        </BackButtonContainer>
        <TitleContainer>
          <TitleText>{story?.title}</TitleText>

          <InfoContainer>
            <If
              condition={
                !isOfferMade || offerState === OFFER_STATE.BUYER_DECLINED || offerState === OFFER_STATE.SELLER_DECLINED
              }
            >
              <StyledOfferButton onClick={() => setShowMakeOffer(true)} data-testid="showmakeoffer-btn">
                <StyledImg src={TicketIcon} />
                <If
                  condition={offerState !== OFFER_STATE.BUYER_DECLINED && offerState !== OFFER_STATE.SELLER_DECLINED}
                  otherwise={translate('re_offer')}
                >
                  {translate('make_offer')}
                </If>
              </StyledOfferButton>
            </If>
            <If condition={offerIdData !== ''}>
              <StyledOfferButton
                onClick={() =>
                  history.push({
                    pathname: '/dashboard',
                    search: `?activeTab=offer-history&storyId=${storyId}&offerId=${offerIdData}&authorId=${authorId}`,
                    state: {
                      counterOfferAmount: counterOfferData?.amount,
                      authorName: story?.writer?.firstName + ' ' + story?.writer?.lastName
                    }
                  })
                }
              >
                {translate('view_offer_history')}
              </StyledOfferButton>
            </If>
            <If condition={story?.expectedOffer !== 0}>
              <SubHeadingText>
                {translate('expected_price', { amount: story?.expectedOffer?.toLocaleString('en-IN') })}
              </SubHeadingText>
            </If>
          </InfoContainer>
        </TitleContainer>
        <AuthorContainer>
          <AuthorInfoContainer>
            <If condition={process.env.ENVIRONMENT_NAME === 'qa'}>
              <ProfileImg
                src={
                  story?.writer?.profilePic !== '' ? `${PROFILE_IMAGE_QA_URL}/${story?.writer?.profilePic}` : ProfilePic
                }
              />
            </If>
            <If condition={process.env.ENVIRONMENT_NAME === 'production'}>
              <ProfileImg
                src={
                  story?.writer?.profilePic !== ''
                    ? `${PROFILE_IMAGE_PROD_URL}/${story?.writer?.profilePic}`
                    : ProfilePic
                }
              />
            </If>
            <If condition={process.env.ENVIRONMENT_NAME !== 'production' && process.env.ENVIRONMENT_NAME !== 'qa'}>
              <ProfileImg
                src={
                  story?.writer?.profilePic !== '' ? `${PROFILE_IMAGE_URL}/${story?.writer?.profilePic}` : ProfilePic
                }
              />
            </If>
            <AuthorNameText>{`by ${story?.writer?.firstName} ${story?.writer?.lastName}`}</AuthorNameText>
            <AuthorDesignationText>{translate('author')}</AuthorDesignationText>
          </AuthorInfoContainer>
          <Accordion accordion={true} expandIconPosition={'right'}>
            <AccordionPanel header={translate('commission')} key={1}>
              <TipInfo>{translate('commission_content')}</TipInfo>
            </AccordionPanel>
            <AccordionPanel header={translate('faqs')} key={2}>
              <TipInfo>
                {translate('faq_before_underline')}
                <UnderlineTextLink href={FAQ_URL} target="_blank">
                  {translate('faqs')}
                </UnderlineTextLink>
                {translate('faq_after_underline')}
              </TipInfo>
            </AccordionPanel>
          </Accordion>
        </AuthorContainer>

        <If condition={isOfferCountered && offerState === OFFER_STATE.SELLER_COUNTERED}>
          <CounterOfferContainer
            acceptOffer={setShowAcceptOffer}
            declineOffer={setShowDeclineOffer}
            offerAmount={counterOfferData?.amount}
            counterOffer={setShowCounterOfferModal}
          />
        </If>
        <If
          condition={
            isOfferMade && (offerState === OFFER_STATE.BUYER_COUNTERED || offerState === OFFER_STATE.OFFER_PLACED)
          }
        >
          <AuthorDesignationText>{translate('awaiting_response_seller')}</AuthorDesignationText>
          <SubHeadingText>
            {translate('latest_buyer_offer', { amount: buyerOfferAmount?.toLocaleString('en-IN') })}
          </SubHeadingText>
        </If>

        <If condition={offerState === OFFER_STATE.BUYER_DECLINED}>
          <AuthorDesignationText>{translate('buyer_declined_offer')}</AuthorDesignationText>
        </If>

        <If condition={offerState === OFFER_STATE.SELLER_DECLINED}>
          <AuthorDesignationText>{translate('seller_declined_offer')}</AuthorDesignationText>
        </If>

        <StoryInfoContainer>
          <StorySubContainer>
            <StoryTableContainer>
              <InfoContainer>
                <SubHeadingText>{translate('language')}</SubHeadingText>
                {story?.languages?.map((el: any) => (
                  <InfoText key={el.id}>{el.name}</InfoText>
                ))}
              </InfoContainer>
              <InfoContainer>
                <SubHeadingText>{translate('genre')}</SubHeadingText>
                {story?.genres?.map((el: any) => (
                  <InfoText key={el.id}>{el.name}</InfoText>
                ))}
              </InfoContainer>
              <InfoContainer>
                <SubHeadingText>{translate('story_type')}</SubHeadingText>
                <InfoText>{story?.storyType?.name}</InfoText>
              </InfoContainer>
            </StoryTableContainer>
            <InfoContainer>
              <SubHeadingText>{translate('logline')}</SubHeadingText>
              <InfoText>{story?.logline}</InfoText>
            </InfoContainer>
            <InfoContainer>
              <StorylineTitleContainer>
                <InfoText>{translate('synopsis')}</InfoText>
                <PointerImg
                  src={MinimizeIcon}
                  onClick={() => setShowStoryline((prev) => !prev)}
                  data-testid="showmakeoffer-btn-3"
                />
              </StorylineTitleContainer>
              <If condition={showStoryline}>
                <SubHeadingText>{story?.storyline}</SubHeadingText>
              </If>
            </InfoContainer>
          </StorySubContainer>
          <StorySub2Container>
            <If condition={story?.coAuthor1 || story?.coAuthor2}>
              <TagsContainer>
                <InfoText>{translate('co_author')}</InfoText>
                <SubHeadingText>{story?.coAuthor1}</SubHeadingText>
                <SubHeadingText>{story?.coAuthor2}</SubHeadingText>
              </TagsContainer>
            </If>
            <TagsContainer>
              <InfoText>{translate('previous_sold')}</InfoText>
              {story?.writer?.publishedStories?.map((el: any) => (
                <Tags key={el.id} name={el.name} />
              ))}
            </TagsContainer>
            <TagsContainer>
              <InfoText>{translate('tags')}</InfoText>
              <TagsSubContainer>
                {story?.tags?.map((el: any) => (
                  <Tags key={el.id} name={el.name} />
                ))}
              </TagsSubContainer>
            </TagsContainer>
          </StorySub2Container>
        </StoryInfoContainer>
        <InlineDownloadContainers>
          <DownloadSubContainer>
            <SubHeadingText>{translate('storyline')}</SubHeadingText>
            <StoryTableContainer>
              <DownloadText>{translate('download_pdf')}</DownloadText>
              <If condition={!storyPdfLoading} otherwise={<Spin indicator={SpinIcon} />}>
                <PointerImg src={DownloadIcon} onClick={downloadStoryPdf} />
              </If>
            </StoryTableContainer>
          </DownloadSubContainer>
          <DownloadSubContainer>
            <SubHeadingText>{translate('screenplay')}</SubHeadingText>
            <StoryTableContainer>
              <If condition={story?.buyerActions?.hasScreenPlayAccess === SCREENPLAY_ACCESS_STATE.SCREENPLAY_APPROVED}>
                <DownloadText>{translate('download_pdf')}</DownloadText>
                <If condition={!screenPlayUrlLoading} otherwise={<Spin indicator={SpinIcon} />}>
                  <PointerImg src={DownloadIcon} onClick={handleScreenplayDownload} data-testid="screenplay-download" />
                </If>
              </If>
              <If
                condition={
                  story?.buyerActions?.hasScreenPlayAccess === SCREENPLAY_ACCESS_STATE.SCREENPLAY_NOT_REQUESTED ||
                  (story?.buyerActions?.hasScreenPlayAccess === SCREENPLAY_ACCESS_STATE.SCREENPLAY_DECLINED &&
                    story?.buyerActions?.hasScreenPlayAccessRequestCount < 3)
                }
              >
                <RequestAccessButton onClick={() => setShowRequestScreebplayPage(true)}>
                  {translate('request_access')}
                </RequestAccessButton>
              </If>
              <If
                condition={
                  story?.buyerActions?.hasScreenPlayAccess === SCREENPLAY_ACCESS_STATE.SCREENPLAY_DECLINED &&
                  story?.buyerActions?.hasScreenPlayAccessRequestCount >= 3
                }
              >
                <ExhaustedAttemptsText>{translate('exhausted_attempts')}</ExhaustedAttemptsText>
              </If>
              <If condition={story?.buyerActions?.hasScreenPlayAccess === SCREENPLAY_ACCESS_STATE.SCREENPLAY_REQUESTED}>
                <Link
                  to={{
                    pathname: '/dashboard',
                    search: `?activeTab=offer-history&storyId=${storyId}&authorId=${story?.writer?.id}&type=conversation`
                  }}
                >
                  <DownloadText>{translate('access_requested')}</DownloadText>
                </Link>
              </If>
            </StoryTableContainer>
          </DownloadSubContainer>
        </InlineDownloadContainers>
        <InlineDownloadContainers>
          <DownloadSubContainer>
            <SubHeadingText>{translate('audio_narration')}</SubHeadingText>
            <StoryTableContainer>
              <DownloadText>{translate('download_mp3')}</DownloadText>
              <If condition={!narrationUrlLoading} otherwise={<Spin indicator={SpinIcon} />}>
                <PointerImg src={DownloadIcon} onClick={handleNarrationDownload} data-testid="narration-download" />
              </If>
            </StoryTableContainer>
          </DownloadSubContainer>
          <DownloadSubContainer>
            <SubHeadingText>{translate('supporting_images')}</SubHeadingText>
            <StoryTableContainer>
              <DownloadText>{translate('download_jpeg')}</DownloadText>
              <If condition={!imagesUrlLoading} otherwise={<Spin indicator={SpinIcon} />}>
                <PointerImg src={DownloadIcon} onClick={handleImagesDownload} data-testid="images-download" />
              </If>
            </StoryTableContainer>
          </DownloadSubContainer>
        </InlineDownloadContainers>
        <If
          condition={
            !isOfferMade || offerState === OFFER_STATE.BUYER_DECLINED || offerState === OFFER_STATE.SELLER_DECLINED
          }
        >
          <ButtonContainer>
            <AddToFavouritesButton onClick={handleFavouriteAdd}>
              <StyledImg src={isFavourite ? SelectedHeartIcon : HeartIcon} />
              {!isFavourite ? translate('add_favourites') : translate('remove_favourites')}
            </AddToFavouritesButton>

            <StyledOfferButton onClick={() => setShowMakeOffer(true)} data-testid="showmakeoffer-btn-2">
              <StyledImg src={TicketIcon} />
              <If
                condition={offerState !== OFFER_STATE.BUYER_DECLINED && offerState !== OFFER_STATE.SELLER_DECLINED}
                otherwise={translate('re_offer')}
              >
                {translate('make_offer')}
              </If>
            </StyledOfferButton>
          </ButtonContainer>
        </If>
        {/* <ViewContainer>
          <StyledImg src={EyeIcon} />
          <BackButtonText>{translate('viewing_stories')}</BackButtonText>
        </ViewContainer> */}
        <If condition={isOfferCountered && offerState === OFFER_STATE.SELLER_COUNTERED}>
          <CounterOfferContainer
            acceptOffer={setShowAcceptOffer}
            declineOffer={setShowDeclineOffer}
            offerAmount={counterOfferData?.amount}
            counterOffer={setShowCounterOfferModal}
          />
        </If>

        <If condition={canShareWithTeam}>
          <AddToFavouritesButton width="29.3rem" margin="1.5rem" onClick={handleShareStory} data-testid="share-button">
            <StyledImg src={ShareIcon} />
            {translate('share_with_team')}
          </AddToFavouritesButton>
        </If>

        <If condition={showReview}>
          <ReviewSubmissionContainer>
            <SubHeadingText>{translate('review_submission')}</SubHeadingText>
            <StyledRate
              character={<StarIcon />}
              value={rateValue}
              onChange={(value) => setRateValue(value)}
              data-testid="rate"
            />
          </ReviewSubmissionContainer>
          <ReviewSubmitContainer>
            <CommentInput
              placeholder={translate('add_comment')}
              value={commentInput}
              onChange={(e) => setCommentInput(e.target.value)}
              data-testid="review-comment-input"
            />
            <StyledOfferButton style={{ height: '2rem' }} onClick={handleSubmitReview} data-testid="review-button">
              {translate('submit_review')}
            </StyledOfferButton>
          </ReviewSubmitContainer>
        </If>
      </ContentContainer>
      <If condition={showMakeOffer}>
        <Portal>
          <MakeOfferComponent handleBack={() => setShowMakeOffer(false)} onOffer={handleOfferMade} />
        </Portal>
      </If>

      <If condition={showCounterOfferModal}>
        <Portal>
          <CounterOfferModal
            handleCancel={() => setShowCounterOfferModal(false)}
            counterOfferAmount={counterOfferData?.amount}
            onCounterOffer={handleCounterOffer}
          />
        </Portal>
      </If>

      <If condition={showDeclineOffer}>
        <Portal>
          <DeclineOfferModal handleCancel={() => setShowDeclineOffer(false)} handleDecline={handleDeclineOffer} />
        </Portal>
      </If>

      <If condition={showAcceptOffer}>
        <Portal>
          <AcceptOfferModal
            handleCancel={() => setShowAcceptOffer(false)}
            handleAccept={handleAcceptOffer}
            authorName={story?.writer?.firstName + ' ' + story?.writer?.lastName}
            offerAmount={counterOfferData?.amount}
            profilePic={story?.writer?.profilePic}
          />
        </Portal>
      </If>

      <If condition={showRequestScreebplayPage}>
        <Portal>
          <RequestScreenplayAccess
            storyTitle={story?.title}
            requestAttempts={story?.buyerActions?.hasScreenPlayAccessRequestCount}
            handleCancel={() => setShowRequestScreebplayPage(false)}
            handleSendRequest={handleSendRequest}
          />
        </Portal>
      </If>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  buyerProfile: selectUserProfile(),
  story: selectStoryData(),
  screenplayUrl: selectScreenPlayUrl(),
  screenPlayUrlLoading: selectScreenplayUrlLoading(),
  narrationUrl: selectNarrationUrl(),
  imagesUrl: selectImagesUrlList(),
  narrationUrlLoading: selectNarrationUrlLoading(),
  imagesUrlLoading: selectImagesUrlListLoading(),
  pollId: selectPollId(),
  storyPdfUrl: selectStoryPdfUrl(),
  accessToken: selectAccessToken(),
  offerIdData: selectOfferId(),
  counterOfferData: selectSellerCounterOfferDetails(),
  storyPdfLoading: selectStoryPdfLoading(),
  offerState: selectOfferState(),
  buyerOfferAmount: selectBuyerLatestOfferAmount()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchMakeOffer: (
      storyId: string,
      offerId: string,
      buyerId: string,
      amount: number,
      notes: string,
      sellerId: string
    ) => dispatch(requestMakeOffer({ storyId, offerId, buyerId, amount, notes, sellerId })),
    dispatchGetStoryDetails: (storyId: string, buyerId: string, limit: number, page: number) =>
      dispatch(requestGetStory({ storyId, buyerId, limit, page })),
    dispatchGetSignedUrl: (folderPath: string, fileName: string) =>
      dispatch(requestGetScreenPlay({ folderPath, fileName })),
    dispatchGetNarrationUrl: (folderPath: string, fileName: string) =>
      dispatch(requestGetNarration({ folderPath, fileName })),
    dispatchGetImagesUrl: (fileNames: string[]) => dispatch(requestGetImages({ fileNames })),
    dispatchResetState: () => dispatch(resetState()),
    dispatchGetViewTime: (storyId: string, pollId: number) => dispatch(requestUpdateViewTime({ storyId, pollId })),
    dispatchSubmitStoryReview: (storyId: string, rating: number, comment: string) =>
      dispatch(requestSubmitReview({ storyId, rating, comment })),
    dispatchShareStory: (buyerId: string, storyId: string) => dispatch(requestShareStory({ buyerId, storyId })),
    dispatchConcludeOffer: (
      storyId: string,
      offerId: string,
      buyerId: string,
      sellerId: string,
      offerAction: string,
      reason: string
    ) => dispatch(requestConcludeOffer({ storyId, offerId, buyerId, sellerId, offerAction, reason })),
    dispatchToggleFavStory: (storyId: string) => dispatch(requestToggleFavourite({ storyId })),
    dispatchGenerateStoryPdf: (buyerId: string, storyId: string, sellerId: string) =>
      dispatch(
        requestGenerateStoryPdf({
          buyerId,
          storyId,
          sellerId
        })
      ),
    dispatchGetSellerCounterOffer: (offerId: string) => dispatch(requestSellerCounterOffer({ offerId })),
    dispatchGetOfferId: (storyId: string, buyerId: string) => dispatch(requestGetOfferId({ storyId, buyerId })),
    dispatchResetStoryPdfUrl: () => dispatch(resetStoryPdfData()),
    dispatchCounterOffer: (
      storyId: string,
      offerId: string,
      buyerId: string,
      amount: number,
      notes: string,
      sellerId: string
    ) => dispatch(requestMakeCounterOffer({ storyId, offerId, buyerId, amount, notes, sellerId })),
    dispatchGetLatestBuyerOffer: (offerId: string) => dispatch(requestGetBuyerLatestOffer({ offerId })),
    dispatchSendAccessRequest: (
      buyerId: string,
      sellerId: string,
      storyId: string,
      message: string,
      type: string,
      state: string
    ) => dispatch(requestSendMessage({ buyerId, sellerId, storyId, message, type, state }))
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, injectIntl)(StoryDetailPage) as React.FC;

export const StoryDetailPageTest = StoryDetailPage;
