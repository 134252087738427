import { gql } from 'apollo-boost';

export const GET_OFFERS = gql`
  query BuyerOffers($filters: OfferFilters, $page: OffersPagination!) {
    buyerOffers(filters: $filters, pagination: $page) {
      offers {
        id
        story {
          id
          state
          writer {
            id
            firstName
            lastName
            profilePic
          }
          title
          languages {
            id
            name
          }
          genres {
            id
            name
          }
          storyType {
            id
            name
          }
          supportingMaterials {
            attachmentType
            fileName
          }
          logline
          storyline
          views
          ratings
          offers
          buyerActions {
            hasLiked
          }
          createdAt
        }
        createdAt
        expiresAt
        details {
          id
          offerId
          amount
          currency
          notes
          offerEntity {
            id
            firstName
            lastName
          }
          offerState
          createdAt
          updatedAt
          deletedAt
        }
      }
      total
    }
  }
`;

export const SEND_MAIL_TO_LEGAL_TEAM = gql`
  query SendMailToLegalTeam($input: LegalSupport!) {
    sendMailToLegalTeam(input: $input)
  }
`;
