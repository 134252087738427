import { createSelector } from 'reselect';
import get from 'lodash-es/get';
import { initialState } from './reducer';

export const selectMyDashboardDomain = (state: any) => state.individualDashboardReducer || initialState;

export const selectRecentlyViewedStories = () =>
  createSelector(selectMyDashboardDomain, (substate) => get(substate, 'recentlyViewedStories'));

export const selectTrendingStories = () =>
  createSelector(selectMyDashboardDomain, (substate) => get(substate, 'trendingStories'));

export const selectRecentlyViewedStoriesLoading = () =>
  createSelector(selectMyDashboardDomain, (substate) => get(substate, 'getRecentlyViewedStoriesLoading'));
