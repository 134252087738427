import { all } from 'redux-saga/effects';
import { adminDashboardSaga } from '../DashboardContainerPage/AdminDashboard/saga';
import { myDashboardSaga } from '../DashboardContainerPage/IndividualDashboard/saga';
import { browseStoriesSaga } from '../DashboardContainerPage/IndividualDashboard/tabs/BrowseStories/saga';
import { myOffersSaga } from '../DashboardContainerPage/IndividualDashboard/tabs/MyOffers/saga';
import { homeContainer } from '../HomeContainer/saga';
import { invitationPageSaga } from '../InvitationPage/saga';
import { loginSaga } from '../LoginContainer/saga';
import { offerHistoryContainerSagaArr } from '../OfferHistory/saga';
import { packageDetailsSaga } from '../PackageDetailsPage/saga';
import { profileContainerSaga } from '../ProfileContainer/saga';
import { signUpSaga } from '../SignUpContainer/saga';
import { storiesDetailsSaga } from '../StoryDetailsPage/saga';
import { individualMyNotificationsSagaArr } from '../DashboardContainerPage/IndividualDashboard/tabs/MyNotifications/saga';
import { adminMyNotificationsSagaArr } from '../DashboardContainerPage/AdminDashboard/tabs/MyNotifications/saga';
import { dashboardContainerSaga } from '../DashboardContainerPage/saga';

export default function* RootSaga() {
  yield all([
    ...invitationPageSaga,
    ...loginSaga,
    ...signUpSaga,
    ...packageDetailsSaga,
    ...profileContainerSaga,
    ...adminDashboardSaga,
    ...myDashboardSaga,
    ...homeContainer,
    ...browseStoriesSaga,
    ...storiesDetailsSaga,
    ...myOffersSaga,
    ...offerHistoryContainerSagaArr,
    ...individualMyNotificationsSagaArr,
    ...adminMyNotificationsSagaArr,
    ...dashboardContainerSaga
  ]);
}
