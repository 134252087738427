import { T } from '@app/components';
import React, { useEffect, useState, useRef, memo, useCallback } from 'react';
import styled from 'styled-components';
import { translate } from '@components/IntlGlobalProvider';
import { Form, Divider, notification, Checkbox, Spin } from 'antd';
import { CustomButton, CustomInput, If } from 'tsw-sdk';
import SelectBox from '@app/components/SelectBox';
import { ArrowRightOutlined, PlusOutlined, CheckOutlined } from '@ant-design/icons';
import Avatar from '@images/avatar.png';
import EditImg from '@images/EditIcon.png';
import { colors, fonts } from '@app/themes';
import PlusIcon from '@images/AddIcon.svg';
import Infotag from '@app/components/InfoTag';
import { connect } from 'react-redux';
import {
  requestCreateProfile,
  requestGetProfile,
  requestUploadProfileImage,
  requestPhoneVerification,
  requestVerifyOtp,
  resetState,
  setSubmitLoading
} from '../reducer';
import {
  selectCreateUserProfileLoading,
  selectPaymentError,
  selectUserProfile,
  selectVerifyOtpSuccess,
  selectVerifuOtpError,
  selectPaymentInitiated,
  selectSubmitProfileLoading
} from '../selectors';
import { createStructuredSelector } from 'reselect';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { injectIntl } from 'react-intl';
import { IndividualProfileProps } from './types';
import {
  BUYER_ACCOUNT_TYPE,
  COGNITO_USER_GROUPS,
  COUPON_INDV,
  PROFILE_IMAGE_PROD_URL,
  PROFILE_IMAGE_QA_URL,
  PROFILE_IMAGE_URL
} from '@app/utils/constants';
import OTPModal from '@app/components/OTPModal';
import { debounce } from 'lodash-es';
import ArrowLeft from '@images/icons/arrow-left.svg';
import { selectAccessToken } from '@app/containers/TokenProvider/selectors';
import ProfileCropModal from '../components/ProfileCropModal';
import { selectCouponCode } from '@app/containers/PackageDetailsPage/selectors';
import { preventInvalidCharsNumberInput } from '@app/utils/uiUtils';

type ShowReelObjType = {
  id: string;
  showReelName: string;
  showReelLink: string;
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5625rem;
  align-items: flex-start;
  position: absolute;
  top: 6.2%;
  left: 12%;
`;

const ContainerTitle = styled(T)`
  && {
    line-height: 3.975rem;

    text-align: center;
  }
`;

const InlineItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px;
  gap: 1.5rem;
`;

const ButtonInlineItems = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  padding: 0px;
  gap: 1.5rem;
`;

const ContactInfoInline = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 0.5rem;
`;

const FormContainer = styled(Form)`
  && {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    margin-bottom: 2rem;
  }
`;

const CustomFormItem = styled(Form.Item)`
  &.ant-form-item {
    width: 16rem;
    margin-bottom: 0;
    row-gap: 0.5rem;
  }
  .ant-form-item-label {
    padding: 0 !important;
    font-size: 1rem;
    row-gap: 0.5rem;
  }
`;

const ExpContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 1.5rem;
  padding: 0px;
`;

const CustomDivider = styled(Divider)`
  && {
    margin: 0;
    border-top: 1px solid ${colors.lightBorder};
  }
`;

const ProfilePicContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  position: absolute;
  left: 76.6%;
  top: 4.4%;
`;

const ProfilePic = styled.img`
  width: 12.75rem;
  height: 12.75rem;
  border-radius: 100%;
`;

const EditIcon = styled.img`
  height: 2rem;
  width: 2rem;
  margin-right: 1rem;
  cursor: pointer;
`;

const SubText = styled.p`
  margin-top: 4px;
  opacity: 0.5;
  ${fonts.style.smallText()}
`;

const ShowReelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px;
`;

const ShowreelLableCont = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  margin-bottom: 8px;
`;

const CustomSaveButton = styled(CustomButton)`
  && {
    justify-content: center;
    border: 1px solid ${colors.primary};
    padding: 0.5rem 1.5rem;
    width: 50%;
  }
`;

const CustomSubmitButton = styled(CustomButton)`
  && {
    justify-content: center;
    width: 50%;
  }
`;
const CustomAddButton = styled(CustomButton)`
  && {
    border: 1px solid ${colors.primary};
    padding: 0.5rem 1.5rem;
    font-size: 0.875rem;
    height: auto;
    margin-top: 2.37rem;
  }
`;
const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1.5rem;
  margin-top: 0.5rem;
`;

const CustomVerifiedButton = styled(CustomButton)`
  && {
    background: ${colors.verifiedBtnBg} !important;
  }
`;

const LabelInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
  padding: 0px;
`;

const CheckBoxText = styled.p`
  line-height: 15px;
  ${fonts.style.checkBoxText()}
`;

const CustomCheckBox = styled(Checkbox)`
  && {
    .ant-checkbox-inner {
      width: 1.5rem;
      height: 1.5rem;
      border: 2px solid ${colors.checkboxborder};
      border-radius: 4px;
    }
  }
`;

const StyledLink = styled.a`
  color: ${colors.styledText};
  opacity: 1;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: ${colors.styledText};
  }
  ${fonts.style.smallText()}
`;

const ProfileImageInput = styled.input`
  display: none;
`;

const BackButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 2rem;
  left: 2.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  ${fonts.weights.bold()}
  ${fonts.size.small()}
  line-height: 1rem;
  color: ${colors.termsAndConditionListItem};
`;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`;

const FormItemLabel = styled.p`
  ${fonts.weights.fw600()};
  ${fonts.size.regular()};
  color: ${colors.tagBorder};
`;

const BillingDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelText = styled.p`
  ${fonts.weights.fw600()};
  ${fonts.size.small()};
  line-height: 1.09rem;
  color: ${colors.tagBorder};
  margin-bottom: 0.93rem;
`;

const BilingAddressContainer = styled.div`
  display: grid;
  grid-template-columns: 16rem 16rem 7.56rem 7.56rem;
  column-gap: 1rem;
  row-gap: 1rem;
`;

const CustomBillingFormItem = styled(Form.Item)`
  &.ant-form-item {
    margin-bottom: 0;
  }
`;

const CustomInputCapital = styled(CustomInput)`
  text-transform: uppercase;

  &&& {
    &:placeholder-shown {
      text-transform: unset;
    }
  }
`;

const PageSpinnerContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000000;
`;

const CustomNumberInput = styled(CustomInput)`
  &&& {
    width: 7rem;
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

export const IndividualProfile = ({
  userProfile,
  createProfileLoading,
  paymentError,
  otpSuccess,
  otpError,
  accessToken,
  // couponCode,
  paymentInitiated,
  dispatchGetUserProfile,
  dispatchUpdateUserProfile,
  dispatchUploadUserProfile,
  dispatchResetState,
  dispatchVerifyOtp,
  dispatchPhoneVerification,
  submitLoading,
  dispatchSetSubmitLoading
}: IndividualProfileProps) => {
  const [profileForm] = Form.useForm();
  const inputRef = useRef<any>(null);
  const [showReelArr, setShowReelArr] = useState<ShowReelObjType[]>([]);
  const [showReelTag, setShowReelTag] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const [otp, setOtp] = useState('');
  const [contactNum, setContactNum] = useState('');
  const [isPhoneVerified, setIsPhoneVerified] = useState<boolean>(false);

  const [isIndian, setIsIndian] = useState(false);
  const [isOrgUser, setIsOrgUser] = useState<boolean>(false);

  const [profileUrlLocal, setProfileUrlLocal] = useState<string>('');
  const [croppedUrlLocal, setCroppedUrlLocal] = useState<string>('');

  const [imageCropModalVisible, setImageCropModalVisible] = useState<boolean>(false);

  const [componentLoaded, setComponentLoaded] = useState<boolean>(false);
  const [isAdult, setIsAdult] = useState<boolean>(false);

  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  const cinRegex = /^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/;

  const NumcodeArr = [
    {
      name: '+91',
      value: 'ind'
    }
  ];

  useEffect(() => {
    if (!componentLoaded) {
      if (Object.keys(userProfile).length > 2) {
        setComponentLoaded(true);
        profileForm.setFieldsValue({
          firstName: userProfile?.firstName,
          lastName: userProfile?.lastName,
          shortBio: userProfile?.bioDescription,
          productionHouse: userProfile?.company ? userProfile?.company.name : '',
          designation: userProfile?.company ? userProfile?.company.designation : '',
          favGen1: userProfile?.favGenres && userProfile?.favGenres[0] ? userProfile?.favGenres[0]?.name : '',
          favGen2: userProfile?.favGenres && userProfile?.favGenres[1] ? userProfile?.favGenres[1]?.name : '',
          favGen3: userProfile?.favGenres && userProfile?.favGenres[2] ? userProfile?.favGenres[2]?.name : '',
          favMovie1: userProfile?.favMovies && userProfile?.favMovies[0] ? userProfile?.favMovies[0]?.name : '',
          favMovie2: userProfile?.favMovies && userProfile?.favMovies[1] ? userProfile?.favMovies[1]?.name : '',
          favMovie3: userProfile?.favMovies && userProfile?.favMovies[2] ? userProfile?.favMovies[2]?.name : '',
          favProd1:
            userProfile?.producedFilms && userProfile?.producedFilms[0] ? userProfile?.producedFilms[0]?.name : '',
          favProd2:
            userProfile?.producedFilms && userProfile?.producedFilms[1] ? userProfile?.producedFilms[1]?.name : '',
          favProd3:
            userProfile?.producedFilms && userProfile?.producedFilms[2] ? userProfile?.producedFilms[2]?.name : '',
          panNum: userProfile?.billingAddress?.panNumber,
          companyIdNum: userProfile?.billingAddress?.companyIdentificationNo,
          gstNum: userProfile?.billingAddress?.gstNumber,
          floorNum: userProfile?.billingAddress?.streetAddress,
          locality: userProfile?.billingAddress?.localeAddress,
          state: userProfile?.billingAddress?.state,
          city: userProfile?.billingAddress?.city,
          postCode: userProfile?.billingAddress?.pinCode
        });

        if (userProfile?.contactNumber) {
          profileForm.setFieldsValue({ contactNo: Number(userProfile?.contactNumber.substring(3)) });
          setIsPhoneVerified(true);
        }

        if (userProfile?.showreels) {
          if (userProfile?.showreels.length) {
            setShowReelArr(
              userProfile?.showreels.map((showReel: any) => ({
                id: showReel.id,
                showReelName: showReel.name,
                showReelLink: showReel.link
              }))
            );
          }
        }

        if (userProfile?.profilePic) {
          if (process.env.ENVIRONMENT_NAME === 'qa') {
            setCroppedUrlLocal(`${PROFILE_IMAGE_QA_URL}/${userProfile?.profilePic}`);
          } else if (process.env.ENVIRONMENT_NAME === 'production') {
            setCroppedUrlLocal(`${PROFILE_IMAGE_PROD_URL}/${userProfile?.profilePic}`);
          } else {
            setCroppedUrlLocal(`${PROFILE_IMAGE_URL}/${userProfile?.profilePic}`);
          }
        }

        if (userProfile?.nationality) {
          setIsIndian(true);
        }

        if (userProfile?.isLegalAge) {
          setIsAdult(true);
        }
      }
    }
  }, [userProfile]);

  useEffect(() => {
    if (accessToken) {
      const cognitoGroups = accessToken['cognito:groups'];
      if (cognitoGroups) {
        if (cognitoGroups.length) {
          if (cognitoGroups.includes(COGNITO_USER_GROUPS.ORGANIZATION)) {
            setIsOrgUser(true);
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    if (otpSuccess) {
      setIsModalOpen(false);
      openSuccessOtpNotification();
      setIsPhoneVerified(true);
    }
  }, [otpSuccess]);

  useEffect(() => {
    dispatchGetUserProfile();

    return () => {
      dispatchResetState();
    };
  }, []);

  useEffect(() => {
    if (paymentError) {
      notification.open({
        message: translate('error'),
        description: paymentError
      });
    }
  }, [paymentError]);

  const openNotification = (err: string) => {
    notification.open({
      message: 'Error',
      description: err
    });
  };

  const openSuccessOtpNotification = () => {
    notification.open({
      message: translate('success'),
      description: translate('phone_verified')
    });
  };

  const handleAddShowReel = () => {
    const showReelName = profileForm.getFieldValue('showReelName');
    const showReelLink = profileForm.getFieldValue('showReelLink');

    if (showReelName && showReelLink) {
      const showReelObj = {
        id: Date.now().toString(),
        showReelName: showReelName,
        showReelLink: showReelLink
      };

      setShowReelArr([...showReelArr, showReelObj]);
      setShowReelTag(false);
      profileForm.resetFields(['showReelName', 'showReelLink']);
    } else {
      notification.open({
        message: translate('error'),
        description: translate('enter_all_fields_error')
      });
    }
  };

  const handleDeleteShowReel = (id: string) => {
    setShowReelArr(showReelArr.filter((item) => item.id !== id));
    setShowReelTag(true);
  };

  const validateFormData = (formData: any) => {
    let formDataValid = true;
    let errorMessage = '';

    if (!formData?.postCode?.trim()) {
      formDataValid = false;
      errorMessage = translate('post_code_error');
    }

    if (!formData?.city?.trim()) {
      formDataValid = false;
      errorMessage = translate('city_error');
    }

    if (!formData?.floorNum?.trim()) {
      formDataValid = false;
      errorMessage = translate('floor_error');
    }

    if (!formData?.gstNum?.trim()) {
      formDataValid = false;
      errorMessage = translate('gst_num_error');
    }

    if (!gstRegex.test(formData?.gstNum?.toUpperCase())) {
      formDataValid = false;
      errorMessage = translate('invalid_gst_error_message');
    }

    if (!formData?.state?.trim()) {
      formDataValid = false;
      errorMessage = translate('state_error');
    }

    if (!formData?.locality?.trim()) {
      formDataValid = false;
      errorMessage = translate('locality_error');
    }

    if (!formData?.companyIdNum?.trim()) {
      formDataValid = false;
      errorMessage = translate('comp_id_num_error');
    }

    if (!cinRegex.test(formData?.companyIdNum)) {
      formDataValid = false;
      errorMessage = translate('invalid_cin_error_message');
    }

    if (!formData?.panNum?.trim()) {
      formDataValid = false;
      errorMessage = translate('pan_num_error');
    }

    if (!panRegex.test(formData?.panNum?.toUpperCase())) {
      formDataValid = false;
      errorMessage = translate('invalid_pan_error_message');
    }

    if (!isAdult) {
      formDataValid = false;
      errorMessage = translate('age_error');
    }

    if (!isIndian) {
      formDataValid = false;
      errorMessage = translate('nationality_error');
    }

    if (!formData?.contactNo?.toString()?.trim()) {
      formDataValid = false;
      errorMessage = translate('contact_no_error');
    } else {
      if (!isPhoneVerified) {
        formDataValid = false;
        errorMessage = translate('verify_phone_num');
      }
    }

    if (!formData?.designation?.trim()) {
      formDataValid = false;
      errorMessage = translate('designation_error');
    }

    if (!formData?.productionHouse?.trim()) {
      formDataValid = false;
      errorMessage = translate('production_house_error');
    }

    if (!formData?.lastName?.trim()) {
      formDataValid = false;
      errorMessage = translate('last_name_error');
    }

    if (!formData?.firstName?.trim()) {
      formDataValid = false;
      errorMessage = translate('first_name_error');
    }

    return { formDataValid, errorMessage };
  };

  const validateFormDataSave = (formData: any) => {
    let formDataValid = true;
    let errorMessage = '';

    if (!formData?.lastName?.trim() && !formData?.firstName?.trim()) {
      if (!formData?.lastName?.trim()) {
        formDataValid = false;
        errorMessage = translate('last_name_error');
      }

      if (!formData?.firstName?.trim()) {
        formDataValid = false;
        errorMessage = translate('first_name_error');
      }
    }

    return { formDataValid, errorMessage };
  };

  useEffect(() => {
    if (otpError) {
      openNotification(otpError);
    }
  }, [otpError]);

  const contructProducedFilms = (formData: any) => {
    const producedFilms = [
      {
        name: formData.favProd1
      },
      {
        name: formData.favProd2
      },
      {
        name: formData.favProd3
      }
    ];

    const newProducedFilms = [];

    for (let i = 0; i < producedFilms.length; i++) {
      if (producedFilms[i].name !== '' && producedFilms[i].name !== undefined) {
        newProducedFilms[i] = producedFilms[i];
      }
    }

    return newProducedFilms;
  };

  const constructFavouriteGenres = (formData: any) => {
    const favouriteGenres = [
      {
        name: formData.favGen1
      },
      {
        name: formData.favGen2
      },
      {
        name: formData.favGen3
      }
    ];

    const newfavouriteGenres = [];

    for (let i = 0; i < favouriteGenres.length; i++) {
      if (favouriteGenres[i].name !== '' && favouriteGenres[i].name !== undefined) {
        newfavouriteGenres[i] = favouriteGenres[i];
      }
    }

    return newfavouriteGenres;
  };

  const constructFavouriteMovies = (formData: any) => {
    const favouriteMovies = [
      {
        name: formData.favMovie1
      },
      {
        name: formData.favMovie2
      },
      {
        name: formData.favMovie3
      }
    ];

    const newFavouriteMovies = [];

    for (let i = 0; i < favouriteMovies.length; i++) {
      if (favouriteMovies[i].name !== '' && favouriteMovies[i].name !== undefined) {
        newFavouriteMovies[i] = favouriteMovies[i];
      }
    }

    return newFavouriteMovies;
  };

  const constructApiPayload = (data: any) => {
    const cognitoGroups = accessToken['cognito:groups'];

    const formDataPayload = {
      buyerProfileInput: {
        firstName: data.firstName,
        lastName: data.lastName,
        contactNumber: data.contactNo ? `+91${data.contactNo}` : '',
        nationality: 'Indian',
        bioDescription: data.shortBio ? data.shortBio : '',
        profileType: 'INDIVIDUAL',
        company: {
          id: userProfile?.company ? userProfile?.company?.id : undefined,
          name: data.productionHouse,
          designation: data.designation ? data.designation : ''
        },
        showreels: showReelArr.length
          ? showReelArr.map((showReel) => ({
              name: showReel.showReelName,
              link: showReel.showReelLink
            }))
          : [],
        producedFilms: contructProducedFilms(data),
        favGenres: constructFavouriteGenres(data),
        favMovies: constructFavouriteMovies(data),
        isLegalAge: isAdult ? true : undefined,
        profilePic: userProfile?.profilePic,
        isSave: false,
        billingAddress: {
          panNumber:
            data.panNum?.trim() !== '' && data.panNum !== null && panRegex.test(data.panNum?.toUpperCase())
              ? data.panNum?.toUpperCase()
              : undefined,
          gstNumber:
            data.gstNum?.trim() !== '' && data.gstNum !== null && gstRegex.test(data.gstNum?.toUpperCase())
              ? data.gstNum?.toUpperCase()
              : undefined,
          streetAddress: data.floorNum !== '' && data.floorNum !== null ? data.floorNum : undefined,
          localeAddress: data.locality !== '' && data.locality !== null ? data.locality : undefined,
          city: data.city !== '' && data.city !== null ? data.city : undefined,
          state: data.state !== '' && data.state !== null ? data.state : undefined,
          pinCode: data.postCode !== '' && data.postCode !== null ? data.postCode : undefined,
          companyIdentificationNo:
            data.companyIdNum?.trim() !== '' && data.companyIdNum !== null && cinRegex.test(data?.companyIdNum)
              ? data.companyIdNum
              : undefined
        }
      }
    };

    if (cognitoGroups) {
      if (cognitoGroups.length) {
        if (cognitoGroups.includes(COGNITO_USER_GROUPS.ORGANIZATION)) {
          formDataPayload.buyerProfileInput.profileType = BUYER_ACCOUNT_TYPE.ORGANIZATION_USER;
        }
      }
    }

    return formDataPayload;
  };

  const handleFinish = () => {
    const values = profileForm.getFieldsValue();
    const formDataValid = validateFormData(values);

    if (formDataValid.formDataValid) {
      const formData = constructApiPayload(values);

      formData.buyerProfileInput.isSave = false;

      dispatchUpdateUserProfile({ formData, autoSave: false, couponCode: COUPON_INDV });
      dispatchSetSubmitLoading();
    } else {
      notification.open({
        message: translate('error'),
        description: formDataValid.errorMessage
      });
    }
  };

  const handleSave = () => {
    const values = profileForm.getFieldsValue();
    const formDataValid = validateFormDataSave(values);
    if (formDataValid.formDataValid) {
      const formData = constructApiPayload(values);

      formData.buyerProfileInput.isSave = true;

      dispatchUpdateUserProfile({
        formData,
        autoSave: false
      });
    } else {
      notification.open({
        message: translate('error'),
        description: formDataValid.errorMessage
      });
    }
  };

  const handleOtpInput = (currOtp: string) => {
    setOtp(currOtp);
  };

  const toggleResend = () => {
    setShowResend(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onOtpVerify = async () => {
    try {
      if (otp.length === 6) {
        await dispatchVerifyOtp(otp);
      } else {
        openNotification('Enter a valid otp');
      }
    } catch (e) {
      openNotification('something went wrong');
    }
  };

  const resendOtpConfirm = async () => {
    try {
      await dispatchPhoneVerification(contactNum);
      notification.open({
        message: translate('success_title'),
        description: translate('otp_sent_message')
      });
      setShowResend(false);
    } catch (e) {
      openNotification('something went wrong');
    }
  };

  const handleOTPModal = () => {
    const numberRegex = /^(0|91)?[6-9][0-9]{9}$/;
    const num = profileForm.getFieldValue('contactNo');

    if (!numberRegex.test(`91${num}`)) {
      openNotification(translate('invalid_number'));
      return;
    }

    setContactNum('+91' + num);
    dispatchPhoneVerification('+91' + num);
    setIsModalOpen(true);
  };

  const handleImageInputChange = (e: any) => {
    const file = e.target.files[0];

    const fileUrl = URL.createObjectURL(file);

    setProfileUrlLocal(fileUrl);
    setImageCropModalVisible(true);
    e.target.value = null;
  };

  const handleCrop = (file: any) => {
    const fileUrl = URL.createObjectURL(file);
    setCroppedUrlLocal(fileUrl);
    dispatchUploadUserProfile(file);
    setImageCropModalVisible(false);
  };

  const debounceSave = useCallback(
    debounce(
      () => {
        const values = profileForm.getFieldsValue();

        const formData = constructApiPayload(values);

        formData.buyerProfileInput.isSave = true;

        dispatchUpdateUserProfile({
          formData,
          autoSave: true
        });
      },
      3000,
      {
        maxWait: 1000
      }
    ),
    [isAdult, isIndian, profileUrlLocal, userProfile, showReelArr]
  );

  useEffect(() => {
    if (isIndian) {
      debounceSave();
    }
  }, [isIndian]);

  useEffect(() => {
    if (isAdult) {
      debounceSave();
    }
  }, [isAdult]);

  useEffect(() => {
    debounceSave();
  }, [showReelArr]);

  useEffect(() => {
    if (componentLoaded) {
      if (userProfile?.profilePic) {
        debounceSave();
      }
    }
  }, [userProfile]);

  const handleChange = () => {
    debounceSave();
  };

  return (
    <>
      <If condition={!isOrgUser}>
        <BackButton onClick={() => history.back()}>
          <img src={ArrowLeft} />
          {translate('back')}
        </BackButton>
      </If>

      <Container data-testid="individual-form">
        <If condition={submitLoading}>
          <PageSpinnerContainer>
            <Spin />
          </PageSpinnerContainer>
        </If>
        <ProfileImageInput
          type={'file'}
          accept="image/png, image/jpeg"
          onChange={handleImageInputChange}
          ref={inputRef}
          data-testid="profile-upload-input"
        />
        {imageCropModalVisible && (
          <ProfileCropModal
            image={profileUrlLocal}
            visible={imageCropModalVisible}
            onCrop={handleCrop}
            handleCancel={() => setImageCropModalVisible(false)}
            cropMode="profile"
          />
        )}
        <ContainerTitle id="profile_create_title" type="spoofHeading" />
        <FormContainer layout="vertical" form={profileForm} onValuesChange={handleChange} initialValues={userProfile}>
          <InlineItems>
            <CustomFormItem label={translate('first_name')} name="firstName">
              <CustomInput placeholder={translate('first_name_place')} data-testid="first-name-input" />
            </CustomFormItem>
            <CustomFormItem label={translate('last_name')} name="lastName">
              <CustomInput placeholder={translate('last_name_place')} data-testid="last-name-input" />
            </CustomFormItem>
          </InlineItems>
          <CustomDivider />
          <CustomFormItem label={translate('company_name')} name="productionHouse">
            <CustomInput
              placeholder={translate('company_name_place')}
              data-testid="company-name-input"
              disabled={isOrgUser}
            />
          </CustomFormItem>
          <CustomFormItem label={translate('designation')} name="designation">
            <CustomInput placeholder={translate('designation_place')} data-testid="designation-input" />
          </CustomFormItem>
          <CustomDivider />
          <ExpContainer>
            <ContactContainer>
              <FormItemLabel>{translate('contact_no')}</FormItemLabel>

              <ContactInfoInline>
                <SelectBox optionArr={NumcodeArr} defaultValue="+91" width="3.5rem" />
                <CustomFormItem name="contactNo">
                  <CustomNumberInput
                    placeholder={translate('num_place')}
                    style={{ width: '5.75rem' }}
                    data-testid="phone-input"
                    type="number"
                    onKeyDown={preventInvalidCharsNumberInput}
                    disabled={isPhoneVerified}
                  />
                </CustomFormItem>
              </ContactInfoInline>
            </ContactContainer>

            <If
              condition={!isPhoneVerified}
              otherwise={
                <CustomVerifiedButton data-testid="verified-otp-btn">
                  {translate('verified')} <CheckOutlined />
                </CustomVerifiedButton>
              }
            >
              <CustomButton onClick={handleOTPModal} data-testid="verify-otp-btn">
                {translate('verify_otp')}
              </CustomButton>
            </If>
          </ExpContainer>
          <CustomDivider />
          <LabelInput>
            <CustomFormItem name="nationality" label={translate('nationality')} style={{ width: '100%' }}>
              <CheckBoxContainer>
                <CustomCheckBox
                  onChange={(e) => {
                    setIsIndian(e.target.checked);
                  }}
                  checked={isIndian}
                  data-testid="nationality-checkbox"
                />
                <CheckBoxText>{translate('confirm_indian')}</CheckBoxText>
              </CheckBoxContainer>
            </CustomFormItem>

            <SubText>
              {translate('only_indians')}
              <StyledLink style={{ opacity: '10', color: 'black' }} href="https://www.spotwriters.com/" target="_blank">
                {translate('website')}
              </StyledLink>
            </SubText>
          </LabelInput>
          <CustomDivider />

          <LabelInput>
            <CustomFormItem name="age" label={translate('age')} style={{ width: '100%' }}>
              <CheckBoxContainer>
                <CustomCheckBox
                  onChange={(e) => {
                    setIsAdult(e.target.checked);
                  }}
                  checked={isAdult}
                  data-testid="age-checkbox"
                />
                <CheckBoxText>{translate('confirm_adult')}</CheckBoxText>
              </CheckBoxContainer>
            </CustomFormItem>

            <SubText>
              {translate('only_adults')}
              <StyledLink style={{ opacity: '10', color: 'black' }} href="https://www.spotwriters.com/" target="_blank">
                {translate('website')}
              </StyledLink>
            </SubText>
          </LabelInput>
          <CustomDivider />

          <BillingDetailsContainer>
            <LabelText>{translate('billing_address')}</LabelText>
            <BilingAddressContainer>
              <CustomBillingFormItem name="panNum">
                <CustomInputCapital
                  placeholder={translate('pan_no_placeholder')}
                  data-testid="pan-num-input"
                  disabled={isOrgUser}
                />
              </CustomBillingFormItem>
              <CustomBillingFormItem name="companyIdNum">
                <CustomInput
                  placeholder={translate('comp_id_placeholder')}
                  data-testid="comp-id-input"
                  disabled={isOrgUser}
                />
              </CustomBillingFormItem>
              <CustomBillingFormItem name="locality">
                <CustomInput placeholder={translate('locality')} data-testid="locality-input" disabled={isOrgUser} />
              </CustomBillingFormItem>
              <CustomBillingFormItem name="state">
                <CustomInput placeholder={translate('state')} data-testid="state-input" disabled={isOrgUser} />
              </CustomBillingFormItem>
              <CustomBillingFormItem name="gstNum">
                <CustomInputCapital
                  placeholder={translate('gst_num_placeholder')}
                  data-testid="gst-num"
                  disabled={isOrgUser}
                />
              </CustomBillingFormItem>
              <CustomBillingFormItem name="floorNum">
                <CustomInput
                  placeholder={translate('floor_num_plaeholder')}
                  data-testid="floor-num"
                  disabled={isOrgUser}
                />
              </CustomBillingFormItem>
              <CustomBillingFormItem name="city">
                <CustomInput placeholder={translate('city')} data-testid="city-input" disabled={isOrgUser} />
              </CustomBillingFormItem>
              <CustomBillingFormItem name="postCode">
                <CustomNumberInput
                  placeholder={translate('post_code')}
                  data-testid="post-code-input"
                  type="number"
                  onKeyDown={preventInvalidCharsNumberInput}
                  disabled={isOrgUser}
                />
              </CustomBillingFormItem>
            </BilingAddressContainer>
          </BillingDetailsContainer>

          <CustomDivider />

          <ShowReelContainer>
            <ShowreelLableCont>
              <p>{translate('showreel_label')}</p>
              <If condition={!showReelTag && showReelArr.length < 3}>
                <img
                  src={PlusIcon}
                  onClick={() => setShowReelTag(true)}
                  style={{ cursor: 'pointer' }}
                  data-testid="add-icon"
                />
              </If>
            </ShowreelLableCont>
            <If condition={showReelTag}>
              <InlineItems data-testid="showreel-form">
                <CustomFormItem name="showReelName">
                  <CustomInput placeholder={translate('showreel_name_place')} data-testid="showreel-name" />
                </CustomFormItem>
                <CustomFormItem name="showReelLink">
                  <CustomInput placeholder={translate('showreel_link_place')} data-testid="showreel-link" />
                </CustomFormItem>
              </InlineItems>
              <SubText>{translate('max_warn')}</SubText>
              <CustomAddButton
                bg_color={`${colors.secondaryText}`}
                text_color={`${colors.darkBorder}`}
                onClick={handleAddShowReel}
                data-testid="add-showreel-btn"
              >
                <PlusOutlined /> {translate('add_link')}
              </CustomAddButton>
            </If>
            <TagsContainer>
              {showReelArr.map((items, index) => {
                return (
                  <React.Fragment key={index}>
                    <Infotag {...items} onDelete={handleDeleteShowReel} data-testid="info-tag" />
                  </React.Fragment>
                );
              })}
            </TagsContainer>
          </ShowReelContainer>
          <CustomDivider />
          <CustomFormItem label={translate('short_bio')} name="shortBio">
            <CustomInput placeholder={translate('bio_placeholder')} style={{ height: '2.875rem' }} />
          </CustomFormItem>
          <CustomDivider />
          <InlineItems>
            <CustomFormItem label={translate('genres_label')} name="favGen1">
              <CustomInput placeholder={translate('fav_genre_1')} />
            </CustomFormItem>
            <CustomFormItem name="favGen2">
              <CustomInput placeholder={translate('fav_genre_2')} />
            </CustomFormItem>
            <CustomFormItem name="favGen3">
              <CustomInput placeholder={translate('fav_genre_3')} />
            </CustomFormItem>
          </InlineItems>
          <InlineItems>
            <CustomFormItem label={translate('movies_label')} name="favMovie1">
              <CustomInput placeholder={translate('fav_movie_1')} />
            </CustomFormItem>
            <CustomFormItem name="favMovie2">
              <CustomInput placeholder={translate('fav_movie_2')} />
            </CustomFormItem>
            <CustomFormItem name="favMovie3">
              <CustomInput placeholder={translate('fav_movie_3')} />
            </CustomFormItem>
          </InlineItems>
          <InlineItems>
            <CustomFormItem label={translate('produced_film_label')} name="favProd1">
              <CustomInput placeholder={translate('fav_produced_1')} />
            </CustomFormItem>
            <CustomFormItem name="favProd2">
              <CustomInput placeholder={translate('fav_produced_2')} />
            </CustomFormItem>
            <CustomFormItem name="favProd3">
              <CustomInput placeholder={translate('fav_produced_3')} />
            </CustomFormItem>
          </InlineItems>
          <ButtonInlineItems>
            <CustomSaveButton
              bg_color={`${colors.secondaryText}`}
              text_color={`${colors.darkBorder}`}
              onClick={handleSave}
              data-testid="save-btn"
            >
              {translate('save')}
            </CustomSaveButton>
            <CustomSubmitButton
              loading={createProfileLoading && paymentInitiated}
              onClick={handleFinish}
              data-testid="submit-btn"
            >
              {translate('submit')} <ArrowRightOutlined />
            </CustomSubmitButton>
          </ButtonInlineItems>
        </FormContainer>
      </Container>
      <ProfilePicContainer>
        <ProfilePic src={croppedUrlLocal !== '' ? croppedUrlLocal : Avatar} />
        <EditIcon src={EditImg} onClick={() => inputRef?.current?.click()} data-testid="edit-profile-btn" />
      </ProfilePicContainer>
      <OTPModal
        visible={isModalOpen}
        handleOtpInput={handleOtpInput}
        toggleResend={toggleResend}
        handleCancel={handleCancel}
        showResend={showResend}
        contactNum={contactNum}
        onOtpVerify={onOtpVerify}
        resendOtpConfirm={resendOtpConfirm}
        data-testid="otp-modal"
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  userProfile: selectUserProfile(),
  createProfileLoading: selectCreateUserProfileLoading(),
  paymentError: selectPaymentError(),
  otpSuccess: selectVerifyOtpSuccess(),
  otpError: selectVerifuOtpError(),
  accessToken: selectAccessToken(),
  couponCode: selectCouponCode(),
  paymentInitiated: selectPaymentInitiated(),
  submitLoading: selectSubmitProfileLoading()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGetUserProfile: () => dispatch(requestGetProfile({})),
    dispatchUpdateUserProfile: (profileData: any) => dispatch(requestCreateProfile(profileData)),
    dispatchUploadUserProfile: (fileData: any) => dispatch(requestUploadProfileImage(fileData)),
    dispatchResetState: () => dispatch(resetState()),
    dispatchPhoneVerification: (contactNum: string) => dispatch(requestPhoneVerification(contactNum)),
    dispatchVerifyOtp: (otp: string) => dispatch(requestVerifyOtp(otp)),
    dispatchSetSubmitLoading: () => dispatch(setSubmitLoading())
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo, injectIntl)(IndividualProfile) as React.ComponentType;

export const IndividualProfileTest = IndividualProfile;
