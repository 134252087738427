import React, { memo } from 'react';
import { AVAILABLE_PACKAGES } from '@app/utils/constants';
import { compose } from '@reduxjs/toolkit';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import AdminProfileContainer from './AdminProfile';
import IndividualProfile from './IndividualProfile';
import { selectCurrentSelectedPackage } from './selectors';
import { ProfileContainerProps } from './types';
import { selectGlobalLoading } from '../App/selectors';
import { PageSpinner } from 'tsw-sdk';

const ProfileContainer = ({ selectedPackage, globalLoading }: ProfileContainerProps) => {
  switch (selectedPackage) {
    case AVAILABLE_PACKAGES.ADMIN:
      return (
        <>
          <PageSpinner loading={globalLoading} />
          <AdminProfileContainer />
        </>
      );
    case AVAILABLE_PACKAGES.INDIVIDUAL:
      return (
        <>
          <PageSpinner loading={globalLoading} />
          <IndividualProfile />
        </>
      );
    default:
      return (
        <>
          <PageSpinner loading={globalLoading} />
          <IndividualProfile />
        </>
      );
  }
};

const mapStateToProps = createStructuredSelector({
  selectedPackage: selectCurrentSelectedPackage(),
  globalLoading: selectGlobalLoading()
});

const withConnect = connect(mapStateToProps, null);

export default compose(withConnect, memo, injectIntl)(ProfileContainer) as React.FC;

export const ProfileContainerTest = ProfileContainer;
