import { T } from '@app/components';
import { translate } from '@app/components/IntlGlobalProvider';
import { Form, notification } from 'antd';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { CustomButton, CustomInput, CustomPassword } from 'tsw-sdk';
import ReCAPTCHA from 'react-google-recaptcha';
import { ArrowRightOutlined } from '@ant-design/icons';
import { createStructuredSelector } from 'reselect';
import { selectLoading, selectLoginSuccess, selectReCaptchaState } from './selectors';
import {
  requestLoginUser,
  requestResetPassword,
  requestVerifyRecaptcha,
  resetCaptchaState,
  resetState
} from './reducer';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { InvitationPageProps } from './types';
import { colors, fonts } from '@app/themes';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  gap: 2rem;
`;

const FormTitle = styled(T)`
  && {
    line-height: 3.975rem;

    text-align: center;
  }
`;

const CustomFormItem = styled(Form.Item)`
  &.ant-form-item {
    width: 100%;
    margin-bottom: 0;
  }
  .ant-form-item-label {
    padding: 0 !important;
    font-size: 1rem;
  }

  & input {
    font-size: 12px;
  }
`;

const CustomResetPasswordForm = styled(Form)`
  && {
    display: flex;
    flex-direction: column;
    width: 16rem;
    align-items: center;
    text-align: left;
    gap: 2rem;
    margin-bottom: 2rem;
  }
`;

const CustomMessage = styled.p`
  ${fonts.weights.fw600()}
  ${fonts.size.regular()}
  color: ${colors.tagBorder};
  line-height: 1.25rem;
  text-align: center;
`;

const InvitaionPage = ({
  reCaptchaState,
  loading,
  dispatchResetState,
  dispatchResetCaptchaState,
  dispatchVerifyRecaptcha,
  dispatchResetPassword
}: InvitationPageProps) => {
  const [passwordResetForm] = Form.useForm();

  useEffect(() => {
    dispatchResetState();
    dispatchResetCaptchaState();
  }, []);

  const validateData = (values: any) => {
    const temporaryPassword = values.tempPassword;
    const newPassword = values.newPassword;
    const confirmPassword = values.confirmPassword;

    let dataValid = true;

    if (temporaryPassword === newPassword) {
      dataValid = false;
      passwordResetForm.setFields([
        {
          name: 'newPassword',
          errors: [translate('password_cannot_be_same')]
        }
      ]);
    }

    if (newPassword !== confirmPassword) {
      dataValid = false;
      passwordResetForm.setFields([
        {
          name: 'confirmPassword',
          errors: [translate('password_not_match')]
        }
      ]);
    }

    return dataValid;
  };

  const handlePasswordResetFinish = (values: any) => {
    const email = values.email;
    const temporaryPassword = values.tempPassword;
    const newPassword = values.newPassword;

    const dataValid = validateData(values);

    if (reCaptchaState === 'Success' && dataValid) {
      dispatchResetPassword(email, temporaryPassword, newPassword);
    } else {
      notification.open({
        message: translate('error'),
        description: translate('verify_recaptcha')
      });
    }
  };

  const handleReCaptcha = (value: any) => {
    dispatchVerifyRecaptcha(value);
  };

  const getPasswordResetForm = () => {
    return (
      <Container>
        <FormContainer>
          <FormTitle id="welcome_aboard" type="spoofHeading" />
          <CustomMessage>{translate('welcome_aboard_message')}</CustomMessage>
          <CustomResetPasswordForm layout="vertical" onFinish={handlePasswordResetFinish} form={passwordResetForm}>
            <CustomFormItem
              label={translate('email')}
              name="email"
              rules={[
                {
                  type: 'email',
                  message: `${translate('email_warning_2')}`
                },
                {
                  required: true,
                  message: `${translate('email_warning_1')}`
                }
              ]}
              validateTrigger="onBlur"
            >
              <CustomInput
                type="email"
                placeholder={translate('email_placeholder')}
                data-testid="email-reset-password"
              />
            </CustomFormItem>
            <CustomFormItem
              label={translate('temp_password')}
              name="tempPassword"
              rules={[
                {
                  required: true,
                  message: `${translate('password_warning')}`
                },
                { min: 8, message: `${translate('min_char_required')}` }
              ]}
              validateTrigger="onBlur"
            >
              <CustomPassword type="password" placeholder="*******" data-testid="temp-reset-password" />
            </CustomFormItem>
            <CustomFormItem
              label="New Password"
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: `${translate('required_password')}`
                },
                { min: 8, message: `${translate('min_char_required')}` },
                {
                  pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&#])[A-Za-z\\d@$!%*?&#]{8,}$'),
                  message: `${translate('password_strength')}`
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('tempPassword') !== value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error(`${translate('password_cannot_be_same')}`));
                  }
                })
              ]}
              validateTrigger="onBlur"
            >
              <CustomPassword type="password" placeholder="*******" data-testid="new-reset-password" />
            </CustomFormItem>
            <CustomFormItem
              label="Re-enter New Password"
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: `${translate('confirm_password')}`
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error(`${translate('password_not_match')}`));
                  }
                })
              ]}
              validateTrigger="onBlur"
            >
              <CustomPassword type="password" placeholder="*******" data-testid="confirm-reset-password" />
            </CustomFormItem>
            <ReCAPTCHA sitekey={process.env.RECAPTCHA_SITE_KEY} onChange={handleReCaptcha} />
            <CustomButton htmlType="submit" loading={loading} data-testid="submit-btn-reset-password">
              {translate('login')} <ArrowRightOutlined />
            </CustomButton>
          </CustomResetPasswordForm>
        </FormContainer>
      </Container>
    );
  };

  return getPasswordResetForm();
};

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchLoginUserData: (email: string, password: string) => dispatch(requestLoginUser({ email, password })),
    dispatchResetState: () => dispatch(resetState()),
    dispatchResetCaptchaState: () => dispatch(resetCaptchaState()),
    dispatchResetPassword: (email: string, currentPassword: string, newPassword: string) =>
      dispatch(requestResetPassword({ email, currentPassword, newPassword })),
    dispatchVerifyRecaptcha: (value: string) => dispatch(requestVerifyRecaptcha({ value }))
  };
}

const mapStateToProps = createStructuredSelector({
  loading: selectLoading(),
  reCaptchaState: selectReCaptchaState(),
  loginSuccess: selectLoginSuccess()
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, injectIntl)(InvitaionPage) as React.FC;

export const InvitaionPageTest = InvitaionPage;
