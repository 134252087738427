import React from 'react';
import styled from 'styled-components';
import cancelIcon from '@images/icons/cancelIcon.svg';
import { colors } from '@app/themes';

type InfoTagProps = {
  id: string;
  showReelName?: string;
  showReelLink?: string;
  onDelete: (x: string) => void;
};

const TagContainer = styled.div`
  max-width: 13.68rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0.25rem 0.5rem;
  gap: 0.5rem;
  background: ${colors.tagBg};
  border: 0.5px solid ${colors.tagBorder};
  border-radius: 0.5rem;
`;

const TextContainer = styled.div`
  max-width: 93%;
  display: flex;
  flex-direction: row;
  gap: 0.75rem;

  &&& {
    & > p:first-of-type {
      width: 30%;
    }

    & > p:last-of-type {
      width: 69%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const Text = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem;
`;

const Infotag = ({ onDelete, id, showReelName, showReelLink }: InfoTagProps) => {
  return (
    <TagContainer>
      <TextContainer>
        <Text>{showReelName}</Text>
        <Text>|</Text>
        <Text>{showReelLink}</Text>
      </TextContainer>
      <img src={cancelIcon} onClick={() => showReelName && onDelete(id)} data-testid="cancel-icon" />
    </TagContainer>
  );
};

export default Infotag;
