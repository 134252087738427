import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import { StyledOfferButton } from '../..';
import TicketIcon from '@images/icons/browse-stories/ticket2-icon.svg';
import StackIcon from '@images/icons/browse-stories/stack-icon.svg';
import declineOfferWhiteIcon from '@images/icons/my-offers/decline-offer-white.svg';
import { CounterOfferContainerProps } from '../../types';

const Container = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-top: 3rem;
  align-items: flex-end;
`;

const TextContainer = styled.div`
  display: flex;
`;

const CounterOfferText = styled.h3`
  ${fonts.weights.fw800()};
  ${fonts.size.xBig()};
  line-height: 1.5rem;
  color: ${colors.myOffers.counterOfferText};
  margin-bottom: 0;
  text-align: center;
  letter-spacing: 0.48px;
`;

const CounterAmountText = styled.h3`
  ${fonts.weights.fw800()};
  ${fonts.size.xBig()};
  line-height: 1.5rem;
  color: ${colors.myOffers.storyTitle};
  margin-bottom: 0;
  text-align: center;
  letter-spacing: 0.48px;
`;
const StyledImg = styled.img``;

const CounterOfferContainer = ({
  acceptOffer,
  declineOffer,
  counterOffer,
  offerAmount
}: CounterOfferContainerProps) => {
  return (
    <Container>
      <TextContainer>
        <CounterOfferText>{translate('counter_offer')}</CounterOfferText>
        <CounterAmountText>{`₹ ${offerAmount?.toLocaleString('en-IN')}`}</CounterAmountText>
      </TextContainer>
      <StyledOfferButton onClick={() => counterOffer(true)} data-testid="counterBtn">
        <StyledImg src={TicketIcon} />
        {translate('counter_offer_2')}
      </StyledOfferButton>
      <StyledOfferButton onClick={() => acceptOffer(true)} data-testid="acceptBtn">
        <StyledImg src={StackIcon} />
        {translate('accept')}
      </StyledOfferButton>
      <StyledOfferButton onClick={() => declineOffer(true)} data-testid="declineBtn">
        <StyledImg src={declineOfferWhiteIcon} />
        {translate('decline')}
      </StyledOfferButton>
    </Container>
  );
};

export default CounterOfferContainer;
