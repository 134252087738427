import React, { memo } from 'react';
import { COGNITO_USER_GROUPS } from '@app/utils/constants';
import { compose } from '@reduxjs/toolkit';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import EditAdminProfileContainer from './EditAdminProfile';
import EditIndividualProfile from './EditIndividualProfile';
import { EditProfileContainerProps } from './types';
import { selectAccessToken } from '../TokenProvider/selectors';

const EditProfileContainer = ({ accessToken }: EditProfileContainerProps) => {
  const cognitoGroups = accessToken['cognito:groups'];

  switch (cognitoGroups[0]) {
    case COGNITO_USER_GROUPS.ADMIN:
      return <EditAdminProfileContainer />;
    case COGNITO_USER_GROUPS.INDIVIDUAL:
      return <EditIndividualProfile />;
    default:
      return <EditIndividualProfile />;
  }
};

const mapStateToProps = createStructuredSelector({
  accessToken: selectAccessToken()
});

const withConnect = connect(mapStateToProps, null);

export default compose(withConnect, memo, injectIntl)(EditProfileContainer) as React.FC;

export const EditProfileContainerTest = EditProfileContainer;
