import { put, takeLatest, call, all } from 'redux-saga/effects';
import {
  requestAddUserToOrganisation,
  successAddUserToOrganisation,
  failureAddUserToOrganisation,
  successGetAddedUsersToOrganisation,
  failureGetAddedUsersToOrganisation,
  requestGetAddedUsers,
  requestGeneratePaymentLink,
  failureGeneratePaymentLink,
  successGeneratePaymentLink,
  failureRemoveOrganisationUser,
  successRemoveOrganistaionUser,
  requestRemoveOrganisationUser
} from './reducer';
import { getMutationResponse, getQueryResponse } from '@utils/graphqlUtils';
import { ADD_USER_TO_ORGANISATION, GENERATE_PAYMENT, GET_ADDED_USERS, REMOVE_ORG_USER } from './queries';
import { AnyAction } from '@reduxjs/toolkit';
import { message, notification } from 'antd';
import { translate } from '@app/components/IntlGlobalProvider';
import { get } from 'lodash-es';

export function* addUserToOrganisation(action: AnyAction): Generator<any, any, any> {
  try {
    const payload: any = {
      email: action.payload.email,
      firstName: action.payload.firstName,
      lastName: action.payload.lastName
    };

    const { ok, error } = yield call(getMutationResponse, ADD_USER_TO_ORGANISATION, { input: payload });

    if (ok) {
      yield put(successAddUserToOrganisation());

      notification.open({
        message: translate('success'),
        description: translate('user_added_to_org')
      });
    } else {
      yield put(failureAddUserToOrganisation(error));
      message.error(get(error, 'message'));
    }
  } catch (err: any) {
    yield put(failureAddUserToOrganisation(err));

    if (
      err?.message?.includes(
        'AddOrganizationUser Failed:: A Previously Disabled Organization Buyer exists with this email. Please use a new Email Address!'
      )
    ) {
      const errorMessage = translate('org_user_deleted');
      message.error(errorMessage);
    } else {
      message.error(get(err, 'message', 'something_went_wrong').replace('GraphQL error:', ''));
    }
  }
}

export function* getAddedUsers(): Generator<any, any, any> {
  try {
    const { ok, data, error } = yield call(getQueryResponse, GET_ADDED_USERS, {});

    if (ok) {
      const responseData = get(data, 'getOrganizationUsers', {});
      const maxUsersCapacity = get(responseData, 'maxUsersCapacity', 0);
      const availableCapacity = get(responseData, 'availableCapacity', 0);

      const users = get(responseData, 'buyers', []);

      yield put(
        successGetAddedUsersToOrganisation({
          users: users,
          maxUsersCapacity: maxUsersCapacity,
          availableCapacity: availableCapacity
        })
      );
    } else {
      yield put(failureGetAddedUsersToOrganisation(error));
    }
  } catch (err) {
    yield put(failureGetAddedUsersToOrganisation(err));
  }
}

export function* generatePaymentLink(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      reason: action.payload.reason,
      notes: action.payload.notes
    };
    const { error, data, ok } = yield call(getMutationResponse, GENERATE_PAYMENT, payload);

    if (ok) {
      const generatePaymentLink = get(data, 'generatePaymentLink', {});

      const url = get(generatePaymentLink, 'url', '');

      window.open(url, '_self');

      yield put(successGeneratePaymentLink());
    } else {
      yield put(failureGeneratePaymentLink(error));
    }
  } catch (err) {
    yield put(failureGeneratePaymentLink(err));
  }
}

export function* removeOrganisationUser(action: AnyAction): Generator<any, any, any> {
  try {
    const { ok, error } = yield call(getMutationResponse, REMOVE_ORG_USER, { buyerId: action.payload.buyerId });

    if (ok) {
      yield put(successRemoveOrganistaionUser());

      yield call(getAddedUsers);
    } else {
      yield put(failureRemoveOrganisationUser(error));
    }
  } catch (err) {
    yield put(failureRemoveOrganisationUser(err));
  }
}

export default function* AdminDashboard() {
  yield all([
    takeLatest(requestAddUserToOrganisation.toString(), addUserToOrganisation),
    takeLatest(requestGetAddedUsers.toString(), getAddedUsers),
    takeLatest(requestGeneratePaymentLink.toString(), generatePaymentLink),
    takeLatest(requestRemoveOrganisationUser.toString(), removeOrganisationUser)
  ]);
}

export const adminDashboardSaga = [
  takeLatest(requestAddUserToOrganisation.toString(), addUserToOrganisation),
  takeLatest(requestGetAddedUsers.toString(), getAddedUsers),
  takeLatest(requestGeneratePaymentLink.toString(), generatePaymentLink),
  takeLatest(requestRemoveOrganisationUser.toString(), removeOrganisationUser)
];
