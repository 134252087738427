/**
 * This file contains the application's colors.
 *
 * Define color here instead of duplicating them throughout the components.
 * That allows to change them more easily later on.
 */

const primary = '#333333';
const text = '#212529';
const secondaryText = '#FFFFFF';
const white = '#FFFFFF';
const secondary = '#b0b0b0';
const success = '#28a745';
const error = '#dc3545';
const gotoStories = '#1890ff';
const cardBg = '#d8d8d8';
const lightBorder = '#a0a0a0';
const darkBorder = '#333333';
const headerColor = '#bdbdbd';
const cardHeader = '#d9d9d9';
const cardSubtitle = '#8b8b8b';
const cardUserCount = '#686868';
const listItemColor = '#5c5c5c';
const termsAndConditionListItem = '#383838';
const termsAndConditionCloseBtn = '#efefef';
const cardShadow = 'rgba(132, 132, 132, 0.2)';
const dropdownBg = '#eff1f1';
const tagBg = '#efefef';
const tagBorder = '#383838';
const selectedCard = '#e8e8e8';
const btnDisabled = '#525252';
const checkboxborder = '#d7d7d7';
const dashboardBg = '#F8F8F8';
const sidebarMenuItemText = '#8A8A8A';
const viewAllColor = '#4F4F4F';
const verifiedBtnBg = '#a1a1a1';
const styledText = '#3d3d3d';
const redClr = '#FF0000';
const formTextColor = '#121212';

const myDashboardColors = {
  heading: '#A1A1A1',
  userAvatar: '#6B6B6B',
  newTagBg: '#EEEEEE',
  newTagText: '#A9A9A9',
  storyName: '#333333',
  storyItemCheckmarkBorder: '#696969',
  storyItemCheckmarkCheckedBg: '#C6C6C6',
  storyItemCheckmarkUncheckedBg: '#E4E4E4',
  cardShadow: 'rgba(132, 132, 132, 0.2)',
  cardTitle: '#181818',
  cardSubtitle: '#5C5C5C',
  trendingStoryDate: '#747474',
  subscriptionInActiveColor: 'rgba(0,0,0,0.7)',
  accordionBorder: '#CACACA',
  storyTitleColor: '#4A4A4A'
};

const adminDashboardColors = {
  designation: '#333333',
  bioDescriptionColors: '#121212',
  addUserBtnBg: '#EFEFEF',
  userLimitText: '#8A8A8A',
  userAccessDropdownBg: '#EAEAEA'
};

const browseStoriesColors = {
  labelColor: '#383838',
  cardHeaderColor: '#C4C4C4',
  subTextColor: '#A9A9A9',
  loglineTextColor: '#121212',
  bannerTextColor: '#CBCBCB',
  authorInfoBg: '#EEEEEE',
  largeBannerTextColor: '#DEDEDE'
};

const profileContainerColors = {
  grayBtnBg: '#E0E0E0',
  grayBtnColor: '#4F4F4F'
};

const profilePageColors = {
  titleColor: '#A1A1A1',
  companyLogoBg: '#EFEFEF',
  companyName: '#333333',
  companyRole: '#181818',
  roleDuration: '#5C5C5C',
  filmCardBg: '#D5D5D5',
  cardText: '#181818'
};

const myOffers = {
  closedOfferCardBg: '#EEEEEE',
  offerCardShadowColor: 'rgba(132, 132, 132, 0.2)',
  offerCardHeaderBg: '#C4C4C4',
  bannerTextClr: '#DEDEDE',
  storyTitle: '#333333',
  dateText: '#A9A9A9',
  closedTagBg: '#D8D8D8',
  counterOfferText: '#787878',
  grayBtnBg: '#E0E0E0',
  grayBtnColor: '#4F4F4F',
  titleColor: '#A1A1A1',
  storyName: '#333333',
  offerDateColor: '#A9A9A9',
  scriptIncludedPillBg: '#EEEEEE',
  scriptIncludedPillColor: '#A9A9A9',
  cardHeaderBgTextColor: '#DEDEDE',
  closedPillBg: '#D8D8D8',
  offerCardPillText: '#757575',
  counterOfferNoteColor: '#A0A0A0',
  counterOfferFormLabel: '#383838',
  collapseBtnBg: '#7C7B7B',
  tagBg: '#EFEFEF',
  disableBg: '#DDDDDD',
  tableHeadingBorder: '#D9D9D9',
  checkboxLabelColor: '#121212',
  cancelBtnText: '#3D3D3D',
  offerAcceptMessageClr: '#767676',
  disableBackground: '#848484',
  rejectedStateColor: '#B70000',
  supportButtonBg: '#262626',
  supportText: '#DCDCDC'
};

const storyPage = {
  starIconFill: '#7C7B7B',
  subTextClr: '#AEAEAE'
};

const notificationColors = {
  pageTitle: '#A1A1A1',
  notificationPillColor: '#FF0000',
  disabledOverlay: 'rgba(255,255,255,0.7)',
  notificationTitle: '#181818'
};

const neutral = {
  neutral90: '#C7CDD6',
  neutral80: '#F2F4F7',
  neutral70: '#F9FAFB'
};

const colors = {
  transparent: 'rgba(0,0,0,0)',
  // Example colors:
  formTextColor,
  text,
  primary,
  secondary,
  success,
  error,
  secondaryText,
  gotoStories,
  cardBg,
  lightBorder,
  darkBorder,
  headerColor,
  cardHeader,
  cardSubtitle,
  cardUserCount,
  listItemColor,
  termsAndConditionListItem,
  termsAndConditionCloseBtn,
  cardShadow,
  dropdownBg,
  tagBg,
  tagBorder,
  selectedCard,
  btnDisabled,
  white,
  checkboxborder,
  dashboardBg,
  sidebarMenuItemText,
  myDashboardColors,
  adminDashboardColors,
  viewAllColor,
  verifiedBtnBg,
  styledText,
  browseStoriesColors,
  profileContainerColors,
  profilePageColors,
  myOffers,
  storyPage,
  notificationColors,
  redClr,
  neutral,
  theme: {
    lightMode: {
      primary,
      secondary
    },
    darkMode: {
      primary: secondary,
      secondary: primary
    }
  }
};
module.exports = colors;
