import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import { ShowreelItemProps } from './types';

const ShowreelItemContainer = styled.div`
  width: 18rem;
  height: 14.5rem;
  background-color: ${colors.profilePageColors.companyName};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  padding: 2rem;

  &:not(:first-of-type) {
    margin-left: 2rem;
  }
`;
const ShowreelTitle = styled.p`
  ${fonts.family.SpoofRegular()};
  ${fonts.size.xBig()};
  ${fonts.weights.fw400()};
  color: ${colors.white};
  line-height: 1.75rem;
`;

const ViewShowReel = styled.a`
  margin-top: auto;
  ${fonts.family.SpoofRegular()};
  ${fonts.size.regular()};
  ${fonts.weights.fw400()};
  color: ${colors.white};
  text-decoration: underline;
  cursor: pointer;
  line-height: 1.31rem;
`;

const ShowreelItem = ({ showreel }: ShowreelItemProps) => {
  return (
    <ShowreelItemContainer>
      <ShowreelTitle>{showreel.name}</ShowreelTitle>
      <ViewShowReel href={showreel.link} target="_blank">
        {translate('view_showreel')}
      </ViewShowReel>
    </ShowreelItemContainer>
  );
};

export default ShowreelItem;
