import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import { prepare } from '@app/utils';

export const initialState = {
  transactions: [],
  offerState: null,
  daysLeft: null,
  offerPlacedOn: null,
  offerEndsOn: null,
  getTransactionDataLoading: false,
  getTransactionDataError: null,
  getConversationDataLoading: false,
  conversations: [],
  conversationError: null,
  sellerData: null
};

const offerHistorySlice = createSlice({
  name: 'offerHistory',
  initialState,
  reducers: {
    requestGetTransactionData: {
      reducer: (state) => {
        state.getTransactionDataLoading = true;
      },
      prepare
    },
    requestGetConversationData: {
      reducer: (state) => {
        state.getConversationDataLoading = true;
      },
      prepare
    },
    successGetTransactionData(state, action) {
      state.getTransactionDataLoading = false;
      state.getTransactionDataError = null;
      state.transactions = action.payload.transactions;
      state.offerState = action.payload.offerState;
      state.daysLeft = action.payload.daysLeft;
      state.offerPlacedOn = action.payload.placedOn;
      state.offerEndsOn = action.payload.expiresOn;
    },
    failureGetTransactionData(state, action) {
      state.getTransactionDataError = get(action.payload, 'message', 'something_went_wrong');
      state.getTransactionDataLoading = false;
    },
    successGetConversationData(state, action) {
      state.getConversationDataLoading = false;
      state.conversations = action.payload.conversationDetails;
      state.sellerData = action.payload.seller;
    },
    failureGetConversationData(state, action) {
      state.getConversationDataLoading = false;
      state.conversationError = get(action.payload, 'message', 'something_went_wrong');
    },

    resetState(state) {
      state.transactions = [];
      state.offerState = null;
      state.daysLeft = null;
      state.getTransactionDataLoading = false;
      state.getTransactionDataError = null;
      state.getConversationDataLoading = false;
      state.conversationError = null;
      state.conversations = [];
      state.sellerData = null;
    }
  }
});

export const {
  requestGetTransactionData,
  successGetTransactionData,
  failureGetTransactionData,
  requestGetConversationData,
  successGetConversationData,
  failureGetConversationData,
  resetState
} = offerHistorySlice.actions;

export default offerHistorySlice.reducer;
