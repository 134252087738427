import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  requestLoginUser,
  successLoginUser,
  failureLoginUser,
  successReCaptcha,
  failureRecaptcha,
  requestVerifyRecaptcha,
  requestResetPassword,
  successResetPassword,
  failureResetPassword
} from './reducer';
import { cognito } from 'tsw-sdk';
import { getMutationResponse } from '@utils/graphqlUtils';
import { VERIFY_RECAPTCHA } from '@utils/queries';
import routeConstants from '@app/utils/routeConstants';
import history from '@utils/history';
import { notification } from 'antd';
import { translate } from '@app/components/IntlGlobalProvider';
import { get } from 'lodash-es';

const { createCognitoUserPool, login, changePassword } = cognito;
const userPoolId = process.env.USER_POOL_ID as string;
const userPoolClientId = process.env.USER_POOL_CLIENT_ID as string;

export function* loginUser(action: any): Generator<any, any, any> {
  try {
    yield call(createCognitoUserPool, userPoolId, userPoolClientId);
    yield call(login, action.payload.email, action.payload.password);

    yield put(successLoginUser());
  } catch (err) {
    yield put(failureLoginUser(err));
    notification.open({
      message: translate('error'),
      description: get(err, 'message', translate('something_went_wrong'))
    });
  }
}
export function* getRecaptchaVerify(action: any): Generator<any, any, any> {
  const payload = {
    input: {
      token: action.payload.value
    }
  };
  try {
    const data = yield call(getMutationResponse, VERIFY_RECAPTCHA, payload);
    yield put(successReCaptcha(data));
  } catch (err) {
    yield put(failureRecaptcha(err));
  }
}

export function* resetPassword(action: any): Generator<any, any, any> {
  try {
    yield call(createCognitoUserPool, userPoolId, userPoolClientId);

    yield call(changePassword, action.payload.email, action.payload.currentPassword, action.payload.newPassword);

    successResetPassword();

    history.push(routeConstants.login.route);
  } catch (err: any) {
    yield put(failureResetPassword(err));
    notification.open({
      message: translate('error'),
      description: get(err, 'message', translate('something_went_wrong'))
    });
  }
}

// Individual exports for testing
export default function* InvitationPageSaga() {
  yield all([
    takeLatest(requestLoginUser.toString(), loginUser),
    takeLatest(requestVerifyRecaptcha.toString(), getRecaptchaVerify),
    takeLatest(requestResetPassword.toString(), resetPassword)
  ]);
}

export const invitationPageSaga = [
  takeLatest(requestLoginUser.toString(), loginUser),
  takeLatest(requestVerifyRecaptcha.toString(), getRecaptchaVerify),
  takeLatest(requestResetPassword.toString(), resetPassword)
];
