import { colors, fonts } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import trashIcon from '@images/icons/trash-icon.svg';

export type AdminUserItemProps = {
  userName: string;
  userEmail: string;
  index: number;
  buyerId: string;
  removeUser: (buyerId: string) => void;
};

const AdminUserItemContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.5rem;
`;

const UserNumberItem = styled.p`
  ${fonts.weights.black()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
  color: ${colors.adminDashboardColors.designation};
`;

const UserName = styled.p`
  ${fonts.weights.black()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
  color: ${colors.adminDashboardColors.designation};
  margin-left: 1.5rem;
`;

const UserEmail = styled.p`
  ${fonts.weights.medium()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
  color: ${colors.adminDashboardColors.designation};
  margin-left: 3.5rem;
`;

const TrashIcon = styled.img`
  width: 1rem;
  margin-left: 1.62rem;
  cursor: pointer;
`;

const AdminUserItem = ({ removeUser, buyerId, userName, userEmail, index }: AdminUserItemProps) => {
  return (
    <AdminUserItemContainer>
      <UserNumberItem>{index}.</UserNumberItem>
      <UserName>{userName}</UserName>
      <UserEmail>{userEmail}</UserEmail>
      <TrashIcon src={trashIcon} onClick={() => removeUser(buyerId)} data-testid="remove-user-btn" />
    </AdminUserItemContainer>
  );
};

export default AdminUserItem;
