import { createSelector } from 'reselect';
import get from 'lodash-es/get';
import { initialState } from './reducer';

export const selectBrowseStoriesDomain = (state: any) => state.browseStoriesReducer || initialState;

export const selectLanguageList = () =>
  createSelector(selectBrowseStoriesDomain, (substate) => get(substate, 'languageList'));

export const selectGenreList = () =>
  createSelector(selectBrowseStoriesDomain, (substate) => get(substate, 'genreList'));

export const selectTagsList = () => createSelector(selectBrowseStoriesDomain, (substate) => get(substate, 'tagsList'));

export const selectSavedFiltersList = () =>
  createSelector(selectBrowseStoriesDomain, (substate) => get(substate, 'savedFiltersData'));

export const selectStories = () =>
  createSelector(selectBrowseStoriesDomain, (substate) => get(substate, 'storiesData'));

export const selectStoryPdfUrl = () =>
  createSelector(selectBrowseStoriesDomain, (substate) => get(substate, 'storyPdfUrl'));

export const selectStoryPdfLoading = () =>
  createSelector(selectBrowseStoriesDomain, (substate) => get(substate, 'storyPdfLoading'));

export const selectBrowseStoriesTotal = () =>
  createSelector(selectBrowseStoriesDomain, (substate) => get(substate, 'total'));

export const selectLoadingStories = () =>
  createSelector(selectBrowseStoriesDomain, (substate) => get(substate, 'loadingStories'));

export const selectStoryTypeList = () =>
  createSelector(selectBrowseStoriesDomain, (substate) => get(substate, 'storyTypeList'));
