import { createSelector } from 'reselect';
import get from 'lodash-es/get';
import { initialState } from './reducer';

export const selectMyOffersDomain = (state: any) => state.myOffersReducer || initialState;

export const selectOpenOffers = () => createSelector(selectMyOffersDomain, (substate) => get(substate, 'openOffers'));

export const selectClosedOffers = () =>
  createSelector(selectMyOffersDomain, (substate) => get(substate, 'closedOffers'));

export const selectMissedOffers = () =>
  createSelector(selectMyOffersDomain, (substate) => get(substate, 'missedOffers'));

export const selectOpenOffersTotal = () =>
  createSelector(selectMyOffersDomain, (substate) => get(substate, 'openOffersTotal'));

export const selectClosedOffersTotal = () =>
  createSelector(selectMyOffersDomain, (substate) => get(substate, 'closedOffersTotal'));

export const selectMissedOffersTotal = () =>
  createSelector(selectMyOffersDomain, (substate) => get(substate, 'missedOffersTotal'));

export const selectSendMailToLegalTeamLoading = () =>
  createSelector(selectMyOffersDomain, (substate) => get(substate, 'sendMailToLegalTeamLoading'));
