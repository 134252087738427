import { css, FlattenInterpolation, FlattenSimpleInterpolation, ThemeProps } from 'styled-components';
import { media } from '@themes/index';

// sizes

export const dynamicFontSize = (
  font: () => FlattenSimpleInterpolation | FlattenInterpolation<ThemeProps<any>>,
  desktopDelta = 0,
  tabletDelta = 0
) => css`
  ${font()}
  ${media.greaterThan('tablet')`font-size: ${
    tabletDelta + parseInt((font()[0]! as string).replace('font-size:', '').replace('rem;', '').replace(/\s+/g, ''))
  }rem;`}
${media.greaterThan('desktop')`font-size: ${
    desktopDelta + parseInt((font()[0]! as string).replace('font-size:', '').replace('rem;', '').replace(/\s+/g, ''))
  }rem;`}
`;
const regular = () => css`
  font-size: 1rem;
`;

const xRegular = () => css`
  font-size: 1.125rem;
`;
const small = () => css`
  font-size: 0.875rem;
`;

const extraSmall = () => css`
  font-size: 0.5rem;
`;

const xSmall = () => css`
  font-size: 0.75rem;
`;

const big = () => css`
  font-size: 1.25rem;
`;

const xBig = () => css`
  font-size: 1.5rem;
`;

const large = () => css`
  font-size: 2rem;
`;
const extraLarge = () => css`
  font-size: 3rem;
`;

const xxSmall = () => css`
  font-size: 0.625rem;
`;

const BannerText = () => css`
  font-size: 2.62rem;
`;

const LargeBannerText = () => css`
  font-size: 5.59rem;
`;

// Font-Family
const Inter = () => css`
  font-family: 'Inter';
`;

const SpoofFamily = () => css`
  font-family: 'Spoof-Bold';
`;

const SpoofRegular = () => css`
  font-family: 'Spoof-Regular';
`;

const SpotwriterFamily = () => css`
  font-family: 'Spotwriter-Headline';
`;

// weights
const light = () => css`
  font-weight: light;
`;
const bold = () => css`
  font-weight: bold;
`;

const normal = () => css`
  font-weight: normal;
`;

const medium = () => css`
  font-weight: 500;
`;

const black = () => css`
  font-weight: 700;
`;

const fw200 = () => css`
  font-weight: 200;
`;

const fw400 = () => css`
  font-weight: 400;
`;

const fw600 = () => css`
  font-weight: 600;
`;

const fw700 = () => css`
  font-weight: 700;
`;

const fw800 = () => css`
  font-weight: 800;
`;

const fw900 = () => css`
  font-weight: 900;
`;

// styles
const pageTitle = () => css`
  ${bold()}
  ${extraLarge()}
`;

const heading = () => css`
  ${large()}
  ${bold()}
`;

const subheading = () => css`
  ${big()}
  ${bold()}
`;

const smallBoldText = () => css`
  ${small()}
  ${bold()}
`;

const standard = () => css`
  ${regular()}
  ${normal()}
`;

const subText = () => css`
  ${small()}
  ${normal()}
`;

const smallText = () => css`
  ${xSmall()}
  ${fw400()}
`;

const cardSubHeading = () => css`
  ${xBig()}
  ${medium()}
`;

const spoofHeading = () => css`
  ${SpoofFamily()}
  ${extraLarge()}
`;

const spotwriterHeading = () => css`
  ${SpotwriterFamily()}
  ${large()}
`;

const sidebarText = () => css`
  ${black()}
  ${small()}
`;

const checkBoxText = () => css`
  ${xSmall()}
  ${fw400()}
`;

const MyDashboardHeading = () => css`
  ${SpoofFamily()}
  ${pageTitle()}
`;

export default {
  dynamicFontSize,
  size: {
    regular,
    small,
    xSmall,
    extraSmall,
    big,
    xBig,
    large,
    extraLarge,
    xRegular,
    xxSmall,
    BannerText,
    LargeBannerText
  },
  style: {
    heading,
    subheading,
    standard,
    subText,
    smallBoldText,
    spoofHeading,
    spotwriterHeading,
    smallText,
    pageTitle,
    cardSubHeading,
    sidebarText,
    checkBoxText,
    MyDashboardHeading
  },
  weights: {
    light,
    bold,
    normal,
    medium,
    black,
    fw200,
    fw400,
    fw600,
    fw700,
    fw800,
    fw900
  },
  family: {
    SpoofFamily,
    SpotwriterFamily,
    SpoofRegular,
    Inter
  }
};
