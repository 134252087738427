import React from 'react';
import { Property } from 'csstype';
import styled from 'styled-components';
import { Select } from 'antd';
import { colors } from '@app/themes';

type CustomSelectProps = {
  width?: Property.Width;
  children?: React.ReactNode;
};
export interface optionArr {
  name: string;
  value: string | number;
}

export interface SelectBoxTypes {
  width?: string;
  optionArr: optionArr[];
  defaultValue?: string;
  onChange?: (values: any) => void;
  selectBoxState?: string;
}

export const CustomSelect = styled(Select)<CustomSelectProps>`
  && {
    &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border-right-width: 0.5px !important;
    }

    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customise-input) .ant-select-selector {
      box-shadow: none;
      border-right-width: 0.5px !important;
    }
    &.ant-select {
      font-size: 0.75rem;
      width: ${(prop) => (prop.width ? prop.width : '100%')};
    }
    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 31px;
      padding: 0 4px !important;
    }

    &.ant-select-single .ant-select-selector {
      border: 0.5px solid ${colors.lightBorder} !important;
      border-radius: 5px;
    }
    &.ant-select-single .ant-select-selector .ant-select-selection-item,
    &.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 2rem;
      text-align: left;
    }
  }
`;

const { Option } = Select;

const CustomOption = styled(Option)`
  && {
    padding: 0;
    border: 1px solid red;
  }
`;

const SelectBox = ({ width, optionArr, onChange, ...props }: SelectBoxTypes) => {
  return (
    <CustomSelect
      aria-required
      onChange={onChange}
      width={width}
      bordered={false}
      dropdownStyle={{ background: `${colors.dropdownBg}` }}
      data-testid="custom-select"
      placeholder="Select an option"
      {...props}
    >
      {optionArr.map((item, index) => {
        return (
          <CustomOption key={`${index}_${item.value}`} value={item.value} data-testid="custom-select-option">
            {item.name}
          </CustomOption>
        );
      })}
    </CustomSelect>
  );
};

export default SelectBox;
