import { translate } from '@app/components/IntlGlobalProvider';
import PdfViewer from '@app/components/PdfViewer';
import { colors, fonts } from '@app/themes';
import { PRIVACY_POLICY_URL } from '@app/utils/constants';
import React from 'react';
import styled from 'styled-components';

const PrivacyPolicyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 6.87rem 8.31rem 8.31rem 9.43rem;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${colors.white};
  align-items: center;
  justify-content: center;
`;

const HeaderText = styled.p`
  ${fonts.family.SpoofFamily};
  font-style: normal;
  ${fonts.weights.black};
  font-size: 3rem;
  line-height: 3.93rem;
  text-align: center;
  color: ${colors.primary};
  text-transform: capitalize;
`;

const CloseBtn = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.62rem 2rem;
  width: 6.75rem;
  height: 2.75rem;
  background: ${colors.termsAndConditionCloseBtn};
  border-radius: 0.5rem;
  border: none;
  outline: none;
  color: ${colors.termsAndConditionListItem};
  font-style: normal;
  ${fonts.weights.bold};
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 2rem;
  cursor: pointer;
`;

export type PrivatePolicyPageProps = {
  handleCancel: () => void;
  handleLastPageSeen: () => void;
};

const PrivatePolicyPage = ({ handleCancel, handleLastPageSeen }: PrivatePolicyPageProps) => {
  return (
    <PrivacyPolicyContainer>
      <HeaderText>{translate('privacy_policy')}</HeaderText>
      <PdfViewer file={PRIVACY_POLICY_URL} handleLastPageSeen={handleLastPageSeen} />
      <CloseBtn onClick={handleCancel} data-testid="policy-back-btn">
        {translate('close_btn')}
      </CloseBtn>
    </PrivacyPolicyContainer>
  );
};

export default PrivatePolicyPage;
