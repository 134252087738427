import { gql } from 'apollo-boost';

export const MAKE_OFFER = gql`
  mutation PlaceOffer($input: PlaceOfferInput!) {
    placeOfferOnStory(input: $input)
  }
`;

export const GET_STORY_DETAILS = gql`
  query Stories($filters: NewStoryFilters, $pages: StoryPagination!) {
    stories(filters: $filters, pagination: $pages) {
      stories {
        id
        title
        languages {
          id
          name
        }
        genres {
          id
          name
        }
        storyType {
          id
          name
        }
        tags {
          id
          name
        }
        logline
        storyline
        state
        expiresAt
        views
        offers
        ratings
        createdAt
        writer {
          id
          firstName
          lastName
          profilePic
          publishedStories {
            id
            name
            publishDate
          }
        }

        buyerActions {
          hasOffered
          hasReviewed
          hasDownloaded
          hasViewed
          hasLiked
          hasScreenPlayAccess
          hasScreenPlayAccessRequestCount
        }

        supportingMaterials {
          id
          attachmentType
          fileName
        }
        expectedOffer
        coAuthor1
        coAuthor2
      }
      total
    }
  }
`;

export const GET_SIGNED_URL = gql`
  query SignedUrl($urlinput: SignedURLInput!) {
    signedURL(input: $urlinput) {
      rootFolder
      fileName
      signedURL
    }
  }
`;

export const UPDATE_VIEW_TIME = gql`
  mutation UpdateViewTime($input: ViewTimeInput!) {
    updateViewTime(input: $input) {
      response
      pollId
    }
  }
`;

export const SUBMIT_STORY_REVIEWS = gql`
  mutation SubmitStoryReview($input: StoryReviewInput!) {
    submitStoryReview(input: $input) {
      id
      storyId
      reviewer {
        id
        firstName
        lastName
      }
      ratings
      comment
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const SHARE_STORY = gql`
  mutation ShareStoryToTeam($storyId: Int!, $buyerId: Int) {
    shareStoryToTeam(storyId: $storyId, buyerId: $buyerId)
  }
`;

export const CONCLUDE_OFFER = gql`
  mutation ConcludeOffer($input: ConcludeOfferInput!) {
    concludeOfferOnStory(input: $input)
  }
`;

export const SELLER_COUNTER_OFFER_DETAILS = gql`
  query SellerCounterAmount($input: OfferIdInput!) {
    sellerCounterAmount(input: $input) {
      detailsId
      offerId
      sellerId
      amount
    }
  }
`;

export const GET_OFFERID = gql`
  query OfferID($input: OfferOnStoryInput!) {
    buyerOfferOnStory(input: $input) {
      message
      offerId
      offerState
    }
  }
`;

export const BUYER_LATEST_OFFER = gql`
  query buyerLatestOffer($input: OfferIdInput!) {
    buyerOfferDetails(input: $input) {
      id
      buyerName
      amount
    }
  }
`;

export const SEND_REQUEST_MESSAGE = gql`
  mutation sendMessage($input: ConversationInput!) {
    sendMessage(input: $input)
  }
`;
