import { createSelector } from 'reselect';
import get from 'lodash-es/get';
import { initialState } from './reducer';

export const selectStoryDetailsDomain = (state: any) => state.storiesDetailsReducer || initialState;

export const selectStoryData = () => createSelector(selectStoryDetailsDomain, (substate) => get(substate, 'storyData'));

export const selectScreenPlayUrl = () =>
  createSelector(selectStoryDetailsDomain, (substate) => get(substate, 'screenPlayUrl'));

export const selectScreenplayUrlLoading = () =>
  createSelector(selectStoryDetailsDomain, (substate) => get(substate, 'getScreenPlaySignedUrlLoading'));

export const selectNarrationUrl = () =>
  createSelector(selectStoryDetailsDomain, (substate) => get(substate, 'narrationUrl'));

export const selectNarrationUrlLoading = () =>
  createSelector(selectStoryDetailsDomain, (substate) => get(substate, 'getNarrationSignedUrlLoading'));

export const selectImagesUrlList = () =>
  createSelector(selectStoryDetailsDomain, (substate) => get(substate, 'imagesUrl'));

export const selectImagesUrlListLoading = () =>
  createSelector(selectStoryDetailsDomain, (substate) => get(substate, 'getImagesSignedUrlLoading'));

export const selectProfileUrl = () =>
  createSelector(selectStoryDetailsDomain, (substate) => get(substate, 'profileUrl'));

export const selectPollId = () => createSelector(selectStoryDetailsDomain, (substate) => get(substate, 'pollId'));

export const selectSellerCounterOfferDetails = () =>
  createSelector(selectStoryDetailsDomain, (substate) => get(substate, 'sellerCounterOffer'));

export const selectOfferId = () => createSelector(selectStoryDetailsDomain, (substate) => get(substate, 'offerId'));

export const selectOfferState = () =>
  createSelector(selectStoryDetailsDomain, (substate) => get(substate, 'offerState'));

export const selectBuyerLatestOfferAmount = () =>
  createSelector(selectStoryDetailsDomain, (substate) => get(substate, 'buyerLatestOfferAmount'));
