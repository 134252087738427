import React from 'react';
import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import { Button } from 'antd';
import styled from 'styled-components';
import { AcceptOfferModalProps } from './types';
import defaultAvatarIcon from '@images/icons/my-offers/avatar-profile.svg';
import acceptOfferIcon from '@images/icons/browse-stories/stack-icon.svg';
import { PROFILE_IMAGE_PROD_URL, PROFILE_IMAGE_QA_URL, PROFILE_IMAGE_URL } from '@app/utils/constants';
import { If } from 'tsw-sdk';

// const AcceptOfferModalContainer = styled(Modal)`
//   && {
//     & .ant-modal-footer {
//       display: none;
//     }

//     & .ant-modal-content {
//       border-radius: 1.5rem;
//     }

//     & .ant-modal-body {
//       border-radius: 1.5rem;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       flex-direction: column;
//     }
//   }
// `;

const AcceptOfferModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6.5rem;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${colors.white};
`;

const AcceptOfferTitle = styled.p`
  width: 45rem;
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  color: ${colors.primary};
  text-align: center;
  line-height: 3.93rem;
  margin-left: auto;
  margin-right: auto;
`;

const ActionsContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 5.18rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CancelBtn = styled(Button)`
  width: 7.81rem;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0.75rem 2rem 0.75rem 2rem;
  display: flex;
  align-items: center;
  background-color: ${colors.myOffers.grayBtnBg};
  color: ${colors.myOffers.grayBtnColor};
  cursor: pointer;
  ${fonts.weights.fw800()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
  border: none;

  &:hover {
    background-color: ${colors.myOffers.grayBtnBg};
    color: ${colors.myOffers.grayBtnColor};
    border: none;
  }
`;

const AcceptOfferBtn = styled(Button)`
  width: 12.62rem;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0.75rem 2rem 0.75rem 2rem;
  display: flex;
  align-items: center;
  background-color: ${colors.primary};
  color: ${colors.white};
  margin-left: 2rem;
  cursor: pointer;
  ${fonts.weights.fw800()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
  border: none;

  &:hover {
    background-color: ${colors.primary};
    color: ${colors.white};
    border: none;
  }

  &:focus {
    background-color: ${colors.primary};
    color: ${colors.white};
    border: none;
  }
`;

const ProfileContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3.06rem;
`;

const ProfileImage = styled.img`
  width: 9rem;
  height: 9rem;
  border-radius: 6.25rem;
`;

const UserName = styled.p`
  margin-top: 1.25rem;
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw900()};
  ${fonts.size.big()};
  line-height: 1.68rem;
  color: ${colors.myOffers.storyName};
`;

const UserDesignation = styled.p`
  margin-top: 0.68rem;
  ${fonts.weights.fw400()};
  ${fonts.size.regular()};
  line-height: 1.37rem;
  color: ${colors.myOffers.storyName};
`;

const OfferAmount = styled.p`
  margin-top: 1.25rem;
  ${fonts.weights.fw700()};
  ${fonts.size.large()};
  line-height: 0.75rem;
  color: ${colors.myOffers.storyName};
`;

const BtnImage = styled.img`
  margin-right: 0.62rem;
`;

const MessageText = styled.p`
  width: 31.12rem;
  margin-top: 1.18rem;
  ${fonts.weights.fw600()};
  ${fonts.size.regular()};
  line-height: 1.25rem;
  color: ${colors.myOffers.offerAcceptMessageClr};
  text-align: center;
`;

const AcceptOfferModal = ({
  handleCancel,
  handleAccept,
  offerAmount,
  authorName,
  profilePic
}: AcceptOfferModalProps) => {
  return (
    <AcceptOfferModalContent>
      <AcceptOfferTitle>{translate('accept_offer_confirm_question')}</AcceptOfferTitle>
      <MessageText>{translate('offer_accept_message')}</MessageText>
      <ProfileContainer>
        <If condition={process.env.ENVIRONMENT_NAME === 'qa'}>
          <ProfileImage src={profilePic !== '' ? `${PROFILE_IMAGE_QA_URL}/${profilePic}` : defaultAvatarIcon} />
        </If>
        <If condition={process.env.ENVIRONMENT_NAME === 'production'}>
          <ProfileImage src={profilePic !== '' ? `${PROFILE_IMAGE_PROD_URL}/${profilePic}` : defaultAvatarIcon} />
        </If>
        <If condition={process.env.ENVIRONMENT_NAME !== 'production' && process.env.ENVIRONMENT_NAME !== 'qa'}>
          <ProfileImage src={profilePic !== '' ? `${PROFILE_IMAGE_URL}/${profilePic}` : defaultAvatarIcon} />
        </If>
        <UserName>{authorName}</UserName>
        <UserDesignation>{translate('storyteller')}</UserDesignation>
        <OfferAmount>{`₹ ${offerAmount?.toLocaleString('en-IN')}`}</OfferAmount>
      </ProfileContainer>
      <ActionsContainer>
        <CancelBtn onClick={handleCancel} data-testid="accept-offer-cancel">
          {translate('cancel')}
        </CancelBtn>
        <AcceptOfferBtn onClick={handleAccept} data-testid="accept-offer-accept-btn">
          <BtnImage src={acceptOfferIcon} />
          {translate('accept_offer')}
        </AcceptOfferBtn>
      </ActionsContainer>
    </AcceptOfferModalContent>
  );
};

export default AcceptOfferModal;
