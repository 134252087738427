/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers, AnyAction } from 'redux';

import languageProvider from '@containers/LanguageProvider/reducer';
import home from '@containers/HomeContainer/reducer';
import signUp from '@containers/SignUpContainer/reducer';
import login from '@containers/LoginContainer/reducer';
import tokenProvider from '@containers/TokenProvider/reducer';
import profileReducer from '@app/containers/ProfileContainer/reducer';
import individualDashboardReducer from '@app/containers/DashboardContainerPage/IndividualDashboard/reducer';
import adminDashboardReducer from '@app/containers/DashboardContainerPage/AdminDashboard/reducer';
import invitePageReducer from '@app/containers/InvitationPage/reducer';
import packageDetailsPageReducer from '@app/containers/PackageDetailsPage/reducer';
import browseStoriesReducer from '@app/containers/DashboardContainerPage/IndividualDashboard/tabs/BrowseStories/reducer';
import storiesDetailsReducer from '@app/containers/StoryDetailsPage/reducer';
import myOffersReducer from '@app/containers/DashboardContainerPage/IndividualDashboard/tabs/MyOffers/reducer';
import offerHistoryReducer from '@app/containers/OfferHistory/reducer';
import dashboardContainerReducer from '@app/containers/DashboardContainerPage/reducer';
import individualNotificationsReducer from '@app/containers/DashboardContainerPage/IndividualDashboard/tabs/MyNotifications/reducer';
import adminNotificationsReducer from '@app/containers/DashboardContainerPage/AdminDashboard/tabs/MyNotifications/reducer';
import globalReducer from '@containers/App/reducer';

import { createAction } from '@reduxjs/toolkit';
import { setClient } from './utils/graphqlUtils';

export const logoutAction = createAction('USER_LOGOUT');

const requestBlacklist = [
  'profile/requestCreateProfile',
  'storiesDetails/requestUpdateViewTime',
  'individualNotifications/requestGetInboxNotifications',
  'individualNotifications/requestGetNotifications',
  'individualNotifications/requestReadNotification',
  'adminNotifications/requestGetInboxNotifications',
  'adminNotifications/requestGetNotifications',
  'adminNotifications/requestReadNotification',
  'profile/requestGetAccountType',
  'home/requestAccountStatus',
  'profile/requestGetProfile',
  'browseStories/requestGetSavedFilters',
  'browseStories/requestLangGenre',
  'browseStories/requestGetTags',
  'browseStories/requestGenerateStoryPdf',
  'browseStories/requestToggleFavourite'
];

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducer = {}) {
  const appReducer = combineReducers({
    ...injectedReducer,
    languageProvider,
    home,
    signUp,
    login,
    tokenProvider,
    profileReducer,
    individualDashboardReducer,
    adminDashboardReducer,
    invitePageReducer,
    packageDetailsPageReducer,
    browseStoriesReducer,
    storiesDetailsReducer,
    myOffersReducer,
    offerHistoryReducer,
    individualNotificationsReducer,
    adminNotificationsReducer,
    dashboardContainerReducer,
    globalReducer
  });

  const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: AnyAction) => {
    if (action.type === logoutAction.type) {
      setClient('');
      return appReducer(undefined, { type: undefined });
    }

    const newState = Object.assign({}, state);

    if (action.type.includes('request') && !requestBlacklist.includes(action.type)) {
      if (newState) {
        newState.globalReducer = {
          loading: true
        };
      }
    } else {
      if (action.type.includes('failure') || action.type.includes('success')) {
        if (newState) {
          newState.globalReducer = {
            loading: false
          };
        }
      }
    }

    return appReducer(newState, action);
  };

  return rootReducer;
}
