import homeIcon from '@images/icons/sidebar/home.svg';
import homeActiveIcon from '@images/icons/sidebar/home-active.svg';

// import storiesIcon from '@images/icons/sidebar/stories.svg';
import compassIcon from '@images/icons/sidebar/compass.svg';
import offerIcon from '@images/icons/sidebar/offer.svg';
import favouritesIcon from '@images/icons/sidebar/favourite.svg';
// import communityIcon from '@images/icons/sidebar/community.svg';
import notificationIcon from '@images/icons/sidebar/notification.svg';
import profileIcon from '@images/icons/sidebar/profile.svg';

// import storiesActiveIcon from '@images/icons/sidebar/stories-active.svg';
import compassActiveIcon from '@images/icons/sidebar/compass-active.svg';
import offerActiveIcon from '@images/icons/sidebar/offer-active.svg';
import favouritesActiveIcon from '@images/icons/sidebar/favourite-active.svg';
// import communityActiveIcon from '@images/icons/sidebar/community-active.svg';
import notificationActiveIcon from '@images/icons/sidebar/notification-active.svg';
import profileActiveIcon from '@images/icons/sidebar/profile-active.svg';

type SidebarConstant = {
  icon: any;
  activeIcon: any;
  key: string;
};

export const individualDashboardConstants: SidebarConstant[] = [
  {
    icon: homeIcon,
    activeIcon: homeActiveIcon,
    key: 'my_dashboard'
  },
  // {
  //   icon: storiesIcon,
  //   activeIcon: storiesActiveIcon,
  //   key: 'stories_received'
  // },
  {
    icon: compassIcon,
    activeIcon: compassActiveIcon,
    key: 'browse_stories'
  },
  {
    icon: offerIcon,
    activeIcon: offerActiveIcon,
    key: 'my_offers'
  },
  {
    icon: favouritesIcon,
    activeIcon: favouritesActiveIcon,
    key: 'my_favourites'
  },
  // {
  //   icon: communityIcon,
  //   activeIcon: communityActiveIcon,
  //   key: 'my_community'
  // },
  {
    icon: notificationIcon,
    activeIcon: notificationActiveIcon,
    key: 'my_notifications'
  },
  {
    icon: profileIcon,
    activeIcon: profileActiveIcon,
    key: 'my_profile'
  }
];

export const adminDashboardConstants: SidebarConstant[] = [
  // {
  //   icon: homeIcon,
  //   activeIcon: homeActiveIcon,
  //   key: 'my_offer'
  // },
  {
    icon: notificationIcon,
    activeIcon: notificationActiveIcon,
    key: 'my_notifications'
  },
  {
    icon: profileIcon,
    activeIcon: profileActiveIcon,
    key: 'admin_dashboard'
  }
];
