import React, { useEffect, useState } from 'react';
import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { If, Pagination } from 'tsw-sdk';
import NotificationItem from './components/NotificationItem';
import { requestGetInboxNotifications, requestGetNotifications, requestReadNotification } from './reducer';
import {
  selectInboxNotifications,
  selectInboxNotificationsTotal,
  selectNotifications,
  selectNotificationsTotal,
  selectReadNotificationLoading
} from './selectors';
import { MyNotificationsProps } from './types';
import { selectUserProfile } from '@app/containers/ProfileContainer/selectors';
import { injectIntl } from 'react-intl';
import NotificationPopup from './components/NotificationPopup';
import { NOTIFICATION_TYPES } from '@app/utils/constants';
import routeConstants from '@app/utils/routeConstants';
import { useHistory } from 'react-router-dom';
import CustomEmpty from '@app/components/CustomEmpty';

const NotificationPageContainer = styled.div`
  width: 100%;
  min-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-top: 3.25rem;
  background-color: ${colors.dashboardBg};
  padding-left: 2rem;
  padding-right: 4rem;
  padding-bottom: 2rem;
`;

const SectionTitleContainer = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:first-of-type) {
    margin-top: 1.5rem;
  }
`;

const SectionTitle = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  line-height: 3.93rem;
  color: ${colors.notificationColors.pageTitle};
`;

const MyNotificationsIndividual = ({
  inboxNotifications,
  inboxNotificationsTotal,
  userProfile,
  notifications,
  notificationsTotal,
  readNotificationLoading,
  dispatchGetInboxNotifications,
  dispatchGetNotifications,
  dispatchReadNotification
}: MyNotificationsProps) => {
  const inboxPerPage = 5;
  const notificationsPerPage = 5;

  const [inboxTotal, setInboxTotal] = useState<number>(0);
  const [inboxPage, setInboxPage] = useState<number>(1);

  const [notificationsTotalCount, setNotificationsTotal] = useState<number>(0);
  const [notificationsPage, setNotificationsPage] = useState<number>(1);

  const [notificationPopupVisible, setNotificationPopupVisible] = useState<boolean>(false);
  const [currentNotification, setCurrentNotification] = useState<any>({});

  const history = useHistory();

  useEffect(() => {
    if (userProfile?.id) {
      dispatchGetInboxNotifications(userProfile?.id, inboxPerPage, inboxPage);
      dispatchGetNotifications(userProfile?.id, notificationsPerPage, notificationsPage);
    }
  }, [userProfile]);

  useEffect(() => {
    if (userProfile?.id) {
      dispatchGetInboxNotifications(userProfile?.id, inboxPerPage, inboxPage);
    }
  }, [inboxPage]);

  useEffect(() => {
    if (userProfile?.id) {
      dispatchGetNotifications(userProfile?.id, notificationsPerPage, notificationsPage);
    }
  }, [notificationsPage]);

  useEffect(() => {
    setInboxTotal(inboxNotificationsTotal);
  }, [inboxNotificationsTotal]);

  useEffect(() => {
    setNotificationsTotal(notificationsTotal);
  }, [notificationsTotal]);

  useEffect(() => {
    if (!readNotificationLoading) {
      if (userProfile?.id) {
        dispatchGetInboxNotifications(userProfile?.id, inboxPerPage, inboxPage);
        dispatchGetNotifications(userProfile?.id, notificationsPerPage, notificationsPage);
      }
    }
  }, [readNotificationLoading]);

  const handleNotificationClick = (notification: any, type: string) => {
    if (userProfile?.id && type === 'inbox') {
      dispatchReadNotification(userProfile?.id, notification?.id);
    }

    if (!notification?.redirect) {
      setCurrentNotification(notification);
      setNotificationPopupVisible(true);
    } else {
      const redirectObj = JSON.parse(notification?.redirect);

      switch (redirectObj?.type) {
        case NOTIFICATION_TYPES.OFFER:
          if (redirectObj?.offerId && redirectObj?.storyId && redirectObj?.sellerId) {
            history?.push(
              `${routeConstants.dashboard.route}?activeTab=offer-history&offerId=${redirectObj?.offerId}&storyId=${redirectObj?.storyId}&authorId=${redirectObj?.sellerId}`
            );
          } else {
            history?.push(`${routeConstants.dashboard.route}?activeTab=my_offers`);
          }

          break;

        case NOTIFICATION_TYPES.COPYRIGHT:
          history?.push(`${routeConstants.dashboard.route}?activeTab=my_offers`);
          break;

        case NOTIFICATION_TYPES.MY_OFFER:
          history?.push(`${routeConstants.dashboard.route}?activeTab=my_offers`);
          break;

        case NOTIFICATION_TYPES.CONVERSATION:
          history?.push(
            `${routeConstants.dashboard.route}?activeTab=offer-history&storyId=${redirectObj?.storyId}&authorId=${redirectObj?.sellerId}&type=conversation`
          );
          break;

        case NOTIFICATION_TYPES.ACCEPTED_OFFER:
          history.push(
            `${routeConstants.dashboard.route}?activeTab=accepted-offer&storyId=${redirectObj?.storyId}&offerId=${redirectObj.OfferId}`
          );
          break;
      }
    }
  };

  return (
    <NotificationPageContainer>
      <SectionTitleContainer>
        <SectionTitle>{translate('inbox')}</SectionTitle>
        <Pagination
          perPage={inboxPerPage}
          currentPage={inboxPage}
          total={inboxTotal}
          setPage={(page: number) => setInboxPage(page)}
          testKey={'inbox-pagination'}
        />
      </SectionTitleContainer>
      <If condition={inboxNotifications.length > 0} otherwise={<CustomEmpty text={'no_notifications'} />}>
        {inboxNotifications.map((inboxNotification: any) => (
          <NotificationItem
            key={inboxNotification?.id}
            isRead={inboxNotification?.isRead}
            clickable={true}
            showTitle={true}
            notification={inboxNotification}
            type="inbox"
            handleClick={handleNotificationClick}
          />
        ))}
      </If>
      <SectionTitleContainer>
        <SectionTitle>{translate('notifications')}</SectionTitle>
        <Pagination
          perPage={notificationsPerPage}
          currentPage={notificationsPage}
          total={notificationsTotalCount}
          setPage={(page: number) => setNotificationsPage(page)}
          testKey={'notifications-pagination'}
        />
      </SectionTitleContainer>
      <If condition={notifications.length > 0} otherwise={<CustomEmpty text={'no_notifications'} />}>
        {notifications.map((notification: any) => (
          <NotificationItem
            key={notification?.id}
            isRead={true}
            clickable={true}
            showTitle={false}
            notification={notification}
            type="notification"
            handleClick={handleNotificationClick}
          />
        ))}
      </If>
      <NotificationPopup
        isVisible={notificationPopupVisible}
        notification={currentNotification}
        handleClose={() => setNotificationPopupVisible(false)}
      />
    </NotificationPageContainer>
  );
};

export const mapStateToProps = createStructuredSelector({
  inboxNotifications: selectInboxNotifications(),
  inboxNotificationsTotal: selectInboxNotificationsTotal(),
  notifications: selectNotifications(),
  notificationsTotal: selectNotificationsTotal(),
  userProfile: selectUserProfile(),
  readNotificationLoading: selectReadNotificationLoading()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGetInboxNotifications: (userId: string, limit: number, page: number) =>
      dispatch(requestGetInboxNotifications({ userId, limit, page })),
    dispatchGetNotifications: (userId: string, limit: number, page: number) =>
      dispatch(requestGetNotifications({ userId, limit, page })),
    dispatchReadNotification: (userId: string, notificationId: number) =>
      dispatch(requestReadNotification({ userId, notificationId }))
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, injectIntl)(MyNotificationsIndividual) as React.FC;

export const MyNotificationsIndividualTest = MyNotificationsIndividual;
