import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Avatar from '@images/icons/user-avatar-icon.svg';
import editIcon from '@images/icons/edit-banner.svg';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { AdminDashboardProps } from './types';
import {
  selectProfileFilePath,
  selectUserProfile,
  selectBannerFilePath,
  selectCreateProfileError
} from '@app/containers/ProfileContainer/selectors';
import {
  requestCreateProfile,
  requestUploadBannerImage,
  requestUploadProfileImage
} from '@app/containers/ProfileContainer/reducer';
import { colors, fonts } from '@app/themes';
import plusIcon from '@images/icons/add-user-plus.svg';
import { translate } from '@app/components/IntlGlobalProvider';
import { Button, Form, message, Modal } from 'antd';
import { CustomButton, CustomInput, If } from 'tsw-sdk';
import {
  requestAddUserToOrganisation,
  requestGeneratePaymentLink,
  requestGetAddedUsers,
  requestRemoveOrganisationUser
} from './reducer';
import { selectAddedUsers, selectAddUserToOrganisationLoading, selectAvailableCapacity } from './selectors';
import AdminUserItem from './components/AdminUserItem';
import { injectIntl } from 'react-intl';
import ProfileCropModal from '@app/containers/ProfileContainer/components/ProfileCropModal';
import {
  BANNER_IMAGE_PROD_URL,
  BANNER_IMAGE_QA_URL,
  BANNER_IMAGE_URL,
  BUYER_ACCOUNT_TYPE,
  COGNITO_USER_GROUPS,
  PRIVACY_POLICY_URL,
  PROFILE_IMAGE_PROD_URL,
  PROFILE_IMAGE_QA_URL,
  PROFILE_IMAGE_URL,
  TERMS_AND_CONDITIONS_URL
} from '@app/utils/constants';
import { selectAccessToken } from '@app/containers/TokenProvider/selectors';
import _ from 'lodash-es';
import routeConstants from '@app/utils/routeConstants';
import { useHistory } from 'react-router-dom';
import { BannerProps } from '../IndividualDashboard/tabs/MyProfile/types';
import downloadIcon from '@images/icons/download-icon-gray.svg';
import BillingDetailsContainer from '@app/components/BillingDetailsContainer';

const AdminDashboardContainer = styled.div`
  width: 100%;
  min-height: 100%;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  background-color: ${colors.dashboardBg};
`;

const DashboardBanner = styled.div<BannerProps>`
  width: 100%;
  height: 12.75rem;
  position: relative;
  background-color: ${colors.btnDisabled};
  background-image: ${(props) => (props.backgroundImage !== '' ? `url(${props.backgroundImage})` : '')};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &:hover img {
    opacity: 1;
  }
`;

const EditBannerIcon = styled.img`
  opacity: 0;
  transition: opacity 0.2s;
  position: absolute;
  bottom: 1.25rem;
  right: 1.25rem;
  cursor: pointer;
`;

const ProfilePictureContainer = styled.div`
  width: 9rem;
  height: 9rem;
  border-radius: 100%;
  position: absolute;
  left: calc(50% - 4.5rem);
  bottom: -4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProfilePicture = styled.img`
  width: 9rem;
  height: 9rem;
  border-radius: 100%;
`;

const EditProfileIcon = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
`;

const UserName = styled.h2`
  margin-top: 5.93rem;
  ${fonts.family.SpoofFamily()};
  ${fonts.size.large()};
  text-align: center;
  margin-bottom: 0px;
`;

const CompanyName = styled.h3`
  margin-top: 0.75rem;
  ${fonts.size.xBig()};
  ${fonts.weights.black()};
  line-height: 2.18rem;
  text-align: center;
  margin-bottom: 0px;
`;

const Designation = styled.p`
  ${fonts.weights.fw400()};
  ${fonts.size.xBig()};
  margin-top: 0.75rem;
  line-height: 2.18rem;
  color: ${colors.adminDashboardColors.designation};
  text-align: center;
`;

const BioDescription = styled.p`
  width: 24rem;
  ${fonts.weights.fw400()};
  ${fonts.size.regular()};
  color: ${colors.adminDashboardColors.bioDescriptionColors};
  text-align: center;
  line-height: 1.25rem;
  margin: 0.75rem auto 0 auto;
`;

const AddUserBtn = styled(Button)`
  &&& {
    width: 16rem;
    height: 3.5rem;
    border-radius: 0.5rem;
    background-color: ${colors.adminDashboardColors.addUserBtnBg};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 1.5rem 1rem 1.62rem;
    margin: 2rem auto 0px auto;
    border: none;
    outline: none;
    cursor: pointer;
    ${fonts.size.big()};
    ${fonts.weights.black()};
    white-space: nowrap;
    & img {
      margin-right: 0.76rem;
    }

    &:hover {
      background-color: ${colors.adminDashboardColors.addUserBtnBg};

      border: none;
    }

    &:focus {
      background-color: ${colors.adminDashboardColors.addUserBtnBg};

      border: none;
    }
  }
`;

const AddUserModal = styled(Modal)`
  width: 35rem;
  height: 19.75rem;

  &&& {
    & .ant-modal-content {
      border-radius: 0.75rem;
    }
  }
`;

const AddUserModalFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0px 0px 0px;
`;

const AddUserModalTitle = styled.h2`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.black()};
  ${fonts.size.large()};
  text-align: center;
  line-height: 2.625rem;
`;

const FormContainer = styled(Form)`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
`;

const CustomFormItem = styled(Form.Item)`
  &.ant-form-item {
    width: 16rem;
    margin-bottom: 0;
    row-gap: 0.5rem;
  }
  .ant-form-item-label {
    padding: 0 !important;
    font-size: 1rem;
    row-gap: 0.5rem;
  }
`;

const CustomSubmitButton = styled(CustomButton)`
  && {
    justify-content: center;
  }
`;

const UserLimitText = styled.p`
  ${fonts.weights.fw400()};
  ${fonts.size.regular()};
  line-height: 1.25rem;
  text-align: center;
  color: ${colors.adminDashboardColors.userLimitText};
  margin-top: 0.5rem;
`;

const ProfileImageInput = styled.input`
  display: none;
`;

const UpdateProfileBtn = styled(CustomButton)`
  && {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 8rem;
    margin-top: 2rem;
  }
`;

const LegalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.62rem;
`;

const LegalContainerTitle = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.size.extraLarge()};
  ${fonts.weights.fw700()};
  line-height: 3.93rem;
  color: ${colors.profilePageColors.titleColor};
`;

const LegalContainerItem = styled.div`
  margin-top: 1.56rem;
  display: flex;
  align-items: center;
`;

const LegalContainerItemText = styled.p`
  ${fonts.size.big()};
  ${fonts.weights.fw700()};
  line-height: 1.5rem;
  color: ${colors.profilePageColors.companyName};
`;

const DownloadIcon = styled.img`
  margin-left: 1.5rem;
  cursor: pointer;
`;

const DownloadLink = styled.a``;

const BillingDetailsContainerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    width: auto !important;

    & > div {
      justify-content: center;
    }
  }
`;

const LaunchOfferText = styled.p`
  ${fonts.weights.fw400()};
  ${fonts.size.small()};
  line-height: 1rem;
  color: ${colors.redClr};
  text-align: center;
  margin-top: 0.5rem;
`;

const AdminDashboard = ({
  userProfile,
  addUserToOrganisationLoading,
  addedUsers,
  accessToken,
  filePath,
  bannerFilePath,
  availableCapacity,
  createProfileError,
  dispatchAddUserToOrganisation,
  dispatchGetAddedUsers,
  dispatchUploadUserProfile,
  dispatchCreateUserProfile,
  dispatchUploadBanner,
  dispatchGeneratePaymentLink,
  dispatchRemoveOrganisationUser
}: AdminDashboardProps) => {
  const [addUserModalVisible, setAddUserModalVisible] = useState<boolean>(false);
  const [addingUserToOrganisationLoading, setAddingUserToOrganisationLoading] = useState<boolean>(false);

  const profileInputRef = useRef<any>(null);
  const bannerInputRef = useRef<any>(null);

  const [cropMode, setCropMode] = useState<'profile' | 'banner'>('profile');

  const [imageCropModalVisible, setImageCropModalVisible] = useState<boolean>(false);

  const [profileUrlLocal, setProfileUrlLocal] = useState<string>('');
  const [bannerUrlLocal, setBannerUrlLocal] = useState<string>('');

  const history = useHistory();

  useEffect(() => {
    if (!addUserToOrganisationLoading && addingUserToOrganisationLoading) {
      setAddUserModalVisible(false);
    }
  }, [addUserToOrganisationLoading]);

  useEffect(() => {
    setAddingUserToOrganisationLoading(addUserToOrganisationLoading);

    if (!addUserToOrganisationLoading) {
      dispatchGetAddedUsers();
    }
  }, [addUserToOrganisationLoading]);

  useEffect(() => {
    dispatchGetAddedUsers();
  }, []);

  useEffect(() => {
    setProfileUrlLocal('');
    setBannerUrlLocal('');
  }, [userProfile]);

  useEffect(() => {
    if (userProfile && filePath) {
      const newProfileData = constructPayload();

      dispatchCreateUserProfile({
        formData: {
          buyerProfileInput: {
            ...newProfileData,
            profilePic: filePath,
            isSave: true
          }
        },
        autoSave: true
      });
    }
  }, [filePath]);

  useEffect(() => {
    if (userProfile && bannerFilePath) {
      const newProfileData = constructPayload();

      dispatchCreateUserProfile({
        formData: {
          buyerProfileInput: {
            ...newProfileData,
            profileBanner: bannerFilePath,
            isSave: true
          }
        },
        autoSave: true
      });
    }
  }, [bannerFilePath]);

  const handleFinish = (values: any) => {
    const firstName = values.first_name;
    const lastName = values.last_name;

    let errorOccured = false;
    let errorMessage = '';

    if (!firstName) {
      errorOccured = true;
      errorMessage = translate('first_name_error');
    }

    if (!lastName) {
      errorOccured = true;
      errorMessage = translate('last_name_error');
    }

    if (errorOccured) {
      message.error(errorMessage);
    } else {
      dispatchAddUserToOrganisation(values.user_email, firstName, lastName);
    }
  };

  const handleImageInputChange = (e: any) => {
    const file = e.target.files[0];

    const fileUrl = URL.createObjectURL(file);
    setCropMode('profile');
    setProfileUrlLocal(fileUrl);
    setImageCropModalVisible(true);
    e.target.value = null;
  };

  const handleBannerInputChange = (e: any) => {
    const file = e.target.files[0];

    const fileUrl = URL.createObjectURL(file);

    setCropMode('banner');
    setBannerUrlLocal(fileUrl);
    setImageCropModalVisible(true);

    e.target.value = null;
  };

  const handleCrop = (file: any) => {
    if (cropMode === 'profile') {
      dispatchUploadUserProfile(file);
    } else {
      dispatchUploadBanner(file);
    }
    setImageCropModalVisible(false);
    setBannerUrlLocal('');
    setProfileUrlLocal('');
  };

  const handleRemoveUser = (buyerId: string) => {
    dispatchRemoveOrganisationUser(buyerId);
  };

  const constructPayload = () => {
    let newProfileData: any = _.cloneDeep(userProfile);

    let userType = accessToken['cognito:groups'];

    if (userType) {
      if (userType.length) {
        if (userType.includes(COGNITO_USER_GROUPS.ORGANIZATION)) {
          userType = BUYER_ACCOUNT_TYPE.ORGANIZATION_USER;
        } else if (userType.includes(COGNITO_USER_GROUPS.INDIVIDUAL)) {
          userType = BUYER_ACCOUNT_TYPE.INDIVIDUAL;
        } else if (userType.includes(COGNITO_USER_GROUPS.ADMIN)) {
          userType = BUYER_ACCOUNT_TYPE.ORGANIZATION_ADMIN;
        }
      }
    }

    delete newProfileData?.typename;
    delete newProfileData?.email;
    delete newProfileData?.id;
    delete newProfileData?.company?.typename;
    delete newProfileData?.billingAddress?.typename;

    if (newProfileData?.showreels) {
      if (newProfileData?.showreels.length) {
        for (let i = 0; i < newProfileData?.showreels?.length; i++) {
          const newShowReel: any = {};
          Object.assign(newShowReel, newProfileData?.showreels[i]);
          delete newShowReel?.typename;
          delete newShowReel?.id;
          newProfileData.showreels[i] = newShowReel;
        }
      }
    }

    if (newProfileData?.favGenres) {
      if (newProfileData?.favGenres.length) {
        for (let i = 0; i < newProfileData?.favGenres?.length; i++) {
          const newGenre: any = {};
          Object.assign(newGenre, newProfileData?.favGenres[i]);
          delete newGenre?.typename;
          delete newGenre?.id;
          newProfileData.favGenres[i] = newGenre;
        }
      }
    }

    if (newProfileData?.favMovies) {
      if (newProfileData?.favMovies.length) {
        for (let i = 0; i < newProfileData?.favMovies?.length; i++) {
          const newMovie: any = {};
          Object.assign(newMovie, newProfileData?.favMovies[i]);
          delete newMovie?.typename;
          delete newMovie?.id;
          newProfileData.favMovies[i] = newMovie;
        }
      }
    }

    if (newProfileData?.producedFilms) {
      if (newProfileData?.producedFilms.length) {
        for (let i = 0; i < newProfileData?.producedFilms?.length; i++) {
          const newProducedFilm: any = {};
          Object.assign(newProducedFilm, newProfileData?.producedFilms[i]);
          delete newProducedFilm?.typename;
          delete newProducedFilm?.id;
          newProfileData.producedFilms[i] = newProducedFilm;
        }
      }
    }

    newProfileData = { ...newProfileData, profileType: userType };

    return newProfileData;
  };

  const saveBillingInfo = (billingInfo: any) => {
    const newProfileData = constructPayload();

    dispatchCreateUserProfile({
      formData: {
        buyerProfileInput: {
          ...newProfileData,
          billingAddress: billingInfo,
          isSave: true
        }
      },
      autoSave: false
    });
  };

  return (
    <AdminDashboardContainer data-testid="admin-dashboard-container">
      <ProfileImageInput
        type={'file'}
        accept="image/png, image/jpeg"
        onChange={handleImageInputChange}
        ref={profileInputRef}
        data-testid="profile-upload-input"
      />

      <ProfileImageInput
        type={'file'}
        accept="image/png, image/jpeg"
        onChange={handleBannerInputChange}
        ref={bannerInputRef}
        data-testid="banner-upload-input"
      />

      {imageCropModalVisible && (
        <ProfileCropModal
          image={cropMode === 'profile' ? profileUrlLocal : bannerUrlLocal}
          visible={imageCropModalVisible}
          onCrop={handleCrop}
          handleCancel={() => setImageCropModalVisible(false)}
          cropMode={cropMode}
        />
      )}

      <If condition={process.env.ENVIRONMENT_NAME === 'qa'}>
        <DashboardBanner
          backgroundImage={
            userProfile?.profileBanner !== '' ? `${BANNER_IMAGE_QA_URL}/${userProfile?.profileBanner}` : ''
          }
        >
          <ProfilePictureContainer>
            <ProfilePicture
              src={userProfile?.profilePic !== '' ? `${PROFILE_IMAGE_QA_URL}/${userProfile?.profilePic}` : Avatar}
            />
            <EditProfileIcon src={editIcon} onClick={() => profileInputRef?.current?.click()} data-testid="edit-btn" />
          </ProfilePictureContainer>
          <EditBannerIcon
            src={editIcon}
            onClick={() => bannerInputRef?.current?.click()}
            data-testid="edit-banner-btn"
          />
        </DashboardBanner>
      </If>

      <If condition={process.env.ENVIRONMENT_NAME === 'production'}>
        <DashboardBanner
          backgroundImage={
            userProfile?.profileBanner !== '' ? `${BANNER_IMAGE_PROD_URL}/${userProfile?.profileBanner}` : ''
          }
        >
          <ProfilePictureContainer>
            <ProfilePicture
              src={userProfile?.profilePic !== '' ? `${PROFILE_IMAGE_PROD_URL}/${userProfile?.profilePic}` : Avatar}
            />
            <EditProfileIcon src={editIcon} onClick={() => profileInputRef?.current?.click()} data-testid="edit-btn" />
          </ProfilePictureContainer>
          <EditBannerIcon
            src={editIcon}
            onClick={() => bannerInputRef?.current?.click()}
            data-testid="edit-banner-btn"
          />
        </DashboardBanner>
      </If>
      <If condition={process.env.ENVIRONMENT_NAME !== 'production' && process.env.ENVIRONMENT_NAME !== 'qa'}>
        <DashboardBanner
          backgroundImage={userProfile?.profileBanner !== '' ? `${BANNER_IMAGE_URL}/${userProfile?.profileBanner}` : ''}
        >
          <ProfilePictureContainer>
            <ProfilePicture
              src={userProfile?.profilePic !== '' ? `${PROFILE_IMAGE_URL}/${userProfile?.profilePic}` : Avatar}
            />
            <EditProfileIcon src={editIcon} onClick={() => profileInputRef?.current?.click()} data-testid="edit-btn" />
          </ProfilePictureContainer>
          <EditBannerIcon
            src={editIcon}
            onClick={() => bannerInputRef?.current?.click()}
            data-testid="edit-banner-btn"
          />
        </DashboardBanner>
      </If>

      <UserName>{`${userProfile?.firstName} ${userProfile?.lastName}`}</UserName>
      <CompanyName>{userProfile?.company?.name}</CompanyName>
      <Designation>{userProfile?.company?.designation}</Designation>
      <UpdateProfileBtn onClick={() => history.push(routeConstants.editProfilePage.route)}>
        {translate('edit_profile')}
      </UpdateProfileBtn>
      <BioDescription>{userProfile?.bioDescription}</BioDescription>
      <AddUserBtn
        onClick={() => {
          if (availableCapacity > 0) {
            setAddUserModalVisible(!addUserModalVisible);
          } else {
            dispatchGeneratePaymentLink('BUYER_ADD_ORGANIZATION_USER', 'For adding org user');
          }
        }}
        data-testid="add-user-btn"
        disabled={availableCapacity === 0}
      >
        <img src={plusIcon} />
        {availableCapacity === 0 ? translate('pay_to_add_user') : translate('add_first_user')}
      </AddUserBtn>
      <UserLimitText>
        {availableCapacity === 0
          ? translate('user_limit_reached')
          : translate('user_limit', { amount: availableCapacity })}
      </UserLimitText>
      <If condition={availableCapacity === 0}>
        <LaunchOfferText>{translate('launch_offer')}</LaunchOfferText>
      </If>

      {addedUsers.map((addedUser: any, index: any) => (
        <AdminUserItem
          key={addedUser.id}
          buyerId={addedUser.id}
          index={index + 1}
          userEmail={addedUser.email}
          userName={`${addedUser.firstName} ${addedUser.lastName}`}
          removeUser={handleRemoveUser}
        />
      ))}
      <AddUserModal
        centered={true}
        visible={addUserModalVisible}
        footer={null}
        onCancel={() => setAddUserModalVisible(false)}
        destroyOnClose={true}
        width={561}
      >
        <AddUserModalFormContainer id="add-user-modal" data-testid="add-user-modal-test">
          <AddUserModalTitle>{translate('add_new_user')}</AddUserModalTitle>
          <FormContainer layout="vertical" onFinish={handleFinish}>
            <CustomFormItem
              label={translate('first_name_label')}
              name="first_name"
              rules={[{ required: true, message: translate('first_name_error') }]}
            >
              <CustomInput placeholder={translate('first_name_placeholder')} data-testid="first-name-input" />
            </CustomFormItem>
            <CustomFormItem
              label={translate('last_name_label')}
              name="last_name"
              rules={[{ required: true, message: translate('last_name_error') }]}
            >
              <CustomInput placeholder={translate('last_name_placeholder')} data-testid="last-name-input" />
            </CustomFormItem>
            <CustomFormItem
              label={translate('user_email_label')}
              name="user_email"
              rules={[{ required: true, message: translate('user_email_label_error') }]}
            >
              <CustomInput placeholder={translate('user_email_placeholder')} data-testid="email-input" />
            </CustomFormItem>
            <CustomSubmitButton
              htmlType="submit"
              loading={addingUserToOrganisationLoading}
              data-testid="submit-form-btn"
            >
              {translate('add_user')}
            </CustomSubmitButton>
          </FormContainer>
        </AddUserModalFormContainer>
      </AddUserModal>
      <LegalContainer>
        <LegalContainerTitle>{translate('legal')}</LegalContainerTitle>
        <LegalContainerItem>
          <LegalContainerItemText>{translate('legal_terms_and_conditions')} </LegalContainerItemText>
          <DownloadLink target={'_blank'} href={TERMS_AND_CONDITIONS_URL} rel="noreferrer">
            <DownloadIcon src={downloadIcon} />
          </DownloadLink>
        </LegalContainerItem>
        <LegalContainerItem>
          <LegalContainerItemText>{translate('legal_privacy_policy')}</LegalContainerItemText>
          <DownloadLink target={'_blank'} href={PRIVACY_POLICY_URL} rel="noreferrer">
            <DownloadIcon src={downloadIcon} />
          </DownloadLink>
        </LegalContainerItem>
      </LegalContainer>
      <BillingDetailsContainerWrapper>
        <BillingDetailsContainer
          billingInfo={userProfile?.billingAddress}
          createProfileError={createProfileError}
          handleSave={saveBillingInfo}
        />
      </BillingDetailsContainerWrapper>
    </AdminDashboardContainer>
  );
};

export const mapStateToProps = createStructuredSelector({
  accessToken: selectAccessToken(),
  userProfile: selectUserProfile(),
  addUserToOrganisationLoading: selectAddUserToOrganisationLoading(),
  addedUsers: selectAddedUsers(),
  filePath: selectProfileFilePath(),
  bannerFilePath: selectBannerFilePath(),
  availableCapacity: selectAvailableCapacity(),
  createProfileError: selectCreateProfileError()
});

export const mapDispatchToProps = (dispatch: (arg0: AnyAction) => any) => {
  return {
    dispatchAddUserToOrganisation: (email: string, firstName: string, lastName: string) =>
      dispatch(requestAddUserToOrganisation({ email, firstName, lastName })),
    dispatchGetAddedUsers: () => dispatch(requestGetAddedUsers({})),
    dispatchUploadUserProfile: (fileData: any) => dispatch(requestUploadProfileImage(fileData)),
    dispatchCreateUserProfile: (profileData: any) => dispatch(requestCreateProfile(profileData)),
    dispatchUploadBanner: (fileData: any) => dispatch(requestUploadBannerImage(fileData)),
    dispatchGeneratePaymentLink: (reason: string, notes: string) =>
      dispatch(requestGeneratePaymentLink({ reason, notes })),
    dispatchRemoveOrganisationUser: (buyerId: string) => dispatch(requestRemoveOrganisationUser({ buyerId: buyerId }))
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, injectIntl)(AdminDashboard) as React.FC;

export const AdminDashboardTest = AdminDashboard;
