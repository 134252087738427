import { gql } from 'apollo-boost';

export const VERIFY_RECAPTCHA = gql`
  mutation VerifyRecaptcha($input: RecaptchaToken!) {
    verifyRecaptcha(input: $input) {
      message
    }
  }
`;
export const PAYMENT = gql`
  mutation GeneratePaymentLink($amount: Int!, $reason: PaymentMetaData!, $notes: String!) {
    generatePaymentLink(input: { amount: $amount, reason: $reason, notes: $notes }) {
      url
      status
      referenceId
    }
  }
`;
export const BuyerAccountStatus = gql`
  query BuyerAccountStatus {
    buyerAccountStatus {
      id
      subscription {
        isActive
        planSelected
        daysLeft
        dateOfRenewal
      }
      isRegistrationComplete
      buyerType
      unreadNotifications
    }
  }
`;

export const GENERATE_STORY_PDF = gql`
  query GenerateStoryPdf($input: SubmissionQueryInput!) {
    generateStoryPdf(input: $input)
  }
`;
