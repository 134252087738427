import React, { useCallback, useEffect, useRef, useState } from 'react';
import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import { Button, Checkbox, Form, Input, message, notification, Select, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import styled from 'styled-components';
import { ArrowRightOutlined, CheckOutlined } from '@ant-design/icons';
import userAvatarIcon from '@images/icons/user-avatar-icon.svg';
import editIcon from '@images/icons/edit.svg';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  requestCreateProfile,
  requestGetProfile,
  requestPaymentData,
  resetState,
  requestPhoneVerification,
  requestVerifyOtp,
  requestUploadProfileImage,
  requestGetProfileUrl,
  setSubmitLoading
} from '../reducer';
import {
  selectCreateUserProfileLoading,
  selectPaymentError,
  selectUserProfile,
  selectVerifyOtpSuccess,
  selectVerifuOtpError,
  selectPaymentInitiated,
  selectSubmitProfileLoading
} from '../selectors';
import { createStructuredSelector } from 'reselect';
import { AdminProfileProps } from './types';
import {
  BUYER_ACCOUNT_TYPE,
  COGNITO_USER_GROUPS,
  COUPON_ADMIN,
  PROFILE_IMAGE_PROD_URL,
  PROFILE_IMAGE_QA_URL,
  PROFILE_IMAGE_URL
} from '@app/utils/constants';
import OTPModal from '@app/components/OTPModal';
import { CustomButton, CustomInput, If } from 'tsw-sdk';
import { debounce } from 'lodash-es';
import ArrowLeft from '@images/icons/arrow-left.svg';
import { selectAccessToken } from '@app/containers/TokenProvider/selectors';
import { useHistory } from 'react-router-dom';
import ProfileCropModal from '../components/ProfileCropModal';
import { selectCouponCode } from '@app/containers/PackageDetailsPage/selectors';
import { preventInvalidCharsNumberInput } from '@app/utils/uiUtils';

const { Option } = Select;

const CreateProfilePageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  position: relative;
`;

const CreateProfileFormContianer = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
`;

const CreateProfileTitle = styled.p`
  ${fonts.family.SpoofFamily};
  ${fonts.style.pageTitle};
  line-height: 3.93rem;
  margin-bottom: 2.56rem;
`;

const HorizontalFormDivider = styled.div`
  width: 100%;
  height: 0.06rem;
  background-color: ${colors.lightBorder};
  margin: 1.5rem 0px;
`;

const HorizontalFormContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledInput = styled(Input)`
  width: 16.18rem;
  height: 2rem;
  background-color: ${colors.secondaryText};
  border-radius: 0.31rem;
`;

const StyledSelect = styled(Select)`
  width: 3.56rem;
  background-color: ${colors.secondaryText};
  margin-right: 0.5rem;

  &&& {
    width: 3.56rem;

    & .ant-select-selector {
      height: 2rem;
      padding: 0px;
      padding-left: 0.31rem;
      background-color: ${colors.secondaryText};
      border-radius: 0.37rem;
    }
  }
`;

const OtpVerificationButton = styled(Button)`
  &&& {
    background-color: ${colors.primary};
    color: ${colors.secondaryText};
    border-radius: 0.5rem;
    ${fonts.weights.bold}
  }
`;

const StyledTextArea = styled(TextArea)`
  width: 16.25rem;
  background-color: ${colors.secondaryText};
  border-radius: 0.31rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2.75rem;
  margin-bottom: 2rem;
`;

const SubmitBtn = styled(Button)`
  width: 25.56rem;
  border-radius: 0.5rem;
  margin-left: 1.5rem;
  ${fonts.weights.bold()}
`;

const SaveBtn = styled(Button)`
  width: 25.56rem;
  border-radius: 0.5rem;
  border: 0.06rem solid ${colors.primary};
  ${fonts.weights.bold()}
`;

const ProfileAvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 9.5rem;
`;

const EditAvatarBtn = styled.div`
  width: 1.66rem;
  height: 1.66rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  cursor: pointer;
`;

const CustomVerifiedButton = styled(CustomButton)`
  && {
    background: ${colors.verifiedBtnBg} !important;
  }
`;

const FormItemInline = styled.div`
  display: flex;
  align-items: center;
`;

const ProfileImageInput = styled.input`
  display: none;
`;

const ProfilePic = styled.img`
  width: 12.75rem;
  height: 12.75rem;
  border-radius: 100%;
`;

const BackButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 2rem;
  left: 2.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  ${fonts.weights.bold()}
  ${fonts.size.small()}
  line-height: 1rem;
  color: ${colors.termsAndConditionListItem};
`;

const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
  padding: 0px;
`;

const CheckBoxText = styled.p`
  line-height: 15px;
  ${fonts.style.checkBoxText()}
`;

const CustomCheckBox = styled(Checkbox)`
  && {
    .ant-checkbox-inner {
      width: 1.5rem;
      height: 1.5rem;
      border: 2px solid ${colors.checkboxborder};
      border-radius: 4px;
    }
  }
`;

const LabelInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`;

const SubText = styled.p`
  margin-top: 4px;
  opacity: 0.5;
  ${fonts.style.smallText()}
`;

const StyledLink = styled.a`
  color: ${colors.styledText};
  opacity: 1;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: ${colors.styledText};
  }
  ${fonts.style.smallText()}
`;

const BillingDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelText = styled.p`
  ${fonts.weights.fw600()};
  ${fonts.size.small()};
  line-height: 1.09rem;
  color: ${colors.tagBorder};
  margin-bottom: 0.93rem;
`;

const BilingAddressContainer = styled.div`
  display: grid;
  grid-template-columns: 16rem 16rem 7.56rem 7.56rem;
  column-gap: 1rem;
  row-gap: 1rem;
`;

const CustomBillingFormItem = styled(Form.Item)`
  &.ant-form-item {
    margin-bottom: 0;
  }
`;

const CustomInputCapital = styled(CustomInput)`
  text-transform: uppercase;

  &&& {
    &:placeholder-shown {
      text-transform: unset;
    }
  }
`;

const PageSpinnerContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000000;
`;

const CustomNumberInput = styled(CustomInput)`
  &&& {
    width: 7rem;
    background-color: ${colors.secondaryText};
    border-radius: 0.31rem;
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

const AdminProfileContainer = ({
  userProfile,
  createProfileLoading,
  paymentError,
  otpSuccess,
  otpError,
  accessToken,
  // couponCode,
  paymentInitiated,
  dispatchGetUserProfile,
  dispatchUpdateUserProfile,
  dispatchUploadUserProfile,
  dispatchPhoneVerification,
  dispatchResetState,
  dispatchVerifyOtp,
  submitLoading,
  dispatchSetSubmitLoading
}: AdminProfileProps) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const [otp, setOtp] = useState('');
  const [contactNum, setContactNum] = useState('');
  const inputRef = useRef<any>(null);
  const history = useHistory();

  const [isPhoneVerified, setIsPhoneVerified] = useState<boolean>(false);

  const [profileUrlLocal, setProfileUrlLocal] = useState<string>('');
  const [croppedUrlLocal, setCroppedUrlLocal] = useState<string>('');

  const [imageCropModalVisible, setImageCropModalVisible] = useState<boolean>(false);

  const [componentLoaded, setComponentLoaded] = useState<boolean>(false);

  const [isAdult, setIsAdult] = useState<boolean>(false);

  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  const cinRegex = /^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/;

  useEffect(() => {
    if (!componentLoaded) {
      if (Object.keys(userProfile).length > 2) {
        setComponentLoaded(true);
        form.setFieldsValue({
          firstName: userProfile?.firstName,
          lastName: userProfile?.lastName,
          companyName: userProfile?.company ? userProfile?.company.name : '',
          designation: userProfile?.company ? userProfile?.company.designation : '',
          contactNo: userProfile?.contactNumber,
          shortBio: userProfile?.bioDescription ? userProfile?.bioDescription : '',
          panNum: userProfile?.billingAddress?.panNumber,
          companyIdNum: userProfile?.billingAddress?.companyIdentificationNo,
          gstNum: userProfile?.billingAddress?.gstNumber,
          floorNum: userProfile?.billingAddress?.streetAddress,
          locality: userProfile?.billingAddress?.localeAddress,
          state: userProfile?.billingAddress?.state,
          city: userProfile?.billingAddress?.city,
          postCode: userProfile?.billingAddress?.pinCode
        });

        if (userProfile?.profilePic) {
          if (process.env.ENVIRONMENT_NAME === 'qa') {
            setCroppedUrlLocal(`${PROFILE_IMAGE_QA_URL}/${userProfile?.profilePic}`);
          } else if (process.env.ENVIRONMENT_NAME === 'production') {
            setCroppedUrlLocal(`${PROFILE_IMAGE_PROD_URL}/${userProfile?.profilePic}`);
          } else {
            setCroppedUrlLocal(`${PROFILE_IMAGE_URL}/${userProfile?.profilePic}`);
          }
        }

        if (userProfile?.isLegalAge) {
          setIsAdult(true);
        }

        if (userProfile?.contactNumber) {
          form.setFieldsValue({ contactNo: Number(userProfile?.contactNumber.substring(3)) });
          setIsPhoneVerified(true);
        }
      }
    }
  }, [userProfile]);

  useEffect(() => {
    if (otpSuccess) {
      setIsModalOpen(false);
      openSuccessOtpNotification();
      setIsPhoneVerified(true);
    }
  }, [otpSuccess]);

  const openNotification = (err: string) => {
    notification.open({
      message: 'Error',
      description: err
    });
  };

  const openSuccessOtpNotification = () => {
    notification.open({
      message: translate('success'),
      description: translate('phone_verified')
    });
  };

  useEffect(() => {
    if (otpError) {
      openNotification(otpError);
    }
  }, [otpError]);

  useEffect(() => {
    dispatchGetUserProfile();

    return () => {
      dispatchResetState();
    };
  }, []);

  useEffect(() => {
    if (paymentError) {
      notification.open({
        message: translate('error'),
        description: paymentError
      });
    }
  }, [paymentError]);

  const validateFormData = (formData: any) => {
    let formDataValid = true;
    let errorMessage = '';

    if (!formData?.postCode?.trim()) {
      formDataValid = false;
      errorMessage = translate('post_code_error');
    }

    if (!formData?.city?.trim()) {
      formDataValid = false;
      errorMessage = translate('city_error');
    }

    if (!formData?.floorNum?.trim()) {
      formDataValid = false;
      errorMessage = translate('floor_error');
    }

    if (!gstRegex.test(formData?.gstNum?.toUpperCase())) {
      formDataValid = false;
      errorMessage = translate('invalid_gst_error_message');
    }

    if (!formData?.gstNum?.trim()) {
      formDataValid = false;
      errorMessage = translate('gst_num_error');
    }

    if (!formData?.state?.trim()) {
      formDataValid = false;
      errorMessage = translate('state_error');
    }

    if (!formData?.locality?.trim()) {
      formDataValid = false;
      errorMessage = translate('locality_error');
    }

    if (!formData?.companyIdNum?.trim()) {
      formDataValid = false;
      errorMessage = translate('comp_id_num_error');
    }

    if (!cinRegex.test(formData?.companyIdNum)) {
      formDataValid = false;
      errorMessage = translate('invalid_cin_error_message');
    }

    if (!formData?.panNum?.trim()) {
      formDataValid = false;
      errorMessage = translate('pan_num_error');
    }

    if (!panRegex.test(formData?.panNum?.toUpperCase())) {
      formDataValid = false;
      errorMessage = translate('invalid_pan_error_message');
    }

    if (!isAdult) {
      formDataValid = false;
      errorMessage = translate('age_error');
    }

    if (!formData?.contactNo?.toString()?.trim()) {
      formDataValid = false;
      errorMessage = translate('contact_no_error');
    } else {
      if (!isPhoneVerified) {
        formDataValid = false;
        errorMessage = translate('verify_phone_num');
      }
    }

    if (!formData?.designation?.trim()) {
      formDataValid = false;
      errorMessage = translate('designation_error');
    }

    if (!formData?.companyName?.trim()) {
      formDataValid = false;
      errorMessage = translate('production_house_error');
    }

    if (!formData?.lastName?.trim()) {
      formDataValid = false;
      errorMessage = translate('last_name_error');
    }

    if (!formData?.firstName?.trim()) {
      formDataValid = false;
      errorMessage = translate('first_name_error');
    }

    return { formDataValid, errorMessage };
  };

  const validateFormDataSave = (formData: any) => {
    let formDataValid = true;
    let errorMessage = '';

    if (!formData?.lastName?.trim() && !formData?.firstName?.trim()) {
      if (!formData?.lastName?.trim()) {
        formDataValid = false;
        errorMessage = translate('last_name_error');
      }

      if (!formData?.firstName?.trim()) {
        formDataValid = false;
        errorMessage = translate('first_name_error');
      }
    }

    return { formDataValid, errorMessage };
  };

  const constructApiPayload = (data: any) => {
    const cognitoGroups = accessToken['cognito:groups'];

    const formDataPayload = {
      buyerProfileInput: {
        firstName: data.firstName,
        lastName: data.lastName,
        contactNumber: `+91${data.contactNo}`,
        nationality: 'Indian',
        profileType: 'ADMIN',
        bioDescription: data.shortBio ? data.shortBio : '',
        company: {
          id: userProfile?.company ? userProfile?.company?.id : undefined,
          name: data.companyName,
          designation: data.designation ? data.designation : ''
        },
        isLegalAge: isAdult ? true : undefined,
        profilePic: userProfile?.profilePic,
        isSave: false,
        billingAddress: {
          panNumber:
            data.panNum !== '' && data.panNum !== null && panRegex.test(data.panNum?.toUpperCase())
              ? data.panNum?.toUpperCase()
              : undefined,
          gstNumber:
            data.gstNum !== '' && data.gstNum !== null && gstRegex.test(data.gstNum?.toUpperCase())
              ? data.gstNum?.toUpperCase()
              : undefined,
          streetAddress: data.floorNum !== '' && data.floorNum !== null ? data.floorNum : undefined,
          localeAddress: data.locality !== '' && data.locality !== null ? data.locality : undefined,
          city: data.city !== '' && data.city !== null ? data.city : undefined,
          state: data.state !== '' && data.state !== null ? data.state : undefined,
          pinCode: data.postCode !== '' && data.postCode !== null ? data.postCode : undefined,
          companyIdentificationNo:
            data.companyIdNum !== '' && data.companyIdNum !== null && cinRegex.test(data?.companyIdNum)
              ? data.companyIdNum
              : undefined
        }
      }
    };

    if (cognitoGroups) {
      if (cognitoGroups.length) {
        if (cognitoGroups.includes(COGNITO_USER_GROUPS.ORGANIZATION)) {
          formDataPayload.buyerProfileInput.profileType = BUYER_ACCOUNT_TYPE.ORGANIZATION_USER;
        }
      }
    }

    return formDataPayload;
  };

  const handleFinish = () => {
    const values = form.getFieldsValue();
    const formDataValid = validateFormData(values);

    if (formDataValid.formDataValid) {
      const formData: any = constructApiPayload(values);

      formData.buyerProfileInput.isSave = false;

      dispatchUpdateUserProfile({ formData, autoSave: false, couponCode: COUPON_ADMIN });
      dispatchSetSubmitLoading();
    } else {
      notification.open({
        message: translate('error'),
        description: formDataValid.errorMessage
      });
    }
  };

  const handleOtpInput = (currOtp: string) => {
    setOtp(currOtp);
  };

  const toggleResend = () => {
    setShowResend(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onOtpVerify = async () => {
    try {
      if (otp.length === 6) {
        await dispatchVerifyOtp(otp);
      } else {
        openNotification('Enter a valid otp');
      }
    } catch (e) {
      openNotification('something went wrong');
    }
  };

  const resendOtpConfirm = async () => {
    try {
      await dispatchPhoneVerification(contactNum);
      notification.open({
        message: translate('success_title'),
        description: translate('otp_sent_message')
      });
      setShowResend(false);
    } catch (e) {
      openNotification('something went wrong');
    }
  };

  const handleOTPModal = () => {
    const numberRegex = /^(0|91)?[6-9][0-9]{9}$/;
    const num = form.getFieldValue('contactNo');

    if (!numberRegex.test(`91${num}`)) {
      openNotification(translate('invalid_number'));
      return;
    }

    setContactNum('+91' + num);
    dispatchPhoneVerification('+91' + num);
    setIsModalOpen(true);
  };

  const handleImageInputChange = (e: any) => {
    const file = e.target.files[0];

    const fileUrl = URL.createObjectURL(file);

    setProfileUrlLocal(fileUrl);
    setImageCropModalVisible(true);
    e.target.value = null;
  };

  const handleCrop = (file: any) => {
    const fileUrl = URL.createObjectURL(file);
    setCroppedUrlLocal(fileUrl);
    dispatchUploadUserProfile(file);
    setImageCropModalVisible(false);
  };

  const handleSave = () => {
    const values = form.getFieldsValue();

    const formDataValid = validateFormDataSave(values);

    if (formDataValid) {
      const formData = constructApiPayload(values);

      formData.buyerProfileInput.isSave = true;

      dispatchUpdateUserProfile({
        formData,
        autoSave: true
      });

      message.success(translate('form_saved'));
    } else {
      notification.open({
        message: translate('error'),
        description: translate('enter_all_fields_error')
      });
    }
  };

  const debounceSave = useCallback(
    debounce(
      function () {
        const values = form.getFieldsValue();

        const formData = constructApiPayload(values);

        formData.buyerProfileInput.isSave = true;

        dispatchUpdateUserProfile({
          formData,
          autoSave: true
        });
      },
      3000,
      {
        maxWait: 1000
      }
    ),
    [isAdult, profileUrlLocal, userProfile]
  );

  useEffect(() => {
    if (componentLoaded) {
      if (userProfile?.profilePic) {
        debounceSave();
      }
    }
  }, [userProfile]);

  const handleChange = () => {
    debounceSave();
  };

  return (
    <>
      <BackButton onClick={() => history.goBack()}>
        <img src={ArrowLeft} />
        {translate('back')}
      </BackButton>

      <CreateProfilePageContainer>
        <ProfileImageInput
          type={'file'}
          accept="image/png, image/jpeg"
          onChange={handleImageInputChange}
          ref={inputRef}
          data-testid="profile-upload-input"
        />
        {imageCropModalVisible && (
          <ProfileCropModal
            image={profileUrlLocal}
            visible={imageCropModalVisible}
            onCrop={handleCrop}
            handleCancel={() => setImageCropModalVisible(false)}
            cropMode="profile"
          />
        )}
        <CreateProfileFormContianer data-testid="admin-form">
          <If condition={submitLoading}>
            <PageSpinnerContainer>
              <Spin />
            </PageSpinnerContainer>
          </If>
          <CreateProfileTitle>{translate('create_profile_title')}</CreateProfileTitle>
          <Form layout="vertical" form={form} onValuesChange={handleChange}>
            <HorizontalFormContainer>
              <Form.Item label={translate('first_name_label')}>
                <Form.Item name="firstName" noStyle={true}>
                  <StyledInput placeholder={translate('first_name_placeholder')} data-testid="first-name-input" />
                </Form.Item>
              </Form.Item>
              <Form.Item label={translate('last_name_label')} style={{ marginLeft: '1.5rem' }}>
                <Form.Item name="lastName" noStyle={true}>
                  <StyledInput placeholder={translate('last_name_placeholder')} data-testid="last-name-input" />
                </Form.Item>
              </Form.Item>
            </HorizontalFormContainer>
            <HorizontalFormDivider style={{ marginTop: '0px' }} />
            <Form.Item label={translate('company_name_label')}>
              <Form.Item name="companyName" noStyle={true}>
                <StyledInput placeholder={translate('company_name_placeholder')} data-testid="company-name-input" />
              </Form.Item>
            </Form.Item>
            <Form.Item label={translate('designation_label')}>
              <Form.Item name="designation" noStyle={true}>
                <StyledInput placeholder={translate('designation_placeholder')} data-testid="designation-input" />
              </Form.Item>
            </Form.Item>
            <HorizontalFormDivider />

            <BillingDetailsContainer>
              <LabelText>{translate('billing_address')}</LabelText>
              <BilingAddressContainer>
                <CustomBillingFormItem name="panNum">
                  <CustomInputCapital
                    placeholder={translate('pan_no_placeholder')}
                    data-testid="pan-num-input"
                  ></CustomInputCapital>
                </CustomBillingFormItem>
                <CustomBillingFormItem name="companyIdNum">
                  <CustomInput placeholder={translate('comp_id_placeholder')} data-testid="comp-id-input"></CustomInput>
                </CustomBillingFormItem>
                <CustomBillingFormItem name="locality">
                  <CustomInput placeholder={translate('locality')} data-testid="locality-input"></CustomInput>
                </CustomBillingFormItem>
                <CustomBillingFormItem name="state">
                  <CustomInput placeholder={translate('state')} data-testid="state-input"></CustomInput>
                </CustomBillingFormItem>
                <CustomBillingFormItem name="gstNum">
                  <CustomInputCapital
                    placeholder={translate('gst_num_placeholder')}
                    data-testid="gst-num"
                  ></CustomInputCapital>
                </CustomBillingFormItem>
                <CustomBillingFormItem name="floorNum">
                  <CustomInput placeholder={translate('floor_num_plaeholder')} data-testid="floor-num"></CustomInput>
                </CustomBillingFormItem>
                <CustomBillingFormItem name="city">
                  <CustomInput placeholder={translate('city')} data-testid="city-input"></CustomInput>
                </CustomBillingFormItem>
                <CustomBillingFormItem name="postCode">
                  <CustomNumberInput
                    placeholder={translate('post_code')}
                    data-testid="post-code-input"
                    type="number"
                    onKeyDown={preventInvalidCharsNumberInput}
                  />
                </CustomBillingFormItem>
              </BilingAddressContainer>
            </BillingDetailsContainer>

            <HorizontalFormDivider />
            <Form.Item label={translate('phone_number_label')}>
              <FormItemInline>
                <StyledSelect placeholder={translate('phone_number_extension_placeholder')}>
                  <Option value={'+91'}>+91</Option>
                </StyledSelect>
                <Form.Item name="contactNo" noStyle={true} style={{ display: 'flex' }}>
                  <CustomNumberInput
                    placeholder={translate('phone_number_placeholder')}
                    data-testid="phone-input"
                    type="number"
                    onKeyDown={preventInvalidCharsNumberInput}
                    disabled={isPhoneVerified}
                  />
                </Form.Item>
              </FormItemInline>
            </Form.Item>

            <If
              condition={!isPhoneVerified}
              otherwise={
                <CustomVerifiedButton data-testid="verified-otp-btn">
                  {translate('verified')} <CheckOutlined />
                </CustomVerifiedButton>
              }
            >
              <OtpVerificationButton onClick={handleOTPModal} data-testid="verify-otp-btn">
                {translate('verify_otp')}
              </OtpVerificationButton>
            </If>
            <HorizontalFormDivider />

            <LabelInput>
              <Form.Item name="age" label={translate('age')} style={{ width: '100%' }}>
                <CheckBoxContainer data-testid="age-checkbox">
                  <CustomCheckBox
                    onChange={(e) => {
                      setIsAdult(e.target.checked);
                    }}
                    checked={isAdult}
                  />
                  <CheckBoxText>{translate('confirm_adult')}</CheckBoxText>
                </CheckBoxContainer>
              </Form.Item>

              <SubText>
                {translate('only_adults')}
                <StyledLink
                  style={{ opacity: '10', color: 'black' }}
                  href="https://www.spotwriters.com/"
                  target="_blank"
                >
                  {translate('website')}
                </StyledLink>
              </SubText>
            </LabelInput>
            <HorizontalFormDivider />

            <Form.Item label={translate('company_bio_label')}>
              <Form.Item name="shortBio" noStyle={true} style={{ display: 'flex' }}>
                <StyledTextArea placeholder={translate('company_bio_placeholder')} data-testid="bio-input" />
              </Form.Item>
            </Form.Item>
            <ButtonsContainer>
              <SaveBtn onClick={handleSave} data-testid="save-btn">
                {translate('save_btn_text')}
              </SaveBtn>
              <SubmitBtn
                type="primary"
                onClick={handleFinish}
                loading={createProfileLoading && paymentInitiated}
                data-testid="submit-btn"
              >
                {translate('submit_btn_text')} <ArrowRightOutlined />
              </SubmitBtn>
            </ButtonsContainer>
          </Form>
        </CreateProfileFormContianer>

        <ProfileAvatarContainer>
          <ProfilePic src={croppedUrlLocal !== '' ? croppedUrlLocal : userAvatarIcon} />
          <EditAvatarBtn>
            <img src={editIcon} onClick={() => inputRef?.current?.click()} />
          </EditAvatarBtn>
        </ProfileAvatarContainer>

        <OTPModal
          visible={isModalOpen}
          handleOtpInput={handleOtpInput}
          toggleResend={toggleResend}
          handleCancel={handleCancel}
          showResend={showResend}
          contactNum={contactNum}
          onOtpVerify={onOtpVerify}
          resendOtpConfirm={resendOtpConfirm}
          data-testid="otp-modal"
        />
      </CreateProfilePageContainer>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  userProfile: selectUserProfile(),
  createProfileLoading: selectCreateUserProfileLoading(),
  paymentError: selectPaymentError(),
  otpSuccess: selectVerifyOtpSuccess(),
  otpError: selectVerifuOtpError(),
  accessToken: selectAccessToken(),
  couponCode: selectCouponCode(),
  paymentInitiated: selectPaymentInitiated(),
  submitLoading: selectSubmitProfileLoading()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGetUserProfile: () => dispatch(requestGetProfile({})),
    dispatchUpdateUserProfile: (profileData: any) => dispatch(requestCreateProfile(profileData)),
    dispatchUploadUserProfile: (fileData: any) => dispatch(requestUploadProfileImage(fileData)),
    dispatchPaymentData: (amount: number, reason: string, notes: string) =>
      dispatch(requestPaymentData({ amount, reason, notes })),
    dispatchResetState: () => dispatch(resetState()),
    dispatchPhoneVerification: (contactNum: string) => dispatch(requestPhoneVerification(contactNum)),
    dispatchVerifyOtp: (otp: string) => dispatch(requestVerifyOtp(otp)),
    dispatchGetProfileUrl: (filePath: string) => dispatch(requestGetProfileUrl(filePath)),
    dispatchSetSubmitLoading: () => dispatch(setSubmitLoading())
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, injectIntl)(AdminProfileContainer) as React.ComponentType;

export const AdminProfileContainerTest = AdminProfileContainer;
