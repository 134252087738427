import { prepare } from '@app/utils';
import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';

export const initialState = {
  showSubscriptionBanner: false,
  reminderSendLoading: false,
  reminderSendMessage: '',
  reminderSendError: null,
  generateRenewalPaymentLinkLoading: false,
  generateRenewalPaymentLinkError: null
};

const dashboardSlice = createSlice({
  name: 'dashboardContainer',
  initialState,
  reducers: {
    requestSendReminder: {
      reducer: (state) => {
        state.reminderSendLoading = true;
      },
      prepare
    },
    requestGenerateRenewalPaymentLink: {
      reducer: (state) => {
        state.generateRenewalPaymentLinkLoading = true;
      },
      prepare
    },
    successSendReminder(state, action) {
      state.reminderSendMessage = get(action.payload, 'sendPaymentReminderToAdmin', '');
      state.reminderSendLoading = false;
    },
    failureSendReminder(state, action) {
      state.reminderSendLoading = false;
      state.reminderSendError = get(action.payload, 'message', 'something_went_wrong');
    },
    setShowSubscriptionBanner(state, action) {
      state.showSubscriptionBanner = action.payload;
    },
    successGenerateRenewalPaymentLink(state) {
      state.generateRenewalPaymentLinkLoading = false;
    },
    failureGenerateRenewalPaymentLink(state, action) {
      state.generateRenewalPaymentLinkLoading = false;
      state.generateRenewalPaymentLinkError = get(action.payload, 'message', 'something_went_wrong');
    },
    resetState: () => initialState
  }
});

export const {
  setShowSubscriptionBanner,
  resetState,
  requestSendReminder,
  successSendReminder,
  failureSendReminder,
  requestGenerateRenewalPaymentLink,
  successGenerateRenewalPaymentLink,
  failureGenerateRenewalPaymentLink
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
