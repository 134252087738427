export type RouteConstant = {
  route: string;
  exact?: boolean;
  isProtected?: boolean;
  showHeader: boolean;
  props?: object;
};

const routeConstants: Record<string, RouteConstant> = {
  home: {
    route: '/',
    exact: true,
    showHeader: true,
    isProtected: true
  },
  dashboard: {
    route: '/dashboard',
    exact: true,
    showHeader: false,
    isProtected: true
  },
  signUp: {
    route: '/signup',
    exact: true,
    showHeader: true,
    isProtected: false
  },
  login: {
    route: '/login',
    exact: true,
    showHeader: true,
    isProtected: false
  },
  authPage: {
    route: '/auth',
    exact: true,
    showHeader: true,
    isProtected: false
  },
  packageDetailsPage: {
    route: '/package-detail',
    exact: true,
    showHeader: true,
    isProtected: true
  },
  termsAndConditions: {
    route: '/terms',
    exact: true,
    showHeader: true,
    isProtected: true
  },
  createProfile: {
    route: '/create-profile',
    exact: true,
    showHeader: true,
    isProtected: true
  },
  profile: {
    route: '/profile',
    exact: true,
    showHeader: true,
    isProtected: true
  },
  paymentCompletePage: {
    route: '/payment-complete',
    exact: true,
    showHeader: true,
    isProtected: true
  },
  invitationPage: {
    route: '/invite',
    exact: true,
    showHeader: true,
    isProtected: false
  },
  editProfilePage: {
    route: '/edit-profile',
    exact: true,
    showHeader: true,
    isProtected: true
  }
};

export type RouteKeys = keyof typeof routeConstants;

export default routeConstants;
