import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import { Form, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import styled from 'styled-components';
import { CustomInput } from 'tsw-sdk';
import { StyledOfferButton } from '../..';
import ArrowRight from '@images/icons/browse-stories/arrow-right-icon.svg';
import BackIcon from '@images/icons/browse-stories/back-icon.svg';
import { MakeOfferProps } from '../../types';
import { preventInvalidCharsNumberInput } from '@app/utils/uiUtils';

const OfferPortalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding: 6.5rem 26.4rem 8.81rem 26.4rem;
  background: ${colors.white};
`;

const OfferContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading = styled.h1`
  ${fonts.family.SpoofFamily};
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  line-height: 3.93rem;
  text-align: center;
  color: ${colors.primary};

  margin-bottom: 0px;
  white-space: nowrap;
`;

const FormContainer = styled(Form)`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;

    .ant-form-item {
      margin-bottom: 0px;
    }
    margin-top: 2rem;
  }
`;

const CustomFormItem = styled(Form.Item)`
  &.ant-form-item {
    width: 16.18rem;
    margin-bottom: 0;
  }
  .ant-form-item-label {
    padding: 0 !important;
    ${fonts.weights.fw600()};
    ${fonts.size.regular()};
    line-height: 1.25rem;
    margin-bottom: 0px;
    color: ${colors.tagBorder};
  }
`;

export const StyledTextArea = styled(TextArea)`
  &&& {
    min-height: 1.93rem;
    > * {
      background-color: ${colors.white} !important;
      border: 0.5px solid ${colors.lightBorder};
      border-radius: 0.31rem;
      margin-top: 0.5rem;

      &.ant-input {
        line-height: 0.94rem;
        min-height: 1.93rem;
      }

      &.ant-input:placeholder-shown {
        color: ${colors.lightBorder};
        font-size: 0.75rem;
        ${fonts.weights.fw400};
        line-height: 0.94rem;
      }

      &.textarea.ant-input {
        min-height: 1.93rem;
        background-color: ${colors.white};
      }
    }
    &.ant-input-textarea-show-count::after {
      margin-top: 0.25rem;
      float: left;
      ${fonts.size.extraSmall()};
      line-height: 0.62rem;
      ${fonts.weights.fw400};
      content: ' ' attr(data-count) ' characters ';
      color: ${colors.lightBorder};
    }
  }
`;

const StyledImg = styled.img``;

const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.79rem;

  cursor: pointer;
  position: absolute;
  top: 6.4rem;
  left: 10rem;
`;

const BackButtonText = styled.p`
  ${fonts.weights.fw800()};
  ${fonts.size.small()};
  line-height: 1rem;
  text-align: center;

  color: ${colors.browseStoriesColors.labelColor};
`;

const CustomNumInput = styled(CustomInput)`
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const MakeOfferComponent = ({ handleBack, onOffer }: MakeOfferProps) => {
  const [offerForm] = Form.useForm();

  const handleOffer = () => {
    const { offerAmt, offerNote } = offerForm.getFieldsValue(['offerAmt', 'offerNote']);

    if (offerAmt > 0) {
      onOffer(Number(offerAmt), offerNote);
    } else {
      message.error(translate('valid_offer'));
    }
  };

  return (
    <OfferPortalContainer>
      <BackButtonContainer onClick={handleBack} data-testid="back-btn-offer">
        <StyledImg src={BackIcon} />
        <BackButtonText>{translate('back')}</BackButtonText>
      </BackButtonContainer>
      <OfferContentContainer>
        <Heading>{translate('make_offer')}</Heading>
        <FormContainer layout="vertical" form={offerForm}>
          <CustomFormItem label={translate('offer_amount')} name="offerAmt">
            <CustomNumInput
              placeholder={translate('offer_amt_placeholder')}
              data-testid="offer-amount-input"
              onKeyDown={preventInvalidCharsNumberInput}
              type="number"
              min={1}
            />
          </CustomFormItem>
          <CustomFormItem label={translate('notes_writer')} name="offerNote">
            <StyledTextArea
              rows={1}
              autoSize
              showCount
              maxLength={250}
              data-testid="offer-notes-input"
              onPaste={(e) => {
                const clipboardData = e.clipboardData;
                const pastedData = clipboardData.getData('Text');

                if (pastedData.length > 250) {
                  message.error(translate('250_chars_limit_error'));
                }
              }}
            />
          </CustomFormItem>
          <StyledOfferButton onClick={handleOffer} data-testid="offer-btn">
            {translate('make_offer_btn')}
            <StyledImg src={ArrowRight} />
          </StyledOfferButton>
        </FormContainer>
      </OfferContentContainer>
    </OfferPortalContainer>
  );
};

export default MakeOfferComponent;
