import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { If, Pagination } from 'tsw-sdk';
import SearchIcon from '@images/icons/browse-stories/search-icon.svg';
import Select from 'react-select';
import { Button, Form, Input, message } from 'antd';
import SortIcon from '@images/icons/browse-stories/sort-icon.svg';
import SaveIcon from '@images/icons/browse-stories/save-icon.svg';

import FilterModal from './components/FilterModal';
import { createStructuredSelector } from 'reselect';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  requestGenerateStoryPdf,
  requestGetSavedFilters,
  requestGetStories,
  requestGetStoryTypes,
  requestGetTags,
  requestLangGenre,
  requestSaveFilter,
  requestToggleFavourite,
  resetState,
  resetStoryPdfData
} from './reducer';
import {
  selectLanguageList,
  selectGenreList,
  selectTagsList,
  selectSavedFiltersList,
  selectStories,
  selectStoryPdfUrl,
  selectStoryPdfLoading,
  selectBrowseStoriesTotal,
  selectLoadingStories,
  selectStoryTypeList
} from './selectors';
import { BrowseStoriesProps, saveFilterPayloadType } from './types';
import ViewSavedFilters from './components/ViewSavedFilters';
import StoryCard from '@app/components/StoryCard';
import { STORIES_STATES } from '@app/utils/constants';
import { selectUserProfile } from '@app/containers/ProfileContainer/selectors';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { selectTrendingStories } from '../../selectors';
import debounce from 'lodash-es/debounce';
import { requestGetTrendingStories } from '../../reducer';
import CustomEmpty from '@app/components/CustomEmpty';
import StyledSpin from '@app/components/StyledSpin';

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 4.5rem 7.06rem 2rem 2rem;
  background: ${colors.dashboardBg};
  min-height: 100%;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const BrowseHeading = styled.h1`
  ${fonts.family.SpoofFamily};
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  line-height: 3.93rem;
  color: ${colors.myDashboardColors.heading};
  margin-bottom: 0;
  text-align: center;
`;

const TrendingStoriesHeading = styled.h1`
  ${fonts.family.SpoofFamily};
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  line-height: 3.93rem;
  color: ${colors.myDashboardColors.heading};
  margin-bottom: 0;
  text-align: left;
  margin-top 2rem;
`;

const CustomSearchInput = styled(Input)`
  && {
    width: 18.75rem;
    padding: 0.5rem;
    gap: 0.62rem;
    height: 1.93rem;
    border: 0.5px solid ${colors.lightBorder};
    border-radius: 0.31rem;
    ${fonts.size.xSmall()};
    line-height: 0.93rem;
    background: ${colors.white};

    &.ant-input:placeholder-shown {
      color: ${colors.lightBorder};
      ${fonts.size.xSmall()};
      line-height: 0.93rem;
      ${fonts.weights.fw400()};
    }

    &.ant-input-affix-wrapper > input.ant-input {
      background: ${colors.white};
    }
  }
`;

const SortContainer = styled(Form)`
  && {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    margin-right: 1.93rem;
    margin-top: 1.75rem;
    gap: 1.5rem;

    .ant-form-item {
      margin-bottom: 0px;
    }
  }
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`;

const FieldLabel = styled.p`
  ${fonts.weights.fw600()};
  ${fonts.size.regular()};
  line-height: 1.25rem;
  color: ${colors.browseStoriesColors.labelColor};
`;

const StyledSelect = styled(Select)`
  && {
    width: 11.8rem;
    height: 2rem;
    background: ${colors.white};
    border: 0.5px solid ${colors.lightBorder};
    border-radius: 5px;

    & .css-1s2u09g-control {
      display: flex;
      max-height: 100%;
      min-height: 100%;
      align-items: center;
      flex-direction: row;
      border: none;
    }

    & .css-1okebmr-indicatorSeparator {
      display: none;
    }

    & .css-319lph-ValueContainer {
      display: flex;
      align-items: center;
      max-height: 100%;
      min-height: 100%;

      padding-top: 0;
      padding-bottom: 0;
      margin: 0;
    }

    & .css-1hb7zxy-IndicatorsContainer {
      min-height: 100%;
      max-height: 100%;
      display: flex;
      align-items: center;
    }

    & .css-tlfecz-indicatorContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.25rem;
    }

    & .css-1pahdxg-control {
      max-height: 100%;
      min-height: 100%;
      border: 0px solid ${colors.lightBorder};
      box-shadow: none;
      display: flex;
      align-items: flex-start;
      padding: 0;
    }

    & .css-1gtu0rj-indicatorContainer {
      padding: 0.25rem;
    }
  }
`;

const StyledButton = styled(Button)`
  && {
    width: 7.75rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: ${colors.darkBorder};
    border-radius: 0.5rem;
    ${fonts.weights.fw800()}
    border: 1px solid ${colors.primary};
    padding: 0.5rem 1.5rem;
    line-height: 1rem;
    gap: 0.75rem;
    margin-bottom: 0.31rem;
  }
`;

const FilterButton = styled(Button)`
  && {
    height: 1.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: ${colors.darkBorder};
    border-radius: 0.5rem;
    ${fonts.weights.fw800()}
    border: 1px solid ${colors.primary};
    padding: 0.37rem 1.25rem;
    line-height: 1rem;
    gap: 0.75rem;
  }
`;

const FilterButtonContainers = styled.div`
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-top: 1.25rem;
`;

const StoryCardWrapper = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  margin-top: 2.75rem;
`;

const StyledImg = styled.img``;

const PaginationContainer = styled.div`
  margin-left: auto;
`;

const BrowseStories = ({
  languageList,
  genreList,
  stories,
  buyerProfile,
  storyPdfUrl,
  storyPdfLoading,
  tagsDataList,
  savedFilters,
  total,
  trendingStories,
  loadingStories,
  dispatchGetStories,
  dispatchToggleFavStory,
  dispatchResetState,
  dispatchGetSortedStories,
  dispatchGenerateStoryPdf,
  dispatchResetStoryPdfUrl,
  dispatchGetTrendingStories,
  dispatchGetLangAndGenre,
  dispatchGetTags,
  dispatchSaveFilter,
  dispatchGetSavedFilters,
  storyTypeList,
  dispatchGetStoryTypes
}: BrowseStoriesProps) => {
  const perPage = 10;

  const locationUrl = useLocation<any>();
  const trendingStoriesSectionRef = useRef<HTMLHeadingElement | null>(null);

  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  const [showSavedFilters, setShowSavedFilters] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [filterId, setFilterId] = useState<number>(0);
  const [appliedFilterName, setAppliedFilterName] = useState<string>('');
  const [sortActive, setSortActive] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sectionScrolledTimes, setSectionScrolledTimes] = useState<number>(0);

  const [currentDownloadingStory, setcurrentDownloadingStory] = useState<string>('');

  const [filterForm] = Form.useForm();

  useEffect(() => {
    dispatchGetLangAndGenre(0, 1);
    dispatchGetTags(0, 1);
    dispatchGetSavedFilters();
    dispatchGetTrendingStories();
    dispatchGetStoryTypes(0, 1);

    if (locationUrl?.state?.showsavedFilters) {
      setShowSavedFilters(true);
    }

    if (locationUrl?.state?.filter) {
      const filter = locationUrl?.state?.filter;

      filterForm.setFieldsValue({
        language: { label: filter.language?.value, value: filter.language?.languageId },
        genre: { label: filter.genre?.value, value: filter.genre?.genreId },
        storyType: {
          label: filter.storyType?.value,
          value: filter.storyType?.storyTypeId === 2 ? 'WEB_SERIES' : 'FEATURE_FILM'
        },
        tags: { label: filter.tag?.value, value: filter.tag?.tagId }
      });
    }

    return () => {
      dispatchResetState();
    };
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [total]);

  // useEffect(() => {
  //   if (buyerProfile) {
  //     if (Object.keys(buyerProfile).length > 2) {
  //       dispatchGetStories(STORIES_STATES.PUBLISHED, perPage, currentPage, buyerProfile?.id, searchTerm);
  //     }
  //   }
  // }, [buyerProfile]);

  useEffect(() => {
    if (!sortActive) {
      dispatchGetStories(STORIES_STATES.PUBLISHED, perPage, currentPage, buyerProfile?.id, searchTerm);
    }
  }, [currentPage]);

  useEffect(() => {
    if (!storyPdfLoading) {
      setcurrentDownloadingStory('');
    }
  }, [storyPdfLoading]);

  useEffect(() => {
    setSectionScrolledTimes(sectionScrolledTimes + 1);
  }, [stories]);

  useEffect(() => {
    if (sectionScrolledTimes === 2) {
      if (locationUrl?.hash) {
        trendingStoriesSectionRef?.current?.scrollIntoView({
          behavior: 'smooth'
        });
      }
    }
  }, [sectionScrolledTimes]);

  useEffect(() => {
    if (storyPdfUrl) {
      window.open(storyPdfUrl, '_blank');

      dispatchResetStoryPdfUrl();
    }
  }, [storyPdfUrl]);

  const handleFilterModalClose = () => {
    setShowFilterModal(false);
  };
  const handleSavedFiltersModalClose = () => {
    setShowSavedFilters(false);
  };
  const onFilterSave = (filterName: string) => {
    setShowFilterModal(false);
    if (filterName) {
      const { language, genre, storyType, tags } = filterForm.getFieldsValue([
        'language',
        'genre',
        'storyType',
        'tags'
      ]);

      const saveFilterPayload: saveFilterPayloadType = {
        input: {
          name: filterName,
          language: language?.label ? { id: language.value, value: language.label } : null,
          genre: genre?.label ? { id: genre.value, value: genre.label } : null,
          tag: tags?.label ? { id: tags.value, value: tags.label } : null,
          storyType: storyType?.label ? { id: storyType.value, value: storyType.label } : null
        }
      };

      dispatchSaveFilter(saveFilterPayload);
      filterForm.resetFields();
    } else {
      message.error(translate('enter_filter_name'));
    }
  };

  const handleApplyFilter = (filter: any) => {
    setShowSavedFilters(false);
    filterForm.setFieldsValue({
      language: { label: filter.language?.value, value: filter.language?.languageId },
      genre: { label: filter.genre?.value, value: filter.genre?.genreId },
      storyType: {
        label: filter.storyType?.value,
        value: filter.storyType?.storyTypeId
      },
      tags: { label: filter.tag?.value, value: filter.tag?.tagId }
    });
    setFilterId(Number(filter.id));
    setAppliedFilterName(filter.name);

    if (!_.isEmpty(filter)) {
      dispatchGetSortedStories(
        STORIES_STATES.PUBLISHED,
        0,
        currentPage,
        buyerProfile?.id,
        filter.language?.languageId,
        filter.genre?.genreId,
        filter.tag?.tagId,
        filter.storyType?.storyTypeId
      );
    }
  };

  const toggleCardFavourite = (storyId: string) => {
    dispatchToggleFavStory(storyId);
  };

  const handleSort = () => {
    const { language, genre, storyType, tags } = filterForm.getFieldsValue(['language', 'genre', 'storyType', 'tags']);

    if (!(language || genre || storyType || tags)) {
      message.error(translate('enter_filter'));
    } else {
      setSortActive(true);
      dispatchGetSortedStories(
        STORIES_STATES.PUBLISHED,
        0,
        currentPage,
        buyerProfile?.id,
        language?.value,
        genre?.value,
        tags?.value,
        storyType?.value
      );
    }
  };

  const openSaveModal = () => {
    const { language, genre, storyType, tags } = filterForm.getFieldsValue(['language', 'genre', 'storyType', 'tags']);

    if (appliedFilterName && filterId) {
      const saveFilterPayload = {
        input: {
          id: filterId,
          name: appliedFilterName,
          language: language?.label ? { id: language.value, value: language.label } : null,
          genre: genre?.label ? { id: genre.value, value: genre.label } : null,
          tag: tags?.label ? { id: tags.value, value: tags.label } : null,
          storyType: storyType?.label ? { id: storyType.value, value: storyType.label } : null
        }
      };

      dispatchSaveFilter(saveFilterPayload);
      filterForm.resetFields();
      setAppliedFilterName('');
      setFilterId(0);
    } else {
      if (!(language || genre || storyType || tags)) {
        message.error(translate('enter_filter'));
      } else {
        setShowFilterModal(true);
      }
    }
  };

  const debouncedSearch = useCallback(
    debounce((term) => {
      dispatchGetStories(STORIES_STATES.PUBLISHED, perPage, currentPage, buyerProfile?.id, term);
    }, 500),
    []
  );

  const handleStoryCardDownload = (storyId: string, sellerId: string) => {
    dispatchGenerateStoryPdf(buyerProfile.id, storyId, sellerId);
    setcurrentDownloadingStory(storyId);
  };

  return (
    <Container>
      <FilterContainer>
        <HeadingContainer>
          <BrowseHeading>{translate('browse_stories')}</BrowseHeading>
          <CustomSearchInput
            suffix={<StyledImg src={SearchIcon} data-testid="search-icon" />}
            placeholder={translate('search')}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              debouncedSearch(e.target.value);
            }}
            value={searchTerm}
            data-testid="search-input-fav"
          />
        </HeadingContainer>
        <SortContainer form={filterForm}>
          <FieldContainer>
            <FieldLabel>{translate('language')}</FieldLabel>
            <Form.Item name="language" data-testid="language-select">
              <StyledSelect options={languageList} placeholder={translate('select_language')} />
            </Form.Item>
          </FieldContainer>
          <FieldContainer>
            <FieldLabel>{translate('genre')}</FieldLabel>
            <Form.Item name="genre" data-testid="genre-select">
              <StyledSelect options={genreList} placeholder={translate('select_genre')} />
            </Form.Item>
          </FieldContainer>
          <FieldContainer>
            <FieldLabel>{translate('story_type')}</FieldLabel>
            <Form.Item name="storyType" data-testid="story-type-select">
              <StyledSelect options={storyTypeList} placeholder={translate('select_story_type')} />
            </Form.Item>
          </FieldContainer>
          <FieldContainer>
            <FieldLabel>{translate('tags')}</FieldLabel>
            <Form.Item name="tags" data-testid="tags-select">
              <StyledSelect options={tagsDataList} placeholder={translate('select_tag')} />
            </Form.Item>
          </FieldContainer>
          <StyledButton onClick={handleSort} data-testid="sort-btn">
            <StyledImg src={SortIcon} />
            {translate('sort_by')}
          </StyledButton>
        </SortContainer>
        <FilterButtonContainers>
          <FilterButton onClick={openSaveModal} data-testid="filter-btn">
            <StyledImg src={SaveIcon} />
            {translate('save_filter')}
          </FilterButton>
          <FilterButton onClick={() => setShowSavedFilters(true)} data-testid="view_filters_btn">
            <StyledImg src={SaveIcon} />
            {translate('viewed_saved')}
          </FilterButton>
          <PaginationContainer>
            <Pagination
              total={total}
              currentPage={currentPage}
              perPage={perPage}
              setPage={(page) => setCurrentPage(page)}
              testKey={'browse-stories'}
            />
          </PaginationContainer>
        </FilterButtonContainers>
      </FilterContainer>
      <StoryCardWrapper>
        <If condition={!loadingStories} otherwise={<StyledSpin />}>
          <If
            condition={stories.length > 0}
            otherwise={<CustomEmpty text={searchTerm ? 'no_results' : 'no_stories'} />}
          >
            {stories.map((el: any) => (
              <StoryCard
                key={el.id}
                canMakeOffer={false}
                isFavourite={el.buyerActions?.hasLiked}
                storyData={el}
                toggleFavourite={toggleCardFavourite}
                handleDownload={handleStoryCardDownload}
                downloadLoading={el.id === currentDownloadingStory ? storyPdfLoading : false}
              />
            ))}
          </If>
        </If>
      </StoryCardWrapper>
      <TrendingStoriesHeading ref={trendingStoriesSectionRef}>{translate('trending_stories')}</TrendingStoriesHeading>

      <StoryCardWrapper>
        <If condition={trendingStories.length > 0} otherwise={<CustomEmpty text={'no_stories'} />}>
          {trendingStories?.map((el: any) => (
            <StoryCard
              key={el.id}
              canMakeOffer={false}
              isFavourite={el.buyerActions?.hasLiked}
              storyData={el}
              toggleFavourite={toggleCardFavourite}
              handleDownload={handleStoryCardDownload}
              downloadLoading={el.id === currentDownloadingStory ? storyPdfLoading : false}
            />
          ))}
        </If>
      </StoryCardWrapper>

      <If condition={showFilterModal}>
        <FilterModal handleCancel={handleFilterModalClose} handleFilterSave={onFilterSave} />
      </If>
      <If condition={showSavedFilters}>
        <ViewSavedFilters
          handleCancel={handleSavedFiltersModalClose}
          savedFilters={savedFilters}
          handleApplyFilter={handleApplyFilter}
        />
      </If>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  languageList: selectLanguageList(),
  genreList: selectGenreList(),
  tagsDataList: selectTagsList(),
  savedFilters: selectSavedFiltersList(),
  stories: selectStories(),
  buyerProfile: selectUserProfile(),
  storyPdfUrl: selectStoryPdfUrl(),
  storyPdfLoading: selectStoryPdfLoading(),
  total: selectBrowseStoriesTotal(),
  trendingStories: selectTrendingStories(),
  loadingStories: selectLoadingStories(),
  storyTypeList: selectStoryTypeList()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGetLangAndGenre: (limit: number, page: number) => dispatch(requestLangGenre({ limit, page })),
    dispatchGetTags: (limit: number, page: number) => dispatch(requestGetTags({ limit, page })),
    dispatchSaveFilter: (saveFilterPayload: any) => dispatch(requestSaveFilter(saveFilterPayload)),
    dispatchGetSavedFilters: () => dispatch(requestGetSavedFilters({})),
    dispatchGetStories: (state: string, limit: number, page: number, buyerId: string, search?: string) =>
      dispatch(requestGetStories({ state, limit, page, buyerId, search })),
    dispatchToggleFavStory: (storyId: string) => dispatch(requestToggleFavourite({ storyId })),
    dispatchResetState: () => dispatch(resetState()),
    dispatchGetSortedStories: (
      state: string,
      limit: number,
      page: number,
      buyerId: string,
      language: number,
      genre: number,
      tag: number,
      storyType: string
    ) => dispatch(requestGetStories({ state, limit, page, buyerId, language, genre, tag, storyType })),
    dispatchGenerateStoryPdf: (buyerId: string, storyId: string, sellerId: string) =>
      dispatch(
        requestGenerateStoryPdf({
          buyerId,
          storyId,
          sellerId
        })
      ),
    dispatchResetStoryPdfUrl: () => dispatch(resetStoryPdfData()),
    dispatchGetTrendingStories: () => dispatch(requestGetTrendingStories({})),
    dispatchGetStoryTypes: (limit: number, page: number) => dispatch(requestGetStoryTypes({ limit, page }))
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, injectIntl)(BrowseStories) as React.FC;

export const BrowseStoriesTest = BrowseStories;
