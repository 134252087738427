import React from 'react';
import { colors, fonts } from '@app/themes';
import moment from 'moment';
import styled from 'styled-components';
import { TrendingStoriesItemProps } from './types';
import { Link } from 'react-router-dom';

const TrendingStoriesItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  &:not(:first-of-type) {
    margin-top: 1.5rem;
  }
`;

const TrendingStoriesDate = styled.p`
  ${fonts.weights.black()}
  ${fonts.size.xSmall()}
  color: ${colors.myDashboardColors.trendingStoryDate};
`;

const TrendingStoriesName = styled.p`
  ${fonts.weights.black()}
  ${fonts.size.regular()};
  line-height: 1.25rem;
  color: ${colors.myDashboardColors.cardTitle};
  margin-top: 0.25rem;
`;

const TrendingStoriesItem = ({ trendingStory, storyId }: TrendingStoriesItemProps) => {
  return (
    <TrendingStoriesItemContainer>
      <TrendingStoriesDate>
        {moment(trendingStory?.createdAt).zone('GMT+0530').format('DD.MM.YYYY | HH:mm')}
      </TrendingStoriesDate>
      <Link
        to={{
          pathname: '/dashboard',
          search: `?activeTab=story_details&storyId=${storyId}`
        }}
      >
        <TrendingStoriesName>{trendingStory?.title}</TrendingStoriesName>
      </Link>
    </TrendingStoriesItemContainer>
  );
};

export default TrendingStoriesItem;
