import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import { prepare } from '@app/utils';

export const initialState = {
  loading: false,
  loginSuccess: false,
  resetPasswordError: null,
  loginError: null,
  reCaptchaState: null
};

const invitationPageSlice = createSlice({
  name: 'invitationPage',
  initialState,
  reducers: {
    requestLoginUser: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare
    },
    requestVerifyRecaptcha: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare
    },
    requestResetPassword: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare
    },
    successLoginUser(state) {
      state.loginError = null;
      state.loginSuccess = true;
      state.loading = false;
      state.reCaptchaState = null;
    },
    failureLoginUser(state, action) {
      state.loginError = get(action.payload, 'message', 'something_went_wrong');
      state.loginSuccess = false;
      state.loading = false;
    },
    successReCaptcha(state, action) {
      state.reCaptchaState = get(action.payload, 'message', 'Success');
      state.loading = false;
    },
    failureRecaptcha(state, action) {
      state.reCaptchaState = get(action.payload, 'message', 'Failure');
      state.loading = false;
    },
    successResetPassword(state) {
      state.loginError = null;
      state.loading = false;
      state.loginSuccess = false;
      state.reCaptchaState = null;
      state.resetPasswordError = null;
    },
    failureResetPassword(state, action) {
      state.resetPasswordError = get(action.payload, 'message', 'something_went_wrong');
      state.loading = false;
    },
    resetState(state) {
      state.loginError = null;
      state.loading = false;
      state.loginSuccess = false;
    },
    resetCaptchaState(state) {
      state.reCaptchaState = null;
    }
  }
});

export const {
  requestLoginUser,
  successLoginUser,
  failureLoginUser,
  resetState,
  successReCaptcha,
  failureRecaptcha,
  requestVerifyRecaptcha,
  resetCaptchaState,
  requestResetPassword,
  successResetPassword,
  failureResetPassword
} = invitationPageSlice.actions;

export default invitationPageSlice.reducer;
