import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import BackIcon from '@images/icons/browse-stories/back-icon.svg';
import ProfilePic from '@images/icons/browse-stories/author-profile-icon.svg';

import { colors, fonts } from '@app/themes';
import { translate } from '@app/components/IntlGlobalProvider';
import supportIcon from '@images/icons/my-offers/support.svg';
import bulletPointsIcon from '@images/icons/my-offers/bullet-points.svg';

import { If } from 'tsw-sdk';
import {
  ATTACHMENT_TYPE,
  NARRATION_PATH,
  PROFILE_IMAGE_PROD_URL,
  PROFILE_IMAGE_QA_URL,
  PROFILE_IMAGE_URL,
  SCREENPLAY_PATH
} from '@app/utils/constants';
import downloadIcon from '@images/icons/download-icon-gray.svg';
import { message } from 'antd';
import { AcceptedOfferPageProps } from './types';
import LegalModal from '../DashboardContainerPage/IndividualDashboard/tabs/MyOffers/components/AcceptedOffer/components/LegalModal';
import SupportModal from '../DashboardContainerPage/IndividualDashboard/tabs/MyOffers/components/AcceptedOffer/components/SupportModal';
import { createStructuredSelector } from 'reselect';
import { selectUserProfile } from '../ProfileContainer/selectors';
import {
  selectImagesUrlList,
  selectImagesUrlListLoading,
  selectNarrationUrl,
  selectNarrationUrlLoading,
  selectScreenPlayUrl,
  selectScreenplayUrlLoading,
  selectStoryData
} from '../StoryDetailsPage/selectors';
import {
  requestGetImages,
  requestGetNarration,
  requestGetScreenPlay,
  requestGetStory,
  resetState
} from '../StoryDetailsPage/reducer';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { requestSendMailToLegalTeam } from '../DashboardContainerPage/IndividualDashboard/tabs/MyOffers/reducer';
import { useLocation } from 'react-router-dom';

const AcceptedOfferContainer = styled.div`
  min-height: 100%;
  padding-top: 5rem;
  padding-left: 2.25rem;
  padding-bottom: 2rem;
  background-color: ${colors.dashboardBg};
  overflow-y: auto;
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  ${fonts.weights.fw800()}
  ${fonts.size.small()}
  line-height: 1rem;
  color: ${colors.tagBorder};
  width: 4.25rem;
`;

const StyledImg = styled.img``;

const OfferInfoContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
`;

const ProfilePicImage = styled.img`
  width: 9rem;
  height: 9rem;
  border-radius: 100%;
`;

const UserName = styled.p`
  margin-top: 2rem;
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw900()};
  ${fonts.size.large()};
  line-height: 2.68rem;
  color: ${colors.myOffers.storyName};
`;

const UserDesignation = styled.p`
  margin-top: 0.72rem;
  ${fonts.weights.fw400()};
  ${fonts.size.xBig()};
  line-height: 2.18rem;
  color: ${colors.myOffers.storyName};
`;

// const OfferAmount = styled.p`
//   margin-top: 2rem;
//   ${fonts.weights.fw700()};
//   ${fonts.size.extraLarge()};
//   line-height: 3.75rem;
//   color: ${colors.myOffers.storyName};
// `;

const OfferAcceptedPill = styled.div`
  width: 9.87rem;
  height: 2.25rem;
  margin-top: 0.72rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.37rem 1.11rem;
  text-transform: uppercase;
  border-radius: 6.25rem;
  background-color: ${colors.myOffers.scriptIncludedPillBg};
  color: ${colors.primary};
  ${fonts.weights.fw800()};
  ${fonts.size.xSmall()};
  line-height: 1.5rem;
`;

const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
`;

const BtnIcon = styled.img`
  margin-right: 0.62rem;
`;

const CounterOfferBtn = styled.div`
  width: 10.625rem;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0.75rem 2rem 0.75rem 2rem;
  display: flex;
  align-items: center;
  background-color: ${colors.myOffers.grayBtnBg};
  color: ${colors.myOffers.grayBtnColor};
  cursor: pointer;
  ${fonts.weights.fw800()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
`;

const AcceptOfferBtn = styled.div`
  width: 16.43rem;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0.75rem 2rem 0.75rem 2rem;
  display: flex;
  align-items: center;
  background-color: ${colors.primary};
  color: ${colors.white};
  margin-left: 2rem;
  cursor: pointer;
  ${fonts.weights.fw800()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
`;

const StoryInfoContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6.25rem;
`;

const StoryDetailsContainer = styled.div`
  width: 100%;
  margin-top: 3.1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const StoryDetailsInfoItem = styled.div`
  width: 33%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 2.43rem;

  &:last-of-type {
    margin-right: auto;
  }
`;

const StoryDetailsInfoItemTitle = styled.p`
  ${fonts.size.big()};
  ${fonts.family.Inter()};
  text-transform: uppercase;
`;

const StoryDetailsInfoItemDetail = styled.p`
  ${fonts.family.Inter()};
  ${fonts.size.regular()};
  ${fonts.weights.fw200()};
  margin-top: 1rem;
`;

const StoryDetailsInfoItemTitle2 = styled.p`
  ${fonts.size.big()};
  ${fonts.family.Inter()};
  text-transform: uppercase;
  margin-right: auto;

  &:not(:first-of-type) {
    margin-top: 2.18rem;
  }
`;

const StoryDetailsInfoItemDetail2 = styled.p`
  ${fonts.family.Inter()};
  ${fonts.size.regular()};
  ${fonts.weights.fw200()};
  margin-top: 1rem;
  margin-right: auto;
  line-height: 1.25rem;
`;

const SupportingMaterialsContainer = styled.div`
  width: 100%;
  margin-top: 7rem;
  display: flex;
  align-items: center;
`;

const SupportingMaterialsItem = styled.div`
  width: auto;
  display: flex;
  align-items: center;

  &:not(:first-of-type) {
    margin-left: 4.6rem;
  }
`;

const SupportingMaterialsItemTitle = styled.p`
  ${fonts.size.big()};
  ${fonts.family.Inter()};
`;

const SupportingMaterialDownloadIcon = styled.img`
  margin-left: 0.62rem;
  cursor: pointer;
`;

const StoryTitle = styled.p`
  ${fonts.family.Inter()};
  ${fonts.weights.fw200()};
  ${fonts.size.extraLarge()};
  line-height: 3.93rem;
  color: ${colors.myDashboardColors.storyTitleColor};
`;

const AcceptedOfferPage = ({
  buyerProfile,
  sendMailToLegalTeamLoading,
  screenplayUrl,
  narrationUrl,
  imagesUrl,
  story,
  dispatchSendMailToLegalTeam,
  dispatchGetNarrationUrl,
  dispatchGetImagesUrl,
  dispatchGetSignedUrl,
  dispatchResetState,
  dispatchGetStoryDetails
}: AcceptedOfferPageProps) => {
  const locationUrl = useLocation<any>();

  const [supportModalVisible, setSupportModalVisible] = useState<boolean>(false);
  const [legalModalVisible, setLegalModalVisible] = useState<boolean>(false);

  const [screenPlayName, setScreenPlayName] = useState<string>('');
  const [narrationName, setNarrationName] = useState<string>('');
  const [imagesName, setimagesName] = useState<string[]>([]);

  const [offerId, setOfferId] = useState<number>(0);

  useEffect(() => {
    const params = new URLSearchParams(locationUrl?.search);
    const storyId = params.get('storyId');
    const offerId = params.get('offerId');

    setOfferId(Number(offerId));

    if (storyId && buyerProfile) {
      dispatchGetStoryDetails(storyId, buyerProfile?.id, 0, 1);
      //   dispatchGetOfferId(storyId, buyerProfile?.id);
    }

    return () => {
      dispatchResetState();
    };
  }, [buyerProfile]);

  useEffect(() => {
    if (narrationUrl) {
      const anchorTag = document.createElement('a');
      anchorTag.href = narrationUrl;
      anchorTag.setAttribute('target', '_blank');
      anchorTag.click();
    }
  }, [narrationUrl]);

  useEffect(() => {
    if (screenplayUrl) {
      const anchorTag = document.createElement('a');
      anchorTag.href = screenplayUrl;
      anchorTag.setAttribute('target', '_blank');
      anchorTag.click();
    }
  }, [screenplayUrl]);

  useEffect(() => {
    if (imagesUrl?.length > 0) {
      imagesUrl.forEach((curr: string) => {
        const anchorTag = document.createElement('a');
        anchorTag.href = curr;
        anchorTag.setAttribute('target', '_blank');
        anchorTag.click();
      });
    }
  }, [imagesUrl]);

  useEffect(() => {
    if (!sendMailToLegalTeamLoading && (legalModalVisible || supportModalVisible)) {
      setSupportModalVisible(false);
      setLegalModalVisible(false);
      //   onBack();
    }
  }, [sendMailToLegalTeamLoading]);

  useEffect(() => {
    if (story?.supportingMaterials) {
      story?.supportingMaterials.forEach((el: any) => {
        if (el.attachmentType === ATTACHMENT_TYPE.SCREENPLAY) {
          const newScreenPlayName = el.fileName.replace('storyMaterial/screenplays', '');
          setScreenPlayName(newScreenPlayName);
        } else if (el.attachmentType === ATTACHMENT_TYPE.AUDIO_NARRATION) {
          const newAudioNarration = el.fileName.replace('storyMaterial/audioNarrations', '');
          setNarrationName(newAudioNarration);
        } else {
          setimagesName((curr) => [...curr, el.fileName.replace('storyMaterial/images', '')]);
        }
      });
    }
  }, [story]);

  const handleScreenplayDownload = () => {
    if (screenPlayName) {
      dispatchGetSignedUrl(SCREENPLAY_PATH, screenPlayName);
    } else {
      message.error(translate('no_file_download'));
    }
  };

  const handleNarrationDownload = () => {
    if (narrationName) {
      dispatchGetNarrationUrl(NARRATION_PATH, narrationName);
    } else {
      message.error(translate('no_file_download'));
    }
  };

  const handleImagesDownload = () => {
    if (imagesName.length > 0) {
      dispatchGetImagesUrl(imagesName);
    } else {
      message.error(translate('no_file_download'));
    }
  };

  return (
    <AcceptedOfferContainer data-testid="accepted-offer-container">
      <SupportModal visible={supportModalVisible} onClose={() => setSupportModalVisible(false)} />
      <LegalModal
        visible={legalModalVisible}
        offerId={offerId}
        onClose={() => setLegalModalVisible(false)}
        dispatchSendMailToLegalTeam={dispatchSendMailToLegalTeam}
      />
      <BackButton onClick={() => history.back()} data-testid="back-btn">
        <StyledImg src={BackIcon} />
        {translate('back')}
      </BackButton>
      <OfferInfoContainer>
        <If condition={process.env.ENVIRONMENT_NAME === 'qa'}>
          <ProfilePicImage
            src={story?.writer?.profilePic !== '' ? `${PROFILE_IMAGE_QA_URL}/${story?.writer?.profilePic}` : ProfilePic}
          />
        </If>
        <If condition={process.env.ENVIRONMENT_NAME === 'production'}>
          <ProfilePicImage
            src={
              story?.writer?.profilePic !== '' ? `${PROFILE_IMAGE_PROD_URL}/${story?.writer?.profilePic}` : ProfilePic
            }
          />
        </If>
        <If condition={process.env.ENVIRONMENT_NAME !== 'production' && process.env.ENVIRONMENT_NAME !== 'qa'}>
          <ProfilePicImage
            src={story?.writer?.profilePic !== '' ? `${PROFILE_IMAGE_URL}/${story?.writer?.profilePic}` : ProfilePic}
          />
        </If>
        <UserName>{`${story?.writer?.firstName} ${story?.writer?.lastName}`}</UserName>
        <UserDesignation>{translate('storyteller')}</UserDesignation>
        <OfferAcceptedPill>{translate('offer_accepted')}</OfferAcceptedPill>
        {/* <OfferAmount>{`₹ ${acceptedOfferAmount?.toLocaleString('en-IN')}`}</OfferAmount> */}
        <ActionsContainer>
          <CounterOfferBtn onClick={() => setSupportModalVisible(true)} data-testid="support-btn">
            <BtnIcon src={supportIcon} />
            {translate('support')}
          </CounterOfferBtn>
          <AcceptOfferBtn onClick={() => setLegalModalVisible(true)} data-testid="legal-btn">
            <BtnIcon src={bulletPointsIcon} />
            {translate('legal_next_steps')}
          </AcceptOfferBtn>
        </ActionsContainer>
      </OfferInfoContainer>
      <StoryInfoContainer>
        <StoryTitle>{story?.title}</StoryTitle>
        <StoryDetailsContainer>
          <StoryDetailsInfoItem>
            <StoryDetailsInfoItemTitle>{translate('language')}</StoryDetailsInfoItemTitle>
            {story?.languages?.map((language: any, index: number) => (
              <StoryDetailsInfoItemDetail key={index}>{language?.name}</StoryDetailsInfoItemDetail>
            ))}
          </StoryDetailsInfoItem>
          <StoryDetailsInfoItem>
            <StoryDetailsInfoItemTitle>{translate('genre')}</StoryDetailsInfoItemTitle>
            {story?.genres?.map((genre: any, index: number) => (
              <StoryDetailsInfoItemDetail key={index}>{genre?.name}</StoryDetailsInfoItemDetail>
            ))}
          </StoryDetailsInfoItem>
          <StoryDetailsInfoItem>
            <StoryDetailsInfoItemTitle>{translate('story_type_label')}</StoryDetailsInfoItemTitle>
            <StoryDetailsInfoItemDetail>{story?.storyType?.name}</StoryDetailsInfoItemDetail>
          </StoryDetailsInfoItem>
          <StoryDetailsInfoItem>
            <StoryDetailsInfoItemTitle>{translate('co_writer')}</StoryDetailsInfoItemTitle>
            <StoryDetailsInfoItemDetail>{story?.coAuthor1}</StoryDetailsInfoItemDetail>
            <StoryDetailsInfoItemDetail>{story?.coAuthor2}</StoryDetailsInfoItemDetail>
          </StoryDetailsInfoItem>
          <StoryDetailsInfoItem>
            <StoryDetailsInfoItemTitle>{translate('tags')}</StoryDetailsInfoItemTitle>
            {story?.tags?.map((tag: any, index: number) => (
              <StoryDetailsInfoItemDetail key={index}>{tag?.name}</StoryDetailsInfoItemDetail>
            ))}
          </StoryDetailsInfoItem>
        </StoryDetailsContainer>
        <StoryDetailsInfoItemTitle2>{translate('logline_title')}</StoryDetailsInfoItemTitle2>
        <StoryDetailsInfoItemDetail2>{story?.logline}</StoryDetailsInfoItemDetail2>
        <StoryDetailsInfoItemTitle2>{translate('synopsis_title')}</StoryDetailsInfoItemTitle2>
        <StoryDetailsInfoItemDetail2>{story?.storyline}</StoryDetailsInfoItemDetail2>
        <SupportingMaterialsContainer>
          <SupportingMaterialsItem>
            <SupportingMaterialsItemTitle>{translate('screenplay_title')}</SupportingMaterialsItemTitle>
            <SupportingMaterialDownloadIcon
              src={downloadIcon}
              onClick={handleScreenplayDownload}
              data-testid="download-screenplay-btn"
            />
          </SupportingMaterialsItem>
          <SupportingMaterialsItem>
            <SupportingMaterialsItemTitle>{translate('audio_narration_title')}</SupportingMaterialsItemTitle>
            <SupportingMaterialDownloadIcon
              src={downloadIcon}
              onClick={handleNarrationDownload}
              data-testid="download-narration-btn"
            />
          </SupportingMaterialsItem>
          <SupportingMaterialsItem>
            <SupportingMaterialsItemTitle>{translate('supporting_images_title')}</SupportingMaterialsItemTitle>
            <SupportingMaterialDownloadIcon
              src={downloadIcon}
              onClick={handleImagesDownload}
              data-testid="download-images-btn"
            />
          </SupportingMaterialsItem>
        </SupportingMaterialsContainer>
      </StoryInfoContainer>
    </AcceptedOfferContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  buyerProfile: selectUserProfile(),
  story: selectStoryData(),
  screenplayUrl: selectScreenPlayUrl(),
  screenPlayUrlLoading: selectScreenplayUrlLoading(),
  narrationUrl: selectNarrationUrl(),
  imagesUrl: selectImagesUrlList(),
  narrationUrlLoading: selectNarrationUrlLoading(),
  imagesUrlLoading: selectImagesUrlListLoading()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGetStoryDetails: (storyId: string, buyerId: string, limit: number, page: number) =>
      dispatch(requestGetStory({ storyId, buyerId, limit, page })),
    dispatchSendMailToLegalTeam: (supportNeeded: boolean, offerId: string) =>
      dispatch(
        requestSendMailToLegalTeam({
          supportNeeded,
          offerId
        })
      ),
    dispatchGetSignedUrl: (folderPath: string, fileName: string) =>
      dispatch(requestGetScreenPlay({ folderPath, fileName })),
    dispatchGetNarrationUrl: (folderPath: string, fileName: string) =>
      dispatch(requestGetNarration({ folderPath, fileName })),
    dispatchGetImagesUrl: (fileNames: string[]) => dispatch(requestGetImages({ fileNames })),
    dispatchResetState: () => dispatch(resetState())
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, injectIntl)(AcceptedOfferPage);

export const AcceptedOfferTest = AcceptedOfferPage;
