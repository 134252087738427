import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import editIcon from '@images/icons/edit-banner.svg';
import { colors, fonts } from '@app/themes';
import { createStructuredSelector } from 'reselect';
import { AnyAction, compose } from '@reduxjs/toolkit';
import {
  selectProfileFilePath,
  selectProfileUrl,
  selectUserProfile,
  selectBannerFilePath,
  selectBannerUrl,
  selectCreateProfileError
} from '@app/containers/ProfileContainer/selectors';
import {
  requestCreateProfile,
  requestUploadBannerImage,
  requestUploadProfileImage
} from '@app/containers/ProfileContainer/reducer';
import { connect } from 'react-redux';
import { BannerProps, MyProfileProps } from './types';
import defaultProfilePic from '@images/icons/user-avatar-icon.svg';
import ProfileCropModal from '@app/containers/ProfileContainer/components/ProfileCropModal';
import _ from 'lodash-es';
import { selectAccessToken } from '@app/containers/TokenProvider/selectors';
import {
  BANNER_IMAGE_PROD_URL,
  BANNER_IMAGE_QA_URL,
  BANNER_IMAGE_URL,
  BUYER_ACCOUNT_TYPE,
  COGNITO_USER_GROUPS,
  PRIVACY_POLICY_URL,
  PROFILE_IMAGE_PROD_URL,
  PROFILE_IMAGE_QA_URL,
  PROFILE_IMAGE_URL,
  TERMS_AND_CONDITIONS_URL
} from '@app/utils/constants';
import { translate } from '@app/components/IntlGlobalProvider';
import ShowreelItem from './components/ShowreelItem';
import { ShowreelItemType } from './components/ShowreelItem/types';
import { FilmItemType } from './components/FilmItem/types';
import FilmItem from './components/FilmItem';
import routeConstants from '@app/utils/routeConstants';
import { useHistory } from 'react-router-dom';
import { CustomButton, If } from 'tsw-sdk';
import downloadIcon from '@images/icons/download-icon-gray.svg';
import BillingDetailsContainer from '../../../../../components/BillingDetailsContainer';
import CustomEmpty from '@app/components/CustomEmpty';

const ProfilePageContainer = styled.div`
  width: 100%;
  min-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: ${colors.dashboardBg};
  padding-bottom: 2rem;
`;

const ProfilePageBanner = styled.div<BannerProps>`
  width: 100%;
  height: 12.75rem;
  position: relative;
  background-color: ${colors.btnDisabled};
  background-image: ${(props) => (props.backgroundImage !== '' ? `url(${props.backgroundImage})` : '')};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &:hover > img {
    opacity: 1;
  }
`;

const EditBannerIcon = styled.img`
  width: 3rem;
  height: 3rem;
  position: absolute;
  bottom: 1.25rem;
  right: 1.25rem;
  cursor: pointer;
  opacity: 0;
  transition: all 0.2s;
`;

const ProfileInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -4.25rem;
`;

const ProfileImageContainer = styled.div`
  width: 9rem;
  height: 9rem;
  position: relative;
`;

const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 6.25rem;
`;

const EditProfileBtn = styled.img`
  position: absolute;
  bottom: 0;
  right: -1rem;
  cursor: pointer;
`;

const UserName = styled.p`
  margin-top: 2rem;
  ${fonts.family.SpoofFamily()};
  ${fonts.size.large()};
  ${fonts.weights.fw900()};
  line-height: 2.68rem;
`;

const UserCompany = styled.p`
  margin-top: 0.75rem;
  ${fonts.weights.fw700()};
  ${fonts.size.xBig()};
  line-height: 2.18rem;
`;

const UserDesignation = styled.p`
  margin-top: 0.75rem;
  ${fonts.weights.fw400()};
  ${fonts.size.xBig()};
  line-height: 2.18rem;
`;

const ShowreelContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 3.75rem;
  padding-left: 4.93rem;
`;

const ShowreelTitle = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.size.extraLarge()};
  ${fonts.weights.fw700()};
  line-height: 3.93rem;
  color: ${colors.profilePageColors.titleColor};
`;

const ShowreelListContainer = styled.div`
  width: 100%;
  padding-right: 9rem;
  display: flex;
  align-items: center;
  margin-top: 3.75rem;
`;

const ProfileImageInput = styled.input`
  display: none;
`;

const FavouriteFilmsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 3.75rem;
  padding-left: 4.93rem;
`;

const FavouriteFilmsTitle = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.size.extraLarge()};
  ${fonts.weights.fw700()};
  line-height: 3.93rem;
  color: ${colors.profilePageColors.titleColor};
  margin-bottom: 1rem;
`;

const FavMoviesListContainer = styled.div`
  width: 100%;
  padding-right: 9rem;
  display: flex;
  align-items: center;
  margin-top: 2.75rem;
`;

const UpdateProfileBtn = styled(CustomButton)`
  && {
    justify-content: center;
    width: 8rem;
    margin-top: 2rem;
  }
`;

const LegalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2.62rem;
  padding-left: 4.93rem;
`;

const LegalContainerTitle = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.size.extraLarge()};
  ${fonts.weights.fw700()};
  line-height: 3.93rem;
  color: ${colors.profilePageColors.titleColor};
`;

const LegalContainerItem = styled.div`
  margin-top: 1.56rem;
  display: flex;
  align-items: center;
`;

const LegalContainerItemText = styled.p`
  ${fonts.size.big()};
  ${fonts.weights.fw700()};
  line-height: 1.5rem;
  color: ${colors.profilePageColors.companyName};
`;

const DownloadIcon = styled.img`
  margin-left: 1.5rem;
  cursor: pointer;
`;

const UserBio = styled.p`
  max-width: 24rem;
  margin-top: 2rem;
  ${fonts.weights.fw400()};
  ${fonts.size.regular()};
  line-height: 1.25rem;
  position: relative;
`;

const DownloadLink = styled.a``;

const MyProfile = ({
  userProfile,
  filePath,
  accessToken,
  bannerFilePath,
  createProfileError,
  dispatchCreateUserProfile,
  dispatchUploadUserProfile,
  dispatchUploadBanner
}: MyProfileProps) => {
  const profileInputRef = useRef<any>(null);
  const bannerInputRef = useRef<any>(null);

  const [cropMode, setCropMode] = useState<'profile' | 'banner'>('profile');

  const [imageCropModalVisible, setImageCropModalVisible] = useState<boolean>(false);

  const [profileUrlLocal, setProfileUrlLocal] = useState<string>('');
  const [bannerUrlLocal, setBannerUrlLocal] = useState<string>('');

  const history = useHistory();

  useEffect(() => {
    setProfileUrlLocal('');
    setBannerUrlLocal('');
  }, [userProfile]);

  useEffect(() => {
    if (userProfile && filePath) {
      const newProfileData: any = constructPayload();

      dispatchCreateUserProfile({
        formData: {
          buyerProfileInput: {
            ...newProfileData,
            profilePic: filePath,
            isSave: true
          }
        },
        autoSave: true
      });
    }
  }, [filePath]);

  useEffect(() => {
    if (userProfile && bannerFilePath) {
      const newProfileData = constructPayload();

      dispatchCreateUserProfile({
        formData: {
          buyerProfileInput: {
            ...newProfileData,
            profileBanner: bannerFilePath,
            isSave: true
          }
        },
        autoSave: true
      });
    }
  }, [bannerFilePath]);

  const constructPayload = () => {
    let newProfileData: any = _.cloneDeep(userProfile);

    delete newProfileData?.typename;
    delete newProfileData?.email;
    delete newProfileData?.id;
    delete newProfileData?.company?.typename;
    delete newProfileData?.billingAddress?.typename;

    if (newProfileData?.showreels) {
      if (newProfileData?.showreels.length) {
        for (let i = 0; i < newProfileData?.showreels?.length; i++) {
          const newShowReel: any = {};
          Object.assign(newShowReel, newProfileData?.showreels[i]);
          delete newShowReel?.typename;
          delete newShowReel?.id;
          newProfileData.showreels[i] = newShowReel;
        }
      }
    }

    if (newProfileData?.favGenres) {
      if (newProfileData?.favGenres.length) {
        for (let i = 0; i < newProfileData?.favGenres?.length; i++) {
          const newGenre: any = {};
          Object.assign(newGenre, newProfileData?.favGenres[i]);
          delete newGenre?.typename;
          delete newGenre?.id;
          newProfileData.favGenres[i] = newGenre;
        }
      }
    }

    if (newProfileData?.favMovies) {
      if (newProfileData?.favMovies.length) {
        for (let i = 0; i < newProfileData?.favMovies?.length; i++) {
          const newMovie: any = {};
          Object.assign(newMovie, newProfileData?.favMovies[i]);
          delete newMovie?.typename;
          delete newMovie?.id;
          newProfileData.favMovies[i] = newMovie;
        }
      }
    }

    if (newProfileData?.producedFilms) {
      if (newProfileData?.producedFilms.length) {
        for (let i = 0; i < newProfileData?.producedFilms?.length; i++) {
          const newProducedFilm: any = {};
          Object.assign(newProducedFilm, newProfileData?.producedFilms[i]);
          delete newProducedFilm?.typename;
          delete newProducedFilm?.id;
          newProfileData.producedFilms[i] = newProducedFilm;
        }
      }
    }

    let userType = accessToken['cognito:groups'];

    if (userType) {
      if (userType.length) {
        if (userType.includes(COGNITO_USER_GROUPS.ORGANIZATION)) {
          userType = BUYER_ACCOUNT_TYPE.ORGANIZATION_USER;
        } else if (userType.includes(COGNITO_USER_GROUPS.INDIVIDUAL)) {
          userType = BUYER_ACCOUNT_TYPE.INDIVIDUAL;
        } else if (userType.includes(COGNITO_USER_GROUPS.ADMIN)) {
          userType = BUYER_ACCOUNT_TYPE.ORGANIZATION_ADMIN;
        }
      }
    }

    newProfileData = { ...newProfileData, profileType: userType };

    return newProfileData;
  };

  const handleImageInputChange = (e: any) => {
    const file = e.target.files[0];

    const fileUrl = URL.createObjectURL(file);
    setCropMode('profile');
    setProfileUrlLocal(fileUrl);
    setImageCropModalVisible(true);
    e.target.value = null;
  };

  const handleBannerInputChange = (e: any) => {
    const file = e.target.files[0];

    const fileUrl = URL.createObjectURL(file);

    setCropMode('banner');
    setBannerUrlLocal(fileUrl);
    setImageCropModalVisible(true);

    e.target.value = null;
  };

  const handleCrop = (file: any) => {
    if (cropMode === 'profile') {
      dispatchUploadUserProfile(file);
    } else {
      dispatchUploadBanner(file);
    }
    setImageCropModalVisible(false);
    setBannerUrlLocal('');
    setProfileUrlLocal('');
  };

  const saveBillingInfo = (billingInfo: any) => {
    const newProfileData = constructPayload();

    dispatchCreateUserProfile({
      formData: {
        buyerProfileInput: {
          ...newProfileData,
          billingAddress: billingInfo,
          isSave: true
        }
      },
      autoSave: false
    });
  };

  return (
    <ProfilePageContainer>
      <ProfileImageInput
        type={'file'}
        accept="image/png, image/jpeg"
        onChange={handleImageInputChange}
        ref={profileInputRef}
        data-testid="profile-upload-input"
      />

      <ProfileImageInput
        type={'file'}
        accept="image/png, image/jpeg"
        onChange={handleBannerInputChange}
        ref={bannerInputRef}
        data-testid="banner-upload-input"
      />

      {imageCropModalVisible && (
        <ProfileCropModal
          image={cropMode === 'profile' ? profileUrlLocal : bannerUrlLocal}
          visible={imageCropModalVisible}
          onCrop={handleCrop}
          handleCancel={() => setImageCropModalVisible(false)}
          cropMode={cropMode}
        />
      )}

      <If condition={process.env.ENVIRONMENT_NAME === 'qa'}>
        <ProfilePageBanner
          backgroundImage={
            userProfile?.profileBanner !== '' ? `${BANNER_IMAGE_QA_URL}/${userProfile?.profileBanner}` : ''
          }
        >
          <EditBannerIcon
            src={editIcon}
            onClick={() => bannerInputRef?.current?.click()}
            data-testid="edit-banner-btn"
          />
        </ProfilePageBanner>
      </If>

      <If condition={process.env.ENVIRONMENT_NAME === 'production'}>
        <ProfilePageBanner
          backgroundImage={
            userProfile?.profileBanner !== '' ? `${BANNER_IMAGE_PROD_URL}/${userProfile?.profileBanner}` : ''
          }
        >
          <EditBannerIcon
            src={editIcon}
            onClick={() => bannerInputRef?.current?.click()}
            data-testid="edit-banner-btn"
          />
        </ProfilePageBanner>
      </If>
      <If condition={process.env.ENVIRONMENT_NAME !== 'production' && process.env.ENVIRONMENT_NAME !== 'qa'}>
        <ProfilePageBanner
          backgroundImage={userProfile?.profileBanner !== '' ? `${BANNER_IMAGE_URL}/${userProfile?.profileBanner}` : ''}
        >
          <EditBannerIcon
            src={editIcon}
            onClick={() => bannerInputRef?.current?.click()}
            data-testid="edit-banner-btn"
          />
        </ProfilePageBanner>
      </If>

      <ProfileInfoContainer>
        <ProfileImageContainer>
          <If condition={process.env.ENVIRONMENT_NAME === 'qa'}>
            <ProfileImage
              src={
                userProfile?.profilePic !== ''
                  ? `${PROFILE_IMAGE_QA_URL}/${userProfile?.profilePic}`
                  : defaultProfilePic
              }
            />
          </If>
          <If condition={process.env.ENVIRONMENT_NAME === 'production'}>
            <ProfileImage
              src={
                userProfile?.profilePic !== ''
                  ? `${PROFILE_IMAGE_PROD_URL}/${userProfile?.profilePic}`
                  : defaultProfilePic
              }
            />
          </If>
          <If condition={process.env.ENVIRONMENT_NAME !== 'production' && process.env.ENVIRONMENT_NAME !== 'qa'}>
            <ProfileImage
              src={
                userProfile?.profilePic !== '' ? `${PROFILE_IMAGE_URL}/${userProfile?.profilePic}` : defaultProfilePic
              }
            />
          </If>

          <EditProfileBtn src={editIcon} onClick={() => profileInputRef?.current?.click()} data-testid="edit-btn" />
        </ProfileImageContainer>
        <UserName>{`${userProfile?.firstName} ${userProfile?.lastName}`}</UserName>
        <UserCompany>{userProfile?.company?.name}</UserCompany>
        <UserDesignation>{userProfile?.company?.designation}</UserDesignation>
        <UpdateProfileBtn onClick={() => history.push(routeConstants.editProfilePage.route)}>
          {translate('edit_profile')}
        </UpdateProfileBtn>
        <UserBio>{userProfile?.bioDescription}</UserBio>
      </ProfileInfoContainer>

      <ShowreelContainer>
        <ShowreelTitle>{translate('showreel')}</ShowreelTitle>
        <If condition={userProfile?.showreels?.length > 0} otherwise={<CustomEmpty text={'no_data'} />}>
          <ShowreelListContainer>
            {userProfile?.showreels?.map((showReelItem: ShowreelItemType) => (
              <ShowreelItem key={showReelItem?.id} showreel={showReelItem} />
            ))}
          </ShowreelListContainer>
        </If>
      </ShowreelContainer>

      <FavouriteFilmsContainer>
        <FavouriteFilmsTitle>{translate('favourite_films')}</FavouriteFilmsTitle>
        <If condition={userProfile?.favMovies?.length > 0} otherwise={<CustomEmpty text={'no_data'} />}>
          <FavMoviesListContainer>
            {userProfile?.favMovies?.map((favMovie: FilmItemType) => (
              <FilmItem key={favMovie.id} film={favMovie} />
            ))}
          </FavMoviesListContainer>
        </If>
      </FavouriteFilmsContainer>
      <FavouriteFilmsContainer>
        <FavouriteFilmsTitle>{translate('top_3_genres')}</FavouriteFilmsTitle>
        <If condition={userProfile?.favGenres?.length > 0} otherwise={<CustomEmpty text={'no_data'} />}>
          <FavMoviesListContainer>
            {userProfile?.favGenres?.map((favGenre: FilmItemType) => (
              <FilmItem key={favGenre.id} film={favGenre} />
            ))}
          </FavMoviesListContainer>
        </If>
      </FavouriteFilmsContainer>
      <FavouriteFilmsContainer>
        <FavouriteFilmsTitle>{translate('top_3_produced_films')}</FavouriteFilmsTitle>
        <If condition={userProfile?.producedFilms?.length > 0} otherwise={<CustomEmpty text={'no_data'} />}>
          <FavMoviesListContainer>
            {userProfile?.producedFilms?.map((producedFilm: FilmItemType) => (
              <FilmItem key={producedFilm.id} film={producedFilm} />
            ))}
          </FavMoviesListContainer>
        </If>
      </FavouriteFilmsContainer>
      <LegalContainer>
        <LegalContainerTitle>{translate('legal')}</LegalContainerTitle>
        <LegalContainerItem>
          <LegalContainerItemText>{translate('legal_terms_and_conditions')} </LegalContainerItemText>
          <DownloadLink target={'_blank'} href={TERMS_AND_CONDITIONS_URL} rel="noreferrer">
            <DownloadIcon src={downloadIcon} />
          </DownloadLink>
        </LegalContainerItem>
        <LegalContainerItem>
          <LegalContainerItemText>{translate('legal_privacy_policy')}</LegalContainerItemText>
          <DownloadLink target={'_blank'} href={PRIVACY_POLICY_URL} rel="noreferrer">
            <DownloadIcon src={downloadIcon} />
          </DownloadLink>
        </LegalContainerItem>
      </LegalContainer>
      <BillingDetailsContainer
        billingInfo={userProfile?.billingAddress}
        createProfileError={createProfileError}
        handleSave={saveBillingInfo}
      />
    </ProfilePageContainer>
  );
};

export const mapStateToProps = createStructuredSelector({
  accessToken: selectAccessToken(),
  userProfile: selectUserProfile(),
  filePath: selectProfileFilePath(),
  profileUrl: selectProfileUrl(),
  bannerFilePath: selectBannerFilePath(),
  bannerUrl: selectBannerUrl(),
  createProfileError: selectCreateProfileError()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchCreateUserProfile: (profileData: any) => dispatch(requestCreateProfile(profileData)),
    dispatchUploadUserProfile: (fileData: any) => dispatch(requestUploadProfileImage(fileData)),
    dispatchUploadBanner: (fileData: any) => dispatch(requestUploadBannerImage(fileData))
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(MyProfile);

export const MyProfileTest = MyProfile;
