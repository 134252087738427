import React, { useEffect } from 'react';
import { translate } from '@app/components/IntlGlobalProvider/index';
import styled from 'styled-components';
import history from '@utils/history';
import routeConstants from '@app/utils/routeConstants';
import { colors, fonts } from '@app/themes';
import paymentDoneCheckmark from '@images/icons/circle-checked-icon.svg';

const PaymentCompleteModalContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
`;

const PageTitle = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  line-height: 3.93rem;
  color: ${colors.primary};
`;

const PaymentConfirmedCheckmark = styled.img`
  width: 4.5rem;
  height: 4.5rem;
  margin-top: 1.87rem;
`;

const PaymentComplete = () => {
  useEffect(() => {
    setTimeout(() => {
      history.push(routeConstants.dashboard.route);
    }, 2000);
  }, []);

  return (
    <PaymentCompleteModalContainer>
      <PageTitle>{translate('payment_confirmed')}</PageTitle>
      <PaymentConfirmedCheckmark src={paymentDoneCheckmark} />
    </PaymentCompleteModalContainer>
  );
};

export default PaymentComplete;
