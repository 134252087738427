/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */
import React, { useState, useEffect } from 'react';
import GlobalStyle from '@app/global-styles';
import { routeConfig } from '@app/routeConfig';
import { Layout, message, notification } from 'antd';
import map from 'lodash-es/map';
import { withRouter, useHistory, useLocation } from 'react-router';
import { Switch } from 'react-router-dom';
import { compose, AnyAction } from 'redux';
import styled, { ThemeProvider } from 'styled-components';
import For from '@components/For';
import { colors } from '@themes/index';
import { HEADER_HEIGHT, MIN_SIDEBAR_WIDTH } from '@app/utils/constants';
import CustomHeader from '@app/components/CustomHeader';
import { selectAccessToken, selectCredential } from '../TokenProvider/selectors';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { failureGetJWTToken, successSetJWTToken } from '../TokenProvider/reducer';
import { setClient } from '@app/utils/graphqlUtils';
import { translate } from '@app/components/IntlGlobalProvider';
import { getHeightOfContainer, shouldShowHeader } from '@app/utils/uiUtils';
import { ProtectedRoute } from '@app/components';
import { logoutAction } from '@app/reducers';
import { injectSaga } from 'redux-injectors';
import RootSaga from './saga';
import { Amplify, Auth } from 'aws-amplify';
import { awsConfig } from '@app/utils/awsConfig';

const theme = {
  fg: colors.primary,
  bg: colors.secondaryText,
  headerHeight: HEADER_HEIGHT,
  sidebarWidth: MIN_SIDEBAR_WIDTH
};

type StyledLayoutProps = {
  headerPadding: boolean;
  height: number;
};

type StyledCustomLayoutProps = {
  height: number;
};

const CustomLayout = styled(Layout)<StyledCustomLayoutProps>`
  && {
    width: 100%;
    height: ${(props) => `${props.height}vh`};
    background-color: ${colors.secondaryText};
    min-height: ${(props) => `${props.height}vh`};
  }
`;

const StyledLayoutContent = styled(Layout.Content)<StyledLayoutProps>`
  && {
    overflow: auto;
    height: ${(props) => (props.headerPadding ? `calc(${props.height}% - 4rem)` : `${props.height}%`)};
    padding-top: ${(props) => (props.headerPadding ? '2rem' : '0px')};
    margin-top: ${(props) => (props.headerPadding ? '4rem' : '0px')};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
`;

type TokenData = {
  exp: number;
  email: string;
};

type AppProps = {
  accessToken: TokenData;
  credential: string;
  dispatchClearToken: () => AnyAction;
  logoutUser: () => AnyAction;
  dispatchSetToken: (tokenData: any) => AnyAction;
};

let logoutTimeout: NodeJS.Timeout;

Amplify.configure(awsConfig);

export function App({ accessToken, credential, dispatchClearToken, logoutUser, dispatchSetToken }: AppProps) {
  const history = useHistory();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(true);
  const [accessTokenAvailable, setAccessTokenAvailable] = useState<boolean>(false);
  const [containerHeight, setContainerHeight] = useState(100);

  useEffect(() => {
    const params = new URLSearchParams(location?.search);
    const ssoError = params.get('error_description');
    if (params) {
      if (ssoError && ssoError.includes('already exist')) {
        notification.open({
          message: 'Error',
          description: translate('pre_signup_user_already_exists')
        });
      }
    }
  }, []);

  useEffect(() => {
    dimensionsHandler();
    window.addEventListener('resize', dimensionsHandler);

    return () => {
      clearTimeout(logoutTimeout);
      window.removeEventListener('resize', dimensionsHandler);
    };
  }, []);

  const dimensionsHandler = () => {
    const height = getHeightOfContainer();

    setContainerHeight(height);
  };

  useEffect(() => {
    if (accessToken && accessToken.exp * 1000 > Date.now()) {
      setAccessTokenAvailable(true);

      setClient(credential);
      setIsLoggedIn(true);
      logoutTimeout = setTimeout(() => {
        message.error(translate('session_expired'));
        logoutUser();
        localStorage.clear();
      }, accessToken.exp * 1000 - Date.now());
    } else {
      setAccessTokenAvailable(true);
      setIsLoggedIn(false);
      if (accessToken) {
        dispatchClearToken();
      }
      setClient('');
    }
  }, [accessToken]);

  useEffect(() => {
    if (!isLoggedIn) {
      handleAuth();
    }
  }, [isLoggedIn]);

  const handleAuth = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true });

      const userSession = await currentUser.getSignInUserSession();

      const accessToken = userSession.getAccessToken().getJwtToken();
      const refreshToken = userSession.getRefreshToken().getToken();
      const idToken = userSession.getIdToken().getJwtToken();

      setClient(accessToken);
      dispatchSetToken({
        accessToken: accessToken,
        idToken: idToken,
        refreshToken: refreshToken,
        credential: accessToken
      });
    } catch (err: any) {
      // throw error
    }
  };

  useEffect(() => {
    const routeToReplace = new URLSearchParams(location.search).get('redirect_uri');
    if (routeToReplace) {
      history.replace(routeToReplace);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {shouldShowHeader(location.pathname) && <CustomHeader />}
      {accessTokenAvailable && (
        <CustomLayout height={containerHeight}>
          <StyledLayoutContent headerPadding={shouldShowHeader(location.pathname)} height={containerHeight}>
            <For
              ParentComponent={(props) => <Switch {...props} />}
              of={map(Object.keys(routeConfig))}
              renderItem={(routeKey, index) => {
                const Component = routeConfig[routeKey].component;

                return (
                  <ProtectedRoute
                    isLoggedIn={isLoggedIn}
                    exact={routeConfig[routeKey].exact!}
                    key={index}
                    path={routeConfig[routeKey].route!}
                    render={(props) => {
                      const updatedProps = {
                        ...props,
                        ...routeConfig[routeKey].props
                      };
                      return <Component {...updatedProps} />;
                    }}
                  />
                );
              }}
            />
            <GlobalStyle />
          </StyledLayoutContent>
        </CustomLayout>
      )}
    </ThemeProvider>
  );
}

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchClearToken: () => dispatch(failureGetJWTToken()),
    logoutUser: () => dispatch(logoutAction()),
    dispatchSetToken: (data: any) => dispatch(successSetJWTToken(data))
  };
}

export const mapStateToProps = createStructuredSelector({
  accessToken: selectAccessToken(),
  credential: selectCredential()
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withRouter, withConnect, injectSaga({ key: 'app', saga: RootSaga }))(App) as React.FC;

export const AppTest = App;
