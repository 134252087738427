import { BUYER } from '@app/utils/constants';
import { getMutationResponse, getQueryResponse } from '@app/utils/graphqlUtils';
import { AnyAction } from '@reduxjs/toolkit';
import { get } from 'lodash-es';

import { put, takeLatest, call, all } from 'redux-saga/effects';
import { GET_NOTIFICATIONS, READ_NOTIFICATION } from './queries';
import {
  requestGetInboxNotifications,
  successGetInboxNotifications,
  failureGetInboxNotifications,
  successGetNotifications,
  failureGetNotifications,
  requestGetNotifications,
  successReadNotification,
  failureReadNotification,
  requestReadNotification
} from './reducer';

export function* getInboxNotifications(action: AnyAction): Generator<any, any, any> {
  try {
    const payload: any = {
      input: {
        type: 'INBOX',
        userId: action.payload.userId,
        userType: BUYER
      },
      pagination: {
        limit: action.payload.limit,
        page: action.payload.page
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_NOTIFICATIONS, payload);

    if (ok) {
      const notificationsResponse = get(data, 'notifications', {});

      const notifications = get(notificationsResponse, 'notifications', []);
      const total = get(notificationsResponse, 'total', 0);

      yield put(successGetInboxNotifications({ data: notifications, total: total }));
    } else {
      yield put(failureGetInboxNotifications(error));
    }
  } catch (err) {
    yield put(failureGetInboxNotifications(err));
  }
}

export function* getNotifications(action: AnyAction): Generator<any, any, any> {
  try {
    const payload: any = {
      input: {
        type: 'NOTIFICATION',
        userId: action.payload.userId,
        userType: BUYER
      },
      pagination: {
        limit: action.payload.limit,
        page: action.payload.page
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_NOTIFICATIONS, payload);

    if (ok) {
      const notificationsResponse = get(data, 'notifications', {});

      const notifications = get(notificationsResponse, 'notifications', []);
      const total = get(notificationsResponse, 'total', 0);

      yield put(successGetNotifications({ data: notifications, total: total }));
    } else {
      yield put(failureGetNotifications(error));
    }
  } catch (err) {
    yield put(failureGetNotifications(err));
  }
}

export function* readNotification(action: AnyAction): Generator<any, any, any> {
  try {
    const payload: any = {
      input: {
        notificationId: action.payload.notificationId,
        userId: action.payload.userId,
        userType: BUYER
      }
    };

    const { ok, error } = yield call(getMutationResponse, READ_NOTIFICATION, payload);

    if (ok) {
      yield put(successReadNotification());
    } else {
      yield put(failureReadNotification(error));
    }
  } catch (err) {
    yield put(failureReadNotification(err));
  }
}

// Individual exports for testing
export default function* myNotificationsContainerSaga() {
  yield all([
    takeLatest(requestGetInboxNotifications.toString(), getInboxNotifications),
    takeLatest(requestGetNotifications.toString(), getNotifications),
    takeLatest(requestReadNotification.toString(), readNotification)
  ]);
}

export const individualMyNotificationsSagaArr = [
  takeLatest(requestGetInboxNotifications.toString(), getInboxNotifications),
  takeLatest(requestGetNotifications.toString(), getNotifications),
  takeLatest(requestReadNotification.toString(), readNotification)
];
