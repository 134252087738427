import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import { prepare } from '@app/utils';

export const initialState = {
  makeOfferLoading: false,
  counterOfferLoading: false,
  counterOfferData: '',
  counterOfferError: null,
  getStoryLoading: false,
  makeOfferData: '',
  makeOfferError: null,
  storyData: null,
  storyError: null,
  getScreenPlaySignedUrlLoading: false,
  getNarrationSignedUrlLoading: false,
  getImagesSignedUrlLoading: false,
  screenPlayUrl: '',
  imagesUrl: [],
  narrationUrl: '',
  screenPlayUrlError: null,
  narrationUrlError: null,
  imageUrlError: null,
  profileUrlLoading: false,
  profileUrl: '',
  profileUrlError: null,
  viewTimeLoading: false,
  pollId: 0,
  pollError: null,
  reviewSubmitLoading: false,
  reviewSubmitData: null,
  reviewSubmitError: null,
  storyShareLoading: false,
  storyShareData: null,
  storyShareError: null,
  concludeOfferLoading: false,
  concludeOfferError: null,
  sellerCounterOfferLoading: false,
  sellerCounterOffer: null,
  sellerCounterOfferError: null,
  offerIdLoading: false,
  offerId: '',
  offerIdError: null,
  offerState: '',
  buyerLatestOfferLoading: false,
  buyerLatestOfferAmount: 0,
  buyerLatestOfferError: null,
  concludeOfferState: '',
  sendMessageLoading: false,
  sendMessageError: null
};

const storyDetailsSlice = createSlice({
  name: 'storiesDetails',
  initialState,
  reducers: {
    requestMakeOffer: {
      reducer: (state) => {
        state.makeOfferLoading = true;
      },
      prepare
    },
    requestMakeCounterOffer: {
      reducer: (state) => {
        state.counterOfferLoading = true;
      },
      prepare
    },
    requestGetStory: {
      reducer: (state) => {
        state.getStoryLoading = true;
      },
      prepare
    },
    requestGetScreenPlay: {
      reducer: (state) => {
        state.getScreenPlaySignedUrlLoading = true;
      },
      prepare
    },
    requestGetNarration: {
      reducer: (state) => {
        state.getNarrationSignedUrlLoading = true;
      },
      prepare
    },
    requestGetImages: {
      reducer: (state) => {
        state.getImagesSignedUrlLoading = true;
      },
      prepare
    },
    requestGetProfileUrl: {
      reducer: (state) => {
        state.profileUrlLoading = true;
      },
      prepare
    },
    requestUpdateViewTime: {
      reducer: (state) => {
        state.viewTimeLoading = true;
      },
      prepare
    },
    requestSubmitReview: {
      reducer: (state) => {
        state.reviewSubmitLoading = true;
      },
      prepare
    },
    requestShareStory: {
      reducer: (state) => {
        state.storyShareLoading = true;
      },
      prepare
    },
    requestConcludeOffer: {
      reducer: (state) => {
        state.concludeOfferLoading = true;
      },
      prepare
    },
    requestSellerCounterOffer: {
      reducer: (state) => {
        state.sellerCounterOfferLoading = true;
      },
      prepare
    },
    requestGetOfferId: {
      reducer: (state) => {
        state.offerIdLoading = true;
      },
      prepare
    },
    requestGetBuyerLatestOffer: {
      reducer: (state) => {
        state.buyerLatestOfferLoading = true;
      },
      prepare
    },
    requestSendMessage: {
      reducer: (state) => {
        state.sendMessageLoading = true;
      },
      prepare
    },
    successMakeOffer(state, action) {
      state.makeOfferLoading = false;
      state.makeOfferData = get(action.payload, 'placeOfferOnStory', '');
    },
    failureMakeOffer(state, action) {
      state.makeOfferLoading = false;
      state.makeOfferError = get(action.payload, 'message', 'something went wrong');
      state.makeOfferData = '';
    },
    successMakeCounterOffer(state, action) {
      state.counterOfferLoading = false;
      state.counterOfferData = get(action.payload, 'placeOfferOnStory', '');
    },
    failureMakeCounterOffer(state, action) {
      state.counterOfferLoading = false;
      state.counterOfferError = get(action.payload, 'message', 'something went wrong');
    },
    successGetStory(state, action) {
      state.getStoryLoading = false;
      state.storyData = action.payload;
    },
    failureGetStory(state, action) {
      state.getStoryLoading = false;
      state.storyError = get(action.payload, 'message', 'something went wrong');
      state.storyData = null;
    },
    successGetScreenplayUrl(state, action) {
      state.getScreenPlaySignedUrlLoading = false;
      state.screenPlayUrl = action.payload;
    },
    failureGetScreenPlayurl(state, action) {
      state.getScreenPlaySignedUrlLoading = false;
      state.screenPlayUrlError = get(action.payload, 'message', 'something went wrong');
    },
    successGetNarrationUrl(state, action) {
      state.getNarrationSignedUrlLoading = false;
      state.narrationUrl = action.payload;
    },
    failureGetNarrationUrl(state, action) {
      state.getNarrationSignedUrlLoading = false;
      state.narrationUrlError = get(action.payload, 'message', 'something went wrong');
    },
    successGetImageUrls(state, action) {
      state.getImagesSignedUrlLoading = false;
      state.imagesUrl = action.payload;
    },
    failureGetImageUrls(state, action) {
      state.getImagesSignedUrlLoading = false;
      state.imageUrlError = get(action.payload, 'message', 'something went wrong');
    },
    successGetProfileUrl(state, action) {
      state.profileUrlLoading = false;
      state.profileUrl = action.payload;
    },
    failureGetProfileUrl(state, action) {
      state.profileUrlLoading = false;
      state.profileUrlError = get(action.payload, 'message', 'something went wrong');
    },
    successGetViewTime(state, action) {
      state.viewTimeLoading = false;
      state.pollId = action.payload;
    },
    failureGetViewTime(state, action) {
      state.viewTimeLoading = false;
      state.pollError = get(action.payload, 'message', 'something went wrong');
    },
    successGetSubmitReview(state, action) {
      state.reviewSubmitLoading = false;
      state.reviewSubmitData = action.payload;
    },
    failureGetSubmitReview(state, action) {
      state.reviewSubmitLoading = false;
      state.reviewSubmitError = get(action.payload, 'message', 'something went wrong');
    },
    successShareStory(state, action) {
      state.storyShareLoading = false;
      state.storyShareData = action.payload;
    },
    failureShareStory(state, action) {
      state.storyShareLoading = false;
      state.storyShareError = get(action.payload, 'message', 'something went wrong');
    },
    successConcludeOffer(state, action) {
      state.concludeOfferLoading = false;
      state.concludeOfferState = action.payload;
    },
    failureConcludeOffer(state, action) {
      state.concludeOfferLoading = false;
      state.concludeOfferError = get(action.payload, 'message', 'something went wrong');
    },
    successGetSellerCounterOffer(state, action) {
      state.sellerCounterOfferLoading = false;
      state.sellerCounterOffer = action.payload;
    },
    failureGetSellerCounterOffer(state, action) {
      state.sellerCounterOfferLoading = false;
      state.sellerCounterOfferError = get(action.payload, 'message', 'something went wrong');
    },
    successGetOfferId(state, action) {
      state.offerIdLoading = false;
      state.offerId = action.payload;
    },
    successGetOfferState(state, action) {
      state.offerIdLoading = false;
      state.offerState = action.payload;
    },
    failureGetOfferId(state, action) {
      state.offerIdLoading = false;
      state.offerIdError = get(action.payload, 'message', 'something went wrong');
    },
    successLatestBuyerOffer(state, action) {
      state.buyerLatestOfferLoading = false;
      state.buyerLatestOfferAmount = action.payload;
    },
    failureLatestBuyerOffer(state, action) {
      state.buyerLatestOfferLoading = false;
      state.buyerLatestOfferError = get(action.payload, 'message', 'something went wrong');
    },
    successSendMessage(state) {
      state.sendMessageLoading = false;
    },
    failureSendMessage(state, action) {
      state.sendMessageLoading = false;
      state.sendMessageError = get(action.payload, 'message', 'something went wrong');
    },

    resetState(state) {
      state.makeOfferLoading = false;
      state.getStoryLoading = false;
      state.makeOfferData = '';
      state.makeOfferError = null;
      state.storyData = null;
      state.storyError = null;
      state.getScreenPlaySignedUrlLoading = false;
      state.screenPlayUrl = '';
      state.screenPlayUrlError = null;
      state.narrationUrl = '';
      state.getNarrationSignedUrlLoading = false;
      state.narrationUrlError = null;
      state.getImagesSignedUrlLoading = false;
      state.imagesUrl = [];
      state.imageUrlError = null;
      state.profileUrlLoading = false;
      state.profileUrl = '';
      state.profileUrlError = null;
      state.viewTimeLoading = false;
      state.pollId = 0;
      state.pollError = null;
      state.reviewSubmitLoading = false;
      state.reviewSubmitData = null;
      state.reviewSubmitError = null;
      state.storyShareLoading = false;
      state.concludeOfferError = null;
      state.concludeOfferLoading = false;
      state.sellerCounterOffer = null;
      state.sellerCounterOfferLoading = false;
      state.sellerCounterOfferError = null;
      state.offerId = '';
      state.offerIdError = null;
      state.offerIdLoading = false;
      state.offerState = '';
      state.buyerLatestOfferAmount = 0;
      state.buyerLatestOfferError = null;
      state.buyerLatestOfferLoading = false;
      state.sendMessageLoading = false;
      state.sendMessageError = null;
    }
  }
});

export const {
  requestMakeOffer,
  successMakeOffer,
  failureMakeOffer,
  requestGetStory,
  successGetStory,
  failureGetStory,
  requestGetScreenPlay,
  successGetScreenplayUrl,
  failureGetScreenPlayurl,
  resetState,
  successGetNarrationUrl,
  failureGetNarrationUrl,
  requestGetNarration,
  requestGetImages,
  successGetImageUrls,
  failureGetImageUrls,
  requestGetProfileUrl,
  successGetProfileUrl,
  failureGetProfileUrl,
  requestUpdateViewTime,
  successGetViewTime,
  failureGetViewTime,
  requestSubmitReview,
  failureGetSubmitReview,
  successGetSubmitReview,
  requestShareStory,
  successShareStory,
  failureShareStory,
  requestConcludeOffer,
  failureConcludeOffer,
  requestSellerCounterOffer,
  successGetSellerCounterOffer,
  failureGetSellerCounterOffer,
  successGetOfferId,
  failureGetOfferId,
  requestGetOfferId,
  requestMakeCounterOffer,
  successMakeCounterOffer,
  failureMakeCounterOffer,
  successGetOfferState,
  requestGetBuyerLatestOffer,
  successLatestBuyerOffer,
  failureLatestBuyerOffer,
  successConcludeOffer,
  requestSendMessage,
  successSendMessage,
  failureSendMessage
} = storyDetailsSlice.actions;

export default storyDetailsSlice.reducer;
