import { createSelector } from 'reselect';
import get from 'lodash-es/get';
import { initialState } from './reducer';

export const selectCreateProfileDomain = (state: any) => state.profileReducer || initialState;

export const selectUserProfile = () =>
  createSelector(selectCreateProfileDomain, (substate) => get(substate, 'userProfile'));

export const selectGetUserProfileLoading = () =>
  createSelector(selectCreateProfileDomain, (substate) => get(substate, 'getProfileLoading'));

export const selectCreateUserProfileLoading = () =>
  createSelector(selectCreateProfileDomain, (substate) => get(substate, 'createProfileLoading'));

export const selectCurrentSelectedPackage = () =>
  createSelector(selectCreateProfileDomain, (substate) => get(substate, 'selectedPackage'));

export const selectPaymentError = () =>
  createSelector(selectCreateProfileDomain, (substate) => get(substate, 'paymentError'));

export const selectVerifyOtpSuccess = () =>
  createSelector(selectCreateProfileDomain, (substate) => get(substate, 'otpVerifySuccess'));

export const selectVerifuOtpError = () =>
  createSelector(selectCreateProfileDomain, (substate) => get(substate, 'otpVerifyError'));

export const selectProfileFilePath = () =>
  createSelector(selectCreateProfileDomain, (substate) => get(substate, 'fileName'));

export const selectProfileUrl = () =>
  createSelector(selectCreateProfileDomain, (substate) => get(substate, 'profileUrl'));

export const selectAccountType = () =>
  createSelector(selectCreateProfileDomain, (substate) => get(substate, 'accountType'));

export const selectBannerFilePath = () =>
  createSelector(selectCreateProfileDomain, (substate) => get(substate, 'bannerFileName'));

export const selectBannerUrl = () =>
  createSelector(selectCreateProfileDomain, (substate) => get(substate, 'bannerUrl'));

export const selectPaymentInitiated = () =>
  createSelector(selectCreateProfileDomain, (substate) => get(substate, 'paymentInitiated'));

export const selectSubmitProfileLoading = () =>
  createSelector(selectCreateProfileDomain, (substate) => get(substate, 'submitProfileLoading'));

export const selectCreateProfileError = () =>
  createSelector(selectCreateProfileDomain, (substate) => get(substate, 'createProfileError'));
