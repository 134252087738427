import { colors, fonts } from '@app/themes';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DownloadIcon from '@images/icons/browse-stories/download-icon.svg';
import { translate } from '@app/components/IntlGlobalProvider';

import EyeIcon from '@images/icons/browse-stories/eye-icon.svg';
import StarIcon from '@images/icons/browse-stories/star-icon.svg';
import TicketIcon from '@images/icons/browse-stories/ticket-icon.svg';
import ProfileIcon from '@images/icons/browse-stories/profile-pic-icon.svg';
import FavouriteIcon from '@images/icons/browse-stories/favourites-icon.svg';
import CoinStack from '@images/icons/coin-stack-icon.svg';
import SelectedFavIcon from '@images/icons/browse-stories/fav-heart-icon.svg';

import _ from 'lodash';
import { StoryCardInfoProps, StoryCardProps } from './types';
import { Button, Spin } from 'antd';
import { If } from 'tsw-sdk';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import history from '@app/utils/history';
import { PROFILE_IMAGE_PROD_URL, PROFILE_IMAGE_QA_URL, PROFILE_IMAGE_URL } from '@app/utils/constants';

const CardContainer = styled.div`
  width: 19.12rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 2rem;
  background: ${colors.white};
  border-radius: 1rem;
  box-shadow: 0px 0px 1.25rem ${colors.myDashboardColors.cardShadow};
  cursor: pointer;
`;

const StyledHeader = styled.div`
  width: 100%;
  height: 5.25rem;
  background: ${colors.browseStoriesColors.cardHeaderColor};
  border-radius: 1rem 1rem 0px 0px;
  position: relative;
  overflow: hidden;
`;

const BannerContainer = styled.div`
  width: 200%;
  height: 100%;
  top: -1.31rem;
  left: -2px;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.5rem;
`;

const BannerInfoContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0.93rem;
  width: 100%;
  align-items: flex-start;
  margin-left: 1.08rem;
`;

const AuthorInfoContainer = styled.div`
  width: 10.5rem;
  height: 2.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.25rem 0.75rem;
  gap: 0.62rem;
  background: ${colors.browseStoriesColors.authorInfoBg};
  border-radius: 0.75rem;
  margin-left: 0.37rem;
`;

const BannerText = styled.h1`
  ${fonts.size.BannerText()};
  ${fonts.weights.fw900()};
  line-height: 2.62rem;
  text-align: center;
  color: ${colors.browseStoriesColors.bannerTextColor};
  margin-bottom: 0px;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 0.68rem;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

const TitleText = styled.h1`
  max-width: 15rem;
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw900()};
  ${fonts.size.xBig()};
  line-height: 1.87rem;
  text-align: center;
  color: ${colors.darkBorder};
  margin-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DateText = styled.p`
  ${fonts.weights.fw700()};
  ${fonts.size.xSmall()};
  line-height: 1rem;
  text-align: center;
  color: ${colors.browseStoriesColors.subTextColor};
  margin-top: 1rem;
`;

const AuthorNameText = styled.p`
  ${fonts.weights.fw700()};
  ${fonts.size.xSmall()};
  line-height: 1rem;
  text-align: center;
  color: ${colors.browseStoriesColors.subTextColor};
  white-space: wrap;
`;

const InlineItems = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 1rem;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
`;

const TableHeadtext = styled.p`
  ${fonts.weights.fw700()};
  ${fonts.size.xxSmall()};
  line-height: 0.75rem;
  color: ${colors.browseStoriesColors.subTextColor};
  text-align: left;
`;

const TableDataText = styled.p`
  ${fonts.weights.fw600()};
  ${fonts.size.xSmall()};
  line-height: 0.87rem;
  color: ${colors.darkBorder};
`;

const LoglineText = styled.p`
  width: 15.9rem;
  height: 4rem;
  ${fonts.weights.fw400()};
  ${fonts.size.xSmall()};
  line-height: 0.94rem;
  color: ${colors.browseStoriesColors.loglineTextColor};
  margin-top: 1rem;
`;

const InfoContainer = styled.div<StoryCardInfoProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  ${(props) => (props.canMakeOffer ? 'margin-top:0.75rem' : 'margin-top: 3.5rem')};
`;

const InfoSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
`;

const InfoText = styled.p`
  ${fonts.weights.fw700()};
  ${fonts.size.xSmall()};
  line-height: 1.25rem;
  text-align: center;
  color: ${colors.darkBorder};
`;

const StyledImg = styled.img``;

const ProfileImage = styled.img`
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
`;

const FavouriteImg = styled.img`
  cursor: pointer;
  margin-left: 3.37rem;
`;

const DownloadImg = styled.img`
  cursor: pointer;
`;

export const StyledOfferButton = styled(Button)`
  && {
    height: 1.75rem;
    width: 10.31rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background-color: ${colors.darkBorder};
    color: ${colors.white};
    border-radius: 0.5rem;
    ${fonts.weights.fw800()};
    ${fonts.size.small()};
    line-height: 1rem;
    border: none;
    padding: 0.37rem 1.25rem;
    margin-top: 1rem;
  }
`;

const StyledSubContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const StoryCard = ({
  canMakeOffer,
  isFavourite,
  storyData = [],
  downloadLoading,
  isOfferCountered,
  isOfferMade,
  offerId,
  toggleFavourite,
  handleDownload
}: StoryCardProps) => {
  const [dateCreatedAt, setdateCreatedAt] = useState('');
  const [favourite, setFavourite] = useState<boolean>(isFavourite);
  const [writerId, setWriterId] = useState<string>('');

  const SpinIcon = <LoadingOutlined style={{ fontSize: 12 }} spin />;

  useEffect(() => {
    const date = moment(storyData.createdAt).zone('GMT+0530').format('DD.MM.YYYY | HH:mm');
    setdateCreatedAt(date);

    setWriterId(storyData?.writer?.id);
  }, [storyData]);

  const handleToggle = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e.stopPropagation();
    toggleFavourite(storyData.id);
    setFavourite((prev) => !prev);
  };

  const handleDownloadClick = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e.stopPropagation();
    handleDownload(storyData?.id, writerId);
  };

  const viewOfferHandler = (e: any) => {
    e.stopPropagation();
    history.push({
      pathname: '/dashboard',
      search: `?activeTab=offer-history&storyId=${storyData?.id}&offerId=${offerId}&authorId=${storyData?.writer?.id}`
    });
  };

  return (
    <CardContainer
      onClick={() =>
        history.push({
          pathname: '/dashboard',
          search: `?activeTab=story_details&storyId=${storyData.id}`,
          state: { isOfferCountered: isOfferCountered }
        })
      }
    >
      <StyledHeader>
        <BannerContainer>
          {_.range(30).map((number: number) => (
            <BannerText key={number}>{storyData?.title}</BannerText>
          ))}
        </BannerContainer>
        <BannerInfoContainer>
          <AuthorInfoContainer>
            <If condition={process.env.ENVIRONMENT_NAME === 'qa'}>
              <ProfileImage
                src={
                  storyData?.writer?.profilePic
                    ? `${PROFILE_IMAGE_QA_URL}/${storyData?.writer?.profilePic}`
                    : ProfileIcon
                }
              />
            </If>
            <If condition={process.env.ENVIRONMENT_NAME === 'production'}>
              <ProfileImage
                src={
                  storyData?.writer?.profilePic
                    ? `${PROFILE_IMAGE_PROD_URL}/${storyData?.writer?.profilePic}`
                    : ProfileIcon
                }
              />
            </If>
            <If condition={process.env.ENVIRONMENT_NAME !== 'production' && process.env.ENVIRONMENT_NAME !== 'qa'}>
              <ProfileImage
                src={
                  storyData?.writer?.profilePic ? `${PROFILE_IMAGE_URL}/${storyData?.writer?.profilePic}` : ProfileIcon
                }
              />
            </If>

            <AuthorNameText>{`by ${storyData?.writer?.firstName} ${storyData?.writer?.lastName}`}</AuthorNameText>
          </AuthorInfoContainer>
          <FavouriteImg
            src={favourite ? SelectedFavIcon : FavouriteIcon}
            onClick={(e) => handleToggle(e)}
            data-testid="fav-icon"
          />
        </BannerInfoContainer>
      </StyledHeader>
      <TitleContainer>
        <TitleText>{storyData?.title}</TitleText>
        <If condition={!downloadLoading} otherwise={<Spin indicator={SpinIcon} />}>
          <DownloadImg src={DownloadIcon} onClick={(e) => handleDownloadClick(e)} data-testid="download-icon" />
        </If>
      </TitleContainer>

      <StyledSubContainer>
        <DateText>{dateCreatedAt}</DateText>
        <InlineItems>
          <ListContainer>
            <TableHeadtext>{translate('genre_all_caps')}</TableHeadtext>
            <TableDataText>{storyData?.genres?.length && storyData.genres[0].name}</TableDataText>
          </ListContainer>
          <ListContainer>
            <TableHeadtext>{translate('language_all_caps')}</TableHeadtext>
            <TableDataText>{storyData?.languages?.length && storyData.languages[0].name}</TableDataText>
          </ListContainer>
          <ListContainer>
            <TableHeadtext>{translate('story_type_all_caps')}</TableHeadtext>
            <TableDataText>{storyData?.storyType?.name}</TableDataText>
          </ListContainer>
        </InlineItems>
        <LoglineText>
          {storyData?.logline?.length > 100 ? storyData?.logline?.substring(0, 100) + '...' : storyData?.logline}
        </LoglineText>
        <If condition={canMakeOffer}>
          <StyledOfferButton data-testid="offer-btn">
            <StyledImg src={CoinStack} />
            {translate('make_Offer')}
          </StyledOfferButton>
        </If>

        <If condition={isOfferMade}>
          <StyledOfferButton data-testid="offer-made-btn" onClick={(e: any) => viewOfferHandler(e)}>
            <StyledImg src={CoinStack} />
            {translate('view_offer')}
          </StyledOfferButton>
        </If>

        <InfoContainer canMakeOffer={canMakeOffer}>
          <If condition={storyData?.views}>
            <InfoSubContainer>
              <StyledImg src={EyeIcon} />
              <InfoText>{storyData?.views}</InfoText>
            </InfoSubContainer>
          </If>
          <If condition={storyData?.ratings}>
            <InfoSubContainer>
              <StyledImg src={StarIcon} />
              <InfoText>{storyData?.ratings}</InfoText>
            </InfoSubContainer>
          </If>
          <If condition={storyData?.offers}>
            <InfoSubContainer>
              <StyledImg src={TicketIcon} />
              <InfoText>{storyData?.offers}</InfoText>
            </InfoSubContainer>
          </If>
        </InfoContainer>
      </StyledSubContainer>
    </CardContainer>
  );
};

export default StoryCard;
