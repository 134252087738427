import React, { useState } from 'react';
import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import { Button, Form } from 'antd';
import styled from 'styled-components';
import TextArea from 'antd/lib/input/TextArea';
import { RetractOfferModalProps } from '../../types';
import { ArrowRightOutlined } from '@ant-design/icons';

const RetractOfferModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 6.5rem;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${colors.white};
`;

const RetractOfferTitle = styled.p`
  width: 45.8rem;
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  color: ${colors.primary};
  text-align: center;
  line-height: 3.93rem;
  margin-left: auto;
  margin-right: auto;
`;

const RetractOfferForm = styled(Form)`
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;

  && {
    width: 16rem;

    & > div:nth-of-type(3) {
      margin-top: 3rem;
    }
  }
`;

const RetractOfferFormItem = styled(Form.Item)`
  && {
    margin-bottom: 0px;

    label {
      ${fonts.weights.fw600()};
      ${fonts.size.regular()};
      line-height: 1.25rem;
      color: ${colors.browseStoriesColors.labelColor};
    }

    input {
      background-color: ${colors.white};
      border-radius: 0.31rem;
    }
  }
`;

const FormActionsContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 16.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CancelBtn = styled(Button)`
  width: 7.81rem;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0.75rem 2rem 0.75rem 2rem;
  display: flex;
  align-items: center;
  background-color: ${colors.myOffers.grayBtnBg};
  color: ${colors.myOffers.grayBtnColor};
  cursor: pointer;
  ${fonts.weights.fw800()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
  border: none;

  &:hover {
    background-color: ${colors.myOffers.grayBtnBg};
    color: ${colors.myOffers.grayBtnColor};
    border: none;
  }
`;

export const RetractOfferBtn = styled(Button)`
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0.75rem 2rem 0.75rem 2rem;
  display: flex;
  align-items: center;
  background-color: ${colors.primary};
  color: ${colors.white};
  margin-left: 2rem;
  cursor: pointer;
  ${fonts.weights.fw800()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
  border: none;

  &:hover {
    background-color: ${colors.primary};
    color: ${colors.white};
    border: none;
  }

  &:focus {
    background-color: ${colors.primary};
    color: ${colors.white};
    border: none;
  }
`;

const StyledTextArea = styled(TextArea)`
  &&& {
    min-height: 1.93rem;
    > * {
      background-color: ${colors.white} !important;
      border: 0.5px solid ${colors.lightBorder};
      border-radius: 0.31rem;
      margin-top: 0.5rem;

      &.ant-input {
        line-height: 0.94rem;
        min-height: 1.93rem;
      }

      &.ant-input:placeholder-shown {
        color: ${colors.lightBorder};
        font-size: 0.75rem;
        ${fonts.weights.fw400};
        line-height: 0.94rem;
      }

      &.textarea.ant-input {
        min-height: 1.93rem;
        background-color: ${colors.white};
      }
    }
    &.ant-input-textarea-show-count::after {
      margin-top: 0.25rem;
      float: left;
      ${fonts.size.extraSmall()};
      line-height: 0.62rem;
      ${fonts.weights.fw400};
      content: ' ' attr(data-count) ' characters ';
      color: ${colors.lightBorder};
    }
  }
`;

const RetractOfferModal = ({ handleCancel, handleRetract }: RetractOfferModalProps) => {
  const [retractReason, setRetractReason] = useState<string>('');

  return (
    <RetractOfferModalContent>
      <RetractOfferTitle>{translate('retract')}</RetractOfferTitle>
      <RetractOfferForm layout="vertical">
        <RetractOfferFormItem name={'RetractReason'} label={translate('please_tell')}>
          <StyledTextArea
            rows={1}
            autoSize
            showCount
            maxLength={250}
            data-testid="offer-notes-input"
            value={retractReason}
            onChange={(e) => setRetractReason(e.target.value)}
          />
        </RetractOfferFormItem>
      </RetractOfferForm>
      <FormActionsContainer>
        <CancelBtn onClick={handleCancel} data-testid="Retract-offer-cancel">
          {translate('cancel')}
        </CancelBtn>
        <RetractOfferBtn onClick={() => handleRetract(retractReason)} data-testid="Retract-offer-button">
          {translate('confirm')} <ArrowRightOutlined />
        </RetractOfferBtn>
      </FormActionsContainer>
    </RetractOfferModalContent>
  );
};

export default RetractOfferModal;
