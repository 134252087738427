import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import { BUYER_RENEWAL_PAYLOAD, COGNITO_USER_GROUPS } from '@app/utils/constants';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { CustomButton, If } from 'tsw-sdk';
import { SubscriptionStatusScreenProps } from '../../types';
import cancelIcon from '@images/icons/cancel-icon-white.svg';

const SubscriptionStatusScreenContainer = styled.div`
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10000;

  & button,
  * img {
    pointer-events: all;
  }
`;

const SubscriptionInActiveScreen = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 2.56rem;
  padding-top: 9.5rem;
  background-color: ${colors.myDashboardColors.subscriptionInActiveColor};
  pointer-events: all;
`;

const SubscriptionTextParagraph = styled.p`
  max-width: 53rem;
  ${fonts.size.large()};
  ${fonts.weights.fw700()};
  line-height: 2.43rem;
  color: ${colors.white};

  &:not(:first-of-type) {
    margin-top: 1.25rem;
  }
`;

const CtaButton = styled(CustomButton)`
  &&& {
    min-width: 16rem;
    background-color: ${colors.white};
    color: ${colors.styledText};
    margin-top: 2.18rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const SubscriptionBanner = styled.div`
  width: 100%;
  height: 3.43rem;
  display: flex;
  align-items: center;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  background-color: ${colors.myDashboardColors.subscriptionInActiveColor};
  border-radius: 1rem;
`;

const SubscriptionCountdownText = styled.p`
  ${fonts.weights.fw700()};
  ${fonts.size.regular()};
  line-height: 2.43rem;
  color: ${colors.white};
`;

const CtaButtonBanner = styled(CustomButton)`
  &&& {
    min-width: 14rem;
    height: 2rem;
    background-color: ${colors.white};
    color: ${colors.styledText};
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
  }
`;

const CloseIcon = styled.img`
  width: 1rem;
  height: 1rem;
  margin-left: 1rem;
  cursor: pointer;
`;

const SubscriptionStatusScreen = ({
  userProfile,
  accountType,
  subscriptionStatus,
  showSubscriptionBanner,
  dispatchSetShowSubscriptionBanner,
  dispatchSendRenewalReminder,
  dispatchRenewalPayment
}: SubscriptionStatusScreenProps) => {
  useEffect(() => {
    const dashboardContainer = document.getElementById('dashboard-container');
    if (!subscriptionStatus?.isActive) {
      dashboardContainer?.addEventListener('mousewheel', (e) => {
        e.preventDefault();
      });

      dashboardContainer?.addEventListener('touchmove', (e) => {
        e.preventDefault();
      });
    } else {
      if (!localStorage.getItem('bannerViewed')) {
        if (subscriptionStatus?.daysLeft <= 15) {
          dispatchSetShowSubscriptionBanner(true);
        }
      }
    }
  }, [subscriptionStatus]);

  const handlePayment = () => {
    if ([COGNITO_USER_GROUPS.INDIVIDUAL].includes(accountType)) {
      dispatchRenewalPayment(
        5000,
        BUYER_RENEWAL_PAYLOAD.BUYER_RENEWAL_REASON,
        BUYER_RENEWAL_PAYLOAD.BUYER_RENEWAL_NOTES
      );
    } else {
      dispatchRenewalPayment(
        10000,
        BUYER_RENEWAL_PAYLOAD.BUYER_RENEWAL_REASON,
        BUYER_RENEWAL_PAYLOAD.BUYER_RENEWAL_NOTES
      );
    }
  };

  const getScreenMessage = () => {
    const adminUserTypes = [COGNITO_USER_GROUPS.ADMIN];
    const individualUserTypes = [COGNITO_USER_GROUPS.INDIVIDUAL];

    if (adminUserTypes.includes(accountType) || individualUserTypes.includes(accountType)) {
      return (
        <>
          <SubscriptionTextParagraph>
            {translate('user_greeting', { firstName: userProfile?.firstName, lastName: userProfile?.lastName })}
          </SubscriptionTextParagraph>
          <SubscriptionTextParagraph>{translate('subscription_inactive_message')}</SubscriptionTextParagraph>
          <SubscriptionTextParagraph>{translate('renew_request_message')}</SubscriptionTextParagraph>
          <CtaButton onClick={handlePayment} data-testid="razorpay-btn-inactive">
            {translate('razorpay')}
          </CtaButton>
        </>
      );
    } else {
      return (
        <>
          <SubscriptionTextParagraph>
            {translate('user_greeting', { firstName: userProfile?.firstName, lastName: userProfile?.lastName })}
          </SubscriptionTextParagraph>
          <SubscriptionTextParagraph>{translate('subscription_inactive_message')}</SubscriptionTextParagraph>
          <SubscriptionTextParagraph>{translate('remind_admin_message')}</SubscriptionTextParagraph>
          <CtaButton onClick={() => dispatchSendRenewalReminder()} data-testid="reminder-btn-inactive">
            {translate('send_reminder_to_admin')}
          </CtaButton>
        </>
      );
    }
  };

  const getSubscriptionBannerMessage = () => {
    const adminUserTypes = [COGNITO_USER_GROUPS.ADMIN];
    const individualUserTypes = [COGNITO_USER_GROUPS.INDIVIDUAL];

    if (adminUserTypes.includes(accountType) || individualUserTypes.includes(accountType)) {
      return (
        <>
          <SubscriptionCountdownText>
            {translate('subscription_banner_message', { days: subscriptionStatus?.daysLeft })}
          </SubscriptionCountdownText>
          <CtaButtonBanner onClick={handlePayment}>{translate('razorpay')}</CtaButtonBanner>
        </>
      );
    } else {
      return (
        <>
          <SubscriptionCountdownText>
            {translate('subscription_banner_message', { days: subscriptionStatus?.daysLeft })}
          </SubscriptionCountdownText>
          <CtaButtonBanner onClick={() => dispatchSendRenewalReminder()}>
            {translate('send_reminder_to_admin')}
          </CtaButtonBanner>
        </>
      );
    }
  };

  return (
    <SubscriptionStatusScreenContainer>
      <If condition={subscriptionStatus?.isActive && subscriptionStatus?.daysLeft <= 15 && showSubscriptionBanner}>
        <SubscriptionBanner>
          {getSubscriptionBannerMessage()}
          <CloseIcon
            src={cancelIcon}
            onClick={() => {
              localStorage.setItem('bannerViewed', 'true');
              dispatchSetShowSubscriptionBanner(false);
            }}
            data-testid="close-banner-icon"
          />
        </SubscriptionBanner>
      </If>

      <If condition={!subscriptionStatus?.isActive}>
        <SubscriptionInActiveScreen>{getScreenMessage()}</SubscriptionInActiveScreen>
      </If>
    </SubscriptionStatusScreenContainer>
  );
};

export default SubscriptionStatusScreen;
