import { gql } from 'apollo-boost';

export const GET_SIGNED_URL = gql`
  query SignedUrl($urlinput: SignedURLInput!) {
    signedURL(input: $urlinput) {
      rootFolder
      fileName
      signedURL
    }
  }
`;
