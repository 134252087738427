import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import { prepare } from '@app/utils';

export const initialState = {
  inbox: [],
  inboxTotal: 0,
  getInboxLoading: false,
  getInboxError: null,
  notifications: [],
  notificationsTotal: 0,
  getNotificationsLoading: false,
  getNotificationsError: null,
  readNotificationLoading: false,
  readNotificationError: null
};

const notificationsSlice = createSlice({
  name: 'adminNotifications',
  initialState,
  reducers: {
    requestGetInboxNotifications: {
      reducer: (state) => {
        state.getInboxLoading = true;
      },
      prepare
    },
    requestGetNotifications: {
      reducer: (state) => {
        state.getNotificationsLoading = true;
      },
      prepare
    },
    requestReadNotification: {
      reducer: (state) => {
        state.readNotificationLoading = true;
      },
      prepare
    },
    successReadNotification(state) {
      state.readNotificationLoading = false;
    },
    failureReadNotification(state, action) {
      state.readNotificationLoading = false;
      state.readNotificationError = get(action.payload, 'message', 'something went wrong');
    },
    successGetNotifications(state, action) {
      state.getNotificationsLoading = false;
      state.notifications = action.payload.data;
      state.notificationsTotal = action.payload.total;
    },
    failureGetNotifications(state, action) {
      state.getNotificationsLoading = false;
      state.getNotificationsError = get(action.payload, 'message', 'something went wrong');
    },
    successGetInboxNotifications(state, action) {
      state.getInboxLoading = false;
      state.inbox = action.payload.data;
      state.inboxTotal = action.payload.total;
    },
    failureGetInboxNotifications(state, action) {
      state.getInboxLoading = false;
      state.getInboxError = get(action.payload, 'message', 'something went wrong');
    },
    resetState(state) {
      state.inbox = [];
      state.inboxTotal = 0;
      state.getInboxLoading = false;
      state.getInboxError = null;
      state.notifications = [];
      state.notificationsTotal = 0;
      state.getNotificationsLoading = false;
      state.getNotificationsError = null;
    }
  }
});

export const {
  requestGetInboxNotifications,
  successGetInboxNotifications,
  failureGetInboxNotifications,
  requestGetNotifications,
  successGetNotifications,
  failureGetNotifications,
  requestReadNotification,
  successReadNotification,
  failureReadNotification,
  resetState
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
