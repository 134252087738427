import routeConstants from './routeConstants';

export const shouldShowHeader = (path: string) => {
  let showHeader = true;

  Object.keys(routeConstants).forEach((route) => {
    if (routeConstants[route].route === path) {
      showHeader = routeConstants[route].showHeader;
    }
  });

  return showHeader;
};

export const dataURLtoFile = (dataurl: string, filename: string) => {
  const arr: any = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const preventInvalidCharsNumberInput = (e: any) => {
  if (e.key === '-' || e.key.toLowerCase() === 'e') {
    e.preventDefault();
  }
};

export const handleScreenScaling = () => {
  const MOUNT_NODE: any = document.getElementById('app');

  const width = window.innerWidth;

  const scaleFactor = getScaleFactor(width);

  MOUNT_NODE.style.transform = `scale(${scaleFactor})`;
  MOUNT_NODE.style.transformOrigin = '0 0';

  const scaleFactorToPercentage = ((10 - scaleFactor * 10) / 10) * 100;

  MOUNT_NODE.style.minWidth = `${
    100 + (scaleFactor !== 1 ? scaleFactorToPercentage + getAdditionalPercentage(scaleFactor) : 0)
  }%`;
  MOUNT_NODE.style.minHeight = `${
    100 + (scaleFactor !== 1 ? scaleFactorToPercentage + getAdditionalPercentage(scaleFactor) : 0)
  }%`;
};

const getAdditionalPercentage = (scaleFactor: number) => {
  let additionalPercentage = 0;

  if (scaleFactor === 1) {
    additionalPercentage = 0;
  } else if (scaleFactor === 0.8) {
    additionalPercentage = 5;
  } else if (scaleFactor === 0.7) {
    additionalPercentage = 12.9;
  } else if (scaleFactor === 0.5) {
    additionalPercentage = 50;
  } else if (scaleFactor === 0.4) {
    additionalPercentage = 100;
  }

  return additionalPercentage;
};

export const getHeightOfContainer = () => {
  const width = window.innerWidth;

  let height = 100;

  if (width >= 1390) {
    height = 100;
  } else if (width >= 992) {
    height = 125;
  } else if (width >= 768) {
    height = 143.3;
  } else if (width >= 600) {
    height = 200;
  } else if (width <= 600) {
    height = 250;
  }

  return height;
};

export const getScaleFactor = (width: number) => {
  let scaleFactor = 1;

  if (width >= 1390) {
    scaleFactor = 1;
  } else if (width >= 992) {
    scaleFactor = 0.8;
  } else if (width >= 768) {
    scaleFactor = 0.7;
  } else if (width >= 600) {
    scaleFactor = 0.5;
  } else if (width <= 600) {
    scaleFactor = 0.4;
  }

  return scaleFactor;
};
