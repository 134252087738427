import { createSelector } from 'reselect';
import get from 'lodash-es/get';
import { initialState } from './reducer';

export const selectAdminDashboardDomain = (state: any) => state.adminDashboardReducer || initialState;

export const selectAddUserToOrganisationLoading = () =>
  createSelector(selectAdminDashboardDomain, (substate) => get(substate, 'addUserToOrganisationLoading'));

export const selectAddedUsers = () =>
  createSelector(selectAdminDashboardDomain, (substate) => get(substate, 'addedUsers'));

export const selectAvailableCapacity = () =>
  createSelector(selectAdminDashboardDomain, (substate) => get(substate, 'availableCapacity'));
