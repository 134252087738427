import { getMutationResponse, getQueryResponse } from '@app/utils/graphqlUtils';
import { GENERATE_STORY_PDF } from '@app/utils/queries';
import { AnyAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { get } from 'lodash-es';

import { put, takeLatest, call, all } from 'redux-saga/effects';
import {
  GET_LANGUAGE_GENRE,
  GET_SAVED_FILTERS,
  GET_STORIES,
  GET_STORY_TYPES,
  GET_TAGS,
  SAVE_FILTERS,
  TOGGLE_FAVOURITE
} from './queries';
import {
  failureGenerateStoryPdf,
  failureGetLangGenre,
  failureGetSavedFilters,
  failureGetStories,
  failureGetStoryTypes,
  failureGetTags,
  failureSaveFilter,
  failureToggleFavourite,
  requestGenerateStoryPdf,
  requestGetSavedFilters,
  requestGetStories,
  requestGetStoryTypes,
  requestGetTags,
  requestLangGenre,
  requestSaveFilter,
  requestToggleFavourite,
  successGenerateStoryPdf,
  successGetSavedFilters,
  successGetStories,
  successGetStoryTypes,
  successGetTags,
  successPutGenreList,
  successPutLanguageList,
  successSaveFilter,
  successToggleFavourite
} from './reducer';
import { OptionsType } from './types';

export function* getLangAndGenre(action: any): Generator<any, any, any> {
  try {
    const payload: any = {
      pagination: {
        limit: action.payload.limit,
        page: action.payload.page
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_LANGUAGE_GENRE, payload);

    if (ok) {
      const genreListData = data.genres.genreData;
      const languageListData = data.languages.languageData;
      let genreList: OptionsType[] = [];
      let languageList: OptionsType[] = [];

      if (genreListData) {
        genreList = genreListData.map((genreData: any) => ({ label: genreData.name, value: genreData.id }));
        yield put(successPutGenreList(genreList));
      }

      if (languageListData) {
        languageList = languageListData.map((languageData: any) => ({
          label: languageData.name,
          value: languageData.id
        }));
        yield put(successPutLanguageList(languageList));
      }
    } else {
      yield put(failureGetLangGenre(error));
    }
  } catch (err) {
    yield put(failureGetLangGenre(err));
  }
}

export function* getTags(action: AnyAction): Generator<any, any, any> {
  try {
    const payload: any = {
      pagination: {
        limit: action.payload.limit,
        page: action.payload.page
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_TAGS, payload);

    if (ok) {
      const tagsData = data.tags.tagsData;
      let tagsDataList = [];
      if (tagsData) {
        tagsDataList = tagsData.map((tag: any) => ({ value: tag.id, label: tag.name }));
      }
      yield put(successGetTags(tagsDataList));
    } else {
      yield put(failureGetTags(error));
    }
  } catch (err) {
    yield put(failureGetLangGenre(err));
  }
}

export function* saveFilter(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      ...action.payload
    };

    const { ok, data, error } = yield call(getMutationResponse, SAVE_FILTERS, payload);

    if (ok) {
      yield put(successSaveFilter(data));
      message.success('Filter Saved');
      yield call(getSavedFilters);
    } else {
      yield put(failureSaveFilter(error));
    }
  } catch (err) {
    yield put(failureSaveFilter(err));
  }
}

export function* getSavedFilters(): Generator<any, any, any> {
  try {
    const { ok, data, error } = yield call(getQueryResponse, GET_SAVED_FILTERS);

    if (ok) {
      const filtersData = data.browseStoriesFilters.filters;

      yield put(successGetSavedFilters(filtersData));
    } else {
      yield put(failureGetSavedFilters(error));
    }
  } catch (err) {
    yield put(failureGetSavedFilters(err));
  }
}

export function* getStories(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      filters: {
        search: action.payload.search || '',
        where: {
          languages: {
            equalTo: action.payload.language || null
          },
          genres: {
            equalTo: action.payload.genre || null
          },
          tags: {
            equalTo: action.payload.tag || null
          },
          storyTypes: {
            equalTo: action.payload.storyType || null
          },
          state: {
            equalTo: action.payload.state
          }
        },
        asBuyer: {
          withID: action.payload.buyerId,
          areFavourite: action.payload.onlyFavourites || false
        }
      },
      pages: {
        limit: action.payload.limit,
        page: action.payload.page
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_STORIES, payload);

    if (ok) {
      const storiesData = data.stories.stories;
      const total = data.stories.total;

      yield put(successGetStories({ data: storiesData, total: total }));
    } else {
      yield put(failureGetStories(error));
    }
  } catch (err) {
    yield put(failureGetStories(err));
  }
}

export function* toggleFavStory(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      input: {
        storyId: action.payload.storyId
      }
    };

    const { ok, data, error } = yield call(getMutationResponse, TOGGLE_FAVOURITE, payload);

    if (ok) {
      if (data.toggleStoryFavourite?.isFavourite) {
        message.success('Story added to favourites');
      } else {
        message.success('Story removed from favourites');
      }
      yield put(successToggleFavourite(get(data, 'toggleStoryFavourite', {})));
    } else {
      yield put(failureToggleFavourite(error));
    }
  } catch (err) {
    yield put(failureToggleFavourite(err));
  }
}

export function* generateStoryPdf(action: any): Generator<any, any, any> {
  try {
    const payload = {
      input: {
        buyerId: action.payload.buyerId,
        storyId: action.payload.storyId,
        sellerId: action.payload.sellerId,
        isBuyer: true
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GENERATE_STORY_PDF, payload);

    if (ok) {
      const pdfUrl = get(data, 'generateStoryPdf', '');

      yield put(successGenerateStoryPdf(pdfUrl));
    } else {
      yield put(failureGenerateStoryPdf(error));
    }
  } catch (err) {
    yield put(failureGenerateStoryPdf(err));
  }
}

export function* getStoryTypes(action: AnyAction): Generator<any, any, any> {
  try {
    const payload: any = {
      pagination: {
        limit: action.payload.limit,
        page: action.payload.page
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_STORY_TYPES, payload);

    if (ok) {
      const storyTypeData = data.storyTypes.storyTypeData;
      let storyTypeDataList = [];
      if (storyTypeData) {
        storyTypeDataList = storyTypeData.map((storyType: any) => ({ value: storyType.id, label: storyType.name }));
      }
      yield put(successGetStoryTypes(storyTypeDataList));
    } else {
      yield put(failureGetStoryTypes(error));
    }
  } catch (err) {
    yield put(failureGetStoryTypes(err));
  }
}

// Individual exports for testing
export default function* browseStoriesContainerSaga() {
  yield all([
    takeLatest(requestLangGenre.toString(), getLangAndGenre),
    takeLatest(requestGetTags.toString(), getTags),
    takeLatest(requestSaveFilter.toString(), saveFilter),
    takeLatest(requestGetSavedFilters.toString(), getSavedFilters),
    takeLatest(requestGetStories.toString(), getStories),
    takeLatest(requestToggleFavourite.toString(), toggleFavStory),
    takeLatest(requestGenerateStoryPdf.toString(), generateStoryPdf),
    takeLatest(requestGetStoryTypes.toString(), getStoryTypes)
  ]);
}

export const browseStoriesSaga = [
  takeLatest(requestLangGenre.toString(), getLangAndGenre),
  takeLatest(requestGetTags.toString(), getTags),
  takeLatest(requestSaveFilter.toString(), saveFilter),
  takeLatest(requestGetSavedFilters.toString(), getSavedFilters),
  takeLatest(requestGetStories.toString(), getStories),
  takeLatest(requestToggleFavourite.toString(), toggleFavStory),
  takeLatest(requestGenerateStoryPdf.toString(), generateStoryPdf),
  takeLatest(requestGetStoryTypes.toString(), getStoryTypes)
];
