import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import { prepare } from '@app/utils';

export const initialState = {
  languageGenreLoading: false,
  loadingTags: false,
  loadingSaveFilter: false,
  loadingSavedFilters: false,
  loadingToggleFavourite: false,
  saveFilterError: null,
  saveFilterState: '',
  languageList: [],
  genreList: [],
  langGenError: null,
  tagsList: [],
  tagsError: null,
  savedFiltersData: [],
  savedFiltersError: null,
  loadingStories: false,
  storiesData: [],
  storiesError: null,
  toggleFavError: null,
  lastToggleState: false,
  storyPdfLoading: false,
  storyPdfUrl: null,
  storyPdfError: null,
  total: 0,
  loadingStoryTypes: false,
  storyTypeList: [],
  storyTypeError: null
};

const browseStoriesSlice = createSlice({
  name: 'browseStories',
  initialState,
  reducers: {
    requestLangGenre: {
      reducer: (state) => {
        state.languageGenreLoading = true;
      },
      prepare
    },
    requestGetTags: {
      reducer: (state) => {
        state.loadingTags = true;
      },
      prepare
    },
    requestSaveFilter: {
      reducer: (state) => {
        state.loadingSaveFilter = true;
      },
      prepare
    },
    requestGetSavedFilters: {
      reducer: (state) => {
        state.loadingSavedFilters = true;
      },
      prepare
    },
    requestGetStories: {
      reducer: (state) => {
        state.loadingStories = true;
      },
      prepare
    },
    requestToggleFavourite: {
      reducer: (state) => {
        state.loadingToggleFavourite = true;
      },
      prepare
    },
    requestGenerateStoryPdf: {
      reducer: (state) => {
        state.storyPdfLoading = true;
      },
      prepare
    },
    requestGetStoryTypes: {
      reducer: (state) => {
        state.loadingStoryTypes = true;
      },
      prepare
    },
    successPutLanguageList(state, action) {
      state.languageGenreLoading = false;
      state.languageList = action.payload;
    },
    successPutGenreList(state, action) {
      state.languageGenreLoading = false;
      state.genreList = action.payload;
    },
    failureGetLangGenre(state, action) {
      state.languageGenreLoading = false;
      state.langGenError = get(action.payload, 'message', 'something went wrong');
      state.languageList = [];
      state.genreList = [];
    },
    successGetTags(state, action) {
      state.loadingTags = false;
      state.tagsList = action.payload;
    },
    failureGetTags(state, action) {
      state.loadingTags = false;
      state.tagsError = get(action.payload, 'message', 'something went wrong');
      state.tagsList = [];
    },
    successSaveFilter(state, action) {
      state.loadingSaveFilter = false;
      state.saveFilterState = action.payload;
      state.saveFilterError = null;
    },
    failureSaveFilter(state, action) {
      state.loadingSaveFilter = false;
      state.saveFilterError = get(action.payload, 'message', 'something went wrong');
    },
    successGetSavedFilters(state, action) {
      state.loadingSavedFilters = false;
      state.savedFiltersData = action.payload;
      state.savedFiltersError = null;
    },
    failureGetSavedFilters(state, action) {
      state.loadingSavedFilters = false;
      state.savedFiltersError = get(action.payload, 'message', 'something went wrong');
    },
    successGetStories(state, action) {
      state.loadingStories = false;
      state.storiesData = action.payload.data;
      state.total = action.payload.total;
      state.storiesError = null;
    },
    failureGetStories(state, action) {
      state.loadingStories = false;
      state.storiesError = get(action.payload, 'message', 'something went wrong');
    },
    successToggleFavourite(state, action) {
      state.loadingToggleFavourite = false;
      state.lastToggleState = action.payload.isFavourite;
    },
    failureToggleFavourite(state, action) {
      state.loadingToggleFavourite = false;
      state.toggleFavError = get(action.payload, 'message', 'something went wrong');
    },
    successGenerateStoryPdf(state, action) {
      state.storyPdfUrl = action.payload;
      state.storyPdfLoading = false;
    },
    failureGenerateStoryPdf(state, action) {
      state.storyPdfLoading = false;
      state.storyPdfError = get(action.payload, 'message', 'something_went_wrong');
    },
    resetStoryPdfData(state) {
      state.storyPdfLoading = false;
      state.storyPdfError = null;
      state.storyPdfUrl = null;
    },
    successGetStoryTypes(state, action) {
      state.loadingStoryTypes = false;
      state.storyTypeList = action.payload;
    },
    failureGetStoryTypes(state, action) {
      state.loadingStoryTypes = false;
      state.storyTypeError = get(action.payload, 'message', 'something went wrong');
      state.storyTypeList = [];
    },
    resetState(state) {
      state.languageGenreLoading = false;
      state.loadingTags = false;
      state.loadingSaveFilter = false;
      state.loadingSavedFilters = false;
      state.loadingToggleFavourite = false;
      state.saveFilterError = null;
      state.saveFilterState = '';
      state.languageList = [];
      state.genreList = [];
      state.langGenError = null;
      state.tagsList = [];
      state.tagsError = null;
      state.savedFiltersData = [];
      state.savedFiltersError = null;
      state.loadingStories = false;
      state.storiesData = [];
      state.storiesError = null;
      state.toggleFavError = null;
      state.lastToggleState = false;
      state.storyPdfUrl = null;
      state.storyPdfError = null;
      state.storyPdfLoading = false;
      state.storyTypeList = [];
      state.storyTypeError = null;
      state.loadingStoryTypes = false;
    }
  }
});

export const {
  requestLangGenre,
  successPutLanguageList,
  successPutGenreList,
  failureGetLangGenre,
  requestGetStoryTypes,
  requestGetTags,
  successGetTags,
  failureGetTags,
  requestSaveFilter,
  successSaveFilter,
  failureSaveFilter,
  requestGetSavedFilters,
  successGetSavedFilters,
  failureGetSavedFilters,
  requestGetStories,
  successGetStories,
  failureGetStories,
  requestToggleFavourite,
  successToggleFavourite,
  failureToggleFavourite,
  requestGenerateStoryPdf,
  successGenerateStoryPdf,
  failureGenerateStoryPdf,
  resetState,
  resetStoryPdfData,
  successGetStoryTypes,
  failureGetStoryTypes
} = browseStoriesSlice.actions;

export default browseStoriesSlice.reducer;
