import React, { useState } from 'react';
import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import { Button, Form, message } from 'antd';
import styled from 'styled-components';
import { DeclineOfferModalProps } from './types';
import declineOfferWhiteIcon from '@images/icons/my-offers/decline-offer-white.svg';
import TextArea from 'antd/lib/input/TextArea';

const DeclineOfferModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 6.5rem;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${colors.white};
`;

const DeclineOfferTitle = styled.p`
  width: 45.8rem;
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  color: ${colors.primary};
  text-align: center;
  line-height: 3.93rem;
  margin-left: auto;
  margin-right: auto;
`;

const DeclineOfferForm = styled(Form)`
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;

  && {
    width: 16rem;

    & > div:nth-of-type(3) {
      margin-top: 3rem;
    }
  }
`;

const DeclineOfferFormItem = styled(Form.Item)`
  && {
    margin-bottom: 0px;

    label {
      ${fonts.weights.fw600()};
      ${fonts.size.regular()};
      line-height: 1.25rem;
      color: ${colors.browseStoriesColors.labelColor};
    }

    input {
      background-color: ${colors.white};
      border-radius: 0.31rem;
    }
  }
`;

const FormActionsContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 16.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CancelBtn = styled(Button)`
  width: 7.81rem;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0.75rem 2rem 0.75rem 2rem;
  display: flex;
  align-items: center;
  background-color: ${colors.myOffers.grayBtnBg};
  color: ${colors.myOffers.grayBtnColor};
  cursor: pointer;
  ${fonts.weights.fw800()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
  border: none;

  &:hover {
    background-color: ${colors.myOffers.grayBtnBg};
    color: ${colors.myOffers.grayBtnColor};
    border: none;
  }
`;

const DeclineOfferBtn = styled(Button)`
  width: 12.62rem;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0.75rem 2rem 0.75rem 2rem;
  display: flex;
  align-items: center;
  background-color: ${colors.primary};
  color: ${colors.white};
  margin-left: 2rem;
  cursor: pointer;
  ${fonts.weights.fw800()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
  border: none;

  &:hover {
    background-color: ${colors.primary};
    color: ${colors.white};
    border: none;
  }

  &:focus {
    background-color: ${colors.primary};
    color: ${colors.white};
    border: none;
  }
`;

const StyledImg = styled.img`
  margin-right: 0.62rem;
`;

const StyledTextArea = styled(TextArea)`
  &&& {
    min-height: 1.93rem;
    > * {
      background-color: ${colors.white} !important;
      border: 0.5px solid ${colors.lightBorder};
      border-radius: 0.31rem;
      margin-top: 0.5rem;

      &.ant-input {
        line-height: 0.94rem;
        min-height: 1.93rem;
      }

      &.ant-input:placeholder-shown {
        color: ${colors.lightBorder};
        font-size: 0.75rem;
        ${fonts.weights.fw400};
        line-height: 0.94rem;
      }

      &.textarea.ant-input {
        min-height: 1.93rem;
        background-color: ${colors.white};
      }
    }
    &.ant-input-textarea-show-count::after {
      margin-top: 0.25rem;
      float: left;
      ${fonts.size.extraSmall()};
      line-height: 0.62rem;
      ${fonts.weights.fw400};
      content: ' ' attr(data-count) ' characters ';
      color: ${colors.lightBorder};
    }
  }
`;

const DeclineOfferModal = ({ handleCancel, handleDecline }: DeclineOfferModalProps) => {
  const [declineReason, setDeclineReason] = useState<string>('');

  return (
    <DeclineOfferModalContent>
      <DeclineOfferTitle>{translate('decline_offer_confirm')}</DeclineOfferTitle>
      <DeclineOfferForm layout="vertical">
        <DeclineOfferFormItem name={'declineReason'} label={translate('please_tell')}>
          <StyledTextArea
            rows={1}
            autoSize
            showCount
            maxLength={250}
            data-testid="offer-notes-input"
            value={declineReason}
            onChange={(e) => setDeclineReason(e.target.value)}
            onPaste={(e) => {
              const clipboardData = e.clipboardData;
              const pastedData = clipboardData.getData('Text');

              if (pastedData.length > 250) {
                message.error(translate('250_chars_limit_error'));
              }
            }}
          />
        </DeclineOfferFormItem>
      </DeclineOfferForm>
      <FormActionsContainer>
        <CancelBtn onClick={handleCancel} data-testid="decline-offer-cancel">
          {translate('cancel')}
        </CancelBtn>
        <DeclineOfferBtn onClick={() => handleDecline(declineReason)} data-testid="decline-offer-button">
          <StyledImg src={declineOfferWhiteIcon} /> {translate('decline_offer')}
        </DeclineOfferBtn>
      </FormActionsContainer>
    </DeclineOfferModalContent>
  );
};

export default DeclineOfferModal;
