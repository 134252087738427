import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import defaultProfilePic from '@images/avatar.png';
import { OfferConversationProps, OfferMessageProps } from '../../types';
import moment from 'moment';
import { OFFER_STATE, PROFILE_IMAGE_PROD_URL, PROFILE_IMAGE_QA_URL, PROFILE_IMAGE_URL } from '@app/utils/constants';
import { If } from 'tsw-sdk';
import Tippy from '@tippyjs/react';
import ToolTipIcon from '@images/icons/my-offers/tooltip-icon.svg';
import 'tippy.js/dist/tippy.css';

const OfferMessageCard = styled.div<OfferMessageProps>`
  width: 32.5rem;
  min-height: 21.87rem;
  height: auto;
  border-radius: 1.25rem;
  margin-right: auto;
  margin-left: 3rem;
  box-shadow: 0px 0px 1.13rem ${colors.myOffers.offerCardShadowColor};
  display: flex;
  flex-direction: column;
  padding: 3.12rem 3.12rem 1.87rem 3.12rem;
  position: relative;
  background-color: ${colors.white};
  ${(props) => (props.isRetracted ? 'opacity: 0.4' : '')};

  &:after {
    content: ' ';
    position: absolute;
    left: -2.4rem;
    bottom: -1rem;
    border-top: none;
    border-right: 4.68rem solid transparent;
    border-left: 3.12rem solid transparent;
    border-bottom: 3.12rem solid ${colors.white};
    transform: rotate(-20deg);
  }
`;

const OfferMessageMainContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const OfferMessageOfferInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`;

const UserTitle = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw900()};
  ${fonts.size.large()}
  line-height: 2.43rem;
  color: ${colors.myOffers.storyTitle};
`;

const UserDesignation = styled.p`
  margin-top: 0.62rem;
  ${fonts.weights.fw400()};
  ${fonts.size.xBig()};
  line-height: 1.93rem;
  color: ${colors.myOffers.storyTitle};
`;

const ProfileImage = styled.img`
  width: 8.12rem;
  height: 8.12rem;
  border-radius: 100%;
`;

const OfferMetaContainer = styled.div`
  width: 100%;
  margin-top: 1.81rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const OfferMetaItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const OfferMetaItemTitle = styled.p`
  text-transform: uppercase;
  ${fonts.weights.fw700()};
  ${fonts.size.extraSmall()};
  line-height: 0.75rem;
  color: ${colors.myOffers.dateText};
`;

const OfferMetaItemDescription = styled.p`
  ${fonts.weights.fw600()};
  ${fonts.size.xSmall()};
  line-height: 0.87rem;
  color: ${colors.myOffers.storyTitle};
`;

const OfferAmount = styled.p`
  margin-top: 1.81rem;
  ${fonts.weights.fw700()};
  ${fonts.size.BannerText()};
  line-height: 3.43rem;
  color: ${colors.myOffers.storyTitle};
`;

const OfferRetractedDateContainer = styled.div`
  width: 20.93rem;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0.75rem 2rem 0.75rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.primary};
  margin-top: 2.31rem;
  border: none;
`;

const OfferRetractedDateText = styled.p`
  color: ${colors.white};
  ${fonts.weights.fw800()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
`;

const StyledImg = styled.img`
  position: absolute;
  top: 1rem;
  right: 5%;
`;

const OfferMessage = ({ conversation, offerPlacedOn, offerEndsOn, daysLeft }: OfferConversationProps) => {
  return (
    <OfferMessageCard isRetracted={conversation?.offerState === OFFER_STATE.SELLER_RETRACTED}>
      <Tippy content={conversation?.notes ? conversation?.notes : translate('no_notes')}>
        <StyledImg src={ToolTipIcon} />
      </Tippy>
      <OfferMessageMainContainer>
        <If condition={process.env.ENVIRONMENT_NAME === 'qa'}>
          <ProfileImage
            src={
              conversation?.offerEntity?.profilePic
                ? `${PROFILE_IMAGE_QA_URL}/${conversation?.offerEntity?.profilePic}`
                : defaultProfilePic
            }
          />
        </If>
        <If condition={process.env.ENVIRONMENT_NAME === 'production'}>
          <ProfileImage
            src={
              conversation?.offerEntity?.profilePic
                ? `${PROFILE_IMAGE_PROD_URL}/${conversation?.offerEntity?.profilePic}`
                : defaultProfilePic
            }
          />
        </If>
        <If condition={process.env.ENVIRONMENT_NAME !== 'production' && process.env.ENVIRONMENT_NAME !== 'qa'}>
          <ProfileImage
            src={
              conversation?.offerEntity?.profilePic
                ? `${PROFILE_IMAGE_URL}/${conversation?.offerEntity?.profilePic}`
                : defaultProfilePic
            }
          />
        </If>
        <OfferMessageOfferInfo>
          <UserTitle>{`${conversation?.offerEntity?.firstName} ${conversation?.offerEntity?.lastName}`}</UserTitle>
          <UserDesignation>{translate('author')}</UserDesignation>
        </OfferMessageOfferInfo>
      </OfferMessageMainContainer>
      <OfferAmount>{`₹ ${conversation?.amount?.toLocaleString('en-IN')}`}</OfferAmount>
      <If condition={conversation?.offerState !== OFFER_STATE.SELLER_RETRACTED}>
        <OfferMetaContainer>
          <OfferMetaItem>
            <OfferMetaItemTitle>{translate('offer_placed_on')}</OfferMetaItemTitle>
            <OfferMetaItemDescription>{moment(offerPlacedOn).format('MMM DD, YYYY')}2</OfferMetaItemDescription>
          </OfferMetaItem>
          <OfferMetaItem>
            <OfferMetaItemTitle>{translate('offer_ends_on')}</OfferMetaItemTitle>
            <OfferMetaItemDescription>{moment(offerEndsOn).format('MMM DD, YYYY')}</OfferMetaItemDescription>
          </OfferMetaItem>
          <OfferMetaItem>
            <OfferMetaItemTitle>{translate('days_to_close')}</OfferMetaItemTitle>
            <OfferMetaItemDescription>
              {translate('days_left_amount', { dayAmount: daysLeft })}
            </OfferMetaItemDescription>
          </OfferMetaItem>
        </OfferMetaContainer>
      </If>

      <If condition={conversation?.offerState === OFFER_STATE.SELLER_RETRACTED}>
        <OfferRetractedDateContainer>
          <OfferRetractedDateText>
            {translate('offer_retracted', { retractedDate: moment(conversation?.updatedAt).format('DD/MM/YYYY') })}
          </OfferRetractedDateText>
        </OfferRetractedDateContainer>
      </If>
    </OfferMessageCard>
  );
};

export default OfferMessage;
