import { T } from '@app/components';
import React, { useEffect, useState, memo } from 'react';
import styled from 'styled-components';
import { translate } from '@components/IntlGlobalProvider';
import { Form, Divider, notification } from 'antd';
import { CustomButton, CustomInput, If } from 'tsw-sdk';
import { ArrowRightOutlined, PlusOutlined } from '@ant-design/icons';

import { colors, fonts } from '@app/themes';
import PlusIcon from '@images/AddIcon.svg';
import Infotag from '@app/components/InfoTag';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { injectIntl } from 'react-intl';
import { IndividualProfileProps } from './types';

import { requestCreateProfile, requestGetProfile, resetState } from '@app/containers/ProfileContainer/reducer';
import { selectCreateUserProfileLoading, selectUserProfile } from '@app/containers/ProfileContainer/selectors';
import _ from 'lodash';
import { BUYER_ACCOUNT_TYPE, COGNITO_USER_GROUPS } from '@app/utils/constants';
import { selectAccessToken } from '@app/containers/TokenProvider/selectors';

type ShowReelObjType = {
  id: string;
  showReelName: string;
  showReelLink: string;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5625rem;
  align-items: flex-start;
  position: absolute;
  top: 6.2%;
  left: 12%;
`;

const ContainerTitle = styled(T)`
  && {
    line-height: 3.975rem;

    text-align: center;
  }
`;

const InlineItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px;
  gap: 1.5rem;
`;

const ButtonInlineItems = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  padding: 0px;
  gap: 1.5rem;
`;

const FormContainer = styled(Form)`
  && {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    margin-bottom: 2rem;
  }
`;

const CustomFormItem = styled(Form.Item)`
  &.ant-form-item {
    width: 16rem;
    margin-bottom: 0;
    row-gap: 0.5rem;
  }
  .ant-form-item-label {
    padding: 0 !important;
    font-size: 1rem;
    row-gap: 0.5rem;
  }
`;

const CustomDivider = styled(Divider)`
  && {
    margin: 0;
    border-top: 1px solid ${colors.lightBorder};
  }
`;

const SubText = styled.p`
  margin-top: 4px;
  opacity: 0.5;
  ${fonts.style.smallText()}
`;

const ShowReelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px;
`;

const ShowreelLableCont = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  margin-bottom: 8px;
`;

const CustomSubmitButton = styled(CustomButton)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
  }
`;

const CustomAddButton = styled(CustomButton)`
  && {
    border: 1px solid ${colors.primary};
    padding: 0.5rem 1.5rem;
    font-size: 0.875rem;
    height: auto;
    margin-top: 2.37rem;
  }
`;

const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1.5rem;
  margin-top: 0.5rem;
`;

export const EditIndividualProfile = ({
  userProfile,
  createProfileLoading,
  accessToken,
  dispatchGetUserProfile,
  dispatchUpdateUserProfile,
  dispatchResetState
}: IndividualProfileProps) => {
  const [profileForm] = Form.useForm();
  const [showReelArr, setShowReelArr] = useState<ShowReelObjType[]>([]);
  const [showReelTag, setShowReelTag] = useState<boolean>(true);

  useEffect(() => {
    profileForm.setFieldsValue({
      shortBio: userProfile?.bioDescription,
      favGen1: userProfile?.favGenres && userProfile?.favGenres[0] ? userProfile?.favGenres[0]?.name : '',
      favGen2: userProfile?.favGenres && userProfile?.favGenres[1] ? userProfile?.favGenres[1]?.name : '',
      favGen3: userProfile?.favGenres && userProfile?.favGenres[2] ? userProfile?.favGenres[2]?.name : '',
      favMovie1: userProfile?.favMovies && userProfile?.favMovies[0] ? userProfile?.favMovies[0]?.name : '',
      favMovie2: userProfile?.favMovies && userProfile?.favMovies[1] ? userProfile?.favMovies[1]?.name : '',
      favMovie3: userProfile?.favMovies && userProfile?.favMovies[2] ? userProfile?.favMovies[2]?.name : '',
      favProd1: userProfile?.producedFilms && userProfile?.producedFilms[0] ? userProfile?.producedFilms[0]?.name : '',
      favProd2: userProfile?.producedFilms && userProfile?.producedFilms[1] ? userProfile?.producedFilms[1]?.name : '',
      favProd3: userProfile?.producedFilms && userProfile?.producedFilms[2] ? userProfile?.producedFilms[2]?.name : ''
    });

    if (userProfile?.showreels) {
      if (userProfile?.showreels.length) {
        setShowReelArr(
          userProfile?.showreels.map((showReel: any) => ({
            id: showReel.id,
            showReelName: showReel.name,
            showReelLink: showReel.link
          }))
        );
      }
    }
  }, [userProfile]);

  useEffect(() => {
    dispatchGetUserProfile();

    return () => {
      dispatchResetState();
    };
  }, []);

  const handleAddShowReel = () => {
    const showReelName = profileForm.getFieldValue('showReelName');
    const showReelLink = profileForm.getFieldValue('showReelLink');

    if (showReelName && showReelLink) {
      const showReelObj = {
        id: Date.now().toString(),
        showReelName: showReelName,
        showReelLink: showReelLink
      };

      setShowReelArr([...showReelArr, showReelObj]);
      setShowReelTag(false);
      profileForm.resetFields(['showReelName', 'showReelLink']);
    } else {
      notification.open({
        message: translate('error'),
        description: translate('enter_all_fields_error')
      });
    }
  };

  const handleDeleteShowReel = (id: string) => {
    setShowReelArr(showReelArr.filter((item) => item.id !== id));
    setShowReelTag(true);
  };

  const contructProducedFilms = (formData: any) => {
    const producedFilms = [
      {
        name: formData.favProd1
      },
      {
        name: formData.favProd2
      },
      {
        name: formData.favProd3
      }
    ];

    const newProducedFilms = [];

    for (let i = 0; i < producedFilms.length; i++) {
      if (producedFilms[i].name !== '' && producedFilms[i].name !== undefined) {
        newProducedFilms[i] = producedFilms[i];
      }
    }

    return newProducedFilms;
  };

  const constructFavouriteGenres = (formData: any) => {
    const favouriteGenres = [
      {
        name: formData.favGen1
      },
      {
        name: formData.favGen2
      },
      {
        name: formData.favGen3
      }
    ];

    const newfavouriteGenres = [];

    for (let i = 0; i < favouriteGenres.length; i++) {
      if (favouriteGenres[i].name !== '' && favouriteGenres[i].name !== undefined) {
        newfavouriteGenres[i] = favouriteGenres[i];
      }
    }

    return newfavouriteGenres;
  };

  const constructFavouriteMovies = (formData: any) => {
    const favouriteMovies = [
      {
        name: formData.favMovie1
      },
      {
        name: formData.favMovie2
      },
      {
        name: formData.favMovie3
      }
    ];

    const newFavouriteMovies = [];

    for (let i = 0; i < favouriteMovies.length; i++) {
      if (favouriteMovies[i].name !== '' && favouriteMovies[i].name !== undefined) {
        newFavouriteMovies[i] = favouriteMovies[i];
      }
    }

    return newFavouriteMovies;
  };

  const constructApiPayload = (data: any) => {
    const cognitoGroups = accessToken['cognito:groups'];
    const newProfileData = _.cloneDeep(userProfile);

    delete newProfileData?.typename;
    delete newProfileData?.email;
    delete newProfileData?.id;
    delete newProfileData?.company?.typename;
    delete newProfileData?.billingAddress?.typename;

    const formDataPayload = {
      buyerProfileInput: {
        ...newProfileData,
        profileType: 'INDIVIDUAL',
        bioDescription: data?.shortBio,
        showreels: showReelArr.length
          ? showReelArr.map((showReel) => ({
              name: showReel.showReelName,
              link: showReel.showReelLink
            }))
          : [],
        producedFilms: contructProducedFilms(data),
        favGenres: constructFavouriteGenres(data),
        favMovies: constructFavouriteMovies(data),
        isSave: true
      }
    };

    if (cognitoGroups) {
      if (cognitoGroups.length) {
        if (cognitoGroups.includes(COGNITO_USER_GROUPS.ORGANIZATION)) {
          formDataPayload.buyerProfileInput.profileType = BUYER_ACCOUNT_TYPE.ORGANIZATION_USER;
        }
      }
    }

    return formDataPayload;
  };

  const handleFinish = (values: any) => {
    const formData = constructApiPayload(values);

    dispatchUpdateUserProfile({ formData, autoSave: false, goBack: true });
  };

  return (
    <>
      <Container data-testid="individual-form">
        <ContainerTitle id="edit_profile" type="spoofHeading" />
        <FormContainer layout="vertical" form={profileForm} onFinish={handleFinish} initialValues={userProfile}>
          <ShowReelContainer>
            <ShowreelLableCont>
              <p>{translate('showreel_label')}</p>
              <If condition={!showReelTag && showReelArr.length < 3}>
                <img
                  src={PlusIcon}
                  onClick={() => setShowReelTag(true)}
                  style={{ cursor: 'pointer' }}
                  data-testid="add-icon"
                />
              </If>
            </ShowreelLableCont>
            <If condition={showReelTag && showReelArr.length < 3}>
              <InlineItems data-testid="showreel-form">
                <CustomFormItem name="showReelName">
                  <CustomInput placeholder={translate('showreel_name_place')} data-testid="showreel-name" />
                </CustomFormItem>
                <CustomFormItem name="showReelLink">
                  <CustomInput placeholder={translate('showreel_link_place')} data-testid="showreel-link" />
                </CustomFormItem>
              </InlineItems>
              <SubText>{translate('max_warn')}</SubText>
              <CustomAddButton
                bg_color={`${colors.secondaryText}`}
                text_color={`${colors.darkBorder}`}
                onClick={handleAddShowReel}
                data-testid="add-showreel-btn"
              >
                <PlusOutlined /> {translate('add_link')}
              </CustomAddButton>
            </If>
            <TagsContainer>
              {showReelArr.map((items, index) => {
                return (
                  <React.Fragment key={index}>
                    <Infotag {...items} onDelete={handleDeleteShowReel} data-testid="info-tag" />
                  </React.Fragment>
                );
              })}
            </TagsContainer>
          </ShowReelContainer>
          <CustomDivider />
          <CustomFormItem label={translate('short_bio')} name="shortBio">
            <CustomInput placeholder={translate('bio_placeholder')} style={{ height: '2.875rem' }} />
          </CustomFormItem>
          <CustomDivider />
          <InlineItems>
            <CustomFormItem label={translate('genres_label')} name="favGen1">
              <CustomInput placeholder={translate('fav_genre_1')} />
            </CustomFormItem>
            <CustomFormItem name="favGen2">
              <CustomInput placeholder={translate('fav_genre_2')} />
            </CustomFormItem>
            <CustomFormItem name="favGen3">
              <CustomInput placeholder={translate('fav_genre_3')} />
            </CustomFormItem>
          </InlineItems>
          <InlineItems>
            <CustomFormItem label={translate('movies_label')} name="favMovie1">
              <CustomInput placeholder={translate('fav_movie_1')} />
            </CustomFormItem>
            <CustomFormItem name="favMovie2">
              <CustomInput placeholder={translate('fav_movie_2')} />
            </CustomFormItem>
            <CustomFormItem name="favMovie3">
              <CustomInput placeholder={translate('fav_movie_3')} />
            </CustomFormItem>
          </InlineItems>
          <InlineItems>
            <CustomFormItem label={translate('produced_film_label')} name="favProd1">
              <CustomInput placeholder={translate('fav_produced_1')} />
            </CustomFormItem>
            <CustomFormItem name="favProd2">
              <CustomInput placeholder={translate('fav_produced_2')} />
            </CustomFormItem>
            <CustomFormItem name="favProd3">
              <CustomInput placeholder={translate('fav_produced_3')} />
            </CustomFormItem>
          </InlineItems>
          <CustomDivider />

          <ButtonInlineItems>
            <CustomSubmitButton htmlType="submit" loading={createProfileLoading} data-testid="submit-btn">
              {translate('update')} <ArrowRightOutlined />
            </CustomSubmitButton>
          </ButtonInlineItems>
        </FormContainer>
      </Container>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  userProfile: selectUserProfile(),
  createProfileLoading: selectCreateUserProfileLoading(),
  accessToken: selectAccessToken()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGetUserProfile: () => dispatch(requestGetProfile({})),
    dispatchUpdateUserProfile: (profileData: any) => dispatch(requestCreateProfile(profileData)),
    dispatchResetState: () => dispatch(resetState())
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo, injectIntl)(EditIndividualProfile) as React.FC;

export const EditIndividualProfileTest = EditIndividualProfile;
