import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import StoryStatusCheckmark from '../StoryStatusCheckmark';

const ViewedStoriesItemContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 1.5rem;
`;

const ViewedStoriesItemHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StoryName = styled.p`
  color: ${colors.myDashboardColors.storyName};
  ${fonts.size.regular()}
  ${fonts.weights.medium()}
`;

const NewTag = styled.div`
  width: 2.75rem;
  height: 1.5rem;
  border-radius: 0.75rem;
  padding: 0.25rem 0.75rem;
  background-color: ${colors.myDashboardColors.newTagBg};
  color: ${colors.myDashboardColors.newTagText};
  display: flex;
  align-items: center;
  justify-content: center;
  ${fonts.size.extraSmall()}
  ${fonts.weights.bold()}
`;

const StoryStatusContainer = styled.div`
  width: 100%;
  margin-top: 1.25rem;
  display: flex;
  align-items: center;
`;

const StoryTimeStamp = styled.p`
  color: ${colors.myDashboardColors.newTagText};
  ${fonts.size.xSmall()}
  margin-left: auto;
`;

export type ViewedStoriesItemProps = {
  storyName: string;
  downloaded: boolean;
  reviewed: boolean;
  offeredrejected: boolean;
  isNew: boolean;
  updatedAt: number;
};

const ViewedStoriesItem = ({
  storyName,
  downloaded,
  reviewed,
  offeredrejected,
  isNew,
  updatedAt
}: ViewedStoriesItemProps) => {
  return (
    <ViewedStoriesItemContainer>
      <ViewedStoriesItemHeader>
        <StoryName>{storyName}</StoryName>
        {isNew && <NewTag>{translate('new')}</NewTag>}
      </ViewedStoriesItemHeader>
      <StoryStatusContainer>
        <StoryStatusCheckmark checked={downloaded} text={'Downloaded'} />
        <StoryStatusCheckmark checked={reviewed} text={'Reviewed'} />
        <StoryStatusCheckmark checked={offeredrejected} text={'Offered/ Rejected'} />

        <StoryTimeStamp> {updatedAt && moment(updatedAt).zone('GMT+0530').format('DD.MM.YYYY, HH:mm')}</StoryTimeStamp>
      </StoryStatusContainer>
    </ViewedStoriesItemContainer>
  );
};

export default ViewedStoriesItem;
