import React from 'react';
import NotFound from '@containers/NotFoundPage/Loadable';
import HomeContainer from '@containers/HomeContainer/Loadable';
import SignUpContainer from '@containers/SignUpContainer/Loadable';
import LoginContainer from '@containers/LoginContainer/Loadable';
import ProfileContainer from '@app/containers/ProfileContainer';
import routeConstants, { RouteConstant } from '@utils/routeConstants';
import PackageDetailsPage from './containers/PackageDetailsPage';
import TermsAndConditionsContainer from './containers/TermsAndConditionsContainer';
import DashboardContainerPage from './containers/DashboardContainerPage';
import InvitationPage from './containers/InvitationPage';
import EditProfileContainer from './containers/EditProfileContainer';
import Auth from './containers/Auth';
import PaymentComplete from './containers/PaymentComplete';

type RouteConfig = Record<string, { component: React.FC<any> } & Partial<RouteConstant>>;

export const routeConfig: RouteConfig = {
  home: {
    component: HomeContainer,
    ...routeConstants.home
  },
  signUp: {
    component: SignUpContainer,
    ...routeConstants.signUp
  },
  login: {
    component: LoginContainer,
    ...routeConstants.login
  },
  authPage: {
    component: Auth,
    ...routeConstants.authPage
  },
  packageDetailsPage: {
    component: PackageDetailsPage,
    ...routeConstants.packageDetailsPage
  },
  termsAndConditions: {
    component: TermsAndConditionsContainer,
    ...routeConstants.termsAndConditions
  },
  profile: {
    component: ProfileContainer,
    ...routeConstants.profile
  },
  dashboard: {
    component: DashboardContainerPage,
    ...routeConstants.dashboard
  },
  paymentComplete: {
    component: PaymentComplete,
    ...routeConstants.paymentCompletePage
  },
  invitaionPage: {
    component: InvitationPage,
    ...routeConstants.invitationPage
  },
  editProfile: {
    component: EditProfileContainer,
    ...routeConstants.editProfilePage
  },
  notFoundPage: {
    component: NotFound,
    route: '/'
  }
};
