import React, { FC, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ARROWLEFT, ARROWRIGHT, BACKSPACE } from '@app/utils/constants';
import { colors } from '@app/themes';

interface OtpProps {
  onOtpInput: (x: string) => void;
}

const OtpContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 1rem;
`;

const CustomInput1 = styled.input`
  box-sizing: border-box;
  width: 3rem;
  height: 3rem;

  background: ${colors.secondaryText};
  border: 0.5px solid ${colors.lightBorder};
  border-radius: 5px;

  padding: 0.5rem 1.125rem;
  gap: 10px;

  outline: none;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const OTPField: FC<OtpProps> = ({ onOtpInput }): JSX.Element => {
  const [otp, setOtp] = useState<string[]>(new Array(6).fill(''));
  const [activeOtpIndex, setActiveOtpIndex] = useState<number>(0);

  const inputRef = useRef<HTMLInputElement>(null);

  const isNumeric = (str: string) => {
    if (typeof str !== 'string') {
      return false;
    }

    return (
      str && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    );
  };

  const handleOnKeyDown = ({ key }: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    const newOTP: string[] = [...otp];
    const value = inputRef.current?.value;

    const isNumber = isNumeric(key);

    if (isNumber) {
      newOTP[index] = key;
      setOtp(newOTP);
      if (index < 5) {
        setActiveOtpIndex(index + 1);
      }
    } else {
      if (key === BACKSPACE) {
        if (value?.trim() === '') {
          setActiveOtpIndex(index - 1);
          newOTP[index] = value;
          setOtp(newOTP);
        } else {
          newOTP[index] = '';
          setOtp(newOTP);
        }
      } else if (key === ARROWLEFT) {
        if (index > 0) {
          setActiveOtpIndex(index - 1);
        }
      } else if (key === ARROWRIGHT) {
        if (index < 5) {
          setActiveOtpIndex(index + 1);
        }
      }
    }
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOtpIndex]);

  useEffect(() => {
    onOtpInput(otp.join(''));
  }, [otp]);

  return (
    <OtpContainer>
      {otp.map((_, index) => {
        return (
          <React.Fragment key={index}>
            <CustomInput1
              ref={index === activeOtpIndex ? inputRef : null}
              type="number"
              onKeyDown={(e) => handleOnKeyDown(e, index)}
              onFocus={() => setActiveOtpIndex(index)}
              value={otp[index]}
              data-testid="otp-field"
            />
          </React.Fragment>
        );
      })}
    </OtpContainer>
  );
};

OTPField.propTypes = {
  onOtpInput: PropTypes.func.isRequired
};

export default OTPField;
