import { Spin } from 'antd';
import React from 'react';
import styled from 'styled-components';

const SpinWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0rem;
`;

const StyledSpin = () => {
  return (
    <SpinWrapper>
      <Spin />
    </SpinWrapper>
  );
};

export default StyledSpin;
