import React, { useEffect } from 'react';
import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import { Button, Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import styled from 'styled-components';
import { ArrowRightOutlined } from '@ant-design/icons';

import { AnyAction, compose } from '@reduxjs/toolkit';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import { EditAdminProfileProps } from './types';

import { selectAccessToken } from '@app/containers/TokenProvider/selectors';
import { selectCreateUserProfileLoading, selectUserProfile } from '@app/containers/ProfileContainer/selectors';
import { requestCreateProfile, requestGetProfile, resetState } from '@app/containers/ProfileContainer/reducer';
import _ from 'lodash';

const CreateProfilePageContainer = styled.div`
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  position: relative;
`;

const CreateProfileFormContianer = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
`;

const CreateProfileTitle = styled.p`
  ${fonts.family.SpoofFamily};
  ${fonts.style.pageTitle};
  line-height: 3.93rem;
  margin-bottom: 2.56rem;
`;

const StyledTextArea = styled(TextArea)`
  width: 16.25rem;
  background-color: ${colors.secondaryText};
  border-radius: 0.31rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2.75rem;
  margin-bottom: 2rem;
`;

const SubmitBtn = styled(Button)`
  width: 25.56rem;
  border-radius: 0.5rem;
  ${fonts.weights.bold()}
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
  }
`;

const EditAdminProfileContainer = ({
  userProfile,
  createProfileLoading,
  dispatchGetUserProfile,
  dispatchUpdateUserProfile,
  dispatchResetState
}: EditAdminProfileProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      shortBio: userProfile?.bioDescription ? userProfile?.bioDescription : '',
      panNum: userProfile?.billingAddress?.panNumber,
      companyIdNum: userProfile?.billingAddress?.companyIdentificationNo,
      gstNum: userProfile?.billingAddress?.gstNumber,
      floorNum: userProfile?.billingAddress?.streetAddress,
      locality: userProfile?.billingAddress?.localeAddress,
      state: userProfile?.billingAddress?.state,
      city: userProfile?.billingAddress?.city,
      postCode: userProfile?.billingAddress?.pinCode
    });
  }, [userProfile]);

  useEffect(() => {
    dispatchGetUserProfile();

    return () => {
      dispatchResetState();
    };
  }, []);

  const constructApiPayload = (data: any) => {
    const newProfileData = _.cloneDeep(userProfile);

    delete newProfileData?.typename;
    delete newProfileData?.email;
    delete newProfileData?.id;
    delete newProfileData?.company?.typename;
    delete newProfileData?.billingAddress?.typename;

    const formDataPayload = {
      buyerProfileInput: {
        ...newProfileData,
        profileType: 'ADMIN',
        bioDescription: data.shortBio ? data.shortBio : '',
        isSave: true
      }
    };

    return formDataPayload;
  };

  const handleFinish = (values: any) => {
    const formData: any = constructApiPayload(values);

    dispatchUpdateUserProfile({ formData, autoSave: false, goBack: true });
  };

  return (
    <>
      <CreateProfilePageContainer>
        <CreateProfileFormContianer data-testid="admin-form">
          <CreateProfileTitle>{translate('edit_profile')}</CreateProfileTitle>
          <Form layout="vertical" onFinish={handleFinish} form={form}>
            <Form.Item label={translate('company_bio_label')}>
              <Form.Item name="shortBio" noStyle={true} style={{ display: 'flex' }}>
                <StyledTextArea placeholder={translate('company_bio_placeholder')} data-testid="bio-input" />
              </Form.Item>
            </Form.Item>

            <ButtonsContainer>
              <SubmitBtn type="primary" htmlType="submit" loading={createProfileLoading} data-testid="submit-btn">
                {translate('update')} <ArrowRightOutlined />
              </SubmitBtn>
            </ButtonsContainer>
          </Form>
        </CreateProfileFormContianer>
      </CreateProfilePageContainer>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  userProfile: selectUserProfile(),
  createProfileLoading: selectCreateUserProfileLoading(),
  accessToken: selectAccessToken()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGetUserProfile: () => dispatch(requestGetProfile({})),
    dispatchUpdateUserProfile: (profileData: any) => dispatch(requestCreateProfile(profileData)),
    dispatchResetState: () => dispatch(resetState())
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, injectIntl)(EditAdminProfileContainer) as React.ComponentType;

export const EditAdminProfileContainerTest = EditAdminProfileContainer;
