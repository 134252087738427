import { getQueryResponse } from '@app/utils/graphqlUtils';
import { AnyAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { get } from 'lodash-es';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { GET_CONVERSATION_HISTORY, GET_OFFER_HISTORY } from './queries';
import {
  failureGetConversationData,
  failureGetTransactionData,
  requestGetConversationData,
  requestGetTransactionData,
  successGetConversationData,
  successGetTransactionData
} from './reducer';

export function* getOfferHistory(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      input: {
        storyId: action.payload.storyId,
        sellerId: action.payload.sellerId,
        buyerId: action.payload.buyerId,
        isBuyer: true
      },
      pagination: {
        limit: 0,
        page: 1
      }
    };
    const { ok, data, error } = yield call(getQueryResponse, GET_OFFER_HISTORY, payload);

    if (ok) {
      const offerDetailsHistory = get(data, 'offerDetailsHistory', {});

      yield put(successGetTransactionData(offerDetailsHistory));
    } else {
      yield put(failureGetTransactionData(get(error, 'message', 'something_went_wrong')));
      message.error(get(error, 'message', 'something_went_wrong'));
    }
  } catch (e: any) {
    yield put(failureGetTransactionData(get(e, 'message', 'something_went_wrong')));
    message.error(get(e, 'message', 'something_went_wrong'));
  }
}

export function* getConversationData(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      input: {
        storyID: action.payload.storyId,
        sellerID: action.payload.sellerId,
        buyerID: action.payload.buyerId,
        isBuyer: true
      },
      pagination: {
        limit: 0,
        page: 1
      }
    };
    const { ok, data, error } = yield call(getQueryResponse, GET_CONVERSATION_HISTORY, payload);

    if (ok) {
      const conversationHistory = get(data, 'conversationHistory', {});

      yield put(successGetConversationData(conversationHistory));
    } else {
      yield put(failureGetConversationData(get(error, 'message', 'something_went_wrong')));
    }
  } catch (e: any) {
    yield put(failureGetConversationData(get(e, 'message', 'something_went_wrong')));
  }
}

export default function* offerHistoryContainerSaga() {
  yield all([
    takeLatest(requestGetTransactionData.toString(), getOfferHistory),
    takeLatest(requestGetConversationData.toString(), getConversationData)
  ]);
}

export const offerHistoryContainerSagaArr = [
  takeLatest(requestGetTransactionData.toString(), getOfferHistory),
  takeLatest(requestGetConversationData.toString(), getConversationData)
];
