import { getQueryResponse } from '@app/utils/graphqlUtils';
import { AnyAction } from '@reduxjs/toolkit';
import { get } from 'lodash-es';
import { put, takeLatest, call, all } from 'redux-saga/effects';
import { GET_OFFERS, SEND_MAIL_TO_LEGAL_TEAM } from './queries';
import {
  failureClosedOffers,
  failureMissedOffers,
  failureOpenOffers,
  failureSendMailToLegalTeam,
  requestGetClosedOffers,
  requestGetMissedOffers,
  requestGetOpenOffers,
  requestSendMailToLegalTeam,
  successClosedOffers,
  successMissedOffers,
  successOpenOffers,
  successSendMailToLegalTeam
} from './reducer';

export function* getOpenOffers(action: any): Generator<any, any, any> {
  try {
    const payload = {
      filters: {
        search: action.payload.search || '',
        asBuyer: {
          withID: action.payload.buyerId,
          areOpen: true
        }
      },
      page: {
        limit: action.payload.limit,
        page: action.payload.page
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_OFFERS, payload);

    if (ok) {
      const offersList = get(data, 'buyerOffers', '');

      yield put(successOpenOffers({ data: offersList?.offers, total: offersList.total }));
    } else {
      yield put(failureOpenOffers(error));
    }
  } catch (err) {
    yield put(failureOpenOffers(err));
  }
}

export function* getClosedOffers(action: any): Generator<any, any, any> {
  try {
    const payload = {
      filters: {
        asBuyer: {
          withID: action.payload.buyerId,
          areClosed: true
        }
      },
      page: {
        limit: action.payload.limit,
        page: action.payload.page
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_OFFERS, payload);

    if (ok) {
      const offersList = get(data, 'buyerOffers', '');

      yield put(successClosedOffers({ data: offersList?.offers, total: offersList.total }));
    } else {
      yield put(failureClosedOffers(error));
    }
  } catch (err) {
    yield put(failureClosedOffers(err));
  }
}

export function* getMissedOffers(action: any): Generator<any, any, any> {
  try {
    const payload = {
      filters: {
        asBuyer: {
          withID: action.payload.buyerId,
          areMissed: true
        }
      },
      page: {
        limit: action.payload.limit,
        page: action.payload.page
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_OFFERS, payload);

    if (ok) {
      const offersList = get(data, 'buyerOffers', '');

      yield put(successMissedOffers({ data: offersList?.offers, total: offersList.total }));
    } else {
      yield put(failureMissedOffers(error));
    }
  } catch (err) {
    yield put(failureMissedOffers(err));
  }
}

export function* sendMailToLegalTeam(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      supportNeeded: action.payload.supportNeeded,
      isBuyer: true,
      offerId: action.payload.offerId
    };
    const { ok, error } = yield call(getQueryResponse, SEND_MAIL_TO_LEGAL_TEAM, {
      input: payload
    });

    if (ok) {
      yield put(successSendMailToLegalTeam());
    } else {
      yield put(failureSendMailToLegalTeam(get(error, 'message', 'something_went_wrong')));
    }
  } catch (e: any) {
    yield put(failureSendMailToLegalTeam(get(e, 'message', 'something_went_wrong')));
  }
}

// Individual exports for testing
export default function* myOffersContainerSaga() {
  yield all([
    takeLatest(requestGetOpenOffers.toString(), getOpenOffers),
    takeLatest(requestGetClosedOffers.toString(), getClosedOffers),
    takeLatest(requestGetMissedOffers.toString(), getMissedOffers),
    takeLatest(requestSendMailToLegalTeam.toString(), sendMailToLegalTeam)
  ]);
}

export const myOffersSaga = [
  takeLatest(requestGetOpenOffers.toString(), getOpenOffers),
  takeLatest(requestGetClosedOffers.toString(), getClosedOffers),
  takeLatest(requestGetMissedOffers.toString(), getMissedOffers),
  takeLatest(requestSendMailToLegalTeam.toString(), sendMailToLegalTeam)
];
