import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import BackIcon from '@images/icons/browse-stories/back-icon.svg';
import { useLocation } from 'react-router-dom';
import { PROFILE_IMAGE_PROD_URL, PROFILE_IMAGE_QA_URL, PROFILE_IMAGE_URL } from '@app/utils/constants';
import { If } from 'tsw-sdk';
import defaultAvatarIcon from '@images/icons/my-offers/avatar-profile.svg';

const OfferDetailContainer = styled.div`
  width: 100%;
  min-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-top: 5rem;
  padding-left: 2.56rem;
  padding-bottom: 2rem;
  background-color: ${colors.dashboardBg};
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  ${fonts.weights.fw800()}
  ${fonts.size.small()}
  line-height: 1rem;
  color: ${colors.tagBorder};
`;

const StyledImg = styled.img``;

const ProfileContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3.06rem;
`;

const ProfileImage = styled.img`
  width: 9rem;
  height: 9rem;
  border-radius: 100%;
`;

const UserName = styled.p`
  margin-top: 2rem;
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw900()};
  ${fonts.size.large()};
  line-height: 2.68rem;
  color: ${colors.myDashboardColors.storyName};
`;

const UserOrg = styled.p`
  margin-top: 0.68rem;
  ${fonts.weights.fw700()};
  ${fonts.size.xBig()};
  line-height: 2.18rem;
  color: ${colors.myDashboardColors.storyName};
`;

const OfferAmount = styled.p`
  margin-top: 2rem;
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  line-height: 1.25rem;
  color: ${colors.myDashboardColors.storyName};
`;

const OfferMetaContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 3rem;
`;

const OfferMetaItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const OfferMetaItemTitle = styled.p`
  text-transform: uppercase;
  ${fonts.weights.fw700()};
  ${fonts.size.extraSmall()};
  line-height: 0.75rem;
  color: ${colors.myOffers.dateText};
`;

const OfferMetaItemDescription = styled.p`
  ${fonts.weights.fw600()};
  ${fonts.size.xSmall()};
  line-height: 0.87rem;
  color: ${colors.myDashboardColors.storyName};
`;

const NotesContainer = styled.div`
  margin-top: 4rem;
  width: 41rem;
  display: flex;
  flex-direction: column;
`;

const NotesTitle = styled.p`
  ${fonts.weights.fw700()};
  ${fonts.size.big()};
  line-height: 1.75rem;
  color: ${colors.myDashboardColors.storyName};
`;

const Notes = styled.p`
  ${fonts.weights.fw400()};
  ${fonts.size.big()};
  line-height: 1.75rem;
  color: ${colors.myDashboardColors.storyName};
`;

const OfferDetail = () => {
  const location: any = useLocation();
  const [transaction, setTransaction] = useState<any>({});

  useEffect(() => {
    if (location?.state?.transaction) {
      setTransaction(location?.state?.transaction);
    }
  }, []);
  return (
    <OfferDetailContainer data-testid="manage-offer-container">
      <BackButton data-testid="back-btn" onClick={() => history.back()}>
        <StyledImg src={BackIcon} />
        {translate('back')}
      </BackButton>
      <ProfileContainer>
        <If condition={process.env.ENVIRONMENT_NAME === 'qa'}>
          <ProfileImage
            src={
              transaction?.offerEntity?.profilePic
                ? `${PROFILE_IMAGE_QA_URL}/${transaction?.offerEntity?.profilePic}`
                : defaultAvatarIcon
            }
          />
        </If>
        <If condition={process.env.ENVIRONMENT_NAME === 'production'}>
          <ProfileImage
            src={
              transaction?.offerEntity?.profilePic
                ? `${PROFILE_IMAGE_PROD_URL}/${transaction?.offerEntity?.profilePic}`
                : defaultAvatarIcon
            }
          />
        </If>
        <If condition={process.env.ENVIRONMENT_NAME !== 'production' && process.env.ENVIRONMENT_NAME !== 'qa'}>
          <ProfileImage
            src={
              transaction?.offerEntity?.profilePic
                ? `${PROFILE_IMAGE_URL}/${transaction?.offerEntity?.profilePic}`
                : defaultAvatarIcon
            }
          />
        </If>
        <UserName>{transaction?.offerEntity?.firstName}</UserName>
        <UserOrg>{translate('storyteller')}</UserOrg>
        <OfferAmount>
          {translate('transaction_item_amount', { offerAmount: transaction?.amount?.toLocaleString('en-IN') })}
        </OfferAmount>
        <OfferMetaContainer>
          <OfferMetaItem>
            <OfferMetaItemTitle>{translate('offer_placed_on')}</OfferMetaItemTitle>

            <OfferMetaItemDescription>
              {moment(transaction?.createdAt).zone('GMT+0530').format('MMM DD, YYYY')}
            </OfferMetaItemDescription>
          </OfferMetaItem>
          <OfferMetaItem>
            <OfferMetaItemTitle>{translate('offer_ends_on')}</OfferMetaItemTitle>
            <OfferMetaItemDescription>
              {moment(transaction?.expiresAt).zone('GMT+0530').format('MMM DD, YYYY')}
            </OfferMetaItemDescription>
          </OfferMetaItem>
          <OfferMetaItem>
            <OfferMetaItemTitle>{translate('days_to_close')}</OfferMetaItemTitle>
            <OfferMetaItemDescription>
              {translate('days_left_amount', { dayAmount: transaction?.daysToClose })}
            </OfferMetaItemDescription>
          </OfferMetaItem>
        </OfferMetaContainer>
        <NotesContainer>
          <NotesTitle>{translate('notes_from_writer')}</NotesTitle>
          <Notes>{transaction?.notes ? transaction?.notes : translate('no_notes')}</Notes>
        </NotesContainer>
      </ProfileContainer>
    </OfferDetailContainer>
  );
};

export default OfferDetail;
