import { colors, fonts } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import { If } from 'tsw-sdk';
import { TagsProps } from '../../types';

const StoriesTagContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.25rem 0.5rem;
  gap: 0.75rem;

  background: ${colors.adminDashboardColors.addUserBtnBg};
  border: 0.5px solid ${colors.browseStoriesColors.labelColor};
  border-radius: 1rem;
`;

const TagsText = styled.p`
  ${fonts.weights.fw400()};
  ${fonts.size.xSmall()};
  line-height: 0.93rem;
  color: ${colors.browseStoriesColors.labelColor};
`;

const Tags = ({ name, year }: TagsProps) => {
  return (
    <StoriesTagContainer>
      <TagsText>{name}</TagsText>
      <If condition={year}>
        <TagsText>{` | ${year}`}</TagsText>
      </If>
    </StoriesTagContainer>
  );
};

export default Tags;
