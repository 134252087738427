import React from 'react';
import { adminDashboardConstants, individualDashboardConstants } from '@app/utils/sidebarConstants';
import styled from 'styled-components';
import { translate } from '../IntlGlobalProvider';
import { colors, fonts } from '@app/themes';

import logoutIcon from '@images/icons/sidebar/logout.svg';
import { SidebarProps } from './types';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { COGNITO_USER_GROUPS, SUBSCRIPTION_PLAN_TYPES } from '@app/utils/constants';
import { setClient } from '@app/utils/graphqlUtils';
import routeConstants from '@app/utils/routeConstants';
import { Auth } from 'aws-amplify';

const SidebarContainer = styled.div`
  width: 18.75rem;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 1.43rem 2.24rem 4rem 4.68rem;
  background-color: ${colors.white};
`;

const SidebarLinks = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2.18rem;
`;

const SidebarMenuItem = styled(Link)`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:not(:first-of-type) {
    margin-top: 2rem;
  }
`;

const SidebarMenuItemLogout = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:not(:first-of-type) {
    margin-top: 2rem;
  }
`;

const SidebarMenuText = styled.p`
  height: auto;
  margin-left: 0.75rem;
  ${fonts.style.sidebarText()}
  color: ${colors.sidebarMenuItemText};
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const SidebarHeader = styled.div`
  width: 9.93rem;
`;

const SidebarHeading = styled.h1`
  ${fonts.style.spotwriterHeading}
  text-align: center;
  line-height: 2.125rem;
`;

const LogoutSidebarOption = styled.div`
  margin-top: auto;
  @media (max-height: 50rem) {
    margin-top: 3rem;
`;

const Sidebar = ({ dispatchLogout, userType }: SidebarProps) => {
  const history = useHistory();

  const handleLogout = async () => {
    dispatchLogout();
    localStorage.clear();
    setClient('');
    await Auth.signOut();
    history.push(routeConstants.signUp.route);
  };
  const location = useLocation();

  const params = new URLSearchParams(location?.search);

  const activeTab = params.get('activeTab');

  return (
    <SidebarContainer>
      <SidebarHeader>
        <SidebarHeading>{translate('sidebar_heading')}</SidebarHeading>
      </SidebarHeader>
      <SidebarLinks>
        {userType === COGNITO_USER_GROUPS.INDIVIDUAL ||
        userType === SUBSCRIPTION_PLAN_TYPES.INDIVIDUAL ||
        userType === COGNITO_USER_GROUPS.ORGANIZATION
          ? individualDashboardConstants.map((sidebarMenuItem) => (
              <SidebarMenuItem to={`/dashboard?activeTab=${sidebarMenuItem.key}`} key={sidebarMenuItem.key}>
                <img src={activeTab === sidebarMenuItem.key ? sidebarMenuItem.activeIcon : sidebarMenuItem.icon} />
                <SidebarMenuText>{translate(sidebarMenuItem.key)}</SidebarMenuText>
              </SidebarMenuItem>
            ))
          : adminDashboardConstants.map((sidebarMenuItem) => (
              <SidebarMenuItem to={`/dashboard?activeTab=${sidebarMenuItem.key}`} key={sidebarMenuItem.key}>
                <img src={activeTab === sidebarMenuItem.key ? sidebarMenuItem.activeIcon : sidebarMenuItem.icon} />
                <SidebarMenuText>{translate(sidebarMenuItem.key)}</SidebarMenuText>
              </SidebarMenuItem>
            ))}
      </SidebarLinks>
      <LogoutSidebarOption>
        <SidebarMenuItemLogout onClick={handleLogout} data-testid="logout-menu-item">
          <img src={logoutIcon} />
          <SidebarMenuText>{translate('logout')}</SidebarMenuText>
        </SidebarMenuItemLogout>
      </LogoutSidebarOption>
    </SidebarContainer>
  );
};

export default Sidebar;
