import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import { prepare } from '@app/utils';

export const initialState = {
  openOffers: [],
  openOffersTotal: 0,
  openOffersLoading: false,
  closedOffers: [],
  closedOffersTotal: 0,
  closedOffersLoading: false,
  missedOffers: [],
  missedOffersTotal: 0,
  missedOffersLoading: false,
  openOffersError: null,
  closedOffersError: null,
  misseOffersError: null,
  sendMailToLegalTeamLoading: false,
  sendMailToLegalTeamError: null
};

const myOffersSlice = createSlice({
  name: 'makeOffers',
  initialState,
  reducers: {
    requestGetOpenOffers: {
      reducer: (state) => {
        state.openOffersLoading = true;
      },
      prepare
    },
    requestGetClosedOffers: {
      reducer: (state) => {
        state.closedOffersLoading = true;
      },
      prepare
    },
    requestGetMissedOffers: {
      reducer: (state) => {
        state.missedOffersLoading = true;
      },
      prepare
    },
    requestSendMailToLegalTeam: {
      reducer: (state) => {
        state.sendMailToLegalTeamLoading = true;
      },
      prepare
    },
    successSendMailToLegalTeam(state) {
      state.sendMailToLegalTeamLoading = false;
    },
    failureSendMailToLegalTeam(state, action) {
      state.sendMailToLegalTeamLoading = false;
      state.sendMailToLegalTeamError = get(action.payload, 'message', 'failure');
    },
    successOpenOffers(state, action) {
      state.openOffersLoading = false;
      state.openOffers = action.payload.data;
      state.openOffersTotal = action.payload.total;
    },
    failureOpenOffers(state, action) {
      state.openOffersLoading = false;
      state.openOffersError = get(action.payload, 'message', 'something went wrong');
    },
    successClosedOffers(state, action) {
      state.closedOffersLoading = false;
      state.closedOffers = action.payload.data;
      state.closedOffersTotal = action.payload.total;
    },
    failureClosedOffers(state, action) {
      state.closedOffersLoading = false;
      state.closedOffersError = get(action.payload, 'message', 'something went wrong');
    },
    successMissedOffers(state, action) {
      state.missedOffersLoading = false;
      state.missedOffers = action.payload.data;
      state.missedOffersTotal = action.payload.total;
    },
    failureMissedOffers(state, action) {
      state.missedOffersLoading = false;
      state.misseOffersError = get(action.payload, 'message', 'something went wrong');
    }
  }
});

export const {
  requestGetOpenOffers,
  requestGetClosedOffers,
  requestGetMissedOffers,
  successClosedOffers,
  successMissedOffers,
  successOpenOffers,
  failureClosedOffers,
  failureMissedOffers,
  failureOpenOffers,
  requestSendMailToLegalTeam,
  successSendMailToLegalTeam,
  failureSendMailToLegalTeam
} = myOffersSlice.actions;

export default myOffersSlice.reducer;
