import { gql } from 'apollo-boost';

export const ADD_USER_TO_ORGANISATION = gql`
  mutation AddOrganizationUser($input: OrganizationUserInput!) {
    addOrganizationUser(input: $input)
  }
`;

export const GET_ADDED_USERS = gql`
  query GetOrganizationUsers {
    getOrganizationUsers {
      buyers {
        id
        firstName
        lastName
        email
      }
      maxUsersCapacity
      availableCapacity
    }
  }
`;

export const GENERATE_PAYMENT = gql`
  mutation GeneratePaymentLink($reason: PaymentMetaData!, $notes: String!) {
    generatePaymentLink(input: { reason: $reason, notes: $notes }) {
      url
      status
      referenceId
    }
  }
`;

export const REMOVE_ORG_USER = gql`
  mutation RemoveOrganizationUser($buyerId: Int!) {
    removeOrganizationUser(buyerId: $buyerId)
  }
`;
