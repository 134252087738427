import { gql } from 'apollo-boost';

export const UPDATE_BUYER_PROFILE = gql`
  mutation UpdateBuyerProfile($buyerProfileInput: BuyerProfileInput!) {
    updateBuyerProfile(input: $buyerProfileInput)
  }
`;

export const GET_PROFILE_IMAGE_FILE_NAME = gql`
  mutation UploadBuyerProfileImage {
    uploadBuyerProfileImage {
      filepath
      fileName
      extensions
    }
  }
`;

export const GET_BUYER_PROFILE = gql`
  query GetBuyerProfile {
    getBuyerProfile {
      id
      firstName
      lastName
      email
      contactNumber
      nationality
      bioDescription
      profilePic
      profileBanner
      isLegalAge
      company {
        id
        name
        designation
        description
      }
      showreels {
        id
        name
        link
      }
      favGenres {
        id
        name
      }
      favMovies {
        id
        name
      }
      producedFilms {
        id
        name
      }
      billingAddress {
        id
        panNumber
        gstNumber
        streetAddress
        localeAddress
        city
        state
        pinCode
        companyIdentificationNo
      }
    }
  }
`;

export const GET_BANNER_IMAGE_FILE_NAME = gql`
  mutation UploadBuyerProfileBanner {
    uploadBuyerProfileBanner {
      filepath
      fileName
      extensions
    }
  }
`;
