import { colors, fonts } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import DownloadIcon from '@images/icons/browse-stories/download-icon.svg';

import { translate } from '@app/components/IntlGlobalProvider';
import _ from 'lodash';
import { MissedOfferCardProps } from '../../types';
import { If } from 'tsw-sdk';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const CardContainer = styled.div`
  width: 19.12rem;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  background-color: ${colors.myOffers.closedOfferCardBg};
  box-shadow: 0px 0px 1.25rem ${colors.myOffers.offerCardShadowColor};
  overflow: hidden;
`;

const MissedOfferCardHeader = styled.div`
  width: 100%;
  height: 5.25rem;
  background-color: ${colors.myOffers.offerCardHeaderBg};
  display: flex;
  position: relative;
  overflow: hidden;
`;

const MissedOfferCardHeaderBg = styled.div`
  width: 120%;
  position: absolute;
  top: -1rem;
  left: -10%;
  display: flex;
  flex-wrap: wrap;
  z-index: 1;
`;

const MissedOfferCardHeaderBgText = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw900()};
  ${fonts.size.BannerText()};
  color: ${colors.myOffers.bannerTextClr};
  line-height: 2.62rem;
  margin-left: 0.8rem;
  white-space: nowrap;
`;

const CardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 2rem;
  justify-content: center;
  align-items: center;
`;

const MissedOfferCardBodyTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StoryNameTitle = styled.h3`
  max-width: 80%;
  ${fonts.family.SpoofFamily()};
  ${fonts.size.xBig()};
  ${fonts.weights.fw900()};
  color: ${colors.myOffers.storyTitle};
  margin-right: 0.68rem;
  line-height: 1.87rem;
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
`;

const DownloadOfferIcon = styled.img`
  width: 0.75rem;
  height: 0.75rem;
`;

const MissedOfferCardDate = styled.p`
  width: 100%;
  margin-top: 0.5rem;
  ${fonts.weights.fw700()};
  ${fonts.size.xSmall()};
  line-height: 1rem;
  color: ${colors.myOffers.dateText};
  text-align: center;
`;

const OfferAmountText = styled.p`
  ${fonts.size.extraSmall()};
  ${fonts.weights.fw800()};
  line-height: 1rem;
  color: ${colors.myOffers.dateText};
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  text-align: center;
  letter-spacing: 0.88px;
`;

const MissedTag = styled.div`
  width: 6.12rem;
  height: 1.5rem;
  background-color: ${colors.myOffers.closedTagBg};
  border-radius: 6.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${colors.myOffers.dateText};
  ${fonts.size.regular()};
  ${fonts.weights.fw800()};
  line-height: 1rem;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 0.11rem;
`;

const MissedOfferCard = ({
  title,
  date,
  amount,
  downloadLoading,
  writerId,
  storyId,
  handleDownload
}: MissedOfferCardProps) => {
  const SpinIcon = <LoadingOutlined style={{ fontSize: 12 }} spin />;

  const handleDownloadClick = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e.stopPropagation();
    handleDownload(storyId, writerId);
  };

  return (
    <CardContainer data-testid="closed-offer-card-container">
      <MissedOfferCardHeader>
        <MissedOfferCardHeaderBg>
          {_.range(10).map((number) => (
            <MissedOfferCardHeaderBgText key={number}>{title}</MissedOfferCardHeaderBgText>
          ))}
        </MissedOfferCardHeaderBg>
      </MissedOfferCardHeader>
      <CardContentContainer>
        <MissedOfferCardBodyTitle>
          <StoryNameTitle>{title}</StoryNameTitle>
          <If condition={!downloadLoading} otherwise={<Spin indicator={SpinIcon} />}>
            <DownloadOfferIcon src={DownloadIcon} onClick={(e) => handleDownloadClick(e)} data-testid="download-icon" />
          </If>
        </MissedOfferCardBodyTitle>
        <MissedOfferCardDate>{date}</MissedOfferCardDate>
        <OfferAmountText>{translate('amount_cards', { amount: amount?.toLocaleString('en-IN') })}</OfferAmountText>
        <MissedTag>{translate('missed')}</MissedTag>
      </CardContentContainer>
    </CardContainer>
  );
};

export default MissedOfferCard;
