import { gql } from 'apollo-boost';

// export const GET_RECENTLY_VIEWED_STORIES = gql`
//   query RecentlyViewedStories($pagination: StoryPagination!) {
//     recentlyViewedStories(pagination: $pagination) {
//       total
//       stories {
//         id
//         title
//         createdAt
//       }
//     }
//   }
// `;
export const GET_RECENTLY_VIEWED_STORIES = gql`
  query Stories($filters: NewStoryFilters, $pages: StoryPagination!) {
    stories(filters: $filters, pagination: $pages) {
      stories {
        id
        title
        updatedAt
        buyerActions {
          hasOffered
          hasReviewed
          hasDownloaded
          hasViewed
        }
      }
      total
    }
  }
`;

export const GET_TRENDING_STORIES = gql`
  query TrendingStories($input: TrendingStoriesInput, $pagination: StoryPagination!) {
    trendingStories(input: $input, pagination: $pagination) {
      stories {
        id
        title
        languages {
          id
          name
        }
        genres {
          id
          name
        }
        storyType {
          id
          name
        }
        tags {
          id
          name
        }
        logline
        storyline
        state
        expiresAt
        views
        offers
        ratings
        createdAt
        writer {
          id
          firstName
          lastName
        }
        buyerActions {
          hasLiked
        }
        createdAt
        trendScore
      }
      total
    }
  }
`;
