import { translate } from '@app/components/IntlGlobalProvider';
import { BUYER, OFFER_ACTION, PROFILE_PATH, SUPPORTING_IMAGES_PATH } from '@app/utils/constants';
import { getMutationResponse, getQueryResponse } from '@app/utils/graphqlUtils';
import history from '@app/utils/history';
import { AnyAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { get } from 'lodash-es';
import { put, takeLatest, call, all } from 'redux-saga/effects';
import { GET_OFFER_HISTORY } from '../OfferHistory/queries';
import { failureGetTransactionData, successGetTransactionData } from '../OfferHistory/reducer';
import {
  GET_SIGNED_URL,
  GET_STORY_DETAILS,
  MAKE_OFFER,
  UPDATE_VIEW_TIME,
  SUBMIT_STORY_REVIEWS,
  SHARE_STORY,
  CONCLUDE_OFFER,
  SELLER_COUNTER_OFFER_DETAILS,
  GET_OFFERID,
  BUYER_LATEST_OFFER,
  SEND_REQUEST_MESSAGE
} from './queries';
import {
  failureConcludeOffer,
  failureGetImageUrls,
  failureGetNarrationUrl,
  failureGetOfferId,
  failureGetProfileUrl,
  failureGetScreenPlayurl,
  failureGetSellerCounterOffer,
  failureGetStory,
  failureGetSubmitReview,
  failureGetViewTime,
  failureLatestBuyerOffer,
  failureMakeCounterOffer,
  failureMakeOffer,
  failureSendMessage,
  failureShareStory,
  requestConcludeOffer,
  requestGetBuyerLatestOffer,
  requestGetImages,
  requestGetNarration,
  requestGetOfferId,
  requestGetProfileUrl,
  requestGetScreenPlay,
  requestGetStory,
  requestMakeCounterOffer,
  requestMakeOffer,
  requestSellerCounterOffer,
  requestSendMessage,
  requestShareStory,
  requestSubmitReview,
  requestUpdateViewTime,
  successConcludeOffer,
  successGetImageUrls,
  successGetNarrationUrl,
  successGetOfferId,
  successGetOfferState,
  successGetProfileUrl,
  successGetScreenplayUrl,
  successGetSellerCounterOffer,
  successGetStory,
  successGetSubmitReview,
  successGetViewTime,
  successLatestBuyerOffer,
  successMakeCounterOffer,
  successMakeOffer,
  successSendMessage,
  successShareStory
} from './reducer';

export function* makeOffer(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      input: {
        storyId: action.payload.storyId,
        offerId: action.payload.offerId,
        offerEntity: {
          buyerId: action.payload.buyerId,
          placedBy: BUYER
        },
        amount: action.payload.amount,
        notes: action.payload.notes
      }
    };

    const { ok, data, error } = yield call(getMutationResponse, MAKE_OFFER, payload);

    if (ok) {
      yield put(successMakeOffer(data));

      message.success('Offer made');
      history.push(
        `/dashboard?activeTab=offer-history&storyId=${action.payload.storyId}&authorId=${action.payload.sellerId}`
      );
    } else {
      yield put(failureMakeOffer(error));
    }
  } catch (err) {
    yield put(failureMakeOffer(err));
  }
}

export function* makeCounterOffer(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      input: {
        storyId: action.payload.storyId,
        offerId: action.payload.offerId,
        offerEntity: {
          buyerId: action.payload.buyerId,
          placedBy: BUYER
        },
        amount: action.payload.amount,
        notes: action.payload.notes
      }
    };

    const { ok, data, error } = yield call(getMutationResponse, MAKE_OFFER, payload);

    if (ok) {
      yield put(successMakeCounterOffer(data));

      message.success('Counter-Offer made');

      const payload = {
        input: {
          storyId: action.payload.storyId,
          sellerId: action.payload.sellerId,
          buyerId: action.payload.buyerId,
          isBuyer: true
        },
        pagination: {
          limit: 0,
          page: 1
        }
      };

      const response = yield call(getQueryResponse, GET_OFFER_HISTORY, payload);

      if (response.ok) {
        const offerDetailsHistory = get(response.data, 'offerDetailsHistory', {});

        yield put(successGetTransactionData(offerDetailsHistory));
        yield put(successGetOfferState(offerDetailsHistory?.offerState));
      } else {
        yield put(failureGetTransactionData(get(error, 'message', 'something_went_wrong')));
        message.error(get(error, 'message', 'something_went_wrong'));
      }
    } else {
      yield put(failureMakeCounterOffer(error));
    }
  } catch (err) {
    yield put(failureMakeCounterOffer(err));
  }
}

export function* getStoryDetails(action: AnyAction): Generator<any, any, any> {
  try {
    const payload: any = {
      filters: {
        where: {
          id: {
            equalTo: action.payload.storyId
          }
        },
        asBuyer: {
          withID: action.payload.buyerId
        }
      },
      pages: {
        limit: action.payload.limit,
        page: action.payload.page
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_STORY_DETAILS, payload);

    if (ok) {
      const storiesData = data.stories.stories[0];

      yield put(successGetStory(storiesData));
    } else {
      yield put(failureGetStory(error));
    }
  } catch (err) {
    yield put(failureGetStory(err));
  }
}

export function* getSignedUrl(action: AnyAction): Generator<any, any, any> {
  try {
    const signedUrlPayload = {
      urlinput: {
        user: 'SELLER',
        method: 'GET',
        rootFolder: action.payload.folderPath,
        fileName: action.payload.fileName
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_SIGNED_URL, signedUrlPayload);

    if (ok) {
      const signedUrlPayload = get(data, 'signedUrl', {});

      const signedUrl = get(signedUrlPayload, 'signedUrl', '');

      yield put(successGetScreenplayUrl(signedUrl));
    } else {
      yield put(failureGetScreenPlayurl(error));
    }
  } catch (e: any) {
    yield put(failureGetScreenPlayurl(e));
  }
}

export function* getNarrationSignedUrl(action: AnyAction): Generator<any, any, any> {
  try {
    const signedUrlPayload = {
      urlinput: {
        user: 'SELLER',
        method: 'GET',
        rootFolder: action.payload.folderPath,
        fileName: action.payload.fileName
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_SIGNED_URL, signedUrlPayload);

    if (ok) {
      const signedUrlPayload = get(data, 'signedUrl', {});

      const signedUrl = get(signedUrlPayload, 'signedUrl', '');

      yield put(successGetNarrationUrl(signedUrl));
    } else {
      yield put(failureGetNarrationUrl(error));
    }
  } catch (e: any) {
    yield put(failureGetNarrationUrl(e));
  }
}

export function* getSingleImageSignedUrl(fileName: string): Generator<any, any, any> {
  try {
    const signedUrlPayload = {
      urlinput: {
        user: 'SELLER',
        method: 'GET',
        rootFolder: SUPPORTING_IMAGES_PATH,
        fileName: fileName
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_SIGNED_URL, signedUrlPayload);

    if (ok) {
      const signedUrlPayload = get(data, 'signedUrl', {});

      const signedUrl = get(signedUrlPayload, 'signedUrl', '');

      return signedUrl;
    } else {
      yield put(failureGetImageUrls(error));
    }
  } catch (e: any) {
    yield put(failureGetImageUrls(e));
  }
}

export function* getImageSignedUrls(action: AnyAction): Generator<any, any, any> {
  try {
    let responseList: any[] = [];
    for (const names of action.payload.fileNames) {
      const response = yield call(getSingleImageSignedUrl, names);
      responseList = [...responseList, response];
    }

    if (responseList.length > 0) {
      yield put(successGetImageUrls(responseList));
    }
  } catch (e: any) {
    yield put(failureGetImageUrls(get(e, 'message', 'something_went_wrong')));
  }
}

export function* getSellerProfileSignedUrl(action: AnyAction): Generator<any, any, any> {
  try {
    const signedUrlPayload = {
      urlinput: {
        user: 'SELLER',
        method: 'GET',
        rootFolder: PROFILE_PATH,
        fileName: action.payload.profileName
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_SIGNED_URL, signedUrlPayload);

    if (ok) {
      const signedUrlPayload = get(data, 'signedUrl', {});

      const profileUrl = get(signedUrlPayload, 'signedUrl', '');

      yield put(successGetProfileUrl(profileUrl));
    } else {
      yield put(failureGetProfileUrl(get(error, 'message', 'something_went_wrong')));
    }
  } catch (e: any) {
    yield put(failureGetProfileUrl(get(e, 'message', 'something_went_wrong')));
  }
}

export function* getViewTimePoleId(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      input: {
        storyId: action.payload.storyId,
        pollId: action.payload.pollId
      }
    };

    const { ok, data, error } = yield call(getMutationResponse, UPDATE_VIEW_TIME, payload);
    if (ok) {
      const responseData = get(data, 'updateViewTime', {});

      const pollId = get(responseData, 'pollId', '');

      yield put(successGetViewTime(pollId));
    } else {
      yield put(failureGetViewTime(error));
    }
  } catch (e: any) {
    yield put(failureGetViewTime(e));
  }
}

export function* submitStoryReview(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      input: {
        id: 0,
        storyId: action.payload.storyId,
        ratings: action.payload.rating,
        comment: action.payload.comment
      }
    };

    const { ok, data, error } = yield call(getMutationResponse, SUBMIT_STORY_REVIEWS, payload);
    if (ok) {
      yield put(successGetSubmitReview(data));
      message.success(translate('review_added'));
    } else {
      yield put(failureGetSubmitReview(error));
    }
  } catch (e: any) {
    yield put(failureGetSubmitReview(e));
  }
}

export function* shareStory(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      buyerId: action.payload.buyerId,
      storyId: action.payload.storyId
    };

    const { ok, data, error } = yield call(getMutationResponse, SHARE_STORY, payload);
    if (ok) {
      yield put(successShareStory(data));
      message.success('share_success');
    } else {
      yield put(failureShareStory(error));
      message.error(get(error, 'message', 'something_went_wrong'));
    }
  } catch (e: any) {
    yield put(failureShareStory(e));
    message.error(get(e, 'message', 'something_went_wrong'));
  }
}

export function* concludeOffer(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      input: {
        storyId: action.payload.storyId,
        offerId: action.payload.offerId,
        offerEntity: {
          buyerId: action.payload.buyerId,
          sellerId: action.payload.sellerId,
          concludedBy: BUYER
        },
        action: action.payload.offerAction,
        reason: action.payload.reason
      }
    };

    const { ok, error } = yield call(getMutationResponse, CONCLUDE_OFFER, payload);
    if (ok) {
      message.success('success');
      yield put(successConcludeOffer('success'));

      if (action.payload.offerAction !== OFFER_ACTION.RETRACT_OFFER) {
        history.push('/dashboard?activeTab=my_offers');
      } else {
        const payload = {
          input: {
            storyId: action.payload.storyId,
            sellerId: action.payload.sellerId,
            buyerId: action.payload.buyerId,
            isBuyer: true
          },
          pagination: {
            limit: 0,
            page: 1
          }
        };

        const response = yield call(getQueryResponse, GET_OFFER_HISTORY, payload);

        if (response.ok) {
          const offerDetailsHistory = get(response.data, 'offerDetailsHistory', {});

          yield put(successGetTransactionData(offerDetailsHistory));
          yield put(successGetOfferState(offerDetailsHistory?.offerState));
        } else {
          yield put(failureGetTransactionData(get(error, 'message', 'something_went_wrong')));
          message.error(get(error, 'message', 'something_went_wrong'));
        }
      }
    } else {
      yield put(failureConcludeOffer(error));
      message.error(get(error, 'message', 'something_went_wrong'));
    }
  } catch (e: any) {
    yield put(failureConcludeOffer(e));
    message.error(get(e, 'message', 'something_went_wrong'));
  }
}

export function* sellerCounterOffer(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      input: {
        offerId: action.payload.offerId
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, SELLER_COUNTER_OFFER_DETAILS, payload);
    if (ok) {
      const sellerCounterData = get(data, 'sellerCounterAmount', {});
      yield put(successGetSellerCounterOffer(sellerCounterData));
    } else {
      yield put(failureGetSellerCounterOffer(error));
    }
  } catch (e: any) {
    yield put(failureGetSellerCounterOffer(e));
  }
}

export function* getOfferId(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      input: {
        storyId: action.payload.storyId,
        buyerId: action.payload.buyerId
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_OFFERID, payload);
    if (ok) {
      const buyerOffer = get(data, 'buyerOfferOnStory', {});
      const offerId = get(buyerOffer, 'offerId', '');
      const offerState = get(buyerOffer, 'offerState', '');
      yield put(successGetOfferId(offerId));
      yield put(successGetOfferState(offerState));
    } else {
      yield put(failureGetOfferId(error));
    }
  } catch (e: any) {
    yield put(failureGetOfferId(e));
  }
}

export function* getBuyerLatestOffer(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      input: {
        offerId: action.payload.offerId,
        isBuyer: true
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, BUYER_LATEST_OFFER, payload);
    if (ok) {
      const buyerOffer = get(data, 'buyerOfferDetails', 0);
      const amount = get(buyerOffer, 'amount', 0);
      yield put(successLatestBuyerOffer(amount));
    } else {
      yield put(failureLatestBuyerOffer(error));
    }
  } catch (e: any) {
    yield put(failureLatestBuyerOffer(e));
  }
}

export function* mutateSendMessage(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      input: {
        conversationEntity: {
          isBuyer: true,
          buyerId: action.payload.buyerId,
          sellerId: action.payload.sellerId
        },
        storyId: action.payload.storyId,
        message: action.payload.message,
        type: action.payload.type,
        state: action.payload.state
      }
    };

    const { ok, error } = yield call(getMutationResponse, SEND_REQUEST_MESSAGE, payload);
    if (ok) {
      message.success('success');
      yield put(successSendMessage());
      const payload: any = {
        filters: {
          where: {
            id: {
              equalTo: action.payload.storyId
            }
          },
          asBuyer: {
            withID: action.payload.buyerId
          }
        },
        pages: {
          limit: 0,
          page: 1
        }
      };

      const { ok, data, error } = yield call(getQueryResponse, GET_STORY_DETAILS, payload);

      if (ok) {
        const storiesData = data.stories.stories[0];

        yield put(successGetStory(storiesData));
      } else {
        yield put(failureGetStory(error));
      }
    } else {
      yield put(failureSendMessage(error));
    }
  } catch (e: any) {
    yield put(failureSendMessage(e));
  }
}

// Individual exports for testing
export default function* storiesDetailsContainerSaga() {
  yield all([
    takeLatest(requestMakeOffer.toString(), makeOffer),
    takeLatest(requestGetStory.toString(), getStoryDetails),
    takeLatest(requestGetScreenPlay.toString(), getSignedUrl),
    takeLatest(requestGetNarration.toString(), getNarrationSignedUrl),
    takeLatest(requestGetImages.toString(), getImageSignedUrls),
    takeLatest(requestGetProfileUrl.toString(), getSellerProfileSignedUrl),
    takeLatest(requestUpdateViewTime.toString(), getViewTimePoleId),
    takeLatest(requestSubmitReview.toString(), submitStoryReview),
    takeLatest(requestShareStory.toString(), shareStory),
    takeLatest(requestConcludeOffer.toString(), concludeOffer),
    takeLatest(requestSellerCounterOffer.toString(), sellerCounterOffer),
    takeLatest(requestGetOfferId.toString(), getOfferId),
    takeLatest(requestMakeCounterOffer.toString(), makeCounterOffer),
    takeLatest(requestGetBuyerLatestOffer.toString(), getBuyerLatestOffer),
    takeLatest(requestSendMessage.toString(), mutateSendMessage)
  ]);
}

export const storiesDetailsSaga = [
  takeLatest(requestMakeOffer.toString(), makeOffer),
  takeLatest(requestGetStory.toString(), getStoryDetails),
  takeLatest(requestGetScreenPlay.toString(), getSignedUrl),
  takeLatest(requestGetNarration.toString(), getNarrationSignedUrl),
  takeLatest(requestGetImages.toString(), getImageSignedUrls),
  takeLatest(requestGetProfileUrl.toString(), getSellerProfileSignedUrl),
  takeLatest(requestUpdateViewTime.toString(), getViewTimePoleId),
  takeLatest(requestSubmitReview.toString(), submitStoryReview),
  takeLatest(requestShareStory.toString(), shareStory),
  takeLatest(requestConcludeOffer.toString(), concludeOffer),
  takeLatest(requestSellerCounterOffer.toString(), sellerCounterOffer),
  takeLatest(requestGetOfferId.toString(), getOfferId),
  takeLatest(requestMakeCounterOffer.toString(), makeCounterOffer),
  takeLatest(requestGetBuyerLatestOffer.toString(), getBuyerLatestOffer),
  takeLatest(requestSendMessage.toString(), mutateSendMessage)
];
