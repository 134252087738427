import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { CustomInput } from 'tsw-sdk';
import { FilterModalProps } from '../../types';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 0.75rem;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 5.31rem;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 1.5rem;
`;

export const ModalHeading = styled.h1`
  ${fonts.family.SpoofFamily};
  ${fonts.weights.fw700()};
  ${fonts.size.large()};
  line-height: 2.62rem;
  text-align: center;
  color: ${colors.primary};

  margin-bottom: 0px;
  white-space: nowrap;
`;

const FilterInput = styled(CustomInput)`
  width: 16.18rem;
`;

export const FilterButton = styled(Button)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${colors.darkBorder};
    color: ${colors.white};
    border-radius: 0.5rem;
    ${fonts.weights.fw800()};
    ${fonts.size.regular()};
    line-height: 1.5rem;
    border: none;
    padding: 0.62rem 2rem;
  }
`;

const FilterModal = ({ handleCancel, handleFilterSave }: FilterModalProps) => {
  const [filterName, setFilterName] = useState<string>('');

  return (
    <StyledModal
      visible={true}
      centered={true}
      footer={null}
      closable={true}
      destroyOnClose={true}
      width={561}
      onCancel={handleCancel}
    >
      <ModalContent>
        <ModalHeading>{translate('name_filter')}</ModalHeading>
        <FilterInput
          placeholder={translate('new_filter_placeholder')}
          value={filterName}
          onChange={(e) => setFilterName(e.target.value)}
          data-testid="filter-name-input"
        />
        <FilterButton onClick={() => handleFilterSave(filterName)} data-testid="save-filter-btn">
          {translate('save_filter')}
        </FilterButton>
      </ModalContent>
    </StyledModal>
  );
};

export default FilterModal;
