import { createSelector } from 'reselect';
import get from 'lodash-es/get';
import { initialState } from './reducer';

export const selectPacakageDetailsPageDomain = (state: any) => state.packageDetailsPageReducer || initialState;

export const selectUpdateSelectedPackageLoading = () =>
  createSelector(selectPacakageDetailsPageDomain, (substate) => get(substate, 'updateSelectedPackageLoading'));

export const selectIsTermsChecked = () =>
  createSelector(selectPacakageDetailsPageDomain, (substate) => get(substate, 'isTermsChecked'));

export const selectIsTermsSeen = () =>
  createSelector(selectPacakageDetailsPageDomain, (substate) => get(substate, 'isTermsSeen'));

export const selectIsPrivacyPolicyChecked = () =>
  createSelector(selectPacakageDetailsPageDomain, (substate) => get(substate, 'isPrivacyPolicyChecked'));

export const selectIsPrivacyPolicySeen = () =>
  createSelector(selectPacakageDetailsPageDomain, (substate) => get(substate, 'isPrivacyPolicySeen'));

export const selectCouponCode = () =>
  createSelector(selectPacakageDetailsPageDomain, (substate) => get(substate, 'couponCode'));

export const selectCouponCodeValid = () =>
  createSelector(selectPacakageDetailsPageDomain, (substate) => get(substate, 'couponValid'));
