import { gql } from 'apollo-boost';

export const GET_LANGUAGE_GENRE = gql`
  query LanguagesAndGenres($pagination: LinguisticsPagination!) {
    languages(pagination: $pagination) {
      languageData {
        id
        name
      }
      total
    }
    genres(pagination: $pagination) {
      genreData {
        id
        name
      }
      total
    }
  }
`;

export const GET_TAGS = gql`
  query Tags($pagination: LinguisticsPagination!) {
    tags(pagination: $pagination) {
      tagsData {
        id
        name
      }
      total
    }
  }
`;

export const GET_STORY_TYPES = gql`
  query StoryTypes($pagination: LinguisticsPagination!) {
    storyTypes(pagination: $pagination) {
      storyTypeData {
        id
        name
      }
      total
    }
  }
`;

export const SAVE_FILTERS = gql`
  mutation SaveBrowseStoriesFilters($input: BrowseStoriesFilterInput!) {
    saveBrowseStoriesFilters(input: $input)
  }
`;

export const GET_SAVED_FILTERS = gql`
  query BrowseStoryFilters {
    browseStoriesFilters {
      filters {
        id
        name
        language {
          languageId: id
          value
        }
        genre {
          genreId: id
          value
        }
        storyType {
          storyTypeId: id
          value
        }
        tag {
          tagId: id
          value
        }
      }
      total
    }
  }
`;

export const GET_STORIES = gql`
  query Stories($filters: NewStoryFilters, $pages: StoryPagination!) {
    stories(filters: $filters, pagination: $pages) {
      stories {
        id
        title
        languages {
          id
          name
        }
        genres {
          id
          name
        }
        storyType {
          id
          name
        }
        tags {
          id
          name
        }
        logline
        storyline
        state
        expiresAt
        views
        offers
        ratings
        createdAt
        writer {
          id
          firstName
          lastName
          profilePic
        }
        buyerActions {
          hasLiked
        }
      }
      total
    }
  }
`;

export const TOGGLE_FAVOURITE = gql`
  mutation ToggleStoryFavourite($input: StoryFavouritesInput!) {
    toggleStoryFavourite(input: $input) {
      isFavourite
      id
    }
  }
`;

export const GET_TRENDING_STORIES = gql`
  query TrendingStories($input: TrendingStoriesInput, $pagination: StoryPagination!) {
    trendingStories(input: $input, pagination: $pagination) {
      stories {
        id
        title
        languages {
          id
          name
        }
        genres {
          id
          name
        }
        storyType {
          id
          name
        }
        tags {
          id
          name
        }
        logline
        storyline
        state
        expiresAt
        views
        offers
        ratings
        createdAt
        writer {
          id
          firstName
          lastName
        }
        buyerActions {
          hasLiked
        }
      }
      total
    }
  }
`;
