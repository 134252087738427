import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import { prepare } from '@app/utils';

export const initialState = {
  createProfileLoading: false,
  getProfileLoading: false,
  loading: false,
  uploadProfileImageLoading: false,
  uploadProfileImageSuccess: false,
  submitProfileLoading: false,
  userProfile: { profilePic: '', profileBanner: '' },
  accountType: '',
  getAccountTypeLoading: false,
  getAccountTypeError: null,
  fileName: '',
  profileUrl: '',
  getProfileUrlLoading: false,
  getProfileUrlError: null,
  createProfileError: null,
  uploadProfileImageError: null,
  getProfileError: null,
  otpError: null,
  selectedPackage: '',
  paymentData: {},
  paymentError: null,
  otpVerifySuccess: false,
  otpVerifyError: null,
  otpVerificationCodeSent: false,
  bannerFileName: '',
  bannerUrl: '',
  getBannerUrlLoading: false,
  getBannerUrlError: null,
  uploadBannerImageLoading: false,
  uploadBannerImageSuccess: false,
  uploadBannerImageError: null,
  paymentInitiated: false
};

const createProfileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    requestCreateProfile: {
      reducer: (state) => {
        state.createProfileLoading = true;
      },
      prepare
    },
    requestUploadProfileImage: {
      reducer: (state) => {
        state.uploadProfileImageLoading = true;
      },
      prepare
    },
    requestGetProfile: {
      reducer: (state) => {
        state.getProfileLoading = true;
      },
      prepare
    },
    requestGetProfileUrl: {
      reducer: (state) => {
        state.getProfileUrlLoading = true;
      },
      prepare
    },
    requestPaymentData: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare
    },
    requestPhoneVerification: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare
    },
    requestVerifyOtp: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare
    },
    requestGetAccountType: {
      reducer: (state) => {
        state.getAccountTypeLoading = true;
      },
      prepare
    },
    requestGetBannerUrl: {
      reducer: (state) => {
        state.getBannerUrlLoading = true;
      },
      prepare
    },
    requestUploadBannerImage: {
      reducer: (state) => {
        state.uploadBannerImageLoading = true;
      },
      prepare
    },
    successGetAccountType(state, action) {
      state.getAccountTypeLoading = false;
      state.accountType = action.payload;
    },
    failureGetAccountType(state, action) {
      state.getAccountTypeLoading = false;
      state.getAccountTypeError = get(action.payload, 'message', 'something_went_wrong');
    },
    successCreateProfile(state) {
      state.createProfileError = null;
      state.createProfileLoading = false;
    },
    failureCreateProfile(state, action) {
      state.createProfileError = get(action.payload, 'message', 'something went wrong');
      state.createProfileLoading = false;
      state.submitProfileLoading = false;
    },
    successUploadProfileImage(state) {
      state.uploadProfileImageError = null;
      state.uploadProfileImageLoading = false;
    },
    failureUploadProfileImage(state, action) {
      state.uploadProfileImageError = get(action.payload, 'message', 'something went wrong');
      state.uploadProfileImageLoading = false;
    },
    successGetFileName(state, action) {
      state.fileName = action.payload;
      state.userProfile.profilePic = action.payload;
    },
    successGetProfileUrl(state, action) {
      state.profileUrl = action.payload;
      state.getProfileUrlLoading = false;
    },
    failureGetProfileUrl(state, action) {
      state.getProfileUrlLoading = false;
      state.getProfileUrlError = get(action.payload, 'message', 'something went wrong');
    },
    successGetUserProfile(state, action) {
      state.userProfile = action.payload;
      state.getProfileLoading = false;
      state.getProfileError = null;
    },
    failureGetUserProfile(state, action) {
      state.userProfile = {
        profilePic: '',
        profileBanner: ''
      };
      state.getProfileLoading = false;
      state.getProfileError = get(action.payload, 'message', 'something went wrong');
    },
    successSetSelectedPackage(state, action) {
      state.selectedPackage = action.payload;
    },
    successPaymentData(state) {
      state.paymentError = null;
      state.loading = false;
    },
    failurePaymentData(state, action) {
      state.paymentError = get(action.payload, 'message', 'something went wrong');
      state.loading = false;
    },
    successVerifyOtp(state) {
      state.loading = false;
      state.otpVerifySuccess = true;
      state.otpVerifyError = null;
    },
    successPhoneVerification(state) {
      state.otpVerificationCodeSent = true;
      state.otpVerifyError = null;
      state.loading = false;
    },
    failurePhoneVerification(state, action) {
      state.otpVerifyError = get(action.payload, 'message', 'failure');
      state.loading = false;
    },
    successGetBannerUrl(state, action) {
      state.bannerUrl = action.payload;
      state.getBannerUrlLoading = false;
    },
    failureGetBannerUrl(state, action) {
      state.getBannerUrlLoading = false;
      state.getBannerUrlError = get(action.payload, 'message', 'something went wrong');
    },
    successGetBannerFileName(state, action) {
      state.bannerFileName = action.payload;
      state.userProfile.profileBanner = action.payload;
    },
    successUploadBannerImage(state) {
      state.uploadBannerImageError = null;
      state.uploadBannerImageLoading = false;
    },
    failureUploadBannerImage(state, action) {
      state.uploadBannerImageError = get(action.payload, 'message', 'something went wrong');
      state.uploadBannerImageLoading = false;
    },
    setPaymentInitiated(state, action) {
      state.paymentInitiated = action.payload;
    },
    setSubmitLoading(state) {
      state.submitProfileLoading = true;
    },
    resetState(state) {
      state.createProfileLoading = false;
      state.getProfileLoading = false;
      state.loading = false;
      state.uploadProfileImageLoading = false;
      state.uploadProfileImageSuccess = false;
      state.userProfile = {
        profilePic: '',
        profileBanner: ''
      };
      state.accountType = '';
      state.getAccountTypeLoading = false;
      state.getAccountTypeError = null;
      state.fileName = '';
      state.profileUrl = '';
      state.getProfileUrlLoading = false;
      state.getProfileUrlError = null;
      state.createProfileError = null;
      state.uploadProfileImageError = null;
      state.getProfileError = null;
      state.otpError = null;
      state.selectedPackage = '';
      state.paymentData = {};
      state.paymentError = null;
      state.otpVerifySuccess = false;
      state.otpVerifyError = null;
      state.otpVerificationCodeSent = false;
      state.bannerUrl = '';
      state.getBannerUrlLoading = false;
      state.getBannerUrlError = null;
      state.uploadBannerImageLoading = false;
      state.uploadBannerImageSuccess = false;
      state.uploadBannerImageError = null;
      state.paymentInitiated = false;
      state.submitProfileLoading = false;
    }
  }
});

export const {
  requestCreateProfile,
  requestGetProfile,
  successCreateProfile,
  failureCreateProfile,
  successGetUserProfile,
  failureGetUserProfile,
  resetState,
  successSetSelectedPackage,
  requestPaymentData,
  successPaymentData,
  failurePaymentData,
  requestPhoneVerification,
  failurePhoneVerification,
  successPhoneVerification,
  successVerifyOtp,
  requestVerifyOtp,
  requestUploadProfileImage,
  successUploadProfileImage,
  failureUploadProfileImage,
  successGetFileName,
  requestGetProfileUrl,
  successGetProfileUrl,
  failureGetProfileUrl,
  requestGetAccountType,
  successGetAccountType,
  failureGetAccountType,
  requestGetBannerUrl,
  requestUploadBannerImage,
  successGetBannerUrl,
  failureGetBannerUrl,
  successGetBannerFileName,
  successUploadBannerImage,
  failureUploadBannerImage,
  setPaymentInitiated,
  setSubmitLoading
} = createProfileSlice.actions;

export default createProfileSlice.reducer;
