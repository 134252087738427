import { put, takeLatest, call, all } from 'redux-saga/effects';
import {
  requestCreateProfile,
  successCreateProfile,
  failureCreateProfile,
  failurePaymentData,
  successPaymentData,
  requestPaymentData,
  requestUploadProfileImage,
  failureUploadProfileImage,
  requestPhoneVerification,
  requestVerifyOtp,
  successPhoneVerification,
  failurePhoneVerification,
  successVerifyOtp,
  successGetFileName,
  successUploadProfileImage,
  successGetUserProfile,
  failureGetUserProfile,
  successGetAccountType,
  failureGetAccountType,
  successGetProfileUrl,
  failureGetProfileUrl,
  requestGetProfile,
  requestGetProfileUrl,
  requestGetAccountType,
  successSetSelectedPackage,
  successGetBannerFileName,
  successUploadBannerImage,
  failureUploadBannerImage,
  requestUploadBannerImage,
  successGetBannerUrl,
  failureGetBannerUrl,
  requestGetBannerUrl,
  setPaymentInitiated
} from './reducer';
import { getMutationResponse, getQueryResponse } from '@utils/graphqlUtils';

import {
  UPDATE_BUYER_PROFILE,
  GET_PROFILE_IMAGE_FILE_NAME,
  GET_BUYER_PROFILE,
  GET_BANNER_IMAGE_FILE_NAME
} from './queries';
import { get } from 'lodash-es';
import { cognito } from 'tsw-sdk';
import { BuyerAccountStatus, PAYMENT } from '@app/utils/queries';
import { AnyAction } from '@reduxjs/toolkit';
import { GET_SIGNED_URL } from '../SignedUrlProvider/queries';
import { uploadFile } from '@app/utils/apiUtils';
import {
  AVAILABLE_PACKAGES,
  BANNER_PATH,
  BUYER,
  BUYER_ACCOUNT_TYPE,
  COUPON_BUYER_TYPES,
  PROFILE,
  PROFILE_PATH
} from '@app/utils/constants';
import { message, notification } from 'antd';
import { translate } from '@app/components/IntlGlobalProvider';
import history from '@app/utils/history';
import routeConstants from '@app/utils/routeConstants';
import { APPLY_COUPON } from '../PackageDetailsPage/queries';
import { failureAccountStatus, successAccountStatus } from '../HomeContainer/reducer';

const { createCognitoUserPool, updatePhoneNumberRequest, verifyPhoneNumberRequest } = cognito;

const userPoolId = process.env.USER_POOL_ID as string;
const userPoolClientId = process.env.USER_POOL_CLIENT_ID as string;

export function* getPaymentData(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      amount: action.payload.amount,
      reason: action.payload.reason,
      notes: action.payload.notes
    };
    const { ok, data, error } = yield call(getMutationResponse, PAYMENT, payload);
    if (ok) {
      window.open(data?.generatePaymentLink.url, '_self');
      yield put(setPaymentInitiated(true));

      yield put(successPaymentData());
    } else {
      yield put(failurePaymentData(error));
    }
  } catch (err) {
    yield put(failurePaymentData(err));
  }
}

export function* createUserProfile(action: AnyAction): Generator<any, any, any> {
  try {
    const payload: any = {
      ...action.payload.formData
    };

    const { ok, error } = yield call(getMutationResponse, UPDATE_BUYER_PROFILE, payload);

    if (ok) {
      yield put(successCreateProfile());

      if (action.payload.formData.buyerProfileInput.isSave && !action.payload.autoSave) {
        message.success(translate('form_saved'));
      }

      // If user is added org user redirect to dashboard directly
      if (action.payload.formData.buyerProfileInput.profileType === BUYER_ACCOUNT_TYPE.ORGANIZATION_USER) {
        if (!action.payload.formData.buyerProfileInput.isSave) {
          history.push(routeConstants.dashboard.route);
        }
      } else {
        // If coupon code is sent apply coupon
        if (action.payload.couponCode) {
          const response = yield call(getMutationResponse, APPLY_COUPON, {
            input: {
              isBuyer: true,
              code: action.payload.couponCode,
              userType:
                action.payload.formData.buyerProfileInput.profileType === BUYER_ACCOUNT_TYPE.INDIVIDUAL
                  ? COUPON_BUYER_TYPES.INDIVIDUAL
                  : COUPON_BUYER_TYPES.ADMIN
            }
          });

          if (response.ok) {
            const couponData = response.data;

            const applyCoupon = get(couponData, 'applyCoupon', {});

            const status = get(applyCoupon, 'status', '');

            if (status === 'Success') {
              const { ok, data, error } = yield call(getQueryResponse, BuyerAccountStatus);

              if (ok) {
                const buyerAccountStatus = get(data, 'buyerAccountStatus', {});

                yield put(successAccountStatus(buyerAccountStatus));
                history.push(routeConstants.dashboard.route);
              } else {
                yield put(failureAccountStatus(error));
              }
            } else {
              message.error(status);
            }
          } else {
            message.error(get(response.error, 'message', 'something_went_wrong'));
          }
        } else {
          // If coupon code is not sent initiate payment if isSave is false
          if (!action.payload.formData.buyerProfileInput.isSave) {
            const reason =
              action.payload.formData.buyerProfileInput.profileType === BUYER_ACCOUNT_TYPE.INDIVIDUAL
                ? AVAILABLE_PACKAGES.INDIVIDUAL
                : AVAILABLE_PACKAGES.ADMIN;

            const amount =
              action.payload.formData.buyerProfileInput.profileType === BUYER_ACCOUNT_TYPE.INDIVIDUAL ? 5000 : 10000;

            yield call(getPaymentData as any, {
              payload: {
                amount: amount,
                reason: reason,
                notes: 'For users onboarding'
              }
            });
          }
        }

        if (action.payload.goBack === true) {
          history.goBack();
        }
      }
    } else {
      yield put(failureCreateProfile(error));
      const errorMessage = get(error, 'message', translate('something_went_wrong'));
      notification.open({
        message: translate('error'),
        description: errorMessage.substring(errorMessage.indexOf(':') + 1)
      });

      message.error(errorMessage.substring(errorMessage.indexOf(':') + 1));
    }
  } catch (err) {
    const errorMessage = get(err, 'message', translate('something_went_wrong'));
    yield put(failureCreateProfile(err));

    if (errorMessage.includes('UpdateBillingAddress')) {
      notification.open({
        message: translate('error'),
        description: translate('unique_billing_message')
      });
    } else {
      notification.open({
        message: translate('error'),
        description: errorMessage.substring(errorMessage.indexOf(':') + 1)
      });
    }
  }
}

export function* uploadProfileImage(action: AnyAction): Generator<any, any, any> {
  try {
    const { ok, data, error } = yield call(getMutationResponse, GET_PROFILE_IMAGE_FILE_NAME, {});

    const fileName = ok ? `${data.uploadBuyerProfileImage.fileName}${data.uploadBuyerProfileImage.extensions[0]}` : '';

    if (ok) {
      const signedUrlPayload = {
        urlinput: {
          user: PROFILE,
          method: 'PUT',
          rootFolder: PROFILE_PATH,
          fileName: fileName
        }
      };

      const file = action.payload;

      const { ok, data, error } = yield call(getQueryResponse, GET_SIGNED_URL, signedUrlPayload);

      if (ok) {
        yield call(uploadFile, data.signedUrl.signedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          body: file
        });

        yield put(successGetFileName(fileName));

        yield put(successUploadProfileImage());
        message.success(translate('profile_image_upload_success'));
      } else {
        yield put(failureUploadProfileImage(get(error, 'message', 'something_went_wrong')));
        message.error(get(error, 'message', 'something_went_wrong'));
      }
    } else {
      yield put(failureUploadProfileImage(get(error, 'message', 'something_went_wrong')));
      message.error(get(error, 'message', 'something_went_wrong'));
    }
  } catch (e: any) {
    yield put(failureUploadProfileImage(get(e, 'message', 'something_went_wrong')));
    message.error(get(e, 'message', 'something_went_wrong'));
  }
}

export function* getPhoneVerification(action: any): Generator<any, any, any> {
  try {
    yield call(createCognitoUserPool, userPoolId, userPoolClientId);
    yield call(updatePhoneNumberRequest, action.payload);
    yield put(successPhoneVerification());
  } catch (err) {
    yield put(failurePhoneVerification(err));
  }
}

export function* getVerifyOtp(action: any): Generator<any, any, any> {
  try {
    yield call(createCognitoUserPool, userPoolId, userPoolClientId);
    yield call(verifyPhoneNumberRequest, action.payload);
    yield put(successVerifyOtp());
  } catch (err) {
    yield put(failurePhoneVerification(err));
  }
}

export function* getUserProfile(): Generator<any, any, any> {
  try {
    const { ok, data, error } = yield call(getQueryResponse, GET_BUYER_PROFILE, {});

    if (ok) {
      yield put(successGetUserProfile(get(data, 'getBuyerProfile', {})));
    } else {
      yield put(failureGetUserProfile(error));
    }
  } catch (err) {
    yield put(failureGetUserProfile(err));
  }
}

export function* getAccountType(): Generator<any, any, any> {
  try {
    const { ok, data, error } = yield call(getQueryResponse, BuyerAccountStatus, {});

    if (ok) {
      const buyerAccountStatus = get(data, 'buyerAccountStatus', {});

      const accountType = get(buyerAccountStatus?.subscription, 'planSelected', '');

      const isRegistrationDone = get(buyerAccountStatus, 'isRegistrationComplete', true);
      const buyerType = get(buyerAccountStatus, 'buyerType', '');

      if (!isRegistrationDone) {
        if (buyerType === BUYER_ACCOUNT_TYPE.ORGANIZATION_USER) {
          yield put(successSetSelectedPackage(AVAILABLE_PACKAGES.INDIVIDUAL));
          history.push(routeConstants.profile.route);
        } else {
          history.push(routeConstants.packageDetailsPage.route);
        }
      }

      yield put(successGetAccountType(accountType));
    } else {
      yield put(failureGetAccountType(error));
    }
  } catch (err) {
    yield put(failureGetAccountType(err));
  }
}

export function* getProfileSignedUrl(action: AnyAction): Generator<any, any, any> {
  try {
    const signedUrlPayload = {
      urlinput: {
        user: BUYER,
        method: 'GET',
        rootFolder: PROFILE_PATH,
        fileName: action.payload
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_SIGNED_URL, signedUrlPayload);

    if (ok) {
      const signedUrlPayload = get(data, 'signedUrl', {});

      const profileUrl = get(signedUrlPayload, 'signedUrl', '');

      yield put(successGetProfileUrl(profileUrl));
    } else {
      yield put(failureGetProfileUrl(get(error, 'message', 'something_went_wrong')));
      message.error(get(error, 'message', 'something_went_wrong'));
    }
  } catch (e: any) {
    yield put(failureGetProfileUrl(get(e, 'message', 'something_went_wrong')));
    message.error(get(e, 'message', 'something_went_wrong'));
  }
}

export function* uploadBannerImage(action: AnyAction): Generator<any, any, any> {
  try {
    const { ok, data, error } = yield call(getMutationResponse, GET_BANNER_IMAGE_FILE_NAME, {});

    const fileName = ok
      ? `${data.uploadBuyerProfileBanner.fileName}${data.uploadBuyerProfileBanner.extensions[0]}`
      : '';

    if (ok) {
      const signedUrlPayload = {
        urlinput: {
          user: PROFILE,
          method: 'PUT',
          rootFolder: BANNER_PATH,
          fileName: fileName
        }
      };

      const file = action.payload;

      const { ok, data, error } = yield call(getQueryResponse, GET_SIGNED_URL, signedUrlPayload);

      if (ok) {
        yield call(uploadFile, data.signedUrl.signedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          body: file
        });

        yield put(successGetBannerFileName(fileName));

        yield put(successUploadBannerImage());
        message.success(translate('banner_image_upload_success'));
      } else {
        yield put(failureUploadBannerImage(get(error, 'message', 'something_went_wrong')));
        message.error(get(error, 'message', 'something_went_wrong'));
      }
    } else {
      yield put(failureUploadBannerImage(get(error, 'message', 'something_went_wrong')));
      message.error(get(error, 'message', 'something_went_wrong'));
    }
  } catch (e: any) {
    yield put(failureUploadBannerImage(get(e, 'message', 'something_went_wrong')));
    message.error(get(e, 'message', 'something_went_wrong'));
  }
}

export function* getBannerSignedUrl(action: AnyAction): Generator<any, any, any> {
  try {
    const signedUrlPayload = {
      urlinput: {
        user: BUYER,
        method: 'GET',
        rootFolder: BANNER_PATH,
        fileName: action.payload
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_SIGNED_URL, signedUrlPayload);

    if (ok) {
      const signedUrlPayload = get(data, 'signedUrl', {});

      const profileUrl = get(signedUrlPayload, 'signedUrl', '');

      yield put(successGetBannerUrl(profileUrl));
    } else {
      yield put(failureGetBannerUrl(get(error, 'message', 'something_went_wrong')));
      message.error(get(error, 'message', 'something_went_wrong'));
    }
  } catch (e: any) {
    yield put(failureGetBannerUrl(get(e, 'message', 'something_went_wrong')));
    message.error(get(e, 'message', 'something_went_wrong'));
  }
}

// Individual exports for testing
export default function* ProfileContainerSaga() {
  yield all([
    takeLatest(requestCreateProfile.toString(), createUserProfile),
    takeLatest(requestGetProfile.toString(), getUserProfile),
    takeLatest(requestPaymentData.toString(), getPaymentData),
    takeLatest(requestUploadProfileImage.toString(), uploadProfileImage),
    takeLatest(requestPhoneVerification.toString(), getPhoneVerification),
    takeLatest(requestVerifyOtp.toString(), getVerifyOtp),
    takeLatest(requestGetProfileUrl.toString(), getProfileSignedUrl),
    takeLatest(requestGetAccountType.toString(), getAccountType),
    takeLatest(requestUploadBannerImage.toString(), uploadBannerImage),
    takeLatest(requestGetBannerUrl.toString(), getBannerSignedUrl)
  ]);
}

export const profileContainerSaga = [
  takeLatest(requestCreateProfile.toString(), createUserProfile),
  takeLatest(requestGetProfile.toString(), getUserProfile),
  takeLatest(requestPaymentData.toString(), getPaymentData),
  takeLatest(requestUploadProfileImage.toString(), uploadProfileImage),
  takeLatest(requestPhoneVerification.toString(), getPhoneVerification),
  takeLatest(requestVerifyOtp.toString(), getVerifyOtp),
  takeLatest(requestGetProfileUrl.toString(), getProfileSignedUrl),
  takeLatest(requestGetAccountType.toString(), getAccountType),
  takeLatest(requestUploadBannerImage.toString(), uploadBannerImage),
  takeLatest(requestGetBannerUrl.toString(), getBannerSignedUrl)
];
