import React, { useEffect, useState } from 'react';
import { colors, fonts } from '@app/themes';
import styled from 'styled-components';
// import ArrowLeft from '@images/icons/ArrowLeftIcon.svg';
import { translate } from '@app/components/IntlGlobalProvider';
import CounterOfferMessage from './components/CounterOfferMessage';
import OfferMessage from './components/OfferMessage';

import BackIcon from '@images/icons/browse-stories/back-icon.svg';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import TicketIcon from '@images/icons/browse-stories/ticket2-icon.svg';

import { requestGetConversationData, requestGetTransactionData, resetState } from './reducer';

import {
  selectConversationData,
  selectDaysLeft,
  selectOfferEndsOn,
  selectOfferPlacedOn,
  selectOfferState,
  selectSellerData,
  selectTransactionData
} from './selectors';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { NavigateProps, OfferHistoryProps, ToggleHistoryType } from './types';
import { BUYER, OFFER_ACTION, OFFER_STATE } from '@app/utils/constants';
import { If } from 'tsw-sdk';
import CounterOfferContainer from '../StoryDetailsPage/components/CounterOfferContainer';
import { Portal } from '@app/components/Portal';
import CounterOfferModal from '../DashboardContainerPage/IndividualDashboard/tabs/MyOffers/components/CounterOfferModal';
import DeclineOfferModal from '../DashboardContainerPage/IndividualDashboard/tabs/MyOffers/components/DeclineOfferModal';
import AcceptOfferModal from '../DashboardContainerPage/IndividualDashboard/tabs/MyOffers/components/AcceptOfferModal';
import {
  requestConcludeOffer,
  requestGetOfferId,
  requestGetStory,
  requestMakeCounterOffer,
  requestMakeOffer
} from '../StoryDetailsPage/reducer';
import { selectUserProfile } from '@app/containers/ProfileContainer/selectors';
import { Button } from 'antd';
import RetractOffer from '@images/icons/my-offers/retract-offer.svg';
import RetractOfferModal from './components/RetractOfferModal';
import routeConstants from '@app/utils/routeConstants';
import AccessResponseCard from './components/AccessResponseCard';
import AccessRequestCard from './components/AccessRequestCard';
import { selectOfferId, selectStoryData } from '../StoryDetailsPage/selectors';
import MakeOfferComponent from '../StoryDetailsPage/components/MakeOfferComponent';

const OfferHistoryContainer = styled.div`
  width: 100%;
  min-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-top: 5rem;
  background-color: ${colors.dashboardBg};
  padding-left: 2rem;
  padding-bottom: 2rem;
  padding-right: 2rem;
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  ${fonts.weights.fw800()}
  ${fonts.size.small()}
  line-height: 1rem;
  color: ${colors.tagBorder};
  width: 4.25rem;
`;

const StyledImg = styled.img``;

const OfferHistoryLogContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-top: 3.25rem;
  padding-right: 4.21rem;
`;

const AwaitingResponseContainer = styled.div`
  width: 41rem;
  margin-top: 1.875rem;
  ${fonts.weights.fw700()};
  ${fonts.size.xBig()};
  line-height: 1.75rem;
  color: ${colors.myOffers.storyTitle};
  text-align: center;
  margin-right: auto;
  margin-left: auto;
`;

const OfferEndsPill = styled.div`
  width: fit-content;
  height: 2.4rem;
  margin-top: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.39rem 1.18rem;
  background: ${colors.myOffers.closedOfferCardBg};
  color: ${colors.myOffers.dateText};
  border-radius: 1.18rem;
  margin-left: auto;
  margin-right: auto;
  text-transform: uppercase;
  ${fonts.weights.fw800()};
  ${fonts.size.small()};
  line-height: 1.56rem;
  white-space: nowrap;
`;

const RetractOfferButton = styled(Button)`
  && {
    width: 13.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.62rem;
    background-color: ${colors.darkBorder};
    color: ${colors.white};
    border-radius: 0.5rem;
    ${fonts.weights.fw800()};
    ${fonts.size.regular()};
    line-height: 1.5rem;
    border: none;
    margin: 1.43rem auto;
    padding: 0.62rem 2rem;
  }
`;

const ActionsContainer = styled.div`
  margin: 0 auto;
`;

const StyledLink = styled(Link)`
  && {
    width: 13.25rem;
  }
`;

const NavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const NavigateButtonContainer = styled.div`
  display: flex;
`;

const NavigateButton = styled(Button)<NavigateProps>`
  border-radius: 0.5rem;
  padding: 0.75rem 2rem 0.75rem 2rem;
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.isActive ? `${colors.primary}` : `${colors.myOffers.disableBackground}`)};
  color: ${colors.white};
  cursor: pointer;
  ${fonts.weights.fw800()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
  border: none;

  &:hover {
    background-color: ${(props) => (props.isActive ? `${colors.primary}` : `${colors.myOffers.disableBackground}`)};
    color: ${colors.white};
    border: none;
  }

  &:focus {
    background-color: ${(props) => (props.isActive ? `${colors.primary}` : `${colors.myOffers.disableBackground}`)};
    color: ${colors.white};
    border: none;
  }
`;

const EmptyDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  align-items: center;
  justify-content: center;
`;

const EmptyDataText = styled.p`
  ${fonts.weights.fw800()};
  ${fonts.size.xBig()};
  line-height: 1.5rem;
  color: ${colors.primary};
`;

const ExpectedPriceText = styled.p`
  ${fonts.weights.fw800()};
  ${fonts.size.regular()};
  line-height: 1.5rem;
  color: ${colors.primary};
`;

const ExpectedPriceAmount = styled.p`
  ${fonts.weights.fw800()};
  ${fonts.size.regular()};
  line-height: 1.5rem;
  color: ${colors.myOffers.counterOfferText};
`;

const TextContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  margin-top: 0.75rem;
`;

const OfferHistory = ({
  transactions,
  daysLeft,
  offerPlacedOn,
  offerEndsOn,
  offerState,
  buyerProfile,
  sellerData,
  conversationData,
  offerIdData,
  story,
  dispatchGetOfferHistory,
  dispatchConcludeOffer,
  dispatchCounterOffer,
  dispatchResetState,
  dispatchGetConversationHistory,
  dispatchGetOfferId,
  dispatchGetStoryDetails,
  dispatchMakeOffer
}: OfferHistoryProps) => {
  const location = useLocation<any>();
  const history = useHistory();

  const [showAcceptOfferModal, setShowAcceptOfferModal] = useState<boolean>(false);
  const [showCounterOfferModal, setShowCounterOfferModal] = useState<boolean>(false);
  const [showDeclineOfferModal, setShowDeclineOfferModal] = useState<boolean>(false);
  const [showRetractOfferModal, setShowRetractOfferModal] = useState<boolean>(false);
  const [showMakeOffer, setShowMakeOffer] = useState<boolean>(false);

  const [showHistory, setShowHistory] = useState<ToggleHistoryType>('showOfferHistory');

  const [writerName, setWriterName] = useState<string>('');
  const [currStoryId, setCurrStoryId] = useState<string>('');
  const [currAuthorId, setCurrAuthorId] = useState<string>('');

  const [latestOfferAmount, setLatestOfferAmount] = useState<number>(0);

  const params = new URLSearchParams(location?.search);

  const offerId = params.get('offerId');
  const storyId = params.get('storyId');
  const authorId = params.get('authorId');
  const historyType = params.get('type');

  useEffect(() => {
    if (location?.state?.authorName) {
      setWriterName(location?.state?.authorName);
    }

    // if (!offerId || !storyId || !authorId) {
    //   history.goBack();
    // }

    if (historyType === 'conversation') {
      setShowHistory('showRequestHistory');
    } else {
      setShowHistory('showOfferHistory');
    }

    return () => {
      dispatchResetState();
    };
  }, []);

  useEffect(() => {
    if (storyId && authorId && buyerProfile?.id) {
      dispatchGetOfferHistory(storyId, buyerProfile?.id, authorId);
      dispatchGetConversationHistory(storyId, buyerProfile?.id, authorId);
      dispatchGetOfferId(storyId, buyerProfile?.id);
      dispatchGetStoryDetails(storyId, buyerProfile?.id, 0, 1);
      setCurrStoryId(storyId);
      setCurrAuthorId(authorId);
    }
  }, [storyId, authorId, buyerProfile]);

  useEffect(() => {
    const lastValidTransaction = getLastValidTransaction();

    setLatestOfferAmount(lastValidTransaction?.amount);
  }, [transactions]);

  const getLastValidTransaction = () => {
    let lastValidTransaction = transactions[transactions.length - 1];

    if (transactions.length > 1) {
      const sellerTransactions = [OFFER_STATE.SELLER_COUNTERED];

      const sellerOffers = transactions.filter((transaction: any) =>
        sellerTransactions.includes(transaction.offerState)
      );

      lastValidTransaction = sellerOffers[sellerOffers.length - 1];
    }

    return lastValidTransaction;
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const handleCounterOffer = (counterOffer: number, noteFromBuyer: string) => {
    setShowCounterOfferModal(false);
    dispatchCounterOffer(currStoryId, offerIdData, buyerProfile?.id, counterOffer, noteFromBuyer, currAuthorId);
  };

  const handleAcceptOffer = () => {
    dispatchConcludeOffer(currStoryId, offerIdData, buyerProfile?.id, currAuthorId, OFFER_ACTION.ACCEPT_OFFER, '');
    setShowAcceptOfferModal(false);
  };

  const handleDeclineOffer = (reason: string) => {
    dispatchConcludeOffer(currStoryId, offerIdData, buyerProfile?.id, currAuthorId, OFFER_ACTION.DECLINE_OFFER, reason);
    setShowDeclineOfferModal(false);
  };

  const handleRetractOffer = (reason: string) => {
    dispatchConcludeOffer(currStoryId, offerIdData, buyerProfile?.id, currAuthorId, OFFER_ACTION.RETRACT_OFFER, reason);
    setShowRetractOfferModal(false);
  };

  const handleOfferMade = (offerAmt: number, offerNote: string) => {
    setShowMakeOffer(false);
    dispatchMakeOffer(currStoryId, '', buyerProfile?.id, offerAmt, offerNote, currAuthorId);
  };

  const getPillMessage = () => {
    let message = '';

    if (offerState === OFFER_STATE.BUYER_ACCEPTED) {
      message = translate('offer_accepted_by_you');
    } else if (offerState === OFFER_STATE.SELLER_ACCEPTED) {
      message = translate('offer_accepted_by_writer');
    } else {
      message = translate('offer_ends_in', { time: daysLeft });
    }

    return message;
  };

  return (
    <OfferHistoryContainer data-testid="offer-history-container">
      <NavigationContainer>
        <BackButton onClick={handleGoBack} data-testid="back-btn">
          <StyledImg src={BackIcon} />
          {translate('back')}
        </BackButton>
        <NavigateButtonContainer>
          <NavigateButton
            isActive={showHistory === 'showRequestHistory'}
            onClick={() => setShowHistory('showRequestHistory')}
            data-testid="request-history-btn"
          >
            {translate('send_requests')}
          </NavigateButton>
          <NavigateButton
            isActive={showHistory === 'showOfferHistory'}
            onClick={() => setShowHistory('showOfferHistory')}
            data-testid="offer-history-btn"
          >
            {translate('offers')}
          </NavigateButton>
        </NavigateButtonContainer>
      </NavigationContainer>
      <If condition={showHistory === 'showOfferHistory' && offerIdData !== ''}>
        <OfferHistoryLogContainer>
          {transactions?.map((conversation: any) => {
            if (
              conversation.offerState !== OFFER_STATE.SELLER_DECLINED &&
              conversation.offerState !== OFFER_STATE.SELLER_ACCEPTED &&
              conversation.offerState !== OFFER_STATE.BUYER_DECLINED &&
              conversation.offerState !== OFFER_STATE.BUYER_ACCEPTED
            ) {
              if (conversation.offerEntity?.placedBy === BUYER) {
                return (
                  <CounterOfferMessage
                    conversation={conversation}
                    offerPlacedOn={offerPlacedOn}
                    offerEndsOn={offerEndsOn}
                    daysLeft={daysLeft}
                  />
                );
              } else {
                return (
                  <StyledLink
                    to={{
                      pathname: routeConstants.dashboard.route,
                      search: `?activeTab=offer-detail&offerId=${offerId}`,
                      state: {
                        transaction: conversation,
                        storyId: storyId,
                        currentState: offerState
                      }
                    }}
                  >
                    <OfferMessage
                      conversation={conversation}
                      offerPlacedOn={offerPlacedOn}
                      offerEndsOn={offerEndsOn}
                      daysLeft={daysLeft}
                    />
                  </StyledLink>
                );
              }
            }
          })}
        </OfferHistoryLogContainer>
        <If condition={offerState === OFFER_STATE.SELLER_COUNTERED}>
          <ActionsContainer>
            <CounterOfferContainer
              acceptOffer={setShowAcceptOfferModal}
              declineOffer={setShowDeclineOfferModal}
              offerAmount={latestOfferAmount}
              counterOffer={setShowCounterOfferModal}
            />
          </ActionsContainer>
        </If>
        <If condition={offerState === OFFER_STATE.SELLER_DECLINED}>
          <AwaitingResponseContainer>{translate('seller_declined_offer')}</AwaitingResponseContainer>
        </If>

        <If condition={offerState === OFFER_STATE.BUYER_DECLINED}>
          <AwaitingResponseContainer>{translate('buyer_declined_offer')}</AwaitingResponseContainer>
        </If>

        <If condition={offerState === OFFER_STATE.OFFER_PLACED || offerState === OFFER_STATE.BUYER_COUNTERED}>
          <AwaitingResponseContainer>{translate('awaiting_response')}</AwaitingResponseContainer>
        </If>

        {offerState !== OFFER_STATE.SELLER_DECLINED && offerState !== OFFER_STATE.BUYER_DECLINED && (
          <OfferEndsPill>{getPillMessage()}</OfferEndsPill>
        )}

        <If condition={offerState === OFFER_STATE.OFFER_PLACED || offerState === OFFER_STATE.BUYER_COUNTERED}>
          <RetractOfferButton onClick={() => setShowRetractOfferModal(true)} data-testid="retract-offer-btn">
            <StyledImg src={RetractOffer} />
            {translate('retract')}
          </RetractOfferButton>
        </If>
      </If>

      <If condition={showHistory === 'showOfferHistory' && offerIdData === ''}>
        <EmptyDataContainer>
          <EmptyDataText>{translate('no_offer_history')}</EmptyDataText>
          <If condition={story?.expectedOffer}>
            <TextContainer>
              <ExpectedPriceText>{translate('expected_price_history')}</ExpectedPriceText>
              <ExpectedPriceAmount>{`₹ ${story?.expectedOffer?.toLocaleString('en-IN')}`}</ExpectedPriceAmount>
            </TextContainer>
          </If>

          <RetractOfferButton onClick={() => setShowMakeOffer(true)}>
            <StyledImg src={TicketIcon} />
            {translate('make_offer')}
          </RetractOfferButton>
        </EmptyDataContainer>
      </If>

      <If condition={showHistory === 'showRequestHistory' && conversationData?.length === 0}>
        <EmptyDataContainer>
          <EmptyDataText>{translate('no_conversation_history')}</EmptyDataText>
        </EmptyDataContainer>
      </If>

      <If condition={showHistory === 'showRequestHistory' && conversationData?.length > 0}>
        <OfferHistoryLogContainer>
          {conversationData?.map((conversation: any) =>
            conversation?.userType === BUYER ? (
              <AccessRequestCard notes={conversation?.message} />
            ) : (
              <AccessResponseCard sellerData={sellerData} notes={conversation?.message} state={conversation?.state} />
            )
          )}
        </OfferHistoryLogContainer>
      </If>

      <If condition={showCounterOfferModal}>
        <Portal>
          <CounterOfferModal
            handleCancel={() => setShowCounterOfferModal(false)}
            counterOfferAmount={latestOfferAmount}
            onCounterOffer={handleCounterOffer}
          />
        </Portal>
      </If>

      <If condition={showDeclineOfferModal}>
        <Portal>
          <DeclineOfferModal handleCancel={() => setShowDeclineOfferModal(false)} handleDecline={handleDeclineOffer} />
        </Portal>
      </If>

      <If condition={showAcceptOfferModal}>
        <Portal>
          <AcceptOfferModal
            handleCancel={() => setShowAcceptOfferModal(false)}
            handleAccept={handleAcceptOffer}
            offerAmount={latestOfferAmount}
            authorName={writerName}
            profilePic={transactions[transactions?.length - 1]?.offerEntity?.profilePic}
          />
        </Portal>
      </If>

      <If condition={showRetractOfferModal}>
        <Portal>
          <RetractOfferModal handleCancel={() => setShowRetractOfferModal(false)} handleRetract={handleRetractOffer} />
        </Portal>
      </If>

      <If condition={showMakeOffer}>
        <Portal>
          <MakeOfferComponent handleBack={() => setShowMakeOffer(false)} onOffer={handleOfferMade} />
        </Portal>
      </If>
    </OfferHistoryContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  offerState: selectOfferState(),
  transactions: selectTransactionData(),
  offerPlacedOn: selectOfferPlacedOn(),
  offerEndsOn: selectOfferEndsOn(),
  daysLeft: selectDaysLeft(),
  buyerProfile: selectUserProfile(),
  conversationData: selectConversationData(),
  sellerData: selectSellerData(),
  offerIdData: selectOfferId(),
  story: selectStoryData()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGetOfferHistory: (storyId: string, buyerId: string, sellerId: string) =>
      dispatch(
        requestGetTransactionData({
          storyId,
          sellerId,
          buyerId
        })
      ),
    dispatchConcludeOffer: (
      storyId: string,
      offerId: string,
      buyerId: string,
      sellerId: string,
      offerAction: string,
      reason: string
    ) => dispatch(requestConcludeOffer({ storyId, offerId, buyerId, sellerId, offerAction, reason })),
    dispatchCounterOffer: (
      storyId: string,
      offerId: string,
      buyerId: string,
      amount: number,
      notes: string,
      sellerId: string
    ) => dispatch(requestMakeCounterOffer({ storyId, offerId, buyerId, amount, notes, sellerId })),
    dispatchGetConversationHistory: (storyId: string, buyerId: string, sellerId: string) =>
      dispatch(
        requestGetConversationData({
          storyId,
          sellerId,
          buyerId
        })
      ),
    dispatchMakeOffer: (
      storyId: string,
      offerId: string,
      buyerId: string,
      amount: number,
      notes: string,
      sellerId: string
    ) => dispatch(requestMakeOffer({ storyId, offerId, buyerId, amount, notes, sellerId })),
    dispatchGetStoryDetails: (storyId: string, buyerId: string, limit: number, page: number) =>
      dispatch(requestGetStory({ storyId, buyerId, limit, page })),
    dispatchGetOfferId: (storyId: string, buyerId: string) => dispatch(requestGetOfferId({ storyId, buyerId })),
    dispatchResetState: () => dispatch(resetState())
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OfferHistory) as React.FC;

export const OfferHistoryTest = OfferHistory;
