import { createSelector } from 'reselect';
import get from 'lodash-es/get';
import { initialState } from './reducer';

export const selectOffersHistoryContainerReducer = (state: any) => state.offerHistoryReducer || initialState;

export const selectTransactionData = () =>
  createSelector(selectOffersHistoryContainerReducer, (substate) => get(substate, 'transactions'));

export const selectOfferState = () =>
  createSelector(selectOffersHistoryContainerReducer, (substate) => get(substate, 'offerState'));

export const selectDaysLeft = () =>
  createSelector(selectOffersHistoryContainerReducer, (substate) => get(substate, 'daysLeft'));

export const selectOfferPlacedOn = () =>
  createSelector(selectOffersHistoryContainerReducer, (substate) => get(substate, 'offerPlacedOn'));

export const selectOfferEndsOn = () =>
  createSelector(selectOffersHistoryContainerReducer, (substate) => get(substate, 'offerEndsOn'));

export const selectConversationData = () =>
  createSelector(selectOffersHistoryContainerReducer, (substate) => get(substate, 'conversations'));

export const selectSellerData = () =>
  createSelector(selectOffersHistoryContainerReducer, (substate) => get(substate, 'sellerData'));
