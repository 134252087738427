import React from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import { colors, fonts, media } from '@themes/index';
import history from '@app/utils/history';

const StyledHeader = styled(Layout.Header)`
  && {
    &.ant-layout-header {
      position: fixed;
      width: 100%;
      height: 4rem;
      padding: 0 2rem;
    }
    display: flex;
    ${media.greaterThan('tablet')`
    flex-direction: row;
  `}

    align-items: center;
    justify-content: space-between;
    background-color: ${colors.headerColor};
  }
`;
const StyledTitle = styled.h1`
  ${fonts.style.spotwriterHeading}
  line-height: 3.33rem;
  margin-left: 5.8rem;
  cursor: pointer;
`;

const CustomHeader = () => {
  return (
    <StyledHeader>
      <StyledTitle onClick={() => history.push('/')} data-testid="header-title">
        Spotwriters
      </StyledTitle>
    </StyledHeader>
  );
};

export default CustomHeader;
