import { translate } from '@app/components/IntlGlobalProvider';
import { getMutationResponse } from '@app/utils/graphqlUtils';
import { PAYMENT } from '@app/utils/queries';
import { AnyAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { get } from 'lodash-es';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { SEND_RENEWAL_REMINDER } from './queries';
import {
  failureGenerateRenewalPaymentLink,
  failureSendReminder,
  requestGenerateRenewalPaymentLink,
  requestSendReminder,
  successGenerateRenewalPaymentLink,
  successSendReminder
} from './reducer';

export function* sendRenewalReminderToAdmin(): Generator<any, any, any> {
  try {
    const { ok, data, error } = yield call(getMutationResponse, SEND_RENEWAL_REMINDER, {});

    if (ok) {
      yield put(successSendReminder(data));
      message.success(translate('reminder_sent'));
    } else {
      yield put(failureSendReminder(error));
      message.error(get(error, 'message', ''));
    }
  } catch (err) {
    yield put(failureSendReminder(err));
    message.error(get(err, 'message', ''));
  }
}

export function* sendRenewalPayment(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      amount: action.payload.amount,
      reason: action.payload.reason,
      notes: action.payload.notes
    };

    const { ok, data, error } = yield call(getMutationResponse, PAYMENT, payload);
    if (ok) {
      window.open(data?.generatePaymentLink.url, '_self');

      yield put(successGenerateRenewalPaymentLink());
    } else {
      yield put(failureGenerateRenewalPaymentLink(error));
    }
  } catch (err) {
    yield put(failureGenerateRenewalPaymentLink(err));
  }
}

export default function* DashboardContainerPageSaga() {
  yield all([
    takeLatest(requestSendReminder.toString(), sendRenewalReminderToAdmin),
    takeLatest(requestGenerateRenewalPaymentLink.toString(), sendRenewalPayment)
  ]);
}

export const dashboardContainerSaga = [
  takeLatest(requestSendReminder.toString(), sendRenewalReminderToAdmin),
  takeLatest(requestGenerateRenewalPaymentLink.toString(), sendRenewalPayment)
];
