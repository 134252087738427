import { put, takeLatest, call, all } from 'redux-saga/effects';
import {
  requestUpdateSelectedPackage,
  successUpdateSelectedPackage,
  failureUpdateSelectedPackage,
  requestVerifyCoupon,
  successVerifyCoupon,
  failureVerifyCoupon
} from './reducer';
import { getMutationResponse, getQueryResponse } from '@utils/graphqlUtils';
import { UPDATE_BUYER_SUBSCRIPTION_PLAN, VERIFY_COUPON } from './queries';
import { AnyAction } from '@reduxjs/toolkit';
import { message, notification } from 'antd';
import { translate } from '@app/components/IntlGlobalProvider';
import { get } from 'lodash-es';
import { successSetSelectedPackage } from '../ProfileContainer/reducer';
import history from '@app/utils/history';
import routeConstants from '@app/utils/routeConstants';

import { AVAILABLE_PACKAGES, SUBSCRIPTION_PLAN_TYPES } from '@app/utils/constants';

export function* updateSubscriptionPlan(action: AnyAction): Generator<any, any, any> {
  try {
    const subscriptionPlan = action.payload;

    const { ok, error } = yield call(getMutationResponse, UPDATE_BUYER_SUBSCRIPTION_PLAN, {
      input: { plan: subscriptionPlan }
    });

    if (ok) {
      yield put(successUpdateSelectedPackage());
      yield put(
        successSetSelectedPackage(
          subscriptionPlan === SUBSCRIPTION_PLAN_TYPES.INDIVIDUAL
            ? AVAILABLE_PACKAGES.INDIVIDUAL
            : AVAILABLE_PACKAGES.ADMIN
        )
      );

      history.push(routeConstants.profile.route);
    } else {
      yield put(failureUpdateSelectedPackage(error));
      notification.open({
        message: translate('error'),
        description: get(error, 'message')
      });
    }
  } catch (err) {
    yield put(failureUpdateSelectedPackage(err));
  }
}

export function* verifyCoupon(action: AnyAction): Generator<any, any, any> {
  try {
    const couponCode = action.payload.couponCode;
    const buyerType = action.payload.buyerType;

    const { ok, data, error } = yield call(getQueryResponse, VERIFY_COUPON, {
      input: { isBuyer: true, code: couponCode, userType: buyerType }
    });

    if (ok) {
      const verifyCouponData = get(data, 'verifyCoupon', {});

      const status = get(verifyCouponData, 'status', false);
      const reason = get(verifyCouponData, 'reason', '');

      if (status) {
        yield put(successVerifyCoupon(couponCode));
      } else {
        yield put(failureVerifyCoupon({}));
        message.error(reason);
      }
    } else {
      yield put(failureVerifyCoupon(error));
      notification.open({
        message: translate('error'),
        description: get(error, 'message')
      });
    }
  } catch (err) {
    yield put(failureVerifyCoupon(err));
  }
}

export default function* PackageDetailsPage() {
  yield all([
    takeLatest(requestUpdateSelectedPackage.toString(), updateSubscriptionPlan),
    takeLatest(requestVerifyCoupon.toString(), verifyCoupon)
  ]);
}

export const packageDetailsSaga = [
  takeLatest(requestUpdateSelectedPackage.toString(), updateSubscriptionPlan),
  takeLatest(requestVerifyCoupon.toString(), verifyCoupon)
];
