import { colors, fonts } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import DownloadIcon from '@images/icons/browse-stories/download-icon.svg';
import supportIcon from '@images/icons/my-offers/support-white.svg';

import { translate } from '@app/components/IntlGlobalProvider';
import _ from 'lodash';
import { ClosedCardProps, ClosedOfferCardProps } from '../../types';
import { If } from 'tsw-sdk';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';

const CardContainer = styled.div<ClosedCardProps>`
  width: 19.12rem;
  display: flex;
  flex-direction: column;
  ${(props) => (props.isStoryLegal ? `background-color: ${colors.myOffers.closedOfferCardBg}` : '')};
  border-radius: 1rem;
  box-shadow: 0px 0px 1.25rem ${colors.myOffers.offerCardShadowColor};
  overflow: hidden;
  cursor: pointer;
`;

const ClosedOfferCardHeader = styled.div`
  width: 100%;
  height: 5.25rem;
  background-color: ${colors.myOffers.offerCardHeaderBg};
  display: flex;
  position: relative;
  overflow: hidden;
`;

const ClosedOfferCardHeaderBg = styled.div`
  width: 120%;
  position: absolute;
  top: -1rem;
  left: -10%;
  display: flex;
  flex-wrap: wrap;
  z-index: 1;
`;

const ClosedOfferCardHeaderBgText = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw900()};
  ${fonts.size.BannerText()};
  color: ${colors.myOffers.bannerTextClr};
  line-height: 2.62rem;
  margin-left: 0.8rem;
  white-space: nowrap;
`;

const CardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 2rem;
  justify-content: center;
  align-items: center;
`;

const ClosedOfferCardBodyTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StoryNameTitle = styled.h3`
  max-width: 80%;
  ${fonts.family.SpoofFamily()};
  ${fonts.size.xBig()};
  ${fonts.weights.fw900()};
  color: ${colors.myOffers.storyTitle};
  margin-right: 0.68rem;
  line-height: 1.87rem;
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
`;

const DownloadOfferIcon = styled.img`
  width: 0.75rem;
  height: 0.75rem;
`;

const ClosedOfferCardDate = styled.p`
  width: 100%;
  margin-top: 0.5rem;
  ${fonts.weights.fw700()};
  ${fonts.size.xSmall()};
  line-height: 1rem;
  color: ${colors.myOffers.dateText};
  text-align: center;
`;

const OfferAmountText = styled.p`
  ${fonts.size.extraSmall()};
  ${fonts.weights.fw800()};
  line-height: 1rem;
  color: ${colors.myOffers.dateText};
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  text-align: center;
  letter-spacing: 0.88px;
`;

const ClosedTag = styled.div`
  width: 6.12rem;
  height: 1.5rem;
  background-color: ${colors.myOffers.closedTagBg};
  border-radius: 6.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${colors.myOffers.dateText};
  ${fonts.size.regular()};
  ${fonts.weights.fw800()};
  line-height: 1rem;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 0.11rem;
`;

const RejectedStateText = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw900()};
  ${fonts.size.big()};
  line-height: 1.875rem;
  text-align: center;
  color: ${colors.myOffers.rejectedStateColor};
  margin-top: 5px;
`;

const SupportButton = styled(Button)`
  && {
    padding: 0.75rem 2rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: ${colors.myOffers.supportButtonBg};
    ${fonts.weights.fw800()};
    ${fonts.size.small()};
    color: ${colors.myOffers.supportText};
    line-height: 1.5rem;
    letter-spacing: 2%;
    gap: 0.5rem;
    margin-top: 0.75rem;
  }
`;

const SupportIconImage = styled.img``;

const ClosedOffersCard = ({
  title,
  date,
  amount,
  storyData,
  downloadLoading,
  writerId,
  isStoryLegal,
  latestOfferDetails,
  handleClick,
  handleDownload,
  showSupportModal
}: ClosedOfferCardProps) => {
  const SpinIcon = <LoadingOutlined style={{ fontSize: 12 }} spin />;

  const handleDownloadClick = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e.stopPropagation();
    handleDownload(storyData?.id, writerId);
  };

  return (
    <CardContainer
      isStoryLegal={isStoryLegal}
      onClick={() => handleClick(amount, storyData?.writer)}
      data-testid="closed-offer-card-container"
    >
      <ClosedOfferCardHeader>
        <ClosedOfferCardHeaderBg>
          {_.range(10).map((number) => (
            <ClosedOfferCardHeaderBgText key={number}>{title}</ClosedOfferCardHeaderBgText>
          ))}
        </ClosedOfferCardHeaderBg>
      </ClosedOfferCardHeader>
      <CardContentContainer>
        <ClosedOfferCardBodyTitle>
          <StoryNameTitle>{title}</StoryNameTitle>
          <If condition={!downloadLoading} otherwise={<Spin indicator={SpinIcon} />}>
            <DownloadOfferIcon data-testid="download-icon" src={DownloadIcon} onClick={(e) => handleDownloadClick(e)} />
          </If>
        </ClosedOfferCardBodyTitle>
        <ClosedOfferCardDate>{date}</ClosedOfferCardDate>
        <If condition={isStoryLegal}>
          <OfferAmountText>{translate('amount_cards', { amount: amount?.toLocaleString('en-IN') })}</OfferAmountText>
          <ClosedTag>{translate('closed')}</ClosedTag>
        </If>
        <If condition={!isStoryLegal}>
          <RejectedStateText>{latestOfferDetails?.notes}</RejectedStateText>
          <SupportButton onClick={showSupportModal} data-testid="closed-support-btn">
            <SupportIconImage src={supportIcon} />
            {translate('support')}
          </SupportButton>
        </If>
      </CardContentContainer>
    </CardContainer>
  );
};

export default ClosedOffersCard;
