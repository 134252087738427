import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import SearchIcon from '@images/icons/browse-stories/search-icon.svg';
import StoryCard from '@app/components/StoryCard';
import {
  requestGenerateStoryPdf,
  requestGetStories,
  requestToggleFavourite,
  resetState,
  resetStoryPdfData
} from '../BrowseStories/reducer';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { createStructuredSelector } from 'reselect';
import { selectUserProfile } from '@app/containers/ProfileContainer/selectors';
import {
  selectStoryPdfUrl,
  selectStoryPdfLoading,
  selectBrowseStoriesTotal,
  selectLoadingStories
} from '@app/containers/DashboardContainerPage/IndividualDashboard/tabs/BrowseStories/selectors';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { selectStories } from '../BrowseStories/selectors';
import { MyFavouritesProps } from './types';
import { STORIES_STATES } from '@app/utils/constants';
import { Input } from 'antd';
import { If, Pagination } from 'tsw-sdk';
import CustomEmpty from '@app/components/CustomEmpty';
import StyledSpin from '@app/components/StyledSpin';

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 4.5rem 7.06rem 2rem 2rem;
  background: ${colors.dashboardBg};
  min-height: 100%;
`;

const HeadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const FavoritesHeading = styled.h1`
  ${fonts.family.SpoofFamily};
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  line-height: 3.93rem;
  color: ${colors.myDashboardColors.heading};
  margin-bottom: 0;
  text-align: center;
`;

const CustomSearchInput = styled(Input)`
  && {
    width: 18.75rem;
    padding: 0.5rem;
    gap: 0.62rem;
    height: 1.93rem;
    border: 0.5px solid ${colors.lightBorder};
    border-radius: 0.31rem;
    ${fonts.size.xSmall()};
    line-height: 0.93rem;
    background: ${colors.white};

    &.ant-input:placeholder-shown {
      color: ${colors.lightBorder};
      ${fonts.size.xSmall()};
      line-height: 0.93rem;
      ${fonts.weights.fw400()};
    }

    &.ant-input-affix-wrapper > input.ant-input {
      background: ${colors.white};
    }
  }
`;

const StoryCardWrapper = styled.div`
  display: flex;

  gap: 2rem;
  flex-wrap: wrap;
  margin-top: 3rem;
`;

const StyledImg = styled.img``;

const PaginationContainer = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const MyFavourites = ({
  buyerProfile,
  stories,
  storyPdfUrl,
  storyPdfLoading,
  total,
  loadingFavStories,
  dispatchResetState,
  dispatchToggleFavStory,
  dispatchGenerateStoryPdf,
  dispatchGetFavStories,
  dispatchResetStoryPdf
}: MyFavouritesProps) => {
  const perPage = 10;

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [favStories, setFavStories] = useState(stories);

  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    return () => {
      dispatchResetState();
    };
  }, []);

  useEffect(() => {
    if (storyPdfUrl) {
      const anchorTag = document.createElement('a');

      anchorTag.href = storyPdfUrl;
      anchorTag.setAttribute('target', '_blank');
      anchorTag.click();

      dispatchResetStoryPdf();
    }
  }, [storyPdfUrl]);

  useEffect(() => {
    dispatchGetFavStories(STORIES_STATES.PUBLISHED, perPage, currentPage, buyerProfile?.id, true, searchTerm);
  }, [currentPage]);

  useEffect(() => {
    if (searchTerm) {
      dispatchGetFavStories(STORIES_STATES.PUBLISHED, perPage, currentPage, buyerProfile?.id, true, searchTerm);
    }
  }, [searchTerm]);

  useEffect(() => {
    setFavStories(stories);
  }, [stories]);

  const toggleCardFavourite = (storyId: string) => {
    dispatchToggleFavStory(storyId);
    setFavStories((curr: any) => curr.filter((story: any) => story.id !== storyId));
  };

  const handleStoryCardDownload = (storyId: string, sellerId: string) => {
    dispatchGenerateStoryPdf(buyerProfile.id, storyId, sellerId);
  };

  return (
    <Container>
      <HeadingContainer>
        <FavoritesHeading>{translate('my_fav')}</FavoritesHeading>
        <CustomSearchInput
          suffix={<StyledImg src={SearchIcon} data-testid="search-icon" />}
          placeholder={translate('search')}
          data-testid="search-input-fav"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
        />
      </HeadingContainer>
      <PaginationContainer>
        <Pagination
          perPage={perPage}
          currentPage={currentPage}
          total={total}
          setPage={(page: number) => setCurrentPage(page)}
          testKey="favourites"
        />
      </PaginationContainer>
      <If condition={!loadingFavStories} otherwise={<StyledSpin />}>
        <If
          condition={favStories.length > 0}
          otherwise={<CustomEmpty text={searchTerm ? 'no_results' : 'no_stories'} />}
        >
          <StoryCardWrapper>
            {favStories.map((el: any) => (
              <StoryCard
                key={el.id}
                canMakeOffer={true}
                isFavourite={el.buyerActions?.hasLiked}
                storyData={el}
                toggleFavourite={toggleCardFavourite}
                handleDownload={handleStoryCardDownload}
                downloadLoading={storyPdfLoading}
              />
            ))}
          </StoryCardWrapper>
        </If>
      </If>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  stories: selectStories(),
  buyerProfile: selectUserProfile(),
  storyPdfUrl: selectStoryPdfUrl(),
  storyPdfLoading: selectStoryPdfLoading(),
  total: selectBrowseStoriesTotal(),
  loadingFavStories: selectLoadingStories()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGetFavStories: (
      state: string,
      limit: number,
      page: number,
      buyerId: string,
      onlyFavourites?: boolean,
      search?: string
    ) => dispatch(requestGetStories({ state, limit, page, buyerId, onlyFavourites, search })),
    dispatchResetState: () => dispatch(resetState()),
    dispatchToggleFavStory: (storyId: string) => dispatch(requestToggleFavourite({ storyId })),
    dispatchGenerateStoryPdf: (buyerId: string, storyId: string, sellerId: string) =>
      dispatch(
        requestGenerateStoryPdf({
          buyerId,
          storyId,
          sellerId
        })
      ),
    dispatchResetStoryPdf: () => dispatch(resetStoryPdfData())
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, injectIntl)(MyFavourites) as React.FC;

export const MyFavouritesTests = MyFavourites;
