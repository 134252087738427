import React, { useState, useEffect } from 'react';
import { colors, fonts } from '@app/themes';
import styled from 'styled-components';
import { Checkbox } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { CustomButton, CustomInput, If } from 'tsw-sdk';
import { translate } from '@app/components/IntlGlobalProvider';
import { useHistory } from 'react-router-dom';
import routeConstants from '@app/utils/routeConstants';
import _ from 'lodash';
import { connect } from 'react-redux';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { AVAILABLE_PACKAGES, COUPON_BUYER_TYPES, SUBSCRIPTION_PLAN_TYPES } from '@app/utils/constants';
import {
  requestUpdateSelectedPackage,
  requestVerifyCoupon,
  resetCouponCodeValid,
  setPrivacyPolicyCheckBox,
  setPrivacyPolicySeen,
  setTermsCheckbox,
  setTermsSeen
} from './reducer';
import { createStructuredSelector } from 'reselect';
import {
  selectUpdateSelectedPackageLoading,
  selectIsTermsChecked,
  selectIsTermsSeen,
  selectCouponCode,
  selectIsPrivacyPolicyChecked,
  selectIsPrivacyPolicySeen,
  selectCouponCodeValid
} from './selectors';
import { selectCurrentSelectedPackage } from '@containers/ProfileContainer/selectors';
import { successSetSelectedPackage } from '../ProfileContainer/reducer';
import { Portal } from '@app/components/Portal';
import PrivatePolicyPage from './components/PrivacyPolicyPage';
import { PackageDetailsPageProps } from './types';
import { selectAccountStatusData } from '../HomeContainer/selectors';
import { requestAccountStatus } from '../HomeContainer/reducer';

type PackageCardProps = {
  selected?: boolean;
};

const PackageDetailsPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const PackageDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4.56rem;
  padding: 0 5.81rem;
  flex-wrap: wrap;
  justify-content: center;
`;

const PackageDetailsCard = styled.div<PackageCardProps>`
  width: 35.81rem;
  height: auto;
  min-height: 38.62rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 1rem;
  box-shadow: 0px 0px 1.25rem ${colors.cardShadow};
  cursor: pointer;
  background-color: ${(props) => (props.selected ? colors.selectedCard : 'none')};
`;

const PackageDetailsCardHeader = styled.div`
  width: 100%;
  height: 4rem;
  min-height: 4rem;
  background-color: ${colors.cardHeader};
`;

const PackageDetailsCardTitle = styled.p`
  ${fonts.family.SpoofFamily};
  ${fonts.style.heading};
  line-height: 2.62rem;
  margin-left: 3rem;
  margin-top: 2rem;
`;

const PackageDetailsCardSubTitle = styled.p`
  ${fonts.style.subheading};
  line-height: 1.56rem;
  margin-top: 1rem;
  margin-left: 3rem;
  text-transform: uppercase;
  color: ${colors.cardSubtitle};
  letter-spacing: 0.5rem;
`;

const PackageDetailsCardUserCount = styled.p`
  ${fonts.style.subheading};
  margin-top: 1rem;
  margin-left: 3rem;
  color: ${colors.cardUserCount};
`;

// const PackageDetailsAmount = styled.p`
//   ${fonts.family.SpoofRegular};
//   ${fonts.style.cardSubHeading};
//   color: ${colors.secondary};
//   line-height: 1.93rem;
//   max-width: fit-content;
//   text-decoration: line-through;
//   display: flex-inline;
// `;

// const PackageDetailsAmountSubInfo = styled.p`
//   ${fonts.style.smallBoldText}
//   line-height: 0.93rem;
//   letter-spacing: 0.12rem;
//   color: ${colors.cardUserCount};
//   margin-left: 3rem;
// `;

const PackageDetailsList = styled.ul`
  width: 90%;
  margin-top: 2rem;
  list-style: none;
`;

const PackageDetailsListItem = styled.li`
  ${fonts.size.xRegular};
  line-height: 1.5rem;
  color: ${colors.listItemColor};
`;

const TermsAndConditionsCheckbox = styled(Checkbox)`
  ${fonts.size.xRegular};
  line-height: 1.5rem;
  color: ${colors.listItemColor};

  &&& {
    & .ant-checkbox {
      width: 1.5rem;
      height: 1.5rem;

      & > span {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

const TermsAndConditionsCheckboxLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
  ${fonts.size.xRegular};
  line-height: 1.5rem;
  color: ${colors.listItemColor};
`;

const ReminderText = styled.p`
  ${fonts.size.small()};
  line-height: 1.5rem;
  color: ${colors.redClr};
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: 5rem;
  padding-left: 5.81rem;
  margin-top: 0.875rem;
  padding-bottom: 2rem;
`;

const CheckboxContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-left: 5.81rem;
`;

const CheckboxSubContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const CouponCodeInput = styled(CustomInput)`
  &&& {
    width: 14rem;
    height: 2.5rem;
    margin-right: 1rem;
  }
`;

const CouponCodeInputContainer = styled.div`
  margin-top: 2rem;
  display: none;
  align-items: center;
  padding: 0 5.81rem;
`;

const FreeText = styled.span`
  ${fonts.weights.fw600()};
  ${fonts.family.SpoofRegular};
  ${fonts.size.xBig};
  text-align: center;
  color: ${colors.primary};
`;

const DiscountedPrice = styled.p`
  ${fonts.family.SpoofRegular}
  ${fonts.weights.fw800()};
  font-size: 1rem;
  color: ${colors.primary};

  display: flex;
  align-items: baseline;
  gap: 5px;
  margin-top: 1.5rem;
  margin-left: 3rem;
`;

const PackageDetailsPage = ({
  updateSelectedPackageLoading,
  currentSelectedPackage,
  agreedToTermsOfService,
  isTermsSeen,
  agreedToPrivacyPolicy,
  privacyPolicySeen,
  couponCodeValid,
  accountStatusData,
  couponCode,
  dispatchSetSelectedPackage,
  dispatchSetSelectedPackageLocally,
  dispatchSetTermsCheckbox,
  dispatchSetPrivacyPolicyCheckbox,
  dispatchSetPrivacyPolicySeen,
  dispatchAccountStatus,
  dispatchVerifyCoupon,
  dispatchResetCouponCodeValid
}: PackageDetailsPageProps) => {
  const history = useHistory();

  const [selectedPackage, setSelectedPackage] = useState<number>(3);
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);

  const [agreedWithTerms, setAgreedWithTerms] = useState<boolean>(agreedToTermsOfService);
  // const [termsSeen, setTermsSeenLocal] = useState<boolean>(isTermsSeen);

  const [agreedWithPrivacyPolicyLocal, setAgreedWithPrivacyPolicyLocal] = useState<boolean>(agreedToPrivacyPolicy);
  const [isPrivacyPolicySeen, setIsPrivacyPolicySeen] = useState<boolean>(privacyPolicySeen);

  const [privacyPolicyPageVisible, setPrivacyPolicyPageVisible] = useState<boolean>(false);

  const [coupon, setCoupon] = useState<string>(couponCode);

  const isBtnDisabled = () => {
    let btnDisabled = false;

    if (selectedPackage !== 1 && selectedPackage !== 2) {
      btnDisabled = true;
    }

    if (!agreedWithTerms) {
      btnDisabled = true;
    }

    if (!agreedWithPrivacyPolicyLocal) {
      btnDisabled = true;
    }

    return btnDisabled;
  };

  useEffect(() => {
    const btnDisabled = isBtnDisabled();
    setBtnDisabled(btnDisabled);
  }, [currentSelectedPackage, selectedPackage, agreedWithTerms, agreedWithPrivacyPolicyLocal, isPrivacyPolicySeen]);

  useEffect(() => {
    if (Object.keys(accountStatusData).length > 0) {
      if (accountStatusData?.isRegistrationComplete) {
        history.push(routeConstants.dashboard.route);
      }
    }
  }, [accountStatusData]);

  useEffect(() => {
    dispatchSetTermsCheckbox(agreedWithTerms);
    dispatchSetPrivacyPolicyCheckbox(agreedWithPrivacyPolicyLocal);

    dispatchSetPrivacyPolicySeen(isPrivacyPolicySeen);
  }, [agreedWithTerms, agreedWithPrivacyPolicyLocal, isPrivacyPolicySeen]);

  useEffect(() => {
    if (currentSelectedPackage === AVAILABLE_PACKAGES.INDIVIDUAL) {
      setSelectedPackage(1);
    } else if (currentSelectedPackage === AVAILABLE_PACKAGES.ADMIN) {
      setSelectedPackage(2);
    }
  }, [currentSelectedPackage]);

  useEffect(() => {
    if (selectedPackage !== 3) {
      dispatchSetSelectedPackageLocally(
        selectedPackage === 1 ? AVAILABLE_PACKAGES.INDIVIDUAL : AVAILABLE_PACKAGES.ADMIN
      );
    }
  }, [selectedPackage]);

  useEffect(() => {
    dispatchAccountStatus();
  }, []);

  const handlePackageSelect = () => {
    dispatchSetSelectedPackage(
      selectedPackage === 1 ? SUBSCRIPTION_PLAN_TYPES.INDIVIDUAL : SUBSCRIPTION_PLAN_TYPES.ADMIN
    );
  };

  return (
    <PackageDetailsPageContainer>
      <PackageDetailsContainer>
        <PackageDetailsCard
          onClick={() => setSelectedPackage(1)}
          selected={selectedPackage === 1}
          data-testid="package-card-1"
        >
          <PackageDetailsCardHeader />
          <PackageDetailsCardTitle>{translate('spot_for_creators')}</PackageDetailsCardTitle>
          <PackageDetailsCardSubTitle>{translate('independent_producer')}</PackageDetailsCardSubTitle>
          <PackageDetailsCardUserCount>{translate('spot_for_creators_user_count')}</PackageDetailsCardUserCount>
          <DiscountedPrice>
            {/* <PackageDetailsAmount>{translate('spot_for_creators_price')}</PackageDetailsAmount> */}
            <FreeText>{translate('free')}</FreeText> {translate('as_of_now')}
          </DiscountedPrice>
          <PackageDetailsList>
            <PackageDetailsListItem>
              {_.range(6).map((number: number) => (
                <PackageDetailsListItem key={number}>
                  {translate(`spot_for_creators_feature_${number + 1}`)}
                </PackageDetailsListItem>
              ))}
            </PackageDetailsListItem>
          </PackageDetailsList>
        </PackageDetailsCard>

        <PackageDetailsCard
          onClick={() => setSelectedPackage(2)}
          selected={selectedPackage === 2}
          data-testid="package-card-2"
        >
          <PackageDetailsCardHeader />
          <PackageDetailsCardTitle>{translate('spot_for_organisations')}</PackageDetailsCardTitle>
          <PackageDetailsCardSubTitle>{translate('production_house')}</PackageDetailsCardSubTitle>
          <PackageDetailsCardUserCount>{translate('spot_for_organisations_user_count')}</PackageDetailsCardUserCount>
          <DiscountedPrice>
            {/* <PackageDetailsAmount>{translate('spot_for_creators_price')}</PackageDetailsAmount> */}
            <FreeText>{translate('free')}</FreeText> {translate('as_of_now')}
          </DiscountedPrice>
          {/* <PackageDetailsAmountSubInfo>
            {translate('spot_for_organisations_price_per_extra_user')}
          </PackageDetailsAmountSubInfo> */}
          <PackageDetailsList>
            <PackageDetailsListItem>
              {_.range(9).map((number: number) => (
                <PackageDetailsListItem key={number}>
                  {translate(`spot_for_organisations_feature_${number + 1}`)}
                </PackageDetailsListItem>
              ))}
            </PackageDetailsListItem>
          </PackageDetailsList>
        </PackageDetailsCard>
      </PackageDetailsContainer>
      <CouponCodeInputContainer>
        <CouponCodeInput
          placeholder={translate('enter_coupon_code')}
          onChange={(e) => {
            setCoupon(e.target.value);
            dispatchResetCouponCodeValid();
          }}
          data-testid="coupon-code-input"
          value={coupon}
        />
        <CustomButton
          onClick={() => {
            dispatchVerifyCoupon(
              coupon,
              selectedPackage === 1 ? COUPON_BUYER_TYPES.INDIVIDUAL : COUPON_BUYER_TYPES.ADMIN
            );
          }}
          disabled={couponCodeValid || selectedPackage === 3 || coupon.trim() === ''}
          data-testid="apply-coupon-btn"
        >
          <If condition={!couponCodeValid} otherwise={translate('applied')}>
            {translate('apply')}
          </If>
        </CustomButton>
      </CouponCodeInputContainer>
      <CheckboxContainer>
        <CheckboxSubContainer>
          <TermsAndConditionsCheckbox
            checked={agreedWithTerms}
            disabled={!isTermsSeen}
            onChange={() => {
              setAgreedWithTerms(!agreedWithTerms);
            }}
            data-testid="terms-btn"
          >
            {translate('i_agree_to_the')}
          </TermsAndConditionsCheckbox>
          <TermsAndConditionsCheckboxLink
            onClick={() => {
              // setTermsSeenLocal(true);
              // dispatchSetTermsSeen(true);
              history.push(routeConstants.termsAndConditions.route);
            }}
            data-testid="terms-and-conditions-link"
          >
            {translate('terms_and_conditions')}
          </TermsAndConditionsCheckboxLink>
        </CheckboxSubContainer>
        <CheckboxSubContainer>
          <TermsAndConditionsCheckbox
            checked={agreedWithPrivacyPolicyLocal}
            disabled={!isPrivacyPolicySeen}
            onChange={() => {
              setAgreedWithPrivacyPolicyLocal(!agreedWithPrivacyPolicyLocal);
            }}
            data-testid="privacy-btn"
          >
            {translate('i_agree_to_the')}
          </TermsAndConditionsCheckbox>
          <TermsAndConditionsCheckboxLink
            onClick={() => {
              // setIsPrivacyPolicySeen(true);
              setPrivacyPolicyPageVisible(true);
            }}
            data-testid="privacy-policy-link"
          >
            {translate('privacy_policy_link')}
          </TermsAndConditionsCheckboxLink>
        </CheckboxSubContainer>
        <ReminderText>{translate('package_page_reminder')}</ReminderText>
      </CheckboxContainer>

      <ButtonContainer>
        <CustomButton
          data-testid="lets-begin-btn"
          disabled={btnDisabled}
          bg_color={btnDisabled ? colors.btnDisabled : undefined}
          onClick={handlePackageSelect}
          loading={updateSelectedPackageLoading}
        >
          {translate('lets_begin')} <ArrowRightOutlined />
        </CustomButton>
      </ButtonContainer>

      <If condition={privacyPolicyPageVisible}>
        <Portal>
          <PrivatePolicyPage
            handleCancel={() => setPrivacyPolicyPageVisible(false)}
            handleLastPageSeen={() => setIsPrivacyPolicySeen(true)}
          />
        </Portal>
      </If>
    </PackageDetailsPageContainer>
  );
};

export const mapStateToProps = createStructuredSelector({
  updateSelectedPackageLoading: selectUpdateSelectedPackageLoading(),
  currentSelectedPackage: selectCurrentSelectedPackage(),
  agreedToTermsOfService: selectIsTermsChecked(),
  isTermsSeen: selectIsTermsSeen(),
  agreedToPrivacyPolicy: selectIsPrivacyPolicyChecked(),
  privacyPolicySeen: selectIsPrivacyPolicySeen(),
  accountStatusData: selectAccountStatusData(),
  couponCode: selectCouponCode(),
  couponCodeValid: selectCouponCodeValid()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchSetSelectedPackage: (selectedPackage: string) => dispatch(requestUpdateSelectedPackage(selectedPackage)),
    dispatchSetSelectedPackageLocally: (selectedPackage: string) =>
      dispatch(successSetSelectedPackage(selectedPackage)),
    dispatchSetTermsCheckbox: (checked: boolean) => dispatch(setTermsCheckbox(checked)),
    dispatchSetTermsSeen: (checked: boolean) => dispatch(setTermsSeen(checked)),
    dispatchSetPrivacyPolicyCheckbox: (checked: boolean) => dispatch(setPrivacyPolicyCheckBox(checked)),
    dispatchSetPrivacyPolicySeen: (checked: boolean) => dispatch(setPrivacyPolicySeen(checked)),
    dispatchVerifyCoupon: (couponCode: string, buyerType: string) =>
      dispatch(requestVerifyCoupon({ couponCode, buyerType })),
    dispatchResetCouponCodeValid: () => dispatch(resetCouponCodeValid()),
    dispatchAccountStatus: () => dispatch(requestAccountStatus())
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(PackageDetailsPage);

export const PackageDetailsPageTest = PackageDetailsPage;
