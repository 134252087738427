import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import { prepare } from '@app/utils';

export const initialState = {
  addUserToOrganisationLoading: false,
  addUserToOrgnaisationError: null,
  addedUsers: [],
  maxUsersCapacity: 0,
  availableCapacity: 0,
  getAddedUsersLoading: false,
  getAddedUsersError: null,
  generatePaymentLinkLoading: false,
  generatePaymentLinkError: null,
  removeOrgUserLoading: false,
  removeOrgUserError: null
};

const adminDashboardSlice = createSlice({
  name: 'adminDashboard',
  initialState,
  reducers: {
    requestAddUserToOrganisation: {
      reducer: (state) => {
        state.addUserToOrganisationLoading = true;
      },
      prepare
    },
    requestGetAddedUsers: {
      reducer: (state) => {
        state.getAddedUsersLoading = true;
      },
      prepare
    },
    requestGeneratePaymentLink: {
      reducer: (state) => {
        state.generatePaymentLinkLoading = true;
      },
      prepare
    },
    requestRemoveOrganisationUser: {
      reducer: (state) => {
        state.removeOrgUserLoading = true;
      },
      prepare
    },
    successRemoveOrganistaionUser(state) {
      state.removeOrgUserLoading = false;
    },
    failureRemoveOrganisationUser(state, action) {
      state.removeOrgUserLoading = false;
      state.removeOrgUserError = get(action.payload, 'message', 'something_went_wrong');
    },
    successGeneratePaymentLink(state) {
      state.generatePaymentLinkLoading = false;
    },
    failureGeneratePaymentLink(state, action) {
      state.generatePaymentLinkLoading = false;
      state.generatePaymentLinkError = get(action.payload, 'message', 'something_went_wrong');
    },
    successAddUserToOrganisation(state) {
      state.addUserToOrganisationLoading = false;
    },
    failureAddUserToOrganisation(state, action) {
      state.addUserToOrganisationLoading = false;
      state.addUserToOrgnaisationError = get(action.payload, 'message', 'something_went_wrong');
    },
    successGetAddedUsersToOrganisation(state, action) {
      state.getAddedUsersLoading = false;
      state.addedUsers = get(action.payload, 'users', []);
      state.maxUsersCapacity = get(action.payload, 'maxUsersCapacity', 0);
      state.availableCapacity = get(action.payload, 'availableCapacity', 0);
    },
    failureGetAddedUsersToOrganisation(state, action) {
      state.getAddedUsersLoading = false;
      state.getAddedUsersError = get(action.payload, 'message', 'something_went_wrong');
    },
    resetState(state) {
      state.addUserToOrganisationLoading = false;
      state.addUserToOrgnaisationError = null;
      state.addedUsers = [];
      state.getAddedUsersLoading = false;
      state.getAddedUsersError = null;
    }
  }
});

export const {
  requestAddUserToOrganisation,
  successAddUserToOrganisation,
  failureAddUserToOrganisation,
  requestGetAddedUsers,
  successGetAddedUsersToOrganisation,
  failureGetAddedUsersToOrganisation,
  requestGeneratePaymentLink,
  successGeneratePaymentLink,
  failureGeneratePaymentLink,
  requestRemoveOrganisationUser,
  successRemoveOrganistaionUser,
  failureRemoveOrganisationUser,
  resetState
} = adminDashboardSlice.actions;

export default adminDashboardSlice.reducer;
